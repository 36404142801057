import jQuery from 'jquery'
import $ from 'jquery'
var MEMS = { 'settings': {}, 'behaviors': {}, 'locale': {} };

// Allow other JavaScript libraries to use $.
jQuery.noConflict();

MEMS.attachBehaviors = function (context, settings) {
    context = context || document;
    settings = settings || MEMS.settings;
    // Execute all of them.
    $.each(MEMS.behaviors, function () {
        this.attach(context, settings);
    });
};

MEMS.detachBehaviors = function (context, settings, trigger) {
    context = context || document;
    settings = settings || MEMS.settings;
    trigger = trigger || 'unload';
    // Execute all of them.
    $.each(MEMS.behaviors, function () {
        if(this.detach){
            this.detach(context, settings, trigger);
        }
    });
};

export const init = function () {
  // Class indicating that JS is enabled; used for styling purpose.
  $('html').addClass('js');

  // 'js enabled' cookie.
  document.cookie = 'has_js=1; path=/';

  //Attach all behaviors.
  MEMS.attachBehaviors(document, MEMS.settings);
};
//$(document).on("ready", init);
export default MEMS;
<template>
  <div id="page" class="page">
    <div id="sp-menus">
      <div class="block block-menu sp-header-menus">
        <ul class="menu">
          <li class="first last leaf"><router-link to="/mansion/info" id="id_sp_etc_info">お知らせ一覧</router-link></li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-menu sp-main-menus">
        <ul class="menu">
          <li class="first last leaf">
            <router-link to="/mansion/claim" id="id_sp_menu_claim"><span class="menu_icon_claim"></span><span class="menu_title">料金明細一覧</span></router-link>
          </li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-menu sp-sub-menus">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/member/profile"><span class="menu_icon_payment"></span><span class="menu_title">会員情報の確認・変更</span></router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/change/payment"><span class="menu_icon_payer"></span><span class="menu_title">支払方法の変更</span></router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/member/procedure?type=fee_detail"><span class="menu_icon_plan"></span><span class="menu_title">料金明細確認方法の変更</span></router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/member/procedure?type=rate_plan"><span class="menu_icon_profile"></span><span class="menu_title">料金プランの変更</span></router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/member/procedure?type=departure"><span class="menu_icon_departure"></span><span class="menu_title">退去手続き</span></router-link>
          </li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-menu sp-etc-menus" data-bid="532">
        <ul class="" id="">
          <li class="first leaf">
            <router-link to="/mansion/faq_top" id="id_sp_etc_faq"><span class="menu_icon_faq"></span><span class="menu_title">よくあるご質問</span></router-link>
          </li>
          <li class="leaf"><router-link to="/mansion/inquiry_document" id="id_sp_etc_inq">お問い合わせ</router-link></li>
          <li class="last leaf"><router-link to="/mansion/column_list" id="id_sp_etc_column">コラム</router-link></li>
        </ul>
      </div>
      <!-- /block -->
    </div>
  </div>
  <header id="header">
    <div class="container">
      <div style="display: inline-block">
        <router-link to="/mansion/" title="" rel="home" id="logo"><img src="../assets/images/logo.png" alt="" /></router-link>
        <div class="block block-search" data-bid="265">
          <form action="#" method="post" id="search-block-form--2" accept-charset="UTF-8" @submit.prevent="push()">
            <div>
              <div class="container-inline">
                <h2 class="element-invisible">検索フォーム</h2>
                <div class="form-item form-type-textfield form-item-search-block-form">
                  <label class="element-invisible" for="edit-search-block-form--4">検索 </label>
                  <input title="検索したいキーワードを入力してください" type="text" id="edit-search-block-form--4" name="search_block_form" size="15" maxlength="128" class="form-text" v-model="search" />
                </div>
                <div class="form-actions form-wrapper" id="edit-actions--2"><input type="submit" id="edit-submit--2" name="op" value="検索" class="form-submit" /></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <nav id="navigation" class="menu with-primary with-secondary">
        <div class="container">
          <ul id="secondary" class="sub-menu">
            <li class="menu-15 first last"><router-link to="/mansion/user/logout">ログアウト</router-link></li>
          </ul>
        </div>
      </nav>
      <!-- /navigation -->
      <div id="header-region">
        <!-- /block -->
        <div class="block block-views" data-bid="254">
          <div class="view view-last-login-time view-id-last_login_time view-display-id-block view-dom-id-f92d6ee06f08e03eefa5523977ef704e">
            <div class="view-content" v-if="!isFirst">
              <div class="views-row">
                <span class="views-field views-field-login-timestamp">
                  <span class="views-label views-label-login-timestamp"
                    >最終ログイン: {{ last_login_datetime }} &nbsp;<router-link to="/mansion/member/profile"> {{ room.contractor_name }}</router-link> 様</span
                  >
                </span>
              </div>
              <div class="views-row">
                <span class="views-field views-field-login-timestamp">
                  <span class="views-label views-label-timestamp"
                    >表示中のお部屋: [<select id="building" class="header-mansion" v-model="building_id" @change="selectBuilding">
                      <template v-for="b in building" :key="b">
                        <option :value="b.building_id">{{ b.building_name }}</option>
                      </template>
                    </select>
                    <a href="#" @click.prevent.stop="document.querySelector('#building').touchStart()"></a>] &nbsp;<span style="display: inline-block;"> [<select id="room" class="header-mansion" v-model="room_id" @change="selectRoom">
                      <template v-for="r in rooms" :key="r">
                        <option v-if="r.building_id == building_id" :value="r.room_id">{{ r.room_number }}</option>
                      </template>
                    </select>
                    <a href="#" @click.prevent.stop="document.querySelector('#room').touchStart()"></a>]</span>
                    <div class="header-view-button">
                      <div class="container">
                        <ul class="sub-menu">
                          <li><a href="#" @click.prevent.stop="isBuildingOpen = true">表示</a></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                  <div class="header-tooltip" style="top: 80px" v-show="isBuildingOpen">
                    <fieldset>
                      <legend>
                        <span class="fieldset-legend">マンション情報<button type="button" class="btn btn-light btn-sm" @click="isBuildingOpen = !isBuildingOpen">閉じる</button></span>
                      </legend>
                      <div class="fieldset-wrapper">
                        <div class="views-field">
                          <strong class="views-label">マンション名: </strong>
                          <span class="field-content">{{ room.building_name }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">部屋番号: </strong>
                          <span class="field-content">{{ room.room_number }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">郵便番号: </strong>
                          <span class="field-content">{{ room.building_zip_postal_code }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">都道府県: </strong>
                          <span class="field-content">{{ room.building_state }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">市区町村: </strong>
                          <span class="field-content">{{ room.building_city }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">町名: </strong>
                          <span class="field-content">{{ room.building_address_line }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">番地: </strong>
                          <span class="field-content">{{ room.building_address_line_2 }}</span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">部屋種別: </strong>
                          <span class="field-content">
                            <template v-if="room.room_type == 0">未設定</template>
                            <template v-if="room.room_type == 9">未設定</template>
                            <template v-if="room.room_type == null">専有部</template>
                            <template v-if="room.room_type == undefined">専有部</template>
                            <template v-if="room.room_type == 4">専有部</template>
                            <template v-if="room.room_type == 1">共用部</template>
                            <template v-if="room.room_type == 2">店舗</template>
                            <template v-if="room.room_type == 3">店舗共用</template>
                            <template v-if="room.room_type == 5">太陽光</template>
                            <template v-if="room.room_type == 6">EV</template>
                            <template v-if="room.room_type == 7">二次メーター</template>
                            <template v-if="room.room_type == 8">その他</template>
                          </span>
                        </div>
                        <div class="views-field">
                          <strong class="views-label">部屋備考: </strong>
                          <span class="field-content">{{ room.room_note }}</span>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- /block -->
      </div>
    </div>
  </header>
  <div id="highlighted"> 
    <Header_login_info/>
  </div>
  <!-- /header -->
</template>
<script>
import Header_login_info from "../components/Header_login_info.vue";
import $ from "jquery";
import MEMS from "../assets/js/mems.js";
import { loginStore } from "../stores/store.js";
import { isLogin, loadData, removeData, saveData } from "../stores/store.js";
import { init } from "../assets/js/responsive_menus_simple.js";
export default {
  components: {
    Header_login_info,
  },
  name: "Header_login",
  beforeCreate() {
    var path = this.$router.currentRoute._rawValue.path;
    if(!isLogin()) {
      if(path == "/mansion/create/mailaddress" || path == "/mansion/create/password"){
        // 例外
        return;
      }
      removeData("token");
      removeData("x-token");
      removeData("last_login_datetime");
      loginStore().$reset();
      sessionStorage.clear();
      var query = this.$route.query;
      query["destination"] = encodeURIComponent(path);
      var hash = (this.$route.hash != "") ? "#" + this.$route.hash : "";
      this.$router.replace({path: "/mansion/", query : query, hash: hash});
    }
  },
  data(){
    var store = loginStore();
    MEMS.settings = {
      scroll_to_top: { label: "" },
      responsive_menus: [
        {
          toggler_text: "&#x2261;",
          selectors: ["#sp-menus"],
          media_size: "1024",
          media_unit: "px",
          absolute: true,
          remove_attributes: true,
          responsive_menus_style: "responsive_menus_simple",
        },
      ],
      password: {
        addLowerCase: "小文字を追加してください",
        addNumbers: "数字を追加",
        addPunctuation: "記号を追加",
        addUpperCase: "大文字を追加",
        confirmFailure: "いいえ",
        confirmSuccess: "はい",
        confirmTitle: "パスワードの一致：",
        fair: "適正",
        good: "良い",
        hasWeaknesses: "あなたのパスワードをより強固にするために",
        sameAsUsername: "ユーザー名と同じにしないでください",
        strengthTitle: "パスワードの強度：",
        strong: "強い",
        tooShort: "少なくとも8文字以上にしてください",
        weak: "弱い",
        username: store.customer_id
      },
    };
    if(store.code != 0){
        return { isFirst : true}
    }
    var building = [];
    for (var i in store.rooms) {
      if (building.find((b) => b.building_id == store.rooms[i].building_id) == undefined) {
        building.push(store.rooms[i]);
      }
    }
    return {
      isFirst: false,
      room: store.room,
      building_id: store.room.building_id,
      room_id: store.room.room_id,
      building: building,
      rooms: store.rooms,
      last_login_datetime: loadData("last_login_datetime"),
      search: "",
      isBuildingOpen: false,
    };
  },
  watch: {
    $route (newRoute) {
      if (!isLogin()) {
        var query = this.$route.query;
        query["destination"] = encodeURIComponent(newRoute);
        var hash = (this.$route.hash != "") ? "#" + this.$route.hash : "";
        this.$router.replace({path: "/mansion/", query : query, hash : hash});
      }
    }
  },
  methods: {
    push : function(){
      var keyword = this.$data.search.trim();
      this.$data.search = "";
      if(!loadData("last_login_datetime")){
        removeData("token");
        removeData("x-token");
        loginStore().$reset();
        sessionStorage.clear();
      }
      this.$router.push({
        path : "/mansion/search",
        query : {
          keyword : encodeURIComponent(keyword)
        }
      });
    },
    selectBuilding: function (e) {
      var store = loginStore();
      store.room = this.$data.building.find((r) => e.target.value == r.building_id);
      this.$data.room_id = store.room.room_id;
      this.$data.room = store.room;
      store.customer_id = store.room.customer_id;
      MEMS.settings.password.username = store.room.customer_id;
      saveData("id", store.customer_id);
      this.$router.push({ path: "/mansion/" });
    },
    selectRoom: function (e) {
      var store = loginStore();
      store.room = this.$data.rooms.find((r) => r.room_id == e.target.value && r.building_id == this.$data.building_id);
      this.$data.room = store.room;
      this.$data.room_id = store.room.room_id;
      store.customer_id = store.room.customer_id;
      MEMS.settings.password.username = store.room.customer_id;
      saveData("id", store.customer_id);
      this.$router.push({ path: "/mansion/" });
    },
  },
  mounted: function () {
    this.$nextTick(
      function () {
        document.title = document.querySelector(".title").innerText + " | でんき案内板";
        init();
        MEMS.behaviors.scroll_to_top.attach(document, MEMS.settings);
        MEMS.behaviors.responsive_menus.attach(document, MEMS.settings);
        $("body").trigger("responsive-menus-load");
        $(document).trigger("ready");
        $(document).off("ready");
        
      }.bind(this)
    );
  },
};
import "../assets/js/scroll_to_top.js";
</script>

<style scoped>
select::after {
  content: "a";
}
#search-block-form--2 {
  margin: 0;
}
header {
  height: 90px !important;
}
.block-search {
  width: 300px;
}
.views-row {
  margin-top: 0px !important;
}
#header-region {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  #header-region .block-views .views-field-login-timestamp {
    width: 500px !important;
    margin-right: auto !important;
  }
}
#header-region select {
  max-width: none;
}
.views-label-timestamp {
  padding-top: 5px;
}
.header-view-button {
  display: inline-block;
}
.header-view-button ul {
  margin-top: 0px;
  margin-left: 5px;
}
.header-view-button li {
  list-style-type: none;
}
.header-view-button ul li a {
  color: #222;
}
.header-view-button ul li a {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 22px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  padding: 2px 23px;
  text-decoration: none;
  font-weight: bold;
  text-decoration: none;
}
.header-view-button ul li a:hover {
  background-color: rgba(0, 0, 0, 0.4);
  color: #cee4f9;
}
select.header-mansion {
  -webkit-appearance: none;
  appearance: none;
}
select.header-mansion option {
  background: white;
  color: black;
  font-weight: normal;
  border: 1px solid black;
}
.header-tooltip {
  position: absolute;
  border-radius: 0.1em;
  z-index: 10000;
  width: 500px;
  color: black;
  border: 1px solid;
}
@media screen and (max-width: 530px) {
  .header-tooltip {
    width:auto;
    max-width: 500px;
    min-width: 300px;
  }
}
.header-tooltip .fieldset-legend {
  margin-left: 15px;
}
.header-tooltip fieldset {
  margin-bottom: 0px;
}
.header-tooltip .fieldset-wrapper {
  margin-top: 10px;
}
.header-tooltip .views-field {
  margin-left: 15px;
}
.header-tooltip button {
  float:right;
}
@media screen and (max-width: 768px) {
  span.views-label-timestamp {
    padding-top: 0px !important;
    display: contents;
  }
  .header-tooltip {
    margin: 30px 0;
  }
}
</style>

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createPersistedState } from "pinia-plugin-persistedstate"
import App from './App.vue'
import router from './vue-router/index'

// css
import './assets/css/default.css'
import './assets/css/layout.css'
import './assets/css/ckeditor.css'
import './assets/css/common.css'
import './assets/css/comment.css'
import './assets/css/condition.css'
import './assets/css/ctools.css'
import './assets/css/date.css'
import './assets/css/datepicker.1.7.css'
import './assets/css/field.css'
import './assets/css/font-awesome.min.css'
import './assets/css/html.css'
import './assets/css/layout.css'
import './assets/css/logintoboggan.css'
import './assets/css/node_preview.css'
import './assets/css/normalize.css'
import './assets/css/print.css'
import './assets/css/reset.css'
import './assets/css/responsive_menus_simple.css'
import './assets/css/scroll_to_top.css'
import './assets/css/search.css'
import './assets/css/system.base.css'
import './assets/css/system.menus.css'
import './assets/css/system.messages.css'
import './assets/css/system.theme.css'
import './assets/css/tabs.css'
import './assets/css/ua.css'
import './assets/css/user.css'
import './assets/css/views.css'
import './assets/css/workflow_admin_ui.css'
import './assets/css/ennevision_show_password.css'

import { removeData, loginStore} from '../src/stores/store.js'

const app = createApp(App);
app.use(router);
const pinia = createPinia();
pinia.use(createPersistedState())
app.use(pinia);
app.config.errorHandler = (ex) => {
    if(process.env.NODE_ENV != 'serve'){
        if(ex["response"] != undefined && (ex["response"]["status"] == 401 || ex["response"]["status"] == 403)){
            removeData("token");
            removeData("x-token");
            removeData("last_login_datetime");
            loginStore().$reset();
            sessionStorage.clear();
        }else if(ex["response"] != undefined && ex["response"]["status"] == 500 && ex["response"]["config"]["method"] == 'get'){
            var query = router.currentRoute._rawValue.query;
            if(!query["destination"]){
                query["destination"] = router.currentRoute._rawValue.path;
            }
            var hash = (router.currentRoute._rawValue.hash == "") ?  "": "#" + router.router.currentRoute._rawValue.hash;
            router.replace({path : '/mansion/404', query : query, hash : hash});
        }else{
            removeData("token");
            removeData("x-token");
            removeData("last_login_datetime");
            loginStore().$reset();
            sessionStorage.clear();
            router.replace({path : '/mansion/error'});
        }
    }
}
app.mount('#app');
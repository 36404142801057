<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <template v-if="isLogin">
    <div class="html not-front logged-in no-sidebars page-user page-user-password responsive-menus-load-processed">
      <Header_login />
      <!-- ______________________ MAIN _______________________ -->
      <div id="main">
        <div class="container">
          <h2 class="element-invisible">現在地</h2>
          <div class="breadcrumb">
            <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">パスワードリセット</span>
          </div>
          <section id="content">
            <h1 class="title">パスワードリセット</h1>
            <div class="block block-system" data-bid="255">
              <div class="enne_help"><router-link to="/mansion/faq/oshietepw" target="_blank">FAQ</router-link></div>
            </div>
            <div id="content-area">
              <form action="#" method="post" id="user-pass" accept-charset="UTF-8" @submit.prevent="push()">
                <div>
                  <p>お客様がでんき案内板に登録しているメールアドレス<em class="placeholder">{{ PortalEmail }}</em> あてにパスワードリセットのメールをお送りします。<br />パスワードをリセットするには、ログアウトする必要があります。</p>
                  <div class="form-actions form-wrapper" id="edit-actions"><input type="submit" id="edit-submit" name="op" value="パスワードリセットメール送信" class="form-submit" :disabled="send"/></div>
                  <div class="user_pass_overview">※パスワードを変更する場合は、「<router-link to="/mansion/password/setting">パスワードの変更</router-link>」より手続きください。</div>
                </div>
              </form>
            </div>
          </section>
          <!-- /content-inner /content -->
          <div v-show="false">
            <Sidebar_first_login/>
          </div>
        </div>
      </div>
      <!-- /main -->
      <Footer />
    </div>
  </template>
  <template v-else>
    <div class="html not-front not-logged-in no-sidebars page-user page-user-password responsive-menus-load-processed">
      <Header />
      <div class="messages error" v-if="error == 5">
        <h2 class="element-invisible">エラーメッセージ</h2>
        入力されたメールアドレスが複数存在します。恐れ入りますが、ログインIDをご入力ください。
      </div>
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
        <!-- /block -->
      </div>
      <!-- ______________________ MAIN _______________________ -->
      <div id="main">
        <div class="container">
          <h2 class="element-invisible">現在地</h2>
          <div class="breadcrumb">
            <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">ID・パスワードをお忘れの方へ</span>
          </div>
          <section id="content">
            <!-- <div id="content-header"> -->

            <h1 class="title">ID・パスワードをお忘れの方へ</h1>
            <div class="block block-system" data-bid="255">
              <div class="enne_help"><router-link to="/mansion/faq/824" target="_blank">FAQ</router-link></div>
            </div>
            <!-- /block -->
            <!-- </div> /#content-header -->
            <div id="content-area">
              <form action="#" method="post" id="user-pass" accept-charset="UTF-8" @submit.prevent="push()">
                <div>
                  ◆ログインIDについて<br />ログインIDと初期パスワードは、メールやハガキでお知らせしています。<br />ログインIDは、お客様の部屋ごとに数字12桁で設定しています。<br />お客様がでんき案内板に登録しているポータルサイトメールアドレスでもログインすることができます。<br />※ただし、複数のお部屋で同じポータルサイトメールアドレスを登録している場合は、ポータルサイトメールアドレスをログインIDとして使用できません。<br /><br />◆パスワードについて<br />パスワードは、お客様がご自身で設定したパスワードになります。<br />忘れてしまった場合は、でんき案内板に登録しているポータルサイトメールアドレスまたはログインIDを入力し、「パスワード再発行メールを送信」をクリックしてください。<br />パスワード再発行のメールをお送りします。<br /><br />
                  <div class="form-item form-type-textfield form-item-name">
                    <label for="edit-name">ポータルサイトメールアドレスまたはログインID <span class="form-required" title="これは入力必須項目です。">*</span></label>
                    <input required type="text" id="edit-name" name="name" size="60" maxlength="256" class="form-text required"  v-model="PortalEmail"/>
                  </div>
                  <div class="form-actions form-wrapper" id="edit-actions"><input type="submit" id="edit-submit" name="op" value="パスワード再発行メールを送信" class="form-submit" @click="check" :disabled="send"/></div>
                  <div class="user_pass_overview">
                    ※登録したポータルサイトメールアドレスを忘れた場合またはポータルサイトメールアドレスが使用できなくなっている場合は、<router-link to="/mansion/inquiry_document">お問い合わせフォーム</router-link>よりご連絡ください。 <br /><br />
                    ＜複数のお部屋をご契約のお客様へ＞　※共用部・店舗のお客様も含みます<br />　ポータルサイトメールアドレスまたはログインID欄には、12桁のログインIDを入力してください。
                    <br />
                    　※複数のお部屋に同じポータルサイトメールアドレスを登録している場合は、ポータルサイトメールアドレスを入力してもエラーとなります。
                  </div>
                </div>
              </form>
            </div>
          </section>
          <!-- /content-inner /content -->
        </div>
      </div>
      <!-- /main -->
      <Footer />
    </div>
  </template>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Header_info from "../components/Header_info.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Sidebar_first_login from "@/components/Sidebar_first_login.vue";
import config from "../config.js";
import { mainMessage, removeData } from "../stores/store.js";
import { getData, post } from "../assets/js/request.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Footer,
    Sidebar_first_login
},
  data() {
    var param = {}
    param["isLogin"] =  isLogin();
    param["PortalEmail"] = "";
    param["send"] = false;
    param["error"] = 0;
    return param;
  },
  async created(){
    if(isLogin()){
      var result = await getData(config.url.get.customer);
      // { PortalEmail }
      var param = {}
      param["PortalEmail"] = result.PortalEmail;
      param["isLogin"] =  true;
      Object.assign(this.$data, param);
    }
  },
  methods : {
    check() {
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var email = document.querySelector("[required]");
      if (email.validity.valueMissing) {
        email.setCustomValidity("これは入力必須項目です。");
      }
    },
    push : async function(){
      this.$data.send = true;
      var mail = this.$data.PortalEmail;
      try{
        await post(config.url.post.password_mail,{
          mailAddress: mail
        })
        mainMessage().green = this.$data.isLogin ? 1 : 2;
        removeData("token");
        removeData("x-token");
        removeData("last_login_datetime");
        sessionStorage.clear();
        this.$router.push({path: "/mansion/"});
      }catch(ex){
        if(!isLogin() && ex.response && ex.response.data.returnCode == 5){
          this.$data.error = 5;
          this.$data.send = false;
        }
        else{
          throw ex;
        }
      }
    }
  }
};
</script>
<style scoped src="../assets/css/webform.css" />
<style scoped>
#content-area input[type="text"]{
  width: auto;
}
</style>
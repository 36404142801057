<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-faq-top-list responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/faq_top">よくあるご質問</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">よくあるご質問一覧</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->

          <h1 class="title">よくあるご質問一覧</h1>

          <!-- </div> /#content-header -->

          <div id="content-area">
            <div class="view view-faq-list view-id-faq_list">
              <div class="view-header">
                <h2>{{ category[$route.params.id] }}</h2>
              </div>

              <div class="view-content">
                <table class="views-view-grid cols-1">
                  <tbody>
                    <template v-if="categoryResult.length != 0">
                    <template v-for="f in categoryResult[current- 1]" :key="f">
                      <tr class="row">
                        <td class="col-1 col-first">
                          <div class="views-field views-field-php">
                            <span class="field-content">
                              <fieldset :class="'collapsible form-wrapper collapse-processed' + (f.IsOpen ? '': ' collapsed')">
                                <legend>
                                  <span class="fieldset-legend">
                                    <a class="fieldset-title" href="#" @click.prevent.stop="f.IsOpen = !f.IsOpen">
                                      <span class="fieldset-legend-prefix element-invisible">表示</span>
                                      {{ f.Title }}
                                    </a>
                                    <span class="summary"></span>
                                  </span>
                                </legend>
                                <div class="fieldset-wrapper" v-show="f.IsOpen">
                                  <div v-html="f.Body" class="nid" ></div>
                                </div>
                              </fieldset>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                  </tbody>
                </table>
                <template v-if="pages.length > 1">
                  <h2 class="element-invisible">ページ</h2>
                  <div class="item-list">
                    <ul class="pager">
                      <template v-for="(p, index) in createPage()" :key="p">
                        <template v-if="index == 0 && 1 != current">
                          <li class="page-item">
                            <a href="#" @click="changePage(0)"> ≪ </a>
                          </li>
                          <li class="page-item">
                            <a href="#" @click="changePage((current - 2) * num)"> &lt; </a>
                          </li>
                        </template>
                        <template v-if="p + 1 == current">
                          <li class="pager-current">
                            {{ p + 1 }}
                          </li>
                        </template>
                        <template v-else>
                          <li class="page-item">
                            <a href="#" @click="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                          </li>
                        </template>
                          <template v-if="(index < 10 ? index == Math.ceil(pages.length / num) - 1 : index == 10) && current != Math.ceil(pages.length / num)">
                          <li class="page-item">
                            <a href="#" @click="changePage(current * num)"> &gt; </a>
                          </li>
                          <li class="page-item">
                            <a href="#" @click="changePage((Math.ceil(pages.length / num) - 1) * num)"> ≫ </a>
                          </li>
                        </template>
                      </template>
                    </ul>
                  </div>
                </template>
              </div>
              <div class="view-footer">
                <div class="return-button">
                  <div class="button">
                    <router-link to="/mansion/faq_top">　戻　る　</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Header_info from '../components/Header_info.vue';
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from '../components/Sidebar_first_login.vue';
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return {
      isLogin: isLogin(),
      current: 1,
      num: 1,
      category: [],
      categoryResult: [],
      pages: [],
    };
  },
  async created() {
    var category = [
      "燃料費調整額の変更について",
      "電気の契約・手続きについて",
      "でんき案内板のログインについて",
      "料金プラン（契約容量・アンペア数）について",
      "電気料金の支払について",
      "料金明細・請求書について",
      "停電・故障・法定点検について",
      "退去について",
      "部屋のオーナー・仲介会社・リフォーム会社等の手続きについて",
      "節電ポイントサービス（DR）について",
      "電気以外の手続きについて",
      "その他サービス等について",
      "共用部・店舗のお客様の「よくあるご質問」はこちら",
      "NTTアノードエナジーへの事業承継について",
    ];
    var categoryResult = await getData(config.url.get.faq_list + "?type=category&category=" + this.$route.params.id);
    // [{ FaqID, Title, Body, Tag }]
    var pages = [];
    var td = new Array();
    pages.push(td);
    for (var info in categoryResult) {
        categoryResult[info]["isOpen"] = false;
        if (td.length == 30) {
          td = new Array();
          pages.push(td);
        }
        td.push(categoryResult[info]);
    }
    Object.assign(this.$data, {
      pages: pages,
      categoryResult: pages,
      num : 1,
      current: 1
    });
    this.$data.category = category;
  },
  methods : {
    createPage: function () {
      var side = 5;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }
      return pangeNum;
    },
    changePage: function (index) {
      this.$data.current = index / this.$data.num + 1;
    },
  }
};
</script>
<style scoped>
fieldset legend span a:link {
  color: #36c;;
}
</style>

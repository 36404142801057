import { saveAs } from "file-saver";
import { loginStore, loadData } from "../../stores/store.js";
import axios from "axios";
export const getData = async function (url) {
  var login = loginStore();
  var token = loadData("token");
  var xtoken = loadData("x-token");
  var id_header = null;
  if(login.customer_id){
    id_header = login.customer_id;
  }
  var response = await axios.get(
    url,
    {
      headers: {
        Authorization: token,
        Token: xtoken,
        Id: id_header,
        "User-Agent": window.navigator.userAgent
      },
    }
  );
  return response.data;
};
export const downloadFile = async function (url, file) {
  var login = loginStore();
  var token = loadData("token");
  var xtoken = loadData("x-token");
  var id_header = null;
  if(login.customer_id){
    id_header = login.customer_id;
  }
  var response = await axios.get(
    url,
      {
        headers: {
          Authorization: token,
          Token: xtoken,
          Id: id_header,
          "User-Agent": window.navigator.userAgent
        },
        responseType: "blob",
      }
  );
  const blob = new Blob([response.data], {
    type: response.data.type,
  });
  saveAs(blob, file);
};

export const post = async function (url, param, newToken) {
  var login = loginStore();
  var token = loadData("token");
  var xtoken = loadData("x-token");
  param["customer_id"] = login.customer_id;
  var response = await axios.post(url, param, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: token,
      Token: xtoken,
      Id: login.customer_id,
      NewToken: newToken,
      "User-Agent": window.navigator.userAgent
    }
  });
  return response.data;
};
export const put = async function (url, param) {
  var login = loginStore();
  var token = loadData("token");
  var xtoken = loadData("x-token");
  param["customer_id"] = login.customer_id;
  var response = await axios.put(url, param, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: token,
      Id: login.customer_id,
      Token: xtoken,
      "User-Agent": window.navigator.userAgent
    }
  });
  return response.data;
};

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">確認画面</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->

          <h1 class="title">確認画面</h1>

          <!-- </div> /#content-header -->

          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>

              <div class="content">
                <form class="webform-client-form webform-client-form-82 preview" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="messages warning"><p>変更内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>

                    <fieldset class="webform-component-fieldset webform-component--webform-group-payer-info form-wrapper">
                      <legend><span class="fieldset-legend">お支払者様情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-tel-2 webform-container-inline">
                          <label>日中ご連絡のつく電話番号（携帯電話等） </label>
                          <span class="field-prefix"></span> {{ update.payer_phone}} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-mobile-phone-2 webform-container-inline">
                          <label>上記以外の連絡先（ご自宅の電話番号等） </label>
                          <span class="field-prefix"></span><template v-if="update.delete1">削除</template>
                          <template v-else>{{ update.payer_tel }}</template><span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--delete-payer-mobile-phone-2">
                          <label class="element-invisible">携帯番号削除キー </label>
                        </div>
                      </div>
                    </fieldset>

                    <div class="form-item form-type-password form-item-auth-pass">
                      <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                      <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password"/>
                      <div class="description">パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link></div>
                    </div>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" @click="check" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { payerTelStore } from "../stores/store.js";
import { post } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = payerTelStore();
    if(Object.keys(store.update).length == 0){
      this.$router.push({
        path: '/mansion/member/procedure',
          query: {
            type : "payer_tel"
          }
      });
    }
    var now = store.now;
    var update = store.update;
    store.$reset();
    return {
      auth_error: false,
      send: false,
      now: now,
      update: update,
      password: ""
    };
  },
  methods: {
    back : function(){
      var store = payerTelStore();
      store.update = this.$data.update;
      store.now = this.$data.now;
      this.$router.push({
        path: '/mansion/member/procedure',
        query: {
          type : "payer_tel"
        }
      }) 
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push: async function () {
      var regist = {}
      var now = this.$data.now;
      var update = this.$data.update;
      regist["payer_name"] = now["Name"];
      regist["payer_kana"] = now["NameKana"];
      regist["payer_relationship"] = now["Relationship"];
      regist["payer_relationship_etc"] = now["RelationshipEtc"];
      regist["payer_zip_postal_code"] = now["ZipPostalCode"];
      regist["payer_birthday"] = now["Birthday"];
      regist["payer_state"] = now["State"];
      regist["payer_city"] = now["City"];
      regist["payer_address_line"] = now["Line"];
      regist["payer_address_line_2"] = now["Line2"];
      regist["payer_address_other"] = now["LineOther"];
      regist["payer_tel"] = now["TelSub"];
      regist["payer_phone"] = now["Phone"];
      regist["payer_email"] = now["Email"];
      regist["contractor_same_flag"] = now["ContractorSameFlag"];
      if(update["payer_phone"].trim() != ""){
        regist["payer_phone"] = update["payer_phone"].trim();
      }
      if(update["payer_tel"].trim() != ""){
        regist["payer_tel"] = update["payer_tel"].trim();
      }
      var delete3 = new Array();
      if(update.delete1){
        regist["payer_tel"] = "";
        delete3.push(3);
      }
      try{
        this.$data.auth_error = false;
        this.$data.send = true;
        await post(config.url.post.payer, {
            payer : regist,
            password : this.$data.password,
            delete : delete3,
            option : 1
        });
        this.$router.push({
          path: '/mansion/member/procedure',
          query: {
            type : "payer_tel_complete"
          }
        });
        var store = payerTelStore();
        store.now = this.$data.now;
        store.update = this.$data.update;
      }catch(ex){
        if(ex.response.data == "PasswordError"){
          this.$data.send = false;
          this.$data.auth_error = true;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
      return false;
    },
  },
};
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

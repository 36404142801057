<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-info responsive-menus-load-processed">
  <Header_login />
  <!-- ______________________ MAIN _______________________ -->
  <div id="main">
    <div class="container">
      <h2 class="element-invisible">現在地</h2>
      <div class="breadcrumb">
        <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">お知らせ一覧</span>
      </div>
      <section id="content">
        <h1 class="title">お知らせ一覧</h1>
        <div id="content-area">
          <div class="view view-info view-id-info view-display-id-page view-dom-id-760edabb73142f72ecc112b39e7bdfb2">
            <div class="view-content">
              <table class="views-table cols-4">
                <thead>
                  <tr>
                    <th class="views-field views-field-created" scope="col">公開日</th>
                    <th class="views-field views-field-field-category" scope="col">カテゴリ</th>
                    <th class="views-field views-field-title" scope="col"></th>
                    <th class="views-field views-field-body" scope="col">本文(概要)</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="info in info_list[current - 1]" :key="info">
                    <tr class="odd">
                      <td class="views-field views-field-created">{{ info.ReleaseDate }}</td>
                      <td class="views-field views-field-field-category">
                        <span>{{info.Category}}</span>
                      </td>
                      <td class="views-field views-field-title">
                        <div><router-link :to="'/mansion/info/' + info.Id">{{ info.Title }}</router-link></div>
                      </td>
                      <td class="views-field views-field-body">
                        <div>{{ info.BodySummary }}</div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <h2 class="element-invisible">ページ</h2>
            <div class="item-list">
                <ul class="pager">
                  <template v-for="(p, index) in createPage()" :key="p">
                    <template v-if="index == 0 && 1 != current">
                      <li class="page-item">
                        <a href="#" @click="changePage(0)"> ≪ </a>
                      </li>
                      <li class="page-item">
                        <a href="#" @click="changePage((current - 2) * num)"> &lt; </a>
                      </li>
                    </template>
                    <template v-if="p + 1 == current">
                      <li class="pager-current">
                        {{ p + 1 }}
                      </li>
                    </template>
                    <template v-else>
                      <li class="page-item">
                        <a href="#" @click="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                      </li>
                    </template>
                      <template v-if="(index < 10 ? index == Math.ceil(pages.length / num) - 1 : index == 10) && current != Math.ceil(pages.length / num)">
                      <li class="page-item">
                        <a href="#" @click="changePage(current * num)"> &gt; </a>
                      </li>
                      <li class="page-item">
                        <a href="#" @click="changePage((Math.ceil(pages.length / num) - 1) * num)"> ≫ </a>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </section>
      <!-- /content-inner /content -->
      <Sidebar_first_login />
    </div>
  </div>
  <!-- /main -->
  <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import $ from "jquery";
import { getData } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return {
      current: 1,
      num: 1,
      pages: [],
      info_list: []
    };
  },
  async created() {
    var pages = new Array();
    var result = await getData(config.url.get.info_list);
    // [ {Id, Title, Category, CreateDate, BodySummary} ]
    for (var content in result) {
      var date = new Date(result[content].CreateDate);
      result[content].ReleaseDate = date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日";
    }
    var td = new Array();
    pages.push(td);
    for (var info in result) {
      if (td.length == 30) {
        td = new Array();
        pages.push(td);
      }
      result[info].BodySummary = $("<div>" + result[info].BodySummary + "</div>").text();
      td.push(result[info]);
    }
    Object.assign(this.$data, {
      pages: pages,
      info_list: pages
    });
  },
  methods: {
    createPage: function () {
      var side = 5;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }

      return pangeNum;
    },
    changePage: function (index) {
      this.$data.current = index / this.$data.num + 1;  
    },
  },
};
</script>
<style scoped>
.page-info #main table tr td.views-field-body div {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    min-width: 150px;
}
.page-info #main table tr td.views-field-title div {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    min-width: 150px;
}
</style>
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-member page-member-procedure page-member-procedure-completefee-detail responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">料金明細確認方法の変更手続き</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">料金明細確認方法の変更手続き</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq_category_list/5" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_9 view-dom-id-d2589db0e2e84250cc7d2c9dd152ae86">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset class="other views-fieldset" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend">料金明細確認方法の変更手続きを受け付けました。</span>
                    </legend>
                    <div class="fieldset-wrapper">
                      <div class="views-field views-field-value-26"><strong class="views-label views-label-value-26">料金明細書: </strong>
                        <span class="field-content" v-if="DetailFlag == 0">Web明細に変更する</span>
                        <span class="field-content" v-else>紙明細（有料）に変更する</span>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <p>
                  変更のお手続きが完了した翌月から、お選びいただいた料金明細確認方法に切り替わります。<br />
                  ※変更のお手続きには、 １ヶ月程度かかる場合がございます。
                </p>
                <p>
                  ＜WEB明細に変更の場合＞<br />
                  　変更手続き完了後、「電気料金明細サービス」のお客様番号と初期パスワードが郵送されますので、<br />
                  　案内に従い、サービスをご利用ください。
                </p>
                <div id="top_link"><router-link to="/mansion/member/profile">会員情報ページへ</router-link></div>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { feedetailStore } from "../stores/store.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = feedetailStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push("/mansion/");
    } else {
      var update = store.update;
      store.$reset();
      return update;
    }
  }
};
import "../assets/js/webform.js";
</script>

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-faq-top-search responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div class="messages error" v-if="!isLogin && error">
        <h2 class="element-invisible">エラーメッセージ</h2>
        検索キーワードを入力して下さい。
      </div>
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/faq_top">よくあるご質問</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">よくあるご質問検索結果</span>
        </div>
        <div class="messages error" v-if="isLogin && error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          検索キーワードを入力して下さい。
        </div>
        <section id="content">
          <h1 class="title">よくあるご質問検索結果</h1>
          <div id="content-area">
            <div class="block block-views" data-bid="353">
              <form action="#" method="get" id="views-exposed-form-faq-search-page-2" accept-charset="UTF-8" @submit.prevent="$router.push({ path: '/mansion/faq_search', query: { title: encodeURIComponent(keyword.trim()), _ : new Date().getTime() } })">
                <div>
                  <div class="views-exposed-form">
                    <div class="views-exposed-widgets clearfix">
                      <div id="edit-title-wrapper" class="views-exposed-widget views-widget-filter-title">
                        <div class="views-widget">
                          <div class="form-item form-type-textfield form-item-title">
                            <input placeholder="いずれかのキーワードを含む検索" type="text" id="edit-title" name="title" size="55" maxlength="128" class="form-text" v-model="keyword" />
                          </div>
                        </div>
                      </div>
                      <div class="views-exposed-widget views-submit-button">
                        <input type="submit" id="edit-submit-faq-search" name="" value="検索" class="form-submit" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- /block -->
            <template v-if="categoryResult[0].length > 0">
              <div class="view view-faq-search view-id-faq_search view-display-id-page_2">
                <div class="view-content">
                  <table class="views-view-grid cols-1">
                    <tbody>
                      <template v-for="f in categoryResult[current - 1]" :key="f">
                        <tr class="row">
                          <td class="col-1 col-first">
                            <div class="views-field views-field-php">
                              <span class="field-content">
                                <fieldset :class="'collapsible form-wrapper collapse-processed' + (f.IsOpen ? '' : ' collapsed')">
                                  <legend>
                                    <span class="fieldset-legend">
                                      <a href="#" class="fieldset-title" @click.prevent.stop="f.IsOpen = !f.IsOpen">
                                        <span class="fieldset-legend-prefix element-invisible">表示</span>
                                        {{ f.Title }}
                                      </a>
                                      <span class="summary"></span>
                                    </span>
                                  </legend>
                                  <div class="fieldset-wrapper" v-show="f.IsOpen">
                                    <div v-html="f.Body" class="nid"></div>
                                  </div>
                                </fieldset>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <h2 class="element-invisible">ページ</h2>
              <div class="item-list">
                  <ul class="pager">
                    <template v-for="(p, index) in createPage()" :key="p">
                      <template v-if="index == 0 && 1 != current">
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage(0)"> ≪ </a>
                        </li>
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage((current - 2) * num)"> &lt; </a>
                        </li>
                      </template>
                      <template v-if="p + 1 == current">
                        <li class="pager-current">
                          {{ p + 1 }}
                        </li>
                      </template>
                      <template v-else>
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                        </li>
                      </template>
                        <template v-if="(index < 10 ? index == Math.ceil(pages.length / num) - 1 : index == 10) && current != Math.ceil(pages.length / num)">
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage(current * num)"> &gt; </a>
                        </li>
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage((Math.ceil(pages.length / num) - 1) * num)"> ≫ </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
            </template>
            <template v-else>
              <template v-if="!error">
                <div class="view view-faq-search view-id-faq_search view-display-id-page_2">
                  <div class="view-empty">該当するFAQが存在しません。<br />検索条件を確認の上、再度検索を行ってください。</div>
                </div>
              </template>
            </template>
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import "../assets/js/mems_faq_searchkey_half_space.js";
import { isLogin } from "../stores/store.js";
import $ from "jquery";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var query = decodeURIComponent(this.$route.query["title"] || "");
    return {
      current: 1,
      num: 1,
      error: false,
      isLogin: isLogin(),
      categoryResult: [[]],
      pages: [],
      keyword: query,
    };
  },
  async created() {
    this.view();
  },
  watch: {
    $route: function () {
      this.view();
    },
  },
  methods: {
    view: async function () {
      if(this.$route.query.title == ""){
         this.$data.error = true;
         this.$data.categoryResult = [[]];
         this.$data.pages = [];
         this.$data.current = 1;
         return;
      }
      this.$data.error = false;
      var result = await getData(config.url.get.faq_list + "?keyword=" + this.$route.query.title);
      // { FaqID, Title, Body, Tag }
      var pages = [];
      var td = new Array();
      pages.push(td);
      var keyword = decodeURIComponent(this.$route.query.title);
      keyword.replace(/\s/g," ");
      var split = keyword.split(" ");
      split = split.filter(x => x != "");
      for (var info in result) {
        var body = $("<div>" + result[info].Body + "</div>").text();
        if(split.filter(x => body.indexOf(x) != -1 || result[info].Title.indexOf(x) != -1).length == 0){
          // 一つも当たらないものがあれば、タグに当たっている
          continue;
        }
        result[info]["isOpen"] = false;
        if (td.length == 30) {
          td = new Array();
          pages.push(td);
        }
        td.push(result[info]);
      }
      this.$data.categoryResult = pages;
      this.$data.pages = pages;
      this.$data.current = 1;
    },
    createPage: function () {
      var side = 5;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }

      return pangeNum;
    },
    changePage: function (index) {
      this.$data.current = index / this.$data.num + 1;
      document.body.scrollIntoView();
    },
  },
};
</script>

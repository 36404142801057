<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-portalmail page-portalmail-setting responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
            »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">
            »</span> <span class="inline odd last">メールアドレスの変更</span>
        </div>
        <div class="messages status" v-if="update.green == 1">
          <h2 class="element-invisible">ステータスメッセージ</h2>
          ご登録のメールアドレス宛に手続きに関するご案内を送信しました。メールに記載のURLにアクセスし手続きを完了してください。
        </div>
        <div class="messages status" v-if="update.green == 2">
          <h2 class="element-invisible">ステータスメッセージ</h2>
          メールアドレス変更時のリンクをご登録されたメールアドレス当てに送信しました。
        </div>
        <div class="messages status" v-if="update.green == 3">
          <h2 class="element-invisible">ステータスメッセージ</h2>
          変更内容を保存しました。
        </div>
        <div class="messages error" v-if="update.green == -1">
          <h2 class="element-invisible">ステータスメッセージ</h2>
          メールの設定変更に失敗しました。再度メールの設定を変更してください。
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">メールアドレスの変更</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq/oshietemailsettei" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <form enctype="multipart/form-data" action="#" method="post" id="ennevision-member-portalmail-form" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div>お客様の現在のメールアドレス・通知設定を表示しています。<br />メールアドレスを変更する場合は、新しいメールアドレスに書き換え、「変更」をクリックしてください。</div>
                <div id="edit-account" class="form-wrapper">
                  <div class="form-item form-type-textfield form-item-mail">
                    <label for="edit-mail">ポータルサイトメールアドレス <span class="form-required" title="これは入力必須項目です。">*</span></label>
                    <input required type="email" id="edit-mail" name="mail" size="60" maxlength="254" class="form-text required" v-model="update.PortalEmail" />
                  </div>
                </div>
                <div class="form-item form-type-textfield form-item-field-contracts-sub-email">
                  <label for="edit-field-contracts-sub-email">サブメールアドレス（任意） </label>
                  <input type="email" id="edit-field-contracts-sub-email" name="field_contracts_sub_email" size="60" maxlength="255" class="form-text" v-model="update.PortalSubEmail" />
                </div>
                <div class="description">
                  毎月の電気料金のお知らせメールなど、でんき案内板からのお知らせをお送りするアドレスです。<br />
                  ※ポータルサイトメールアドレスはそのままでんき案内板のログインＩＤとなります
                </div>
                <div class="form-item form-type-checkboxes form-item-field-contracts-notice">
                  <label for="edit-field-contracts-notice">お知らせメール通知設定 </label>
                  <div id="edit-field-contracts-notice" class="form-checkboxes">
                    <div class="form-item form-type-checkbox form-item-field-contracts-notice-3">
                      <input type="checkbox" id="edit-field-contracts-notice-3" name="field_contracts_notice[3]" class="form-checkbox" v-model="update.ValidFlag"/>
                      <label class="option" for="edit-field-contracts-notice-3">送信する </label>
                    </div>
                  </div>
                  <div class="description">でんき案内板の「お知らせ」欄に新着があった時などにお知らせメールが届くようにしたい場合は、チェックボックスにチェックを入れてください。</div>
                </div>
                <div class="description mail_diff_notice">ポータルサイトメールアドレスとサブメールアドレスの違いについては<router-link to="/mansion/faq/oshietemailsettei" target="_blank">こちら</router-link></div>
                <input type="submit" id="edit-confirm" name="confirm" value="変更" class="form-submit" @click="check" :disabled="send"/>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Footer from "../components/Footer.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue"
import config from "../config.js";
import { mailStore } from "../stores/store.js"
import { getData, put } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Footer,
    Sidebar_first_login,
  },
  data(){
    var store = mailStore();
    var update = {
      PortalEmail : "",
      PortalSubEmail : "",
      ValidFlag : 0,
      green : store.update.green,
      send : false
    }
    return {
      update: update
    };
  },
  async created() {
    var store = mailStore();
    if(this.$route.query.token){
      // パスワード変更メールから遷移
      try{
        store.$reset();
        var response = await put(config.url.put.mail, {
          parameter: this.$route.query.token
        });
        // { PortalEmail, PortalSubEmail, mail_setting2 }
        response.ValidFlag = response.mail_setting2 ? true : false;
        this.$data.update.green = 3;
        this.$data.update.ValidFlag = response.ValidFlag;
        this.$data.update.PortalEmail = response.portal_email;
        this.$data.update.PortalSubEmail = response.portal_sub_email;
        return;
      }catch(ex){
        this.$data.update.green = -1;
      }
    }
    if(Object.keys(store.update).length != 0 && store.update.green != 1){
      // 戻る
      var update = store.update;
      store.$reset();
      Object.assign(this.$data.update, update);
    }
    if(Object.keys(store.update).length == 0 || store.update.green == 1){
      // 通常遷移
      store.$reset();
      var result = await getData(config.url.get.customer);
      // { PortalEmail, PortalSubEmail, mail_setting2 }
      result.ValidFlag = result.mail_setting2 ? true : false;
      Object.assign(this.$data.update, result); 
    }
  },
  methods : {
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var email1 = document.querySelector("#edit-mail");
      if (email1.validity.valueMissing) {
        email1.setCustomValidity("ポータルサイトメールアドレス は必須です。");
      }
      var splited1 = this.$data.update.PortalEmail.split("@");
      if (email1.validity.typeMismatch){
        email1.setCustomValidity("メールアドレス " + this.$data.update.PortalEmail + " が有効ではありません。");
      }else if (splited1.length == 2 && (splited1[1].length == 0 || splited1[1].length == splited1[1].replace(".", "").length)) {
        email1.setCustomValidity("メールアドレス " + this.$data.update.PortalEmail + " が有効ではありません。");
      }else if(splited1[0].length > 64) {
        email1.setCustomValidity("メールアドレス " + this.$data.update.PortalEmail + " が有効ではありません。");
      }
      var email2 = document.querySelector("#edit-field-contracts-sub-email");
      var splited2 = this.$data.update.PortalSubEmail.split("@");
      if (email2.validity.typeMismatch){
        email2.setCustomValidity("メールアドレス " + this.$data.update.PortalSubEmail + " が有効ではありません。");
      }else if (splited2.length == 2 && (splited2[1].length == 0 || splited2[1].length == splited2[1].replace(".", "").length)) {
        email2.setCustomValidity("メールアドレス " + this.$data.update.PortalSubEmail + " が有効ではありません。");
      }else if(splited2[0].length > 64) {
        email2.setCustomValidity("メールアドレス " + this.$data.update.PortalSubEmail + " が有効ではありません。");
      }
    },
    push : function(){
      this.$data.send = true;
      var store = mailStore();
      store.update = this.$data.update;
      this.$router.push({ path: "/mansion/portalmail/setting_confirm" });
    }
  }
};
</script>

<style scoped src="../assets/css/webform.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front not-logged-in no-sidebars page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed" v-show="!loading">
    <Header />
    <div class="messages error" v-if="search_error">
      <h2 class="element-invisible">エラーメッセージ</h2>
      郵便番号検索に失敗しました。
    </div>
    <div id="highlighted">
      <Header_info />
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/entry">レジル株式会社の「マンション電力提供サービス」へようこそ</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">電気の新規利用申込み</span>
        </div>
        <section id="content">
          <h1 class="title">電気の新規利用申込み</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq_top" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 webform-conditional-processed" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="form-item webform-component webform-component-hidden webform-component--url" style="display: none"></div>
                    <div class="form-item webform-component webform-component-hidden webform-component--req-id" style="display: none"></div>
                    <div class="form-item webform-component webform-component-markup webform-component--new-entry-description webform-conditional-hidden">
                      本申込みは、レジル株式会社が提供する「マンション電力提供サービス」の利用申込みです。<br />
                      電気をご利用になる際は、あらかじめ当社が提供する「マンション電力提供サービス」（以下「電力提供サービス」といいます。）における<router-link to="user_clause" target="_blank">電力提供サービス利用契約約款（以下「約款」といいます。）および料金メニュー表等</router-link>を承認のうえ、本申込ページにより専有部分用にかかる電力提供サービスにお申込みをしていただきます。<br />
                      本申込ページよりお申込みいただき当社が必要書類に不備事項等のない事を確認させていただいたうえで成立するものといたします。<br />
                      <br />
                      <strong>＜必ずお読みください＞</strong><br />
                      ・お部屋のお引渡日までに、必ず申込みのお手続きをお願いいたします。入居時に申込む場合は、速やかにお手続き願います。<br />
                      ・ご利用料金は、お部屋のお引渡日から適用となります。<br />
                      （入居前でもインターフォンや防災設備・換気設備等の電気料金が発生いたします）<br />
                      &nbsp;通常は鍵のお引渡日やお部屋の所有権移転日となりますので、お部屋の売買契約書等をご確認ください。<br />
                      ・お客様が電気をすぐにお使いいただけるよう、ブレーカーを「入」にすれば電気は使用できるようにしていますが、お申込みがないまま電気を使用され続けた場合は、電気のご利用ができなくなりますのでご注意ください。<br />
                      ・お申込みが遅れた場合でも、電気料金（基本料金等含む）は、お部屋のお引渡日から遡って請求されます。<br />
                      ・弊社は、2025年1月31日をもちましてNTTアノードエナジーの「マンション電力提供サービス」事業を譲受いたしました。<br />
                      <br />
                      <strong>＜【重要】お客様の個人情報の取扱いについて＞</strong><br />
                      ・このページはお客様の個人情報をご入力していただく内容が含まれております。<br />
                      ・当社でのお客様個人情報の取扱いについての詳細は、『<a href="https://rezil.co.jp/privacypolicy/" target="_blank">個人情報保護方針</a>』からご覧いただきご了承いただいた上でご記入ください。<br />
                      ・ご登録していただくお客様情報は、<a href="https://rezil.co.jp/privacypolicy/" target="_blank">目的</a>の範囲内にのみ利用いたします。<br />
                      <br />
                    </div>
                    <div class="form-item webform-component webform-component-checkboxes webform-component--field-policy webform-conditional-hidden">
                      <label class="element-invisible" for="edit-submitted-field-policy">個人情報保護方針 </label>
                      <div id="edit-submitted-field-policy" class="form-checkboxes">
                        <div class="form-item form-type-checkbox form-item-submitted-field-policy-1">
                          <input required type="checkbox" id="edit-submitted-field-policy-1" name="submitted[field_policy][1]" class="form-checkbox webform-conditional-disabled" v-model="check1" />&nbsp;
                          <label class="option" for="edit-submitted-field-policy-1"><router-link to="/mansion/user_clause" target="_blank">電力提供サービス利用契約約款</router-link>に同意します。 </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-item webform-component webform-component-checkboxes webform-component--field-user webform-conditional-hidden">
                      <label class="element-invisible" for="edit-submitted-field-user">サイトの利用規約 </label>
                      <div id="edit-submitted-field-user" class="form-checkboxes">
                        <div class="form-item form-type-checkbox form-item-submitted-field-user-1">
                          <input required type="checkbox" id="edit-submitted-field-user-1" name="submitted[field_user][1]" class="form-checkbox webform-conditional-disabled" v-model="check2" />&nbsp;
                          <label class="option" for="edit-submitted-field-user-1"><a href="https://rezil.co.jp/privacypolicy/" target="_blank">個人情報保護方針</a>及び<router-link to="/mansion/user_terms" target="_blank">サイトの利用規約</router-link>に同意します。 </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-item webform-component webform-component-markup webform-component--field-required-message webform-conditional-hidden"><span class="form-required">*</span>は必須項目です。</div>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed' + (isOpen1 ? '' : ' collapsed')">
                      <legend>
                        <span class="fieldset-legend"
                          ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen1 = !isOpen1"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span
                        ></span>
                      </legend>
                      <div class="fieldset-wrapper" v-show="isOpen1">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-use-mansion-info--field-mansion-name webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-use-mansion-info-field-mansion-name">電気をご利用のマンション名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-use-mansion-info-field-mansion-name" name="submitted[webform_group_use_mansion_info][field_mansion_name]" size="60" maxlength="30" class="form-text required webform-conditional-disabled" v-model="entry_form.form_mansion_name" />
                          <div class="description">（例：○○マンション ○○棟）&nbsp;※棟がある場合は必ず棟名までご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-use-mansion-info--field-room-number webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-use-mansion-info-field-room-number">部屋番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" pattern="[0-9]*" id="edit-submitted-webform-group-use-mansion-info-field-room-number" name="submitted[webform_group_use_mansion_info][field_room_number]" size="5" maxlength="5" class="form-text required webform-conditional-disabled" v-model="entry_form.form_room_no" />
                          <div class="description">（例：123）※棟がある場合は上記マンション名欄にご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-use-mansion-info--field-start-date webform-container-inline webform-conditional-hidden">
                          <label>部屋のお引渡日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline webform-datepicker">
                            <div class="form-item form-type-select form-item-submitted-webform-group-use-mansion-info-field-start-date-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-use-mansion-info-field-start-date-year">年 </label>
                              <select class="year form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-use-mansion-info-field-start-date-year" name="submitted[webform_group_use_mansion_info][field_start_date][year]" v-model="entry_form.move_date_year">
                                <template v-for="n in 4" :key="n">
                                  <option :value="n + year - 3">{{ n + year - 3 }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-use-mansion-info-field-start-date-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-use-mansion-info-field-start-date-month">月 </label>
                              <select class="month form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-use-mansion-info-field-start-date-month" name="submitted[webform_group_use_mansion_info][field_start_date][month]" v-model="entry_form.move_date_month">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-use-mansion-info-field-start-date-day">
                              <label class="element-invisible" for="edit-submitted-webform-group-use-mansion-info-field-start-date-day">日 </label>
                              <select class="day form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-use-mansion-info-field-start-date-day" name="submitted[webform_group_use_mansion_info][field_start_date][day]" v-model="entry_form.move_date_date">
                                <template v-for="n in 31" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(-2, 0, 0) + ' webform-calendar-end-' + getDateString(1, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                            <input type="image" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                          </div>
                          <div class="description">（通常は鍵のお引渡日やお部屋の所有権移転日を記入） ※電気料金（基本料金等含む）の適用開始日となります。</div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-contractor-info form-wrapper collapse-processed' + (isOpen2 ? '' : ' collapsed')">
                      <legend>
                        <span class="fieldset-legend"
                          ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen2 = !isOpen2"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご契約者様情報</a><span class="summary"></span
                        ></span>
                      </legend>
                      <div class="fieldset-wrapper" v-show="isOpen2">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-name webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-contractor-info-field-contractor-name">お名前 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-contractor-info-field-contractor-name" name="submitted[webform_group_contractor_info][field_contractor_name]" size="40" maxlength="20" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_name" />
                          <div class="description">（例：電力　花子）※２０文字以内・環境依存文字以外でご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-kana webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-contractor-info-field-contractor-kana">フリガナ <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" pattern="([\u30A1-\u30FC]|\u3000)*" id="edit-submitted-webform-group-contractor-info-field-contractor-kana" name="submitted[webform_group_contractor_info][field_contractor_kana]" size="40" maxlength="200" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_kana" />
                          <div class="description">（例：デンリョク　ハナコ）※全角カタカナでご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-contractor-info--field-contractor-birthdate webform-conditional-hidden">
                          <label>生年月日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline webform-datepicker">
                            <div class="form-item form-type-select form-item-submitted-webform-group-contractor-info-field-contractor-birthdate-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-contractor-info-field-contractor-birthdate-year">年 </label>
                              <select class="year form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-contractor-info-field-contractor-birthdate-year" name="submitted[webform_group_contractor_info][field_contractor_birthdate][year]" v-model="contractor.birthday_year">
                                <template v-for="n in 101" :key="n">
                                  <option :value="n + year - 101">{{ n + year - 101 }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-contractor-info-field-contractor-birthdate-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-contractor-info-field-contractor-birthdate-month">月 </label>
                              <select class="month form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-contractor-info-field-contractor-birthdate-month" name="submitted[webform_group_contractor_info][field_contractor_birthdate][month]" v-model="contractor.birthday_month">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-contractor-info-field-contractor-birthdate-day">
                              <label class="element-invisible" for="edit-submitted-webform-group-contractor-info-field-contractor-birthdate-day">日 </label>
                              <select class="day form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-contractor-info-field-contractor-birthdate-day" name="submitted[webform_group_contractor_info][field_contractor_birthdate][day]" v-model="contractor.birthday_date">
                                <template v-for="n in 31" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(-100, 0, 0) + ' webform-calendar-end-' + getDateString(0, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                            <input type="image" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                          </div>
                          <div class="description">※お客様の本人確認の際に必要になります。法人のお客様は、会社の創立年月日（契約者名に代表者の名前が含まれる場合は代表者の生年月日）をご登録願います。</div>
                        </div>
                        <div class="form-item webform-component webform-component-checkboxes webform-component--webform-group-contractor-info--field-contractor-mansion-address webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-contractor-info-field-contractor-mansion-address">ご住所 </label>
                          <div id="edit-submitted-webform-group-contractor-info-field-contractor-mansion-address" class="form-checkboxes">
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-contractor-info-field-contractor-mansion-address-1">
                              <input type="checkbox" id="edit-submitted-webform-group-contractor-info-field-contractor-mansion-address-1" name="submitted[webform_group_contractor_info][field_contractor_mansion_address][1]" class="form-checkbox webform-conditional-disabled" v-model="entry_form.same" />
                              <label class="option" for="edit-submitted-webform-group-contractor-info-field-contractor-mansion-address-1">電気をご利用のマンションと同じ </label>
                            </div>
                          </div>
                          <div class="description">※部屋の引渡し後すぐに入居する場合は、『電気をご利用のマンションと同じ 』を選択ください。</div>
                        </div>
                        <div v-show="!entry_form.same">
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-zipcode webform-conditional-hidden">
                            <label for="edit-submitted-webform-group-contractor-info-field-contractor-zipcode">郵便番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" pattern="[0-9]*" style="width: auto" id="edit-submitted-webform-group-contractor-info-field-contractor-zipcode" name="submitted[webform_group_contractor_info][field_contractor_zipcode]" size="7" maxlength="7" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_zip_postal_code" :disabled="entry_form.same" />
                            <span class="field-suffix"><button type="button" id="btn_contractor_zipcode" class="webform-conditional-disabled" @click.prevent.stop="getZipcode1()">郵便番号から住所を自動入力</button></span>
                            <div class="description">（例：0000000）※半角数字のみ「－」なし<br />お住まいの郵便番号がわからない場合は<a href="https://www.post.japanpost.jp/zipcode/" target="_blank">日本郵政の公式サイト</a>よりご確認ください。</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-pref webform-conditional-hidden">
                            <label for="edit-submitted-webform-group-contractor-info-field-contractor-pref">都道府県 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-contractor-info-field-contractor-pref" name="submitted[webform_group_contractor_info][field_contractor_pref]" size="10" maxlength="10" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_state" :disabled="entry_form.same" />
                            <div class="description">（例：東京都）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-city webform-conditional-hidden">
                            <label for="edit-submitted-webform-group-contractor-info-field-contractor-city">市区町村 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-contractor-info-field-contractor-city" name="submitted[webform_group_contractor_info][field_contractor_city]" size="20" maxlength="10" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_city" :disabled="entry_form.same" />
                            <div class="description">（例：港区）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-town webform-conditional-hidden">
                            <label for="edit-submitted-webform-group-contractor-info-field-contractor-town">町名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-contractor-info-field-contractor-town" name="submitted[webform_group_contractor_info][field_contractor_town]" size="20" maxlength="10" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_address_line" :disabled="entry_form.same" />
                            <div class="description">（例：芝浦）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-house-number webform-conditional-hidden">
                            <label for="edit-submitted-webform-group-contractor-info-field-contractor-house-number">番地 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-contractor-info-field-contractor-house-number" name="submitted[webform_group_contractor_info][field_contractor_house_number]" size="20" maxlength="10" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_address_line_2" :disabled="entry_form.same" />
                            <div class="description">（例：0-00-00）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-address-other webform-conditional-hidden">
                            <label for="edit-submitted-webform-group-contractor-info-field-contractor-address-other">マンション名・部屋番号等 </label>
                            <input type="text" id="edit-submitted-webform-group-contractor-info-field-contractor-address-other" name="submitted[webform_group_contractor_info][field_contractor_address_other]" size="50" maxlength="25" class="form-text webform-conditional-disabled" v-model="contractor.contractor_address_other" :disabled="entry_form.same" />
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-tel webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-contractor-info-field-contractor-tel">日中ご連絡のつく電話番号（携帯電話等） <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-contractor-info-field-contractor-tel" name="submitted[webform_group_contractor_info][field_contractor_tel]" size="15" maxlength="15" class="form-text required webform-conditional-disabled" v-model="contractor.contractor_phone" />
                          <div class="description">※手続きに関するご連絡をショートメッセージでお送りする場合もあります。（例：09012345678）半角数字のみ「－」なし</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-mobile-phone webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-contractor-info-field-contractor-mobile-phone">上記以外の連絡先（ご自宅の電話番号等） </label>
                          <input type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-contractor-info-field-contractor-mobile-phone" name="submitted[webform_group_contractor_info][field_contractor_mobile_phone]" size="15" maxlength="15" class="form-text webform-conditional-disabled" v-model="contractor.contractor_tel" />
                          <div class="description">（例：09012345678）※半角数字のみ「－」なし</div>
                        </div>
                        <div class="form-item webform-component webform-component-email webform-component--webform-group-contractor-info--field-contractor-email webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-contractor-info-field-contractor-email">メールアドレス <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" class="email form-text form-email required webform-conditional-disabled" type="email" id="edit-submitted-webform-group-contractor-info-field-contractor-email" name="submitted[webform_group_contractor_info][field_contractor_email]" size="40" maxLength="254" v-model="contractor.contractor_email" readonly />
                          <div class="description">※「@denki-annai.com」ドメインからのメールを受け取れるように設定してください。</div>
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-item webform-component webform-component-checkboxes webform-component--field-contracts-preson webform-conditional-hidden">
                      <label for="edit-submitted-field-contracts-preson">お支払者様 </label>
                      <div id="edit-submitted-field-contracts-preson" class="form-checkboxes">
                        <div class="form-item form-type-checkbox form-item-submitted-field-contracts-preson-1">
                          <input type="checkbox" id="edit-submitted-field-contracts-preson-1" name="submitted[field_contracts_preson][1]" class="form-checkbox webform-conditional-disabled" v-model="payer.same" />
                          <label class="option" for="edit-submitted-field-contracts-preson-1">ご契約者様と同じ </label>
                        </div>
                      </div>
                    </div>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed webform-conditional-hidden' + (isOpen3 ? '' : ' collapsed')" v-show="!payer.same">
                      <legend>
                        <span class="fieldset-legend"
                          ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen3 = !isOpen3"><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払者様情報</a><span class="summary"></span
                        ></span>
                      </legend>
                      <div class="fieldset-wrapper" v-show="isOpen3">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-name webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-name">お名前 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-payer-info-field-payer-name" name="submitted[webform_group_payer_info][field_payer_name]" size="40" maxlength="20" class="form-text required webform-conditional-disabled" v-model="payer.payer_name" :disabled="payer.same" />
                          <div class="description">（例：電力　太郎）※２０文字以内・環境依存文字以外でご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-kana webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-kana">フリガナ <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" pattern="([\u30A1-\u30FC]|\u3000)*" id="edit-submitted-webform-group-payer-info-field-payer-kana" name="submitted[webform_group_payer_info][field_payer_kana]" size="40" maxlength="200" class="form-text required webform-conditional-disabled" v-model="payer.payer_kana" :disabled="payer.same" />
                          <div class="description">（例：デンリョク　タロウ）&nbsp;※全角カタカナでご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-payer-info--field-payer-birthday webform-conditional-hidden">
                          <label>生年月日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline webform-datepicker">
                            <div class="form-item form-type-select form-item-submitted-webform-group-payer-info-field-payer-birthday-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-payer-info-field-payer-birthday-year">年 </label>
                              <select class="year form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-payer-info-field-payer-birthday-year" name="submitted[webform_group_payer_info][field_payer_birthday][year]" v-model="payer.birthday_year" :disabled="payer.same">
                                <template v-for="n in 101" :key="n">
                                  <option :value="n + year - 101">{{ n + year - 101 }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-payer-info-field-payer-birthday-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-payer-info-field-payer-birthday-month">月 </label>
                              <select class="month form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-payer-info-field-payer-birthday-month" name="submitted[webform_group_payer_info][field_payer_birthday][month]" v-model="payer.birthday_month" :disabled="payer.same">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-payer-info-field-payer-birthday-day">
                              <label class="element-invisible" for="edit-submitted-webform-group-payer-info-field-payer-birthday-day">日 </label>
                              <select class="day form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-payer-info-field-payer-birthday-day" name="submitted[webform_group_payer_info][field_payer_birthday][day]" v-model="payer.birthday_date" :disabled="payer.same">
                                <template v-for="n in 31" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(-100, 0, 0) + ' webform-calendar-end-' + getDateString(0, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                            <input type="image" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                          </div>
                          <div class="description">※お客様の本人確認の際に必要になります。法人のお客様は、会社の創立年月日（契約者名に代表者の名前が含まれる場合は代表者の生年月日）をご登録願います。</div>
                        </div>
                        <div class="form-item webform-component webform-component-radios webform-component--webform-group-payer-info--relationship webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-relationship">ご契約者様との続柄 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div id="edit-submitted-webform-group-payer-info-relationship" class="form-radios">
                            <div class="form-item form-type-radio form-item-submitted-webform-group-payer-info-relationship">
                              <input required="required" type="radio" id="edit-submitted-webform-group-payer-info-relationship-1" name="submitted[webform_group_payer_info][relationship]" value="0" checked="checked" class="form-radio webform-conditional-disabled" v-model="payer.payer_relationship" :disabled="payer.same" />
                              <label class="option" for="edit-submitted-webform-group-payer-info-relationship-1">本人 </label>
                            </div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-payer-info-relationship">
                              <input required="required" type="radio" id="edit-submitted-webform-group-payer-info-relationship-2" name="submitted[webform_group_payer_info][relationship]" value="1" class="form-radio webform-conditional-disabled" v-model="payer.payer_relationship" :disabled="payer.same" />
                              <label class="option" for="edit-submitted-webform-group-payer-info-relationship-2">配偶者 </label>
                            </div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-payer-info-relationship">
                              <input required="required" type="radio" id="edit-submitted-webform-group-payer-info-relationship-3" name="submitted[webform_group_payer_info][relationship]" value="2" class="form-radio webform-conditional-disabled" v-model="payer.payer_relationship" :disabled="payer.same" />
                              <label class="option" for="edit-submitted-webform-group-payer-info-relationship-3">その他 </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--relationship-other webform-conditional-hidden" v-show="payer.payer_relationship == 2">
                          <label for="edit-submitted-webform-group-payer-info-relationship-other">その他続柄 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-relationship-other" name="submitted[webform_group_payer_info][relationship_other]" size="60" maxlength="16" class="form-text webform-conditional-disabled" v-model="payer.payer_relationship_etc" :disabled="payer.same || (payer.payer_relationship == 2 ? false : true)" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-zipcode webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-zipcode">郵便番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" pattern="[0-9]{7}" style="width: auto" id="edit-submitted-webform-group-payer-info-field-payer-zipcode" name="submitted[webform_group_payer_info][field_payer_zipcode]" size="7" maxlength="7" class="form-text required webform-conditional-disabled" v-model="payer.payer_zip_postal_code" :disabled="payer.same" />
                          <span class="field-suffix"><button type="button" id="btn_payer_zipcode" class="webform-conditional-disabled" @click.prevent.stop="getZipcode2()">郵便番号から住所を自動入力</button></span>
                          <div class="description">（例：1234567）※半角数字のみ「－」なし<br />お住まいの郵便番号がわからない場合は<a href="https://www.post.japanpost.jp/zipcode/" target="_blank">日本郵政の公式サイト</a>よりご確認ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-pref webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-pref">都道府県 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-payer-info-field-payer-pref" name="submitted[webform_group_payer_info][field_payer_pref]" size="10" maxlength="10" class="form-text required webform-conditional-disabled" v-model="payer.payer_state" :disabled="payer.same" />
                          <div class="description">（例：東京都）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-city webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-city">市区町村 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-payer-info-field-payer-city" name="submitted[webform_group_payer_info][field_payer_city]" size="20" maxlength="10" class="form-text required webform-conditional-disabled" v-model="payer.payer_city" :disabled="payer.same" />
                          <div class="description">（例：中央区）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-town webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-town">町名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-payer-info-field-payer-town" name="submitted[webform_group_payer_info][field_payer_town]" size="20" maxlength="10" class="form-text required webform-conditional-disabled" v-model="payer.payer_address_line" :disabled="payer.same" />
                          <div class="description">（例：八重洲）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-house-number webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-house-number">番地 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-payer-info-field-payer-house-number" name="submitted[webform_group_payer_info][field_payer_house_number]" size="20" maxlength="10" class="form-text required webform-conditional-disabled" v-model="payer.payer_address_line_2" :disabled="payer.same" />
                          <div class="description">（例：1-23-45）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-address-other webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-address-other">マンション名・部屋番号等 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-address-other" name="submitted[webform_group_payer_info][field_payer_address_other]" size="50" maxlength="25" class="form-text webform-conditional-disabled" v-model="payer.payer_address_other" :disabled="payer.same" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-tel webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-tel">日中ご連絡のつく電話番号（携帯電話等） <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-payer-info-field-payer-tel" name="submitted[webform_group_payer_info][field_payer_tel]" size="15" maxlength="15" class="form-text required webform-conditional-disabled" v-model="payer.payer_phone" :disabled="payer.same" />
                          <div class="description">※手続きに関するご連絡をショートメッセージでお送りする場合もあります。（例：09012345678）半角数字のみ「－」なし</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-mobile-phone webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-mobile-phone">上記以外の連絡先（ご自宅の電話番号等） </label>
                          <input type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-payer-info-field-payer-mobile-phone" name="submitted[webform_group_payer_info][field_payer_mobile_phone]" size="15" maxlength="15" class="form-text webform-conditional-disabled" v-model="payer.payer_tel" :disabled="payer.same" />
                          <div class="description">（例：09012345678）※半角数字のみ「－」なし</div>
                        </div>
                        <div class="form-item webform-component webform-component-email webform-component--webform-group-payer-info--field-payer-email webform-conditional-hidden">
                          <label for="edit-submitted-webform-group-payer-info-field-payer-email">メールアドレス <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" class="email form-text form-email required webform-conditional-disabled" type="email" id="edit-submitted-webform-group-payer-info-field-payer-email" name="submitted[webform_group_payer_info][field_payer_email]" size="40" v-model="payer.payer_email" :disabled="payer.same" />
                          <div class="description">※「@denki-annai.com」ドメインからのメールを受け取れるように設定してください。</div>
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-item webform-component webform-component-radios webform-component--field-contract-type webform-conditional-hidden">
                      <label for="edit-submitted-field-contract-type">料金プラン・契約容量 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                      <div id="edit-submitted-field-contract-type" class="form-radios">
                        <div class="form-item form-type-radio form-item-submitted-field-contract-type">
                          <input required="required" type="radio" id="edit-submitted-field-contract-type-1" name="submitted[field_contract_type]" value="0" class="form-radio webform-conditional-disabled" v-model="entry_form.form_plan" />
                          <label class="option" for="edit-submitted-field-contract-type-1">変更なし（初期契約容量のまま） </label>
                        </div>
                        <div class="form-item form-type-radio form-item-submitted-field-contract-type">
                          <input required="required" type="radio" id="edit-submitted-field-contract-type-2" name="submitted[field_contract_type]" value="1" class="form-radio webform-conditional-disabled" v-model="entry_form.form_plan" />
                          <label class="option" for="edit-submitted-field-contract-type-2">変更を希望 </label>
                        </div>
                      </div>
                      <div class="description">
                        ※「変更を希望」を選ぶと「希望種別」欄が表示されます。<br />
                        ※初期契約容量については下記をご覧ください。
                      </div>
                    </div>
                    <div class="form-item webform-component webform-component-textfield webform-component--field-hope-contract-type webform-conditional-hidden" v-show="entry_form.form_plan == 1">
                      <label for="edit-submitted-field-hope-contract-type">希望種別 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                      <input required="required" type="text" id="edit-submitted-field-hope-contract-type" name="submitted[field_hope_contract_type]" size="60" maxlength="128" class="form-text required webform-conditional-disabled" :disabled="entry_form.form_plan == 0" v-model="entry_form.form_kind" />
                      <div class="description">※ご希望の料金プラン、契約容量をご記入ください。</div>
                    </div>
                    <div class="form-item webform-component webform-component-markup webform-component--pay-method-description webform-conditional-hidden">
                      <br />
                      <strong>＜初期契約容量について＞</strong><br />
                      特に希望がない場合は「変更なし（初期契約容量のまま）」をお選びください。（契約容量は後からでも変更できます）<br />
                      ※初期契約プラン、初期容量については部屋の売買契約・賃貸契約時の重要事項説明書等にてご確認いただけます。<br />
                      選択可能な契約プラン、容量はお住まいのマンションによって異なりますので、ご不明な場合は<router-link to="/mansion/inquiry_document" target="_blank">カスタマーセンター</router-link>までお問い合わせください。<br />
                      <br />
                      <strong>＜お支払方法の登録について＞</strong><br />
                      お支払方法は「でんき案内板」のマイページより登録できます。<br />
                      クレジットカード、口座振替でもお支払いできます。<br />
                      <br />
                      ・&nbsp;電気の利用申込みが終了すると、1週間ほどで登録いただいた「お支払者様情報」のメールアドレスへ、お客様のマイページのログインIDと初期パスワードが届きます。ログインいただき、「支払方法の変更」よりお手続きください。<br />
                      ・&nbsp;クレジットカード、口座振替でのお支払いを選択しない場合は、請求書（請求代行サービス「後払い.com」を利用したお支払い）でのお支払いとなります。<br />
                      <br />
                      <strong>＜請求書によるお支払いについて＞</strong><br />
                      ・&nbsp;請求代行サービス「後払い.com」を利用したお支払いになります。<br />
                      ・&nbsp;後払い.comは株式会社キャッチボールが運営するサービスです。<br />
                      ・&nbsp;銀行、ゆうちょ銀行、コンビニエンスストアでお支払いいただけます。<br />
                      ・&nbsp;銀行でのお支払いの際の振込手数料は、お客様負担となります。<br />
                      ・&nbsp;後払い.comのコンビニエンスストアでの料金収納代行業務は、三井住友カード株式会社が実施しています。<br />
                      ・&nbsp;利用可能なコンビニエンスストアにつきましては払込票を確認ください。<br />
                      ・&nbsp;請求書兼払込票はご利用月の翌月15日前後に届きます。請求書兼払込票に記載のお支払い期限までにお支払いください。<br />
                      ・&nbsp;期日以内にお支払いがない場合、再度の請求ごとに335円（税込）の再請求書発行手数料がかかりますのでご注意ください。<br />
                      &nbsp;&nbsp;&nbsp;請求書でのお支払いは、「お支払者住所」宛に請求書が送付されますので、不在がちのお客様はご注意ください。<br />
                      ・&nbsp;ご本人様確認や後払い.comサービス利用にあたって、お電話・メールにてご連絡させていただく場合がございます。<br />
                      ・&nbsp;お客様の個人情報について、必要な範囲のみで三井住友カード株式会社、および株式会社キャッチボールに提供させていただきます。<br />
                    </div>
                    <div class="form-actions">
                      <input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" @click="check" />
                    </div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";
import { entryStore } from "../stores/store.js";
import { post, getData } from "../assets/js/request.js";
import config from "../config.js";
import $ from "jquery";
import MEMS from "../assets/js/mems.js";
import { init } from "../assets/js/mems_webform_replace_half_space.js";
export default {
  components: {
    Header,
    Header_info,
    Footer,
  },
  data() {
    var store = entryStore();
    if (Object.keys(store.update).length != 0) {
      var update = store.update;
      store.$reset();
      update.loading = true;
      return update;
    } else {
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day =  new Date().getDate();

      return {
        loading: true,
        check1: false,
        check2: false,
        isOpen1: true,
        isOpen2: true,
        isOpen3: true,
        year: year,
        month: month,
        day: day,
        entry_form: {
          form_mansion_name: "",
          form_room_no: "",
          form_plan: 0,
          form_kind: "",
          move_date_year: year,
          move_date_month: month,
          move_date_date: day,
          same: false,
        },
        contractor: {
          contractor_name: "",
          contractor_kana: "",
          contractor_phone: "",
          contractor_tel: "",
          contractor_zip_postal_code: "",
          contractor_state: "",
          contractor_city: "",
          contractor_address_line: "",
          contractor_address_line2: "",
          contractor_address_other: "",
          contractor_email: decodeURIComponent(this.$route.query.m),
          birthday_year: year,
          birthday_month: month,
          birthday_date: day,
        },
        payer: {
          same: false,
          payer_name: "",
          payer_kana: "",
          payer_phone: "",
          payer_tel: "",
          payer_zip_postal_code: "",
          payer_state: "",
          payer_city: "",
          payer_address_line: "",
          payer_address_line2: "",
          payer_address_other: "",
          payer_email: "",
          payer_relationship: 2,
          payer_relationship_etc: "",
          birthday_year: year,
          birthday_month: month,
          birthday_date: day,
        },
        onetime_token: this.$route.query["token"],
        search_error: 0,
      };
    }
  },
  async created() {
    try {
      await post(config.url.post.certification, { onetime_token: this.$data.onetime_token });
      this.$data.loading = false;
    } catch {
      this.$router.push({ path: "/mansion/confirm_mail", query: { error: 1 } });
    }
  },
  mounted: function () {
    this.$nextTick(
      function () {
        init();
        MEMS.behaviors.webform.attach(document);
        $("#edit-submitted-webform-group-use-mansion-info-field-start-date-year").on("change", this.changeDay1);
        $("#edit-submitted-webform-group-use-mansion-info-field-start-date-month").on("change", this.changeDay1);
        $("#edit-submitted-webform-group-use-mansion-info-field-start-date-day").on("change", this.changeDay1);
        $("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-year").on("change", this.changeDay2);
        $("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-month").on("change", this.changeDay2);
        $("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-day").on("change", this.changeDay2);
        $("#edit-submitted-webform-group-payer-info-field-payer-birthday-year").on("change", this.changeDay3);
        $("#edit-submitted-webform-group-payer-info-field-payer-birthday-month").on("change", this.changeDay3);
        $("#edit-submitted-webform-group-payer-info-field-payer-birthday-day").on("change", this.changeDay3);
      }.bind(this)
    );
  },
  methods: {
    getDateString: function (year, month, day) {
      var date = new Date();
      date.setFullYear(date.getFullYear() + year);
      date.setMonth(date.getMonth() + month);
      date.setDate(date.getDate() + day);
      return date.toLocaleDateString("sv-SE");
    },
    getZipcode1: async function () {
      this.$data.search_error = 0;
      if (!this.$data.contractor.contractor_zip_postal_code.match(/^\d{7}$/)) {
        return;
      }
      try {
        var result = await getData(config.url.get.zipcode + "?postCode=" + this.$data.contractor.contractor_zip_postal_code);
        // [{ pref, city, town }]
      } catch (ex) {
        this.$data.search_error = 1;
        document.body.scrollIntoView();
        return;
      }
      if (result.length == 0) {
        return;
      }
      // { pref, city, town }
      this.$data.contractor.contractor_state = result[0]["pref"];
      this.$data.contractor.contractor_city = result[0]["city"];
      this.$data.contractor.contractor_address_line = result[0]["town"];
    },
    getZipcode2: async function () {
      this.$data.search_error = 0;
      if (!this.$data.payer.payer_zip_postal_code.match(/^\d{7}$/)) {
        return;
      }
      try {
        var result = await getData(config.url.get.zipcode + "?postCode=" + this.$data.payer.payer_zip_postal_code);
        // [{ pref, city, town }]
      } catch (ex) {
        this.$data.search_error = 1;
        document.body.scrollIntoView();
        return;
      }
      if (result.length == 0) {
        return;
      }
      this.$data.payer.payer_state = result[0]["pref"];
      this.$data.payer.payer_city = result[0]["city"];
      this.$data.payer.payer_address_line = result[0]["town"];
    },
    changeDay1: function () {
      this.$data.entry_form.move_date_year = document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-start-date-year").value;
      this.$data.entry_form.move_date_month = document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-start-date-month").value;
      this.$data.entry_form.move_date_date = document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-start-date-day").value;
    },
    changeDay2: function () {
      this.$data.contractor.birthday_year = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-year").value;
      this.$data.contractor.birthday_month = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-month").value;
      this.$data.contractor.birthday_date = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-day").value;
    },
    changeDay3: function () {
      this.$data.payer.birthday_year = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-birthday-year").value;
      this.$data.payer.birthday_month = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-birthday-month").value;
      this.$data.payer.birthday_date = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-birthday-day").value;
    },
    check: function () {
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("select").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("[required]").forEach(function (input) {
        if (input.validity.valueMissing) {
          input.setCustomValidity("これは入力必須項目です。");
        }
      });
      if (!this.$data.check1) {
        document.querySelector("#edit-submitted-field-policy-1").setCustomValidity("電力提供サービス利用契約約款を確認し、同意する場合はチェックをして下さい。");
      }
      if (!this.$data.check2) {
        document.querySelector("#edit-submitted-field-user-1").setCustomValidity("個人情報保護方針及びサイトの利用規約を確認し、同意する場合はチェックをして下さい。");
      }
      var today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      var start = new Date();
      start.setFullYear(today.getFullYear() - 2);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      var end = new Date();
      end.setFullYear(today.getFullYear() + 1);
      end.setHours(0);
      end.setMinutes(0);
      end.setSeconds(0);
      end.setMilliseconds(0);
      var room = document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-room-number");
      if (room.validity.patternMismatch) {
        room.setCustomValidity("部屋番号は、半角整数のみで入力して下さい。");
      }
      var startDate = new Date(this.$data.entry_form.move_date_year + "/" + this.$data.entry_form.move_date_month + "/" + this.$data.entry_form.move_date_date + " 00:00:00");
      if (!moment(this.$data.entry_form.move_date_year + "/" + this.$data.entry_form.move_date_month + "/" + this.$data.entry_form.move_date_date, "YYYY/M/D").isValid()){
        document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-start-date-year").setCustomValidity("入力された 部屋のお引渡日 は正しい日時ではありません。");
      }
      if(start > startDate) {
        document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-start-date-year").setCustomValidity(start.getFullYear() + "/" + (start.getMonth() + 1) + "/" + start.getDate() + "以降の日付を指定して下さい。");
      }else if(end < startDate) {
        document.querySelector("#edit-submitted-webform-group-use-mansion-info-field-start-date-year").setCustomValidity(end.getFullYear() + "/" + (end.getMonth() + 1) + "/" + end.getDate() + "以前の日付を指定して下さい。");
      }
      var kana1 = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-kana");
      if (kana1.validity.patternMismatch) {
        kana1.setCustomValidity("フリガナは、カタカナと全角スペースのみで入力して下さい。");
      }
      var birthday1 = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-birthdate-year");
      var day1 = new Date(this.$data.contractor.birthday_year + "/" + this.$data.contractor.birthday_month + "/" + this.$data.contractor.birthday_date + " 00:00:00");
      var minday = new Date();
      minday.setFullYear(today.getFullYear() - 100);
      minday.setHours(0);
      minday.setMinutes(0);
      minday.setSeconds(0);
      minday.setMilliseconds(0);
      if (!moment(this.$data.contractor.birthday_year + "/" + this.$data.contractor.birthday_month + "/" + this.$data.contractor.birthday_date, "YYYY/M/D").isValid()){
        birthday1.setCustomValidity("入力された 生年月日 は正しい日時ではありません。");
      }
      if (day1 > today){
        birthday1.setCustomValidity(today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate()+ "以前の日付を指定して下さい。");
      }
      if (minday > day1){
        birthday1.setCustomValidity(minday.getFullYear() + "/" + (minday.getMonth() + 1) + "/" + minday.getDate() + "以降の日付を指定して下さい。");
      }

      if (!this.$data.entry_form.same) {
        var zip1 = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-zipcode");
        if (this.contractor.contractor_zip_postal_code.length != 0 && this.contractor.contractor_zip_postal_code.length < 7) {
          zip1.setCustomValidity("郵便番号は、7桁でご入力ください。");
        }
        if (zip1.validity.patternMismatch) {
          zip1.setCustomValidity("郵便番号は、半角整数のみで入力して下さい。");
        }
        if (this.contractor.contractor_state.length > 4) {
          document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-pref").setCustomValidity("都道府県は、4文字以下で入力して下さい。");
        }
      }

      var phone1 = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-tel");
      if (this.$data.contractor.contractor_phone.startsWith("080") || this.$data.contractor.contractor_phone.startsWith("090") || this.$data.contractor.contractor_phone.startsWith("050") || this.$data.contractor.contractor_phone.startsWith("070")) {
        if (this.$data.contractor.contractor_phone.length != 11) {
          phone1.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      } else {
        if (this.$data.contractor.contractor_phone.length != 0 && this.$data.contractor.contractor_phone.length != 10) {
          phone1.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      }
      if (phone1.validity.patternMismatch) {
        phone1.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
      }

      var tel1 = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-mobile-phone");
      if (this.$data.contractor.contractor_tel.startsWith("080") || this.$data.contractor.contractor_tel.startsWith("090") || this.$data.contractor.contractor_tel.startsWith("050") || this.$data.contractor.contractor_tel.startsWith("070")) {
        if (this.$data.contractor.contractor_tel.length != 11) {
          tel1.setCustomValidity("上記以外の連絡先（ご自宅の電話番号等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      } else {
        if (this.$data.contractor.contractor_tel.length != 0 && this.$data.contractor.contractor_tel.length != 10) {
          tel1.setCustomValidity("上記以外の連絡先（ご自宅の電話番号等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      }
      var email1 = document.querySelector("#edit-submitted-webform-group-contractor-info-field-contractor-email");
      var splited1 = this.$data.contractor.contractor_email.split("@");
      if (email1.validity.valueMissing){
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if (email1.validity.typeMismatch){
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if (splited1.length == 2 && (splited1[1].length == 0 || splited1[1].length == splited1[1].replace(".", "").length)) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if(splited1[0].length > 64) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }
      

      if (!this.$data.payer.same) {
        var kana2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-kana");
        if (kana2.validity.patternMismatch) {
          kana2.setCustomValidity("フリガナは、カタカナと全角スペースのみで入力して下さい。");
        }
        var birthday2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-birthday-year");
        var day2 = new Date(this.$data.payer.birthday_year + "/" + this.$data.payer.birthday_month + "/" + this.$data.payer.birthday_date + " 00:00:00");
        if (!moment(this.$data.payer.birthday_year + "/" + this.$data.payer.birthday_month + "/" + this.$data.payer.birthday_date, "YYYY/M/D").isValid()){
          birthday2.setCustomValidity("入力された 生年月日 は正しい日時ではありません。");
        }
        if (day2 > today){
          birthday2.setCustomValidity(today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate() + "以前の日付を指定して下さい。");
        }
        if (minday > day2){
          birthday2.setCustomValidity(minday.getFullYear() + "/" + (minday.getMonth() + 1) + "/" + minday.getDate()+ "以降の日付を指定して下さい。");
        }
        var zip2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-zipcode");
        if (this.payer.payer_zip_postal_code.length != 0 && this.payer.payer_zip_postal_code.length < 7) {
          zip2.setCustomValidity("郵便番号は、7桁でご入力ください。");
        }
        if (zip2.validity.patternMismatch) {
          zip2.setCustomValidity("郵便番号は、半角整数のみで入力して下さい。");
        }
        if (this.payer.payer_state.length > 4) {
          document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-pref").setCustomValidity("都道府県は、4文字以下で入力して下さい。");
        }
        var phone2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-tel");
        if (this.$data.payer.payer_phone.startsWith("080") || this.$data.payer.payer_phone.startsWith("090") || this.$data.payer.payer_phone.startsWith("050") || this.$data.payer.payer_phone.startsWith("070")) {
          if (this.$data.payer.payer_phone.length != 11) {
            phone2.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
          }
        } else {
          if (this.$data.payer.payer_phone.length != 0 && this.$data.payer.payer_phone.length != 10) {
            phone2.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
          }
        }
        if (phone2.validity.patternMismatch) {
          phone2.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }

        var tel2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-mobile-phone");
        if (this.$data.payer.payer_tel.startsWith("080") || this.$data.payer.payer_tel.startsWith("090") || this.$data.payer.payer_tel.startsWith("050") || this.$data.payer.payer_tel.startsWith("070")) {
          if (this.$data.payer.payer_tel.length != 11) {
            tel2.setCustomValidity("上記以外の連絡先（ご自宅の電話番号等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
          }
        } else {
          if (this.$data.payer.payer_tel.length != 0 && this.$data.payer.payer_tel.length != 10) {
            tel2.setCustomValidity("上記以外の連絡先（ご自宅の電話番号等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
          }
        }
        var email2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-email");
        var splited2 = this.$data.payer.payer_email.split("@");
        if (email2.validity.valueMissing){
          email2.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
        }else if (email2.validity.typeMismatch){
          email2.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
        }else if (splited2.length == 2 && (splited2[1].length == 0 || splited2[1].length == splited2[1].replace(".", "").length)) {
          email2.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
        }else if(splited2[0].length > 64) {
          email2.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
        }
      }
    },
    push: function () {
      this.$data.search_error = 0;
      this.$data.entry_form.form_move_date = this.$data.entry_form.move_date_year + "/" + String(this.$data.entry_form.move_date_month).padStart(2, "0") + "/" + String(this.$data.entry_form.move_date_date).padStart(2, "0");
      this.$data.contractor.contractor_birthday = this.$data.contractor.birthday_year + "/" + String(this.$data.contractor.birthday_month).padStart(2, "0") + "/" + String(this.$data.contractor.birthday_date).padStart(2, "0");
      this.$data.payer.payer_birthday = this.$data.payer.birthday_year + "/" + String(this.$data.payer.birthday_month).padStart(2, "0") + "/" + String(this.$data.payer.birthday_date).padStart(2, "0");
      this.$data.entry_form.form_same_flag = this.$data.entry_form.same ? "1" : "0";
      this.$data.payer.contractor_same_flag = this.$data.payer.same ? "1" : "0";
      var store = entryStore();
      store.update = this.$data;
      this.$router.push({ path: "/mansion/newentry_confirm" });
    },
  },
};
import "../assets/js/webform.js";
</script>
<style scoped>
body input[type="text"],
body input[type="email"],
body input[type="tel"],
body input[type="password"] {
  width: auto;
}
.webform-calendar:focus {
  outline: none;
}
</style>
<style scoped src="../assets/css/style.css" />

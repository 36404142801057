<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-change page-change-payment responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="messages error" :style="'visibility:' + (error ? 'visible' : 'hidden' ) + '; display:' + (error ? 'block' : 'none' ) ">{{ error }}</div>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">お支払方法の変更手続き</span>
        </div>
        <div class="messages status" v-show="green">
        <h2 class="element-invisible">ステータスメッセージ</h2>
        変更内容が保存されました。</div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">お支払方法の変更手続き</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq/oshietepaymethod" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <form target="_blank" action="/mansion/change/payment" method="post" id="ennevision-member-change-payment-form" accept-charset="UTF-8" @submit.prevent="">
              <div>
                <p>
                  お支払いはクレジットカード、口座振替でもお支払いできます。<br />
                  ご希望のお支払方法を下記よりお選びいただき、「支払方法を登録/変更する」ボタンをクリックしてください。<br />
                  クレジットカードの変更や有効期限等の情報を更新する場合は、「クレジットカード」をお選びください。<br />
                  ※手続きが完了するまでは、現在のお支払方法（新規お申込みの場合は請求書）でのお支払いとなります。<br />
                </p>
                <div id="edit-now-payment-form" class="form-wrapper" v-show="paymentBefore != ''">
                  <div class="field field-name-field-payment field-type-list-text field-label-above">
                    <div class="field-label">現在のお支払方法:</div>
                    <div class="field-items">
                      <template v-if="paymentBefore == '04001-0'">
                      <div class="field-item even">請求書（後払い.com）</div>
                      </template>
                      <template v-if="paymentBefore == '04002-0'">
                      <div class="field-item even">口座振替</div>
                      </template>
                      <template v-if="paymentBefore == '04003-0'">
                      <div class="field-item even">クレジットカード</div>
                      </template>
                    </div>
                  </div>
                  <template v-if="paymentBefore == '04002-0'">
                  <fieldset class="payment-method-views-fieldset" data-module="views_fieldsets">
                    <legend><span class="fieldset-legend">口座情報</span></legend>
                    <div class="fieldset-wrapper">
                      <div class="views-field bank-name">
                        <strong class="views-label bank-name">金融機関名: </strong>
                        <span class="field-content">{{ account_transfer_bank_name }}</span>
                      </div>
                      <div class="views-field branch-name">
                        <strong class="views-label branch-name">支店名: </strong>
                        <span class="field-content">{{ account_transfer_branch_name }}</span>
                      </div>
                      <div class="views-field account-holder-kana">
                        <strong class="views-label account-holder-kana">口座名義人（フリガナ）: </strong>
                        <span class="field-content">{{ account_transfer_account_holder }}</span>
                      </div>
                      <div class="views-field account-number">
                        <strong class="views-label account-number">口座番号: </strong>
                        <template v-if="account_transfer_account_number != ''">
                          <span class="field-content">{{ account_transfer_account_number }}</span>
                        </template>
                        <template v-if="account_transfer_sign != '' && account_transfer_account_number != ''">
                          <span class="field-content">{{ account_transfer_sign }}</span>&nbsp;<span class="field-content">{{ account_transfer_account_number }}</span>
                        </template>
                      </div>
                    </div>
                  </fieldset>
                  </template>
                  <template v-if="paymentBefore == '04003-0'">
                  <fieldset class="payment-method-views-fieldset" data-module="views_fieldsets">
                    <legend><span class="fieldset-legend">クレジットカード情報</span></legend>
                    <div class="fieldset-wrapper">
                      <div class="views-field bank-name">
                        <strong class="views-label card-no">クレジットカード番号: </strong>
                        <span class="field-content">{{ credit_card_number }}</span>
                      </div>
                      <div class="views-field branch-name">
                        <strong class="views-label card-date">クレジットカード有効期限: </strong>
                        <span class="field-content">{{ credit_card_expiration_date }}</span>
                      </div>
                    </div>
                  </fieldset>
                  </template>
                </div>
                <div id="edit-change-payment-form" class="form-wrapper">
                  <div class="field">
                    <div class="field-label">
                      <p>ご希望のお支払方法をお選びください。<br /></p>
                    </div>
                  </div>
                  <div id="edit-change-payment" class="form-radios">
                    <div class="form-item form-type-radio form-item-change-payment">
                      <input type="radio" id="edit-change-payment-04003-0" name="change_payment" value="04003-0" class="form-radio" v-model="payment"/>
                      <label class="option"><span class="payment-option-label"><strong>クレジットカード（カード変更・有効期限更新もこちら）&nbsp;<a id="payment-method1" href="#" @click.prevent="isOpen1 = !isOpen1">▼詳細▼</a></strong></span>
                        <div :style="isOpen1 ? '' : 'display: none; visibility:hidden'" id="payment-description1">
                          &nbsp;・VISA、Mastercard、JCB、 American Express、Diners Clubのクレジットカードがご利用可能です。<br />&nbsp;・手続き完了後の翌月からクレジットカードでのお支払いになります。<br />&nbsp;&nbsp;※ご入居前のお客様は、電気料金の請求が開始（お引渡日の翌月）されてからの適用となります。<br /><br />&nbsp;◆クレジットカードの変更や有効期限等の情報を更新する場合◆<br />&nbsp;&nbsp;「クレジットカード」を選択したまま「支払方法を登録/変更する」をクリックし、以降のページで新しいクレジットカード情報をご登録ください。<br />&nbsp;&nbsp;※お使いのクレジットカードの有効期限等が変更になった場合は、お客様ご自身にて登録情報の更新手続きが必要となります。<br />
                        </div>
                      </label>
                    </div>
                    <div class="form-item form-type-radio form-item-change-payment">
                      <input type="radio" id="edit-change-payment-04002-0" name="change_payment" value="04002-0" class="form-radio" v-model="payment"/>
                      <label class="option"><span class="payment-option-label"><strong>口座振替&nbsp;<a id="payment-method2" href="#" @click.prevent="isOpen2 = !isOpen2">▼詳細▼</a></strong></span>
                        <div :style="isOpen2 ? '' : 'display: none; visibility:hidden'" id="payment-description2">&nbsp;・１回ごとに口座振替割引55円（税込）が適用されます。<br />&nbsp;・口座振替日は、ご利用月の翌月27日となります。ご利用可能な金融機関は<router-link to="/mansion/faq/oshietepaymethod" target="_blank">こちら</router-link>からご確認いただけます。<br />&nbsp;・金融機関での手続き完了に2週間程度かかる場合があります。<br />&nbsp;※金融機関によっては、登録口座の暗証番号も入力する必要があります。</div>
                      </label>
                    </div>
                    <div class="form-item form-type-radio form-item-change-payment">
                      <input type="radio" id="edit-change-payment-04001-0" name="change_payment" value="04001-0" class="form-radio" v-model="payment"/>
                      <label class="option"><span class="payment-option-label"><strong>請求書（後払い.com）&nbsp;<a id="payment-method3" href="#" @click.prevent="isOpen3 = !isOpen3">▼詳細▼</a></strong>
                          <div :style="isOpen3 ? '' : 'display: none; visibility:hidden'" id="payment-description3">&nbsp;・請求書（請求代行サービス「後払い.com」を利用したお支払い）でのお支払いとなります。詳細は<router-link to="/mansion/faq/oshietepaymethod" target="_blank">こちら</router-link>からご確認ください。<br />&nbsp;・請求書でのお支払いは「お支払者住所」宛に請求書が送付されます。<br />&nbsp;※支払期日までにお支払いがない場合は、請求書が有償で再発行されますので、不在がちのお客様はご注意ください。</div>
                        </span></label>
                    </div>
                  </div>
                </div>
                <input formtarget="_self" type="submit" id="edit-back" name="back" value="変更しない" class="form-submit" @click.prevent.stop="$router.push('/mansion/')"/><input type="submit" id="edit-submit" name="op" value="支払方法を登録/変更する" class="form-submit" @click.prevent.stop="push()"/><br /><br />
                <p>
                  ※クレジットカード、口座振替の場合は、専用の入力ページ（三井住友カード株式会社の外部サイト）が開きますので、お手元にクレジットカードや通帳をご用意いただき、必要な情報を入力ください。<br />※「支払方法を登録/変更する」ボタンをクリックしても専用ページに切り替わらない場合は、他の端末からアクセスして手続きしてください。<br />詳しくは<router-link to="/mansion/faq/3378" target="_blank">こちら</router-link><br />※変更手続きが完了しても、お客様の会員情報ページへの反映には3日程度かかります。
                </p>
                <p>
                  <router-link to="/mansion/faq_category_list/4" target="_blank">お支払い、電気料金についての「よくあるご質問」はこちら</router-link>
                </p>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { paymentStore } from "../stores/store.js";
import { loginStore } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
import config from "../config.js";
import $ from 'jquery';
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    var login = loginStore();
    if(login.room.room_type == 1 || login.room.room_type == 2 || login.room.room_type == 3 ){
      this.$router.push({ path :"/mansion/access_error" });
    }
    var payment = {
      payment: "04003-0",
      account_transfer_bank_name: "",
      account_transfer_branch_name: "",
      account_transfer_account_holder: "",
      account_transfer_account_number: "",
      account_transfer_sign: "",
      credit_card_number: "",
      credit_card_expiration_date: ""
    }
    payment["BuildingName"] = login.room.building_name;
    payment["RoomNo"] = login.room.room_number;
    payment["paymentBefore"] = payment.payment;
    payment["error"] = undefined;
    payment["green"] = undefined;
    payment["isOpen1"] = false;
    payment["isOpen2"] = false;
    payment["isOpen3"] = false;
    return payment;
  },
  async created() {
    var green = undefined;
    var store = paymentStore();
    if(Object.keys(store.now).length != 0){
      green = store.now.green;
      store.$reset();
    }
    var payment = await getData(config.url.get.payment);
    //{ payment,account_transfer_bank_name,account_transfer_branch_name,account_transfer_account_holder,account_transfer_account_number,account_transfer_sign,
    // credit_card_number, credit_card_expiration_date}
    if(payment.payment == '04003-1'){
      payment.payment = '04003-0';
      payment["paymentBefore"] = payment.payment;
    }else if(payment.payment != "04001-0" 
      && payment.payment != "04002-0"
      && payment.payment != "04003-0"){
        payment["paymentBefore"] = payment.payment;
        payment.payment = '04003-0';
    }else{
      payment["paymentBefore"] = payment.payment;
    }
    payment["green"] = green;
    store.$reset();
    Object.assign(this.$data, payment);
  },
  methods : {
    push : async function(){
      var store = paymentStore();
      var paymentMethod = 0;
      switch(this.$data.payment){
        case "04003-0" :
          paymentMethod = 2;
          break;
        case "04002-0" :
          paymentMethod = 1;
          break;
      }
      if(this.$data.payment == "04001-0"){
        if(this.$data.payment == this.$data.paymentBefore){
          this.$data.error = "お客様のお支払方法は、すでに「請求書」になっているため、変更手続きの必要はございません。";
          document.body.scrollIntoView();
        }else{
          store.now = this.$data;
          store.update = { payment : this.$data.payment };
          this.$router.push({
            path : "/mansion/change/payment_confirm"
          });
        }
      }else{
          var date = new Date();
          var time = date.getTime().toString();
          var newwindow = window.open("","newwindow" + time);
          try{
            var json = await getData(config.url.get.payment_station + "?paymentMethod=" + paymentMethod);
            var form = $('<form />', {
              method: 'post',
              action: json.url,
              target: 'newwindow' + time,
              "accept-charset" : "Shift_JIS"
            });
            for(var key in json.param){
              form.append($('<input />', {
                type: 'hidden',
                name: key,
                value: json.param[key],
              }));
            }
            form.appendTo(document.body);
            form.submit();
            form.remove();
          }catch(ex){
            newwindow.close();
            throw ex;
          }
      }
    }
  }
};
</script>

import MEMS from "./mems";
import $ from "jquery";
window.jQuery = $;
import 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-ja';
/**
 * @file
 * JavaScript behaviors for the front-end display of webforms.
 */
MEMS.behaviors.inputRequiredError = {
  attach: function () {
    $("input").on("invalid", function (event) {
      var input = event.target;
      if ($(input).attr("type") != "radio") {
        if (input.validity.valueMissing) {
          input.setCustomValidity("これは入力必須項目です。");
        }
      }
    });
    $("input").on("input", function (event) {
      var input = event.target;
      input.setCustomValidity("");
    });
  },
};

("use strict");

MEMS.behaviors.webform = MEMS.behaviors.webform || {};

MEMS.behaviors.webform.attach = function (context) {
  // Calendar datepicker behavior.
  MEMS.webform.datepicker(context);

  // Conditional logic.
  if (MEMS.settings.webform && MEMS.settings.webform.conditionals) {
    MEMS.webform.conditional(context);
  }
};

MEMS.webform = MEMS.webform || {};

MEMS.webform.datepicker = function () {
  $("div.webform-datepicker").each(function () {
    var $webformDatepicker = $(this);
    var $calendar = $webformDatepicker.find("input.webform-calendar");

    // Ensure the page we're on actually contains a datepicker.
    if ($calendar.length == 0) {
      return;
    }

    var startDate = $calendar[0].className.replace(/.*webform-calendar-start-(\d{4}-\d{2}-\d{2}).*/, "$1").split("-");
    var endDate = $calendar[0].className.replace(/.*webform-calendar-end-(\d{4}-\d{2}-\d{2}).*/, "$1").split("-");
    var firstDay = $calendar[0].className.replace(/.*webform-calendar-day-(\d).*/, "$1");
    // Convert date strings into actual Date objects.
    startDate = new Date(startDate[0], startDate[1] - 1, startDate[2]);
    endDate = new Date(endDate[0], endDate[1] - 1, endDate[2]);

    // Ensure that start comes before end for datepicker.
    if (startDate > endDate) {
      var laterDate = startDate;
      startDate = endDate;
      endDate = laterDate;
    }

    var startYear = startDate.getFullYear();
    var endYear = endDate.getFullYear();

    // Set up the jQuery datepicker element.
    $calendar.datepicker({
      dateFormat: "yy-mm-dd",
      yearRange: startYear + ":" + endYear,
      firstDay: parseInt(firstDay),
      minDate: startDate,
      maxDate: endDate,
      changeMonth: true,
      changeYear: true,
      onSelect: function (dateText) {
        var date = dateText.split("-");
        $webformDatepicker.find("select.year, input.year").val(+date[0]).trigger("change");
        $webformDatepicker.find("select.month").val(+date[1]).trigger("change");
        $webformDatepicker.find("select.day").val(+date[2]).trigger("change");
      },
      beforeShow: function (input) {
        // Get the select list values.
        var year = $webformDatepicker.find("select.year, input.year").val();
        var month = $webformDatepicker.find("select.month").val();
        var day = $webformDatepicker.find("select.day").val();
        // If empty, default to the current year/month/day in the popup.
        var today = new Date();
        year = year ? year : today.getFullYear();
        month = month ? month : today.getMonth() + 1;
        day = day ? day : today.getDate();

        // Make sure that the default year fits in the available options.
        year = year < startYear || year > endYear ? startYear : year;

        // jQuery UI Datepicker will read the input field and base its date
        // off of that, even though in our case the input field is a button.
        $(input).val(year + "-" + month + "-" + day);
      },
    });

    // Prevent the calendar button from submitting the form.
    $calendar.next("input").on("click", function (event) {
      $(this).prev("input").trigger("focus");
      event.preventDefault();
    });
  });
};

MEMS.webform.conditional = function () {
  // Add the bindings to each webform on the page.
  $.each(MEMS.settings.webform.conditionals, function (formKey, settings) {
    var $form = $("." + formKey + ":not(.webform-conditional-processed)");
    $form.each(function (index, currentForm) {
      var $currentForm = $(currentForm);
      $currentForm.addClass("webform-conditional-processed");
      $currentForm.on("change", { settings: settings }, MEMS.webform.conditionalCheck);

      // Trigger all the elements that cause conditionals on this form.
      MEMS.webform.doConditions($currentForm, settings);
    });
  });
};

/**
 * Event handler to respond to field changes in a form.
 *
 * This event is bound to the entire form, not individual fields.
 */
MEMS.webform.conditionalCheck = function (e) {
  var $triggerElement = $(e.target).closest(".webform-component");
  var $form = $triggerElement.closest("form");
  var triggerElementKey = $triggerElement.filter("[class^='webform-component--']").first();
  var settings = e.data.settings;
  if (settings.sourceMap[triggerElementKey]) {
    MEMS.webform.doConditions($form, settings);
  }
};

/**
 * Processes all conditional.
 */
MEMS.webform.doConditions = function ($form, settings) {
  var stackPointer;
  var resultStack;

  /**
   * Initializes an execution stack for a conditional group's rules.
   *
   * Also initializes sub-conditional rules.
   */
  function executionStackInitialize(andor) {
    stackPointer = -1;
    resultStack = [];
    executionStackPush(andor);
  }

  /**
   * Starts a new subconditional for the given and/or operator.
   */
  function executionStackPush(andor) {
    resultStack[++stackPointer] = {
      results: [],
      andor: andor,
    };
  }

  /**
   * Adds a rule's result to the current sub-conditional.
   */
  function executionStackAccumulate(result) {
    resultStack[stackPointer]["results"].push(result);
  }

  /**
   * Finishes a sub-conditional and adds the result to the parent stack frame.
   */
  function executionStackPop() {
    // Calculate the and/or result.
    var stackFrame = resultStack[stackPointer];
    // Pop stack and protect against stack underflow.
    stackPointer = Math.max(0, stackPointer - 1);
    var $conditionalResults = stackFrame["results"];
    var filteredResults = $.map($conditionalResults, function (val) {
      return val ? val : null;
    });
    return stackFrame["andor"] === "or" ? filteredResults.length > 0 : filteredResults.length === $conditionalResults.length;
  }

  // Track what has been set/hidden for each target component's elements.
  // Hidden elements must be disabled because if they are required and don't
  // have a value, they will prevent submission due to html5 validation.
  // Each execution of the conditionals adds a temporary class
  // webform-disabled-flag so that elements hidden or set can be disabled and
  // also be prevented from being re-enabled by another conditional (such as a
  // parent fieldset). After processing conditionals, this temporary class
  // must be removed in preparation for the next execution of the
  // conditionals.
  $.each(settings.ruleGroups, function (rgid_key) {
    var ruleGroup = settings.ruleGroups[rgid_key];

    // Perform the comparison callback and build the results for this group.
    executionStackInitialize(ruleGroup["andor"]);
    $.each(ruleGroup["rules"], function (m, rule) {
      switch (rule["source_type"]) {
        case "component":
          var elementKey = rule["source"];
          var element = $form.find("." + elementKey)[0];
          var existingValue = settings.values[elementKey] ? settings.values[elementKey] : null;
          executionStackAccumulate(window["MEMS"]["webform"][rule.callback](element, existingValue, rule["value"]));
          break;

        case "conditional_start":
          executionStackPush(rule["andor"]);
          break;

        case "conditional_end":
          executionStackAccumulate(executionStackPop());
          break;
      }
    });
    var conditionalResult = executionStackPop();

    $.each(ruleGroup["actions"], function (aid, action) {
      var $target = $form.find("." + action["target"]);
      var actionResult = action["invert"] ? !conditionalResult : conditionalResult;
      switch (action["action"]) {
        case "show":
          var changed = actionResult != MEMS.webform.isVisible($target);
          if (actionResult) {
            $target.find(".webform-conditional-disabled:not(.webform-disabled-flag)").removeClass("webform-conditional-disabled").prop("disabled", false);
            $target.removeClass("webform-conditional-hidden").show();
            $form.find(".chosen-disabled").prev().trigger("chosen:updated.chosen");
          } else {
            $target.hide().addClass("webform-conditional-hidden").find(":input").addClass("webform-conditional-disabled webform-disabled-flag").prop("disabled", true);
          }
          if (changed && $target.is("tr")) {
            MEMS.webform.restripeTable($target.closest("table").first());
          }
          break;

        case "require":
          var $requiredSpan = $target.find(".form-required, .form-optional").first();
          if (actionResult != $requiredSpan.hasClass("form-required")) {
            var $targetInputElements = $target.find("input:text,textarea,input[type='email'],select,input:radio,input:file");
            // Rather than hide the required tag, remove it so that other
            // jQuery can respond via MEMS behaviors.
            MEMS.detachBehaviors($requiredSpan);
            $targetInputElements.prop("required", actionResult).toggleClass("required", actionResult);
            if (actionResult) {
              $requiredSpan.replaceWith('<span class="form-required" title="このフィールドは入力必須です。">*</span>');
            } else {
              $requiredSpan.replaceWith('<span class="form-optional"></span>');
            }
            MEMS.attachBehaviors($requiredSpan);
          }
          break;

        case "set":
          var $texts = $target.find("input:text,textarea,input[type='email']");
          var $selects = $target.find("select,select option,input:radio,input:checkbox");
          var $markups = $target.filter(".webform-component-markup");
          if (actionResult) {
            var multiple = $.map(action["argument"].split(","), $.trim);
            $selects.webformVal(multiple).prop("disabled", true).addClass("webform-disabled-flag");
            $texts.val([action["argument"]]).prop("readonly", true).addClass("webform-disabled-flag");
            // A special case is made for markup. It is sanitized with
            // filter_xss_admin on the server. otherwise text() should be used
            // to avoid an XSS vulnerability. text() however would preclude
            // the use of tags like <strong> or <a>.
            $markups.html(action["argument"]);
          } else {
            $selects.not(".webform-disabled-flag").prop("disabled", false);
            $texts.not(".webform-disabled-flag").prop("readonly", false);
            // Markup not set? Then restore original markup as provided in
            $markups.each(function () {
              var $this = $(this);
              var original = $this.data("webform-markup");
              if (original !== undefined) {
                $this.html(original);
              }
            });
          }
          break;
      }
    }); // End look on each action for one conditional.
  }); // End loop on each conditional.

  $form.find(".webform-disabled-flag").removeClass("webform-disabled-flag");
};

/**
 * Utility to return current visibility.
 *
 * Uses actual visibility, except for hidden components which use the applied
 * disabled class.
 */
MEMS.webform.isVisible = function ($element) {
  return $element.hasClass("webform-component-hidden") ?
    !$element.find("input").first().hasClass("webform-conditional-disabled") :
     $element.closest(".webform-conditional-hidden").length == 0;
};

/**
 * Make a multi-valued val() function.
 *
 * This is for setting checkboxes, radios, and select elements.
 */
$.fn.webformVal = function (values) {
  this.each(function () {
    var $this = $(this);
    var value = $this.val();
    var on = $.inArray($this.val(), values) != -1;
    if (this.nodeName == "OPTION") {
      $this.prop("selected", on ? value : false);
    } else {
      $this.val(on ? [value] : false);
    }
  });
  return this;
};

/**
 * Given a table's DOM element, restripe the odd/even classes.
 */
MEMS.webform.restripeTable = function (table) {
  // :even and :odd are reversed because jQuery counts from 0 and
  // we count from 1, so we're out of sync.
  // Match immediate children of the parent element to allow nesting.
  $("> tbody > tr, > tr", table)
    .filter(":visible:odd")
    .filter(".odd")
    .removeClass("odd")
    .addClass("even")
    .end()
    .end()
    .filter(":visible:even")
    .filter(".even")
    .removeClass("even")
    .addClass("odd");
};

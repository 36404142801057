<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-portalmail page-portalmail-setting responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">入力内容の確認</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <h1 class="title">入力内容の確認</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq/oshietemailsettei" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <form enctype="multipart/form-data" action="#" method="post" id="ennevision-member-portalmail-form" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div class="preview">
                  <div class="ennevision-dr-point">
                    <div id="edit-ennevision-mail" class="form-item form-type-item">
                      <label for="edit-ennevision-mail">ポータルサイトメールアドレス:</label>
                      <span class="field-value">{{ update.PortalEmail }}</span>
                    </div>
                    <div id="edit-ennevision-sub-mail" class="form-item form-type-item">
                      <label for="edit-ennevision-sub-mail">サブメールアドレス（任意）:</label>
                      <span class="field-value" v-if="PortalSubEmail != ''">{{ update.PortalSubEmail }}</span>
                      <span class="field-value" v-else>なし</span>
                    </div>
                    <div id="edit-ennevision-notice" class="form-item form-type-item">
                      <label for="edit-ennevision-notice">お知らせメール通知設定:</label>
                      <span class="field-value" v-if="update.ValidFlag">送信する</span>
                      <span class="field-value" v-else>送信しない</span>
                    </div>
                  </div>
                </div>
                <div class="form-item form-type-password form-item-auth-pass">
                  <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                  <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password"/>
                  <div class="description">でんき案内板のパスワードを入れてください。パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link></div>
                </div>
                <input type="submit" id="edit-back" name="back" value="戻る" class="form-submit" @click.prevent.stop="back()" :disabled="send"/><input type="submit" id="edit-submit" name="submit" value="登録" class="form-submit" @click="check" :disabled="send"/>
                <div class="mail_regist description">「登録」をクリックすると、メールアドレス変更手続きのご案内を、ポータルサイトメールアドレス欄に入力したアドレスにお送りします。</div>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Footer from "../components/Footer.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue"
import { mailStore } from "../stores/store.js";
import { put } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Footer,
    Sidebar_first_login
  },
  data() {
    var store = mailStore();
    if(Object.keys(store.update).length == 0){
      this.$router.push({ path: 'portalmail/setting' });
      return {};
    }
    var update = store.update;
    store.$reset();
    return { 
      update :update,
      password : "",
      auth_error : false,
      send : false
     };
  },
  methods: {
    back: function(){
        var store = mailStore();
        store.update = this.$data.update;
        this.$router.push({ path: "/mansion/portalmail/setting" });
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push: async function () {
      var store = mailStore();
      try{
        this.$data.send = true;
        this.$data.auth_error = false;
        await put(config.url.put.mail, {
            customer : {
              portal_email : this.$data.update.PortalEmail, 
              portal_sub_email : this.$data.update.PortalSubEmail, 
              mail_setting2 : this.$data.update.ValidFlag ? 1 :0,
            },
            password : this.$data.password
        });
        store.$reset();
        store.update.green = 1;
        this.$router.push({ path: "/mansion/portalmail/setting" });
      }catch(ex){
          store.$reset();
          if(ex.response.data == "PasswordError"){
            this.$data.send = false;
            this.$data.auth_error = true;
            document.body.scrollIntoView();
          }else{
            throw ex;
          }
      }
    },
  },
};
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
    <div
        class="html not-front logged-in one-sidebar sidebar-first page-dr page-dr-agreement responsive-menus-load-processed">
        <Header_login />
        <!-- ______________________ MAIN _______________________ -->
        <div id="main">
            <div class="container">
                <h2 class="element-invisible">現在地</h2>
                <div class="breadcrumb">
                    <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span>
                </div>
                <section id="content" :style="wait ? 'cursor : wait' : ''">
                    <h1 class="title">節電ポイントサービス　利用規約</h1>
                    <div id="content-area">
                        <form action="#" method="post" id="ennevision-graph-agreement-form" accept-charset="UTF-8"
                            @submit.prevent="">
                            <div>
                                <div id="edit-agreement-form" class="form-wrapper">
                                    <div style="height:400px; overflow: auto; border: 1px solid #999;">
                                        <pre>NTTアノードエナジー　節電ポイントサービス　利用規約
【2023年6月30日　制定】

　「NTTアノードエナジー　節電ポイントサービス」（以下、「本サービス」といいます。）利用規約（以下、「本規約」といいます。）は、NTTアノードエナジー株式会社（以下、「当社」といいます。）が実施する本サービスに関する取り扱いを定めたものです。

1．本サービスの内容
当社の電力提供サービス利用契約をご契約のうえで本規約「４．適用条件等」に定める対象の料金メニューをご利用中であるお客様は、本サービスに参加のお申込みをいただき、本規約「5．節電の取組みと節電ポイント」（6）に定める当社からの節電チャレンジ（「節電チャレンジ」とは、本規約「5．節電の取組みと節電ポイント」に定めるお客様の節電の取組みをいいます。以下、本規約において同じとします。）のお知らせメールに従いお客様が節電した電力量に応じて獲得した節電ポイントに基づき、電気料金の割引を受けることができます。

2．本サービスの適用期間
（1）本サービスは、参加お申込日の翌日から参加できるものとします。
※システム反映の状況により、参加日が数日前後する場合があります。

（2）本サービスによる特典が付与される時期は、本規約「7．特典付与時期」で定めるものとします。

3．定義
当社の電力提供サービス利用契約約款（マンション電力提供サービス[専有部分用]）および、料金メニュー表、選択料金メニュー表、選択規約に定義される用語は、本規約においても同様の意味で使用します。

4．適用条件等
当社は、お客様が以下の全ての条件を満たした場合に、本サービスを適用します。なお、一部の条件を満たさない場合でもあっても、当社が必要と認めたときには、本サービスを適用することがあります。

（1）本規約の全てに同意の上、「でんき案内板」より本サービスへの参加のお申込みをいただき、当社がこれを承諾したこと。
お申込みに対する承諾は、本規約「5．節電の取組みと節電ポイント」（6）に従い当社からお送りする節電チャレンジのお知らせメールの送信をもって行うものとします。
なお、当社が実施した「節電推進プログラム2022」に参加いただいたお客様で、引き続き本項に定める適用条件を全て満たしている場合は、自動的に本サービスを適用します。

（2）本サービスへの参加お申込み時点から特典付与手続きの開始時点までの間、専有部住居にて対象の料金メニューが継続して適用されていたお客様であること。
＜対象の料金メニュー＞
専有部分用・LLプラン（全電力会社管内）、専有部分用・従量電灯A　関西、専有部分用・従量電灯B（全電力会社管内）、専有部分用・従量電灯C（全電力会社管内）、専有部分用・エネビジョンプラン（全電力会社管内）、専有部分用・季節別時間帯別電灯　東京、専有部分用・スマート電化プラン　東京、専有部分用・時間帯別電灯[夜間８時間型]　東京、専有部分用・時間帯別電灯[夜間10時間型]　東京、専有部分用・選択規約　PVプラン料金適用規約　東京、専有部分用・選択規約　PTプラン料金適用規約
なお、店舗、共用部にてご利用のお客様は本サービスの対象外となります。

（3）本サービスへの参加お申込み時点から特典付与手続きの開始時点までの間、「でんき案内板」にお客様のメールアドレスを登録していること。

（4）対象の料金メニューが適用されている需要場所に通信機器を有したスマートメーターが設置されていること。

（5）本サービスに関連して、不正に特典を取得したことが発覚した場合、当社からの特典相当額の返還要請を受けた場合に速やかに返還に応じること。

5．節電の取組みと節電ポイント
（1）本サービスでは、（2）ⅠおよびⅡに基づき設定される、お客様ごとの標準的な使用量から実際の使用量を差し引いた残りの値を節電量として定義します。節電量の計算は、30分値を1単位として1単位ごとに行い、節電チャレンジの対象時間帯毎に合計し、小数点以下第2位を四捨五入し算定します。なお、節電チャレンジの対象時間帯毎に合計された標準的な使用量より、節電チャレンジの対象時間帯毎に合計された実際の使用量が大きい場合は、節電量を0kWhとして取り扱います。

（2）標準的な使用量は、お客様ごとの過去の電気の使用状況を活用し、「エネルギー・リソース・アグリゲーション・ビジネスに関するガイドライン」（資源エネルギー庁・令和2年6月1日最終改定）に基づき算定します。

Ⅰ. 節電チャレンジ対象日が平日の場合
①節電チャレンジ実施日の直近5日間（節電チャレンジ対象日当日を含まない）のうち節電チャレンジの対象時間帯における使用量の多い4日間の平均使用量の30分単位の平均値を算出します。
なお、直近5日間において、節電チャレンジの対象時間帯の平均使用量の最小日が複数ある場合は、節電チャレンジ対象日から最も遠い1日を除き、残りの4日間を採用します。
ただし、次に掲げる日については、上記の母数となる直近5日間から除外するものとします。その際、当該母数が5日間となるよう、節電チャレンジ対象日から過去30日以内（平日及び土曜日・日曜日・祝日）で更に日を遡り、直近日を設定します。
・土曜日・日曜日・祝日
・過去の節電チャレンジ対象日
・(5)に定める節電チャレンジの対象時間帯における使用量の平均値が、直近5日間（土日祝日および節電チャレンジ対象日を除く）の節電チャレンジ対象時間帯における使用量の総平均値の25％未満の場合は、当該日。

②節電チャレンジの対象時間帯の5時間前から2時間前までの30分値の6単位について、「節電チャレンジ対象日当日の使用量－上記①の算出方法により算出された値」の平均値を算出します。
③上記①で算出された値における節電チャレンジ対象時間帯の30分値の各単位に、上記②で算出された値を加算したものを、標準的な使用量とします。ただし、算出された標準的な使用量がマイナスになる場合は、マイナスとなる時間帯の標準的な使用量をゼロに補正することとします。

Ⅱ. 節電チャレンジ対象日が土曜日・日曜日・祝日の場合
①節電チャレンジ実施日の直近3日間（節電チャレンジ対象日当日を含まない）のうち節電チャレンジの対象時間帯の平均使用量の多い2日間の平均使用量の30分単位の平均値を算出します。
なお、直近3日間において、節電チャレンジ対象時間帯の平均使用量の最小日が複数ある場合は、節電チャレンジ対象日から最も遠い1日を除き、残りの2日間を採用します。
ただし、次に掲げる日については、上記の母数となる直近3日間から除外するものとします。その際、当該母数が3日間となるよう、節電チャレンジ対象日から過去30日以内（平日及び土曜日・日曜日・祝日）で更に日を遡り、直近日を設定します。
・平日
・過去の節電チャレンジ対象日
・(5)に定める節電チャレンジの対象時間帯における使用量の平均値が、直近３日間（平日および節電チャレンジ対象日を除く）の節電チャレンジ対象時間帯における使用量の総平均値の25％未満の場合は、当該日。

②節電チャレンジ対象時間帯の5時間前から2時間前までの30分値の6単位について、「節電チャレンジ対象日当日の使用量－上記①の算出方法により算出された値」の平均値を算出します。

③上記①で算出された値における節電チャレンジ対象時間帯の30分値の各単位に、上記②で算出された値を加算したものを、標準的な使用量とします。ただし、算出された標準的な使用量がマイナスになる場合は、マイナスとなる時間帯の標準的な使用量をゼロに補正することとします。

（3）本サービスでは対象の料金メニューが適用されている需要場所に設置されたスマートメーターから送られる30分値の使用量をもとに節電量を計算します。スマートメーター未設置やシステム障害、通信障害などにより、使用量データが欠損していた場合は節電チャレンジの節電量の算定および特典付与の対象外です。（2）に基づき設定される標準的な使用量の算定にあたり必要となる日数がたりない場合は、節電チャレンジの節電量の算定および特典付与の対象外になる場合があります。

（4）当社は、本サービスが適用されるお客様に対して、（5）で定める節電チャレンジの対象時間帯の節電量に応じて、節電ポイントを付与します。節電チャレンジの対象時間帯は節電量1kWhあたり、10節電ポイントを付与します。なお、節電量が1kWhに満たない場合は切り捨てとなります。

（5）節電チャレンジの対象時間帯は、当社が任意で設定します。なお、節電チャレンジを設定しない日、時間帯がありますのでご了承ください。

（6）節電チャレンジの対象時間帯は、当社から送信する節電チャレンジのお知らせメールにてご確認いただけます。節電チャレンジのお知らせメールは、本サービス参加お申込み時点で「でんき案内板」に登録されているポータルサイトメールアドレス宛に送付します。

（7）節電チャレンジで獲得した節電ポイントはメールにて通知します。

6．特典内容
1節電ポイント＝1円と換算し、獲得された節電ポイントに応じて電気料金を割引します。

7．特典付与時期
原則、節電チャレンジ実施日の翌月の電気料金のご請求額から節電ポイントに応じて割引します。なお、システムへの反映状況によって、同月の翌月以降のご請求からの割引となる場合があります。

8．注意事項
（1）本サービスは、参加申し込みの時点で当社との間で本規約「4．適用条件等」（２）に定める＜対象の料金メニュー＞に係る利用契約が成立しているお客様のみが対象となります。

（2）本サービスへの参加中に、本規約「4．適用条件等」（２）に定める＜対象の料金メニュー＞に係る利用契約の廃止または解約がなされた場合は、これらにより利用契約が消滅する日をもって節電ポイントの算定を終了します。

（3）本サービスへの参加お申込み時点で「でんき案内板」のメールアドレス登録が完了していない場合や配信停止設定をしている場合、登録メールアドレスを変更した場合、迷惑メール対策として本サービスのメールアドレスのドメイン「@ sg-m.jp」を 受信設定していない場合は、本規約「5．節電の取組みと節電ポイント」（6）に定める節電チャレンジのお知らせメールを含む本サービスに関するお知らせメールが配信されないことがあります。

（4）本サービスへの参加、お問合せにかかる通信料はお客様の負担となります。

（5）本サービスは予告なく変更または終了する場合があります。

（6）当社は、本サービスに関連して、当社の責めに帰すべき事由によりお客様に生じた損害については、当社に故意または重大な過失がある場合を除き、逸失利益を除く通常かつ現実の損害に限って、本サービスによってお客様が獲得された節電ポイントの合計ポイントに相当する割引額を上限として、賠償するものとします。

（7）当社は、今後本サービスとは別に国や地方公共団体等が実施する節電関連事業（以下、「国等の事業」といいます。）に参画することがあります。その場合、本サービスに参加申込みしたお客様がそのまま国等の事業へ参加するために、お客様の利用契約に係る個人情報（利用契約名義、住所、電話番号等）を、必要な範囲で国等の事業の事務局等へ提供することがあります。

（8）生活に支障をきたさない範囲で本サービスにおける節電をお願いします。

以上
                                </pre>
                                    </div>
                                    <div id="edit-agreement" class="form-radios">
                                        <div class="form-item form-type-radio form-item-agreement">
                                            <input type="radio" id="edit-agreement-agree" name="agreement"
                                                class="form-radio" value="ok" v-model="agree"> <label class="option"
                                                for="edit-agreement-agree">利用規約に同意する </label>
                                        </div>
                                    </div><input type="submit" id="edit-agree" name="op" value="参加する"
                                        class="form-submit" :style="agree != 'ok' ? 'color: #ddd; background-color: #ddd' : ''" :disabled="agree != 'ok' || wait" @click="join"><input type="submit"
                                        id="edit-disagree" name="op" value="参加しない" class="form-submit" :disabled="wait" @click="back">
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <!-- /content-inner /content -->
                <Sidebar_first_login />
            </div>
        </div>
        <!-- /main -->
        <Footer />
    </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { getData, put } from "../assets/js/request.js";
export default {
    components: {
        Header_login,
        Sidebar_first_login,
        Footer,
    },
    data() {
        return { agree: "", wait : false };
    },
    async created() {

    },
    methods: {
        async join() {
            this.$data.wait = true;
            await put(config.url.put.dr, {
                customer: { dr: "1" },
            });
            await this.check();
        },
        async check() {
            try
            {
                for(var count = 1; count <= 12; count++){
                    var customer = await getData(config.url.get.customer);
                    if (customer.dr) {
                        this.$data.wait = false;
                        this.$router.push({ path: "/mansion/condition" });
                        break;
                    }
                    if (count == 12) {
                        throw new Error("retry error");
                    }
                    await new Promise(resolve => setTimeout(resolve, 5000));
                }
            } finally {
                this.$data.wait = false;
            }
        },
        back: function () {
            this.$router.push({ path: "/mansion/condition" });
        }
    },
};
</script>
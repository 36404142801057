<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-member page-member-procedure page-member-procedure-completepayer-tel responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">お支払者様情報の変更申請完了</span>
        </div>
        <section id="content">
          <h1 class="title">お支払者様情報の変更申請完了</h1>
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_6 view-dom-id-ae7c39f445d39e72e8bc4431807080c3">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed' + (buildingIsOpen ? '': ' collapsed') " data-module="views_fieldsets" >
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="buildingIsOpen = !buildingIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span></span>
                    </legend>

                    <div class="fieldset-wrapper" v-show="buildingIsOpen">
                      <div class="views-field views-field-value">
                        <span class="field-content"
                          ><p><strong>部屋番号：&nbsp;</strong>{{ now.RoomNo }}</p>
                        </span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed' + (payerIsOpen ? '': ' collapsed') " data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"><a href="#" @click.prevent.stop="payerIsOpen = !payerIsOpen" class="fieldset-title" ><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払者様情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="payerIsOpen">
                      <div class="views-field views-field-value-23"><strong class="views-label views-label-value-23">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ update.payer_phone }}</span></div>
                      <div class="views-field views-field-value-1"><strong class="views-label views-label-value-1">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content"> 
                        <template v-if="update.delete1">削除</template>
                          <template v-else>{{ update.payer_tel }}</template>
                      </span></div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <div id="top_link"><router-link to="/mansion/member/profile">会員情報ページへ</router-link></div>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { payerTelStore } from "../stores/store.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = payerTelStore();
    if(Object.keys(store.update).length == 0){
      this.$router.push({
        path: '/mansion/member/procedure',
          query: {
            type : "payer_tel"
          }
      });
    }
    var now = store.now;
    var update = store.update;
    store.$reset();
    return {
      buildingIsOpen : true,
      payerIsOpen : true,
      now: now,
      update : update
    };
  },
};
import "../assets/js/webform.js";
</script>


import $ from "jquery";
export const init = function () {
  var select_val = $("#edit-submitted-webform-group-inquiry-field-inquiry-type").val();
  if (select_val == 3 || select_val == 4) {
    $(".webform-component--webform-group-other-info.form-wrapper legend").css("display", "none");
    $(".webform-component--webform-group-other-info.form-wrapper .fieldset-wrapper").css("margin-top", "-50px");
  } else if (select_val == 6) {
    $(".logged-in form#webform-client-form-77 .form-actions").css("display", "none");
  }

  $("#edit-submitted-webform-group-inquiry-field-inquiry-type").on("change", function () {
    var select_val = $("#edit-submitted-webform-group-inquiry-field-inquiry-type").val();
    if (select_val == 3 || select_val == 4) {
      $(".webform-component--webform-group-other-info.form-wrapper legend").css("display", "none");
      $(".webform-component--webform-group-other-info.form-wrapper .fieldset-wrapper").css("margin-top", "-50px");
    } else {
      $(".webform-component--webform-group-other-info.form-wrapper legend").css("display", "block");
      $(".webform-component--webform-group-other-info.form-wrapper .fieldset-wrapper").css("margin-top", "0px");
    }
    if (select_val == 6) {
      $(".logged-in form#webform-client-form-77 .form-actions").css("display", "none");
    } else {
      $("form#webform-client-form-77 .form-actions").css("display", "block");
    }
  });

  $(document).on("keydown", "#edit-submitted-field-address-other", function (e) {
    if (e.code === 13) {
      return false; //入力キーを無視
    }
  });

  $(document).on("click", "#calculate_button", function () {
    calculate();
  });
  var calculate = function () {
    var from_year = parseInt($("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year").val());
    var from_month = parseInt($("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-month").val());
    var to_year = parseInt($("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year").val());
    var to_month = parseInt($("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-month").val());
    var value = "";
    if (from_year > to_year) {
      value = "";
    } else if (from_year == to_year) {
      if (from_month > to_month) {
        value = "";
      } else {
        value = 516;
      }
    } else {
      var month = Math.abs((Number(to_year) * 12 + Number(to_month)) - (Number(from_year) * 12 + Number(from_month))) + 1;
      if (month % 12 == 0) {
        value = 516 * Math.trunc(month / 12);
      } else {
        value = 516 * (Math.trunc(month / 12) + 1);
      }
    }
    $("#edit-submitted-webform-group-payment-certificate-field-issuance-fee").val(value);
  };

  var term_change = function () {
    var from_year = $("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year").val();
    var from_month = $("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-month").val();
    var to_year = $("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year").val();
    var to_month = $("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-month").val();
    var month = Math.abs((Number(to_year) * 12 + Number(to_month)) - (Number(from_year) * 12 + Number(from_month))) + 1;
    if (isNaN(month)) {
      month = 1;
    }
    var text = "の" + month + "か月分";
    if ($(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description").next("#term_info").length > 0) {
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description").next("#term_info").text(text);
    } else {
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description").after("<span id='term_info'>" + text + "</span>");
    }
    if (from_month == 12) {
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-from .description .billing_year_from").text(+from_year + 1);
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-from .description .billing_month_from").text(1);
    } else {
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-from .description .billing_year_from").text(from_year);
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-from .description .billing_month_from").text(+from_month + 1);
    }
    if (to_month == 12) {
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description .billing_year_to").text(+to_year + 1);
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description .billing_month_to").text(1);
    } else {
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description .billing_year_to").text(to_year);
      $(".webform-component--webform-group-payment-certificate--field-payment-certificate-term-to .description .billing_month_to").text(+to_month + 1);
    }
  };
  $(document).on("change", "#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year", function () {
    term_change();
  });
  $(document).on("change", "#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-month", function () {
    term_change();
  });
  $(document).on("change", "#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year", function () {
    term_change();
  });
  $(document).on("change", "#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-month", function () {
    term_change();
  });
  term_change();
};
//$(document).on("ready", init);

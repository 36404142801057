
<template>
  <aside id="sidebar-first">
    <div class="block block-system left-main-menu block-menu" data-bid="264">
      <ul class="menu">
        <li class="first last leaf"><router-link to="/mansion/member/profile">会員情報の確認・変更</router-link></li>
      </ul>
    </div>
    <!-- /block -->
    <div class="block block-accordion-menu left-sub-menu" data-bid="302">
      <div class="accordion-menu-wrapper accordion-menu-1 accordion-menu-name-サブメニュー（アコーディオン） accordion-menu-source-menu-submenu ui-accordion ui-widget ui-helper-reset" role="tablist">
        <h3 class="accordion-header accordion-header-1 first no-children item-depth-1 odd menu-mlid-1236 ui-accordion-header ui-helper-reset ui-state-default ui-corner-all ui-accordion-icons" role="tab" id="ui-accordion-1-header-0" aria-controls="ui-accordion-1-panel-0" aria-selected="false" tabindex="0"><span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"></span><router-link to="/mansion/change/payment" class="accordion-link">支払方法の変更</router-link></h3>
        <div class="accordion-content-1 ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom" id="ui-accordion-1-panel-0" aria-labelledby="ui-accordion-1-header-0" role="tabpanel" aria-expanded="false" aria-hidden="true" style="display: none"></div>
        <h3 class="accordion-header accordion-header-2 no-children item-depth-1 even menu-mlid-1237 ui-accordion-header ui-helper-reset ui-state-default ui-corner-all ui-accordion-icons" role="tab" id="ui-accordion-1-header-1" aria-controls="ui-accordion-1-panel-1" aria-selected="false" tabindex="-1"><span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"></span><router-link to="/mansion/member/procedure?type=payer" class="accordion-link">支払者情報の変更</router-link></h3>
        <div class="accordion-content-2 ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom" id="ui-accordion-1-panel-1" aria-labelledby="ui-accordion-1-header-1" role="tabpanel" aria-expanded="false" aria-hidden="true" style="display: none"></div>
        <h3 class="accordion-header accordion-header-3 no-children item-depth-1 odd menu-mlid-1238 ui-accordion-header ui-helper-reset ui-state-default ui-corner-all ui-accordion-icons" role="tab" id="ui-accordion-1-header-2" aria-controls="ui-accordion-1-panel-2" aria-selected="false" tabindex="-1"><span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"></span><router-link to="/mansion/member/procedure?type=departure" title="" class="accordion-link">退去手続き</router-link></h3>
        <div class="accordion-content-3 ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom" id="ui-accordion-1-panel-2" aria-labelledby="ui-accordion-1-header-2" role="tabpanel" aria-expanded="false" aria-hidden="true" style="display: none"></div>
        <h3 style="cursor: pointer;" class="accordion-header accordion-header-4 has-children item-depth-1 even menu-mlid-510 ui-accordion-header ui-helper-reset ui-state-default ui-corner-all ui-accordion-icons" role="tab" id="ui-accordion-1-header-3" aria-controls="ui-accordion-1-panel-3" aria-selected="false" tabindex="-1" @click.prevent.stop="isMenuOpen = !isMenuOpen"><span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-e"></span><span title="" class="accordion-link">その他変更手続き</span></h3>
        <div class="accordion-content-4 ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom" id="ui-accordion-1-panel-3" aria-labelledby="ui-accordion-1-header-3" role="tabpanel" aria-expanded="false" aria-hidden="true" v-show="isMenuOpen">
          <ul class="menu">
            <li class="first leaf"><router-link to="/mansion/password/setting">パスワードの変更</router-link></li>
            <li class="leaf"><router-link to="/mansion/portalmail/setting">メールアドレスの変更</router-link></li>
            <li class="leaf"><router-link to="/mansion/member/profile#contractor" title="">契約者情報の変更</router-link></li>
            <li class="leaf"><router-link to="/mansion/member/profile#payer" title="">支払者情報の変更</router-link></li>
            <li class="leaf"><router-link to="/mansion/change/payment">支払方法の変更</router-link></li>
            <li class="leaf"><router-link to="/mansion/member/procedure?type=fee_detail">料金明細確認方法の変更</router-link></li>
            <li class="last leaf"><router-link to="/mansion/member/procedure?type=departure" title="">退去手続き</router-link></li>
          </ul>
        </div>
        <h3 class="accordion-header accordion-header-5 last no-children item-depth-1 active-trail active odd menu-mlid-512 ui-accordion-header ui-helper-reset ui-state-default ui-corner-all ui-accordion-icons" role="tab" id="ui-accordion-1-header-4" aria-controls="ui-accordion-1-panel-4" aria-selected="false" tabindex="-1">
          <span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"></span><router-link to="/mansion/faq_top" id="id_faq_mypage" class="active-trail accordion-link active">よくあるご質問</router-link>
        </h3>
        <div class="accordion-content-5 ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom" id="ui-accordion-1-panel-4" aria-labelledby="ui-accordion-1-header-4" role="tabpanel" aria-expanded="false" aria-hidden="true" style="display: none"></div>
      </div>
    </div>
    <!-- /block -->
    <div class="block block-menu-block left-bn-01" data-bid="572">
      <div class="menu-block-wrapper menu-block-1 menu-name-menu-sub-menu-2 parent-mlid-0 menu-level-1">
        <ul class="menu">
          <li class="first leaf menu-mlid-1433 link-inquiry"><router-link to="/mansion/inquiry">お問い合わせ</router-link></li>
          <li class="leaf menu-mlid-1747 download"><router-link to="/mansion/document_download">資料ダウンロード</router-link></li>
          <li class="leaf menu-mlid-1946 fuel-cost-adjustment"><router-link to="/mansion/nencho">燃料費調整単価</router-link></li>
          <template v-for="b in banner" :key="b">
            <li :style="'background: #fff url(' + b.image_path + ') no-repeat center center;'">
              <a :href="b.link_path" style="height:60px">&nbsp;</a>
            </li>
          </template>
          <!--
          <li class="leaf menu-mlid-1232 link-mansion"><router-link to="/mansion/setsuden2023">マンション電力提供サービスとは</router-link></li>
          <li class="leaf menu-mlid-1948 link-gekihen"><router-link to="/mansion/gekihen">「電気・ガス価格激変緩和対策事業」電気料金の値引きについて</router-link></li>
          <li class="leaf menu-mlid-1377 link-power-cut"><router-link to="/mansion/faq/oshietedenki">停電したときは？</router-link></li>
          <li class="last leaf menu-mlid-1836 safety"><router-link to="/mansion/safety">電気設備を安全にご使用いただくために</router-link></li>
          -->
        </ul>
      </div>
    </div>
    <!-- /block -->
    <div class="block block-ennevision-mypage left-change-theme" data-bid="237">
      <h3 class="title">テーマの切り替え</h3>

      <div class="change_theme">
        <div class="ennevision">
          <a href="#" @click.prevent.stop="change(undefined)"><span>ennevision</span></a>
        </div>
        <div class="ennevision_green">
          <a href="#" @click.prevent.stop="change('green')"><span>ennevision</span></a>
        </div>
        <div class="ennevision_red">
          <a href="#" @click.prevent.stop="change('red')"><span>ennevision</span></a>
        </div>
      </div>
    </div>
    <!-- /block -->
  </aside>
  <!-- /sidebar-first -->
</template>
<script>
import $ from "jquery";
import config from "../config.js"
import { getData } from "../assets/js/request.js";
export default {
  name: "Sidebar_first_login",
  data(){
    var color = localStorage.getItem("color");
    this.change(color);
    return { 
      banner : [],
      color : color,
      isMenuOpen: false
    };
  },
  async created(){
    var banner = await getData(config.url.get.banner);
    this.$data.banner = banner;
  },
  methods : {
    change: function (color) {
      this.$data.color = color;
      if(this.$data.color != 'red' && this.$data.color != 'green'){
        //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').removeAttr('disabled');
        $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
        $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
      }
      if(this.$data.color == 'red'){
        $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').removeAttr('disabled');
        //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').attr('disabled', 'disabled');
        $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
      }
      if(this.$data.color == 'green'){
        $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').removeAttr('disabled');
        //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').attr('disabled', 'disabled');
        $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
      }
      localStorage.setItem("color", color);
    }
  }
};
</script>
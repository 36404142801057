<template>
  <template v-if="info.length != 0">
    <div class="block block-views block-views-important-notice-link" data-bid="762">
      <div class="view view-views-important-notice-link view-id-views_important_notice_link view-display-id-block">
        <div class="view-content">
          <template v-for="(i, index) in info" :key="i">
            <div :class='"views-row" + (index == 0 ? " views-row-first" : "") + (index == info.length - 1 ? " views-row-last" : "")'>
              <span class="views-field views-field-php-1">
                <span class="field-content">
                  <div class="views-row-notice" style="background-color: #fefff1">
                    <span class="views-notice-field-created" style="color: #000000">{{ i.ReleaseDate }}</span>
                    &nbsp;
                    <span class="views-notice-field-title" style="color: #000000">【重要】{{ i.Title }}</span>
                  </div>
                </span>
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import { infoStore } from "../stores/store.js";

export default {
  name: "Header_login_info",
  data() {
    var infoData = infoStore();
    var info = {};
    if(infoData.login != undefined){
        info = infoData.login;
    }
    return { info: info };
  },
  async created() {
    try{
        var infoData = infoStore();
        if(infoData.login != undefined){
            return;
        }
        var info = await getData(config.url.get.info_list + "?important=1");
        // [{ Id, Title, Category, CreateDate, BodySummary}]
        for (var content in info) {
            var date = new Date(info[content].CreateDate);
            info[content].ReleaseDate = date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日";
        }
        this.$data.info = info;
        infoData.login = info;
    }catch{
        // Do nothing
    }
  },
};
</script>

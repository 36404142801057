import MEMS from './mems'
import $ from 'jquery'

MEMS.behaviors.textarea = {
  attach: function () {
    $('.form-textarea-wrapper.resizable').on('textarea', function () {
      $('.form-textarea-wrapper.resizable').off('textarea');
      var staticOffset = null;
      var textarea = $(this).addClass('resizable-textarea').find('textarea');
      var grippie = $('<div class="grippie"></div>').on('mousedown', startDrag);

      grippie.insertAfter(textarea);
      function startDrag(e) {
        staticOffset = textarea.height() - e.pageY;
        textarea.css('opacity', 0.25);
        $(document).on('mousemove', performDrag).on('mouseup', endDrag);
        return false;
      }

      function performDrag(e) {
        textarea.height(Math.max(32, staticOffset + e.pageY) + 'px');
        return false;
      }

      function endDrag() {
        $(document).off('mousemove', performDrag).off('mouseup', endDrag);
        textarea.css('opacity', 1);
      }
    });
  }
};
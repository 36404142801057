<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span>
          <span class="inline odd last">お支払者様情報の変更</span>
        </div>
        <div class="messages error" v-if="change_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          変更したい項目を入力してください。
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">お支払者様情報の変更</h1>
          <!-- </div> /#content-header -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 webform-conditional-processed" action="/mansion/member/procedure" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="form-item webform-component webform-component-hidden webform-component--url" style="display: none"></div>
                    <div class="form-item webform-component webform-component-hidden webform-component--req-id" style="display: none"></div>
                    <div class="form-item webform-component webform-component-markup webform-component--change-tel-description">
                      <p>
                        新しい電話番号をご記入ください。<br />
                        上記以外の連絡先を削除する場合は、右にある削除欄にチェックを入れてください。
                      </p>
                    </div>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed' + (isOpen ? '' : ' collapsed')">
                      <legend>
                        <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="isOpen = !isOpen">お支払者様情報</a><span class="summary"></span></span>
                      </legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-hidden webform-component--webform-group-rate-plan-info--field-power-area" v-show="isOpen"></div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-tel-2 webform-container-inline">
                          <div class="description">現在登録されている日中ご連絡のつく電話番号（携帯電話等）：{{ now.Phone }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-tel-2">日中ご連絡のつく電話番号（携帯電話等） </label>
                          <input type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-payer-info-field-payer-tel-2" name="submitted[webform_group_payer_info][field_payer_tel_2]" size="15" maxlength="15" class="form-text" v-model="update.payer_phone" />
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-payer-info--field-payer-tel-2-description">※手続きに関するご連絡をショートメッセージでお送りする場合もあります。</div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-mobile-phone-2 webform-container-inline">
                          <div class="description">現在登録されている上記以外の連絡先（ご自宅の電話番号等）：{{ now.TelSub }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-mobile-phone-2">上記以外の連絡先（ご自宅の電話番号等） </label>
                          <input type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-payer-info-field-payer-mobile-phone-2" name="submitted[webform_group_payer_info][field_payer_mobile_phone_2]" size="15" maxlength="15" class="form-text" v-model="update.payer_tel" :disabled="update.delete1" />
                        </div>
                        <div class="form-item webform-component webform-component-checkboxes webform-component--webform-group-payer-info--delete-payer-mobile-phone-2">
                          <label class="element-invisible" for="edit-submitted-webform-group-payer-info-delete-payer-mobile-phone-2">携帯番号削除キー </label>
                          <div id="edit-submitted-webform-group-payer-info-delete-payer-mobile-phone-2" class="form-checkboxes">
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-payer-info-delete-payer-mobile-phone-2-1">
                              <input type="checkbox" id="edit-submitted-webform-group-payer-info-delete-payer-mobile-phone-2-1" name="submitted[webform_group_payer_info][delete_payer_mobile_phone_2][1]" value="1" class="form-checkbox" v-model="update.delete1" @change="deleteItem1" />
                              <label class="option" for="edit-submitted-webform-group-payer-info-delete-payer-mobile-phone-2-1">削除 </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-actions">
                      <input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" @click="check" />
                    </div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { payerTelStore } from "../stores/store.js";
import { loginStore } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var login = loginStore();
    if (login.room.room_type == 1 || login.room.room_type == 2 || login.room.room_type == 3) {
      this.$router.push({ path: "/mansion/access_error" });
    }
    var result = {
      Phone: "",
      TelSub: "",
    };
    var update = {
      payer_phone: "",
      payer_tel: "",
      delete1: false,
    };
    result.RoomNo = login.room.room_number;
    return {
      isOpen: true,
      now: result,
      update: update,
      change_error : 0
    };
  },
  async created() {
    var store = payerTelStore();
    if (Object.keys(store.now).length != 0) {
      var now = store.now;
      var update = store.update;
      store.$reset();
      Object.assign(this.$data.now, now);
      Object.assign(this.$data.update, update);
    } else {
      var result = await getData(config.url.get.payer);
      // { Phone, TelSub }
      Object.assign(this.$data.now, result);
    }
  },
  methods: {
    deleteItem1() {
      if (this.$data.update.delete1) {
        this.$data.update.payer_tel = "";
      }
    },
    check: function () {
      this.$data.change_error = 0;
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var phone2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-tel-2");
      if (this.$data.update.payer_phone.startsWith("080") || this.$data.update.payer_phone.startsWith("090") || this.$data.update.payer_phone.startsWith("050") || this.$data.update.payer_phone.startsWith("070")) {
        if (this.$data.update.payer_phone.length != 11) {
          phone2.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      } else {
        if (this.$data.update.payer_phone.length != 0 && this.$data.update.payer_phone.length != 10) {
          phone2.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      }
      if (phone2.validity.patternMismatch) {
        phone2.setCustomValidity("日中ご連絡のつく電話番号（携帯電話等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
      }

      var tel2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-mobile-phone-2");
      if (this.$data.update.payer_tel.startsWith("080") || this.$data.update.payer_tel.startsWith("090") || this.$data.update.payer_tel.startsWith("050") || this.$data.update.payer_tel.startsWith("070")) {
        if (this.$data.update.payer_tel.length != 11) {
          tel2.setCustomValidity("上記以外の連絡先（ご自宅の電話番号等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      } else {
        if (this.$data.update.payer_tel.length != 0 && this.$data.update.payer_tel.length != 10) {
          tel2.setCustomValidity("上記以外の連絡先（ご自宅の電話番号等）に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      }
    },
    push: function () {
      var store = payerTelStore();
      store.now = this.$data.now;
      store.update = this.$data.update;
      if (store.update.delete1) {
        store.update.payer_tel = "";
      }
      if ((store.update.payer_phone.length == 0 || store.update.payer_phone == this.$data.now.Phone)
          && ((!store.update.delete1 && store.update.payer_tel.length == 0) || store.update.payer_tel == this.$data.now.TelSub))
      {
        this.$data.change_error = 1;
        document.body.scrollIntoView();
        return;
      }
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "payer_tel_confirm",
        },
      });
    },
  },
};
import "../assets/js/mems_webform_replace_half_space.js";
import "../assets/js/webform.js";
</script>
<style scoped>
input[type='tel'] {
  width : auto;
}
</style>

<style scoped src="../assets/css/webform.css" />

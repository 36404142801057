<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-node responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">{{ Title }}</span>
        </div>
        <section id="content">
          <h1 class="title">{{ Title }}</h1>
          <div id="content-area">
            <div class="view view-column-categories view-id-column_categories view-display-id-block_2 ennevision">
              <div class="view-content">
                <div class="item-list">
                  <ul>
                    <template v-for="(c, index) in ColumnCategory" :key="c">
                      <template v-if="c">
                        <li class="views-row">
                          <div class="views-field views-field-name">
                            <span class="field-content"><router-link :to="'/mansion/column_list?category=' + (index + 1)">{{ ColumnCategoryList[index] }}</router-link></span>
                          </div>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <article class="node node-column">
              <div v-html="Main" class="contents">
              </div>
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    return {
      isLogin: isLogin(),
      Title: "",
      Main: "",
      ColumnCategory: [],
      ColumnCategoryList: [
        "やってみよう！", 
        "困ったときに", 
        "カラダにいいこと", 
        "暮らしにプラス", 
        "便利なモノ"
      ]
    };
  },
  async created() {
    var result = await getData(config.url.get.column + this.$route.params.id);
    // { Title, Body }
    this.$data.Title = result.Title;
    this.$data.Main = result.Main;
    this.$data.ColumnCategory = result.ColumnCategory;
    document.title = result.Title + " | でんき案内板";
  },
};
</script>
<style scoped src="../assets/css/ennevision_column_categories.css" />
<style scoped src="../assets/css/ennevision_column_list.css" />

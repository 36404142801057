<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-condition responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">電気の使用状況を詳しく見る</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">電気の使用状況を詳しく見る</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq/2994" target="_blank">FAQ</router-link>
            </div>
            <div class="enne_help_button">
              <router-link to="/mansion/condition/graphguide" target="_blank">このグラフの見方・使い方</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <div class="block block-ennevision-graph" data-bid="366">
              <form action="condition" method="post" id="ennevision-graph-detail-block-form" accept-charset="UTF-8">
                <div>
                  <div id="container">
                    <div id="mainContent">
                      <div class="contentBox">
                        <div id="graphViewArea">
                          <ul class="selectUnit">
                            <li class="mdBtn year" data-tab="year">
                              <a href="#">月別</a>
                            </li>
                            <li class="mdBtn month" data-tab="month">
                              <a href="#">日別</a>
                            </li>
                            <li class="mdBtn day" data-tab="day">
                              <a href="#">時間別</a>
                            </li>
                          </ul>
                            <!-- year tabContent -->
                            <div class="tabContent" id="year">
                              <ul class="amountUse">
                              </ul>
                              <div id="graphView">
                                <canvas width="725px" height="260px"></canvas>
                                <div class="loader"></div>
                                <div class="inBox">
                                  <div class="inner">
                                    <div class="inner2">
                                      <dl class="power">
                                        <dt></dt>
                                        <dd></dd>
                                        <dt></dt>
                                        <dd></dd>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                                <div class="selectArea mdBtn colorType5" id="unit">
                                  <div class="selectTxt">円</div>
                                  <select class="selectObj">
                                    <option value="円" selected="selected">円</option>
                                    <option value="kWh">kWh</option>
                                  </select>
                                </div>
                              </div>
                              <div class="graphSup">
                                <ul class="missing">
                                  <li class="graph">欠測あり</li>
                                  <li class="day">欠測</li>
                                </ul>
                                <ul class="baseline">
                                  <img src="../assets/images/ico-kwh.png" class="ico-kwh" />
                                </ul>
                                <div class="selectWrap">
                                  <div class="selectArea mdBtn colorType5 compare">
                                  </div>
                                </div>
                                <div class="option">
                                  <p class="dr_participation" v-if="dr_join">節電プログラム参加中</p>
                                </div>
                              </div>
                              <div class="notes">
                                <p>●使用電力量（kWh）と電気料金（円）を確認できます。グラフ縦軸の「kWh・円」のボタンで表示を切り替えられます。</p>
                                <p>●当月分の電気料金とみんなの平均電気料金は概算額です。</p>
                                <p>&nbsp;基本料金、基本割引を含みます。燃料費調整額や各種賦課金は含みません。</p>
                                <p>&nbsp;確定金額は料金明細の掲載時（毎月10日前後）に反映されます。</p>
                                <p>●折れ線グラフは去年の値またはみんなの平均です。グラフの下にあるボタンで切り替えられます。</p>
                                <p>&nbsp;※みんなの平均：「でんき案内板」に登録している世帯人数が同一のお客様の中での平均</p>
                                <p>●マンション設備の点検等に伴い停電が発生した場合などは、使用電力量のデ－タを取得できないことがあります。</p>
                                <p>&nbsp;※グラフ表示に使用するデータと料金計算に使用するデータは異なるため、実際の電気料金には影響ありません。</p>
                              </div>
                            </div>
                            <!-- month tabContent -->
                            <div class="tabContent" id="month">
                              <ul class="amountUse">
                              </ul>
                              <div class="selectDate">
                                <ul class="inner colorType2">
                                  <li class="prev">
                                    <a href="#">戻る</a>
                                  </li>
                                  <li class="current">----年--月</li>
                                  <li class="next">
                                    <a href="#">進む</a
                                    >
                                  </li>
                                </ul>
                              </div>
                              <div id="graphView">
                                <canvas width="720px" height="260px"></canvas>
                                <div class="loader"></div>
                                <div class="inBox">
                                  <div class="inner">
                                    <div class="inner2">
                                      <dl class="power">
                                        <dt></dt>
                                        <dd></dd>
                                        <dt></dt>
                                        <dd></dd>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                                <div class="selectArea mdBtn colorType5" id="unit">
                                  <div id="month_data_select_txt" class="selectTxt">円</div>
                                  <select id="month_data_select" class="selectObj">
                                    <option id="month_yen" value="円" selected="selected">円</option>
                                    <option id="month_kwh" value="kWh">kWh</option>
                                  </select>
                                </div>
                              </div>
                              <div class="graphSup">
                                <ul class="priceCheck" style="display: block">
                                  <li class="priceLevel1">1段料金</li>
                                  <li class="priceLevel2">2段料金</li>
                                  <li class="priceLevel3">3段料金</li>
                                </ul>
                                <p class="drInfo">節電おねがい日</p>
                                <ul class="missing">
                                  <li class="graph">欠測あり</li>
                                  <li class="day">欠測</li>
                                </ul>
                                <ul class="baseline">
                                  <img src="../assets/images/ico-kwh.png" class="ico-kwh" />
                                </ul>
                                <div class="selectWrap">
                                  <div class="selectArea mdBtn colorType5 compare">
                                  </div>
                                </div>
                                <div class="option">
                                  <p class="dr_participation" v-if="dr_join">節電プログラム参加中</p>
                                </div>
                              </div>
                              <div class="notes">
                                <p>●当月分は使用電力量（kWh）の他に電気料金の概算額（円）も確認できます。グラフ縦軸の「kWh・円」のボタンで表示を切り替えられます。</p>
                                <p>●当月分の電気料金とみんなの平均電気料金は概算額です。</p>
                                <p>&nbsp;基本料金、基本割引を含みます。燃料費調整額や各種賦課金は含みません。</p>
                                <p>&nbsp;確定金額は料金明細の掲載時（毎月10日前後）に反映されます。</p>
                                <p>●折れ線グラフは去年の値またはみんなの平均です。グラフの下にあるボタンで切り替えられます。</p>
                                <p>&nbsp;※みんなの平均：「でんき案内板」に登録している世帯人数が同一のお客様の中での平均</p>
                                <p>●マンション設備の点検等に伴い停電が発生した場合などは、使用電力量のデ－タを取得できないことがあります。</p>
                                <p>&nbsp;※グラフ表示に使用するデータと料金計算に使用するデータは異なるため、実際の電気料金には影響ありません。</p>
                              </div>
                            </div>
                            <!-- day tabContent -->
                            <div class="tabContent" id="day">
                              <ul class="amountUse">
                              </ul>
                              <div class="selectDate">
                                <ul class="inner colorType2">
                                  <li class="prev">
                                    <a href="#">戻る</a>
                                  </li>
                                  <li class="current">----年--月--日</li>
                                  <li class="next">
                                    <a href="#">進む</a>
                                  </li>
                                </ul>
                              </div>
                              <div id="graphView">
                                <canvas width="725px" height="260px"></canvas>
                                <div class="loader"></div>
                                <div class="inBox info">
                                  <div class="inner"></div>
                                </div>
                                <div class="selectArea mdBtn colorType5" id="unit">
                                  <div class="selectTxt"></div>
                                  <select class="selectObj" disabled="disabled">
                                    <option value="kWh" selected="selected">kWh</option>
                                  </select>
                                </div>
                                <div class="selectArea mdBtn colorType5" id="time">
                                  <div class="selectTxt"></div>
                                  <select class="selectObj">
                                    <option value="時" selected="selected">時</option>
                                    <option value="30分">30分</option>
                                  </select>
                                </div>
                              </div>
                              <div class="graphSup">
                                <dl class="priceCheck" style="display: none">
                                  <input id="userid" type="checkbox" checked><label for="userid">時間ごとの料金を表示</label>
                                </dl>
                                <ul class="missing">
                                  <li class="graph">欠測あり</li>
                                  <li class="day">欠測</li>
                                </ul>
                                <ul class="baseline">
                                  <li class="dr">節電目標</li>
                                </ul>
                                <ul class="baseline">
                                  <img src="../assets/images/ico-kwh.png" class="ico-kwh" />
                                </ul>
                                <div class="selectWrap">
                                  <div class="selectArea mdBtn colorType5 compare">
                                  </div>
                                </div>
                                <div class="option">
                                  <p class="dr_participation" v-if="dr_join">節電プログラム参加中</p>
                                </div>
                              </div>
                              <div class="notes">
                                <p>●グラフ上部の「月別・日別・時間別」のボタンで表示を切り替えられます。</p>
                                <p>●グラフ右側の「時」ボタンで利用時間の単位（1時間ごと・30分ごと）を選択できます。</p>
                                <p>●折れ線グラフは去年の使用電力量またはみんなの平均使用電力量です。グラフの下にあるボタンで切り替えられます。</p>
                                <p>&nbsp;※みんなの平均使用電力量：「でんき案内板」に登録している世帯人数が同一のお客様の中での平均電力量</p>
                                <p>●目安として1時間前までの使用電力量データが反映されています。</p>
                                <p>●マンション設備の点検等に伴い停電が発生した場合などは、使用電力量のデ－タを取得できないことがあります。</p>
                                <p>&nbsp;※グラフ表示に使用するデータと料金計算に使用するデータは異なるため、実際の電気料金には影響ありません。</p>
                              </div>
                              <div class="memoWrap"></div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions form-wrapper" v-if="!dr_join" id="edit-actions--3">
                    <input type="submit" id="edit-preview" name="op" value="節電ポイントサービスに参加する" class="form-submit" @click="push_dr()" />
                  </div>
                </div>
              </form>
            </div>
            <!-- /block -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js"
import { getData } from "../assets/js/request.js";
import { execute } from "../assets/js/app.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var date = new Date();
    var month = new Date();
    return {
      summary : {
        hourly : { 
          electricEnergyAddTime: 0,
          electricEnergyAverage: 0,
        },
        daily : { 
          electricEnergyAmountAddDate: 0,
          billing_amount : 0,
        },
        monthly : { 
          electricEnergyAmountAddMonth: 0,
          billing_amount : 0,
        }
      },
      day: date,
      month: month,
      kind: 2,
      dr_join: 0
    };
  },
  async created() {
    var customer = await getData(config.url.get.customer);
    // { dr }
    this.$data.dr_join = customer.dr;
  },
  methods: {
    push_dr: async function () {
      this.$router.push({path: "/mansion/dr/agreement"});
      /*
      await put(config.url.put.dr, {
        customer: { dr: "1" },
      });
      this.$data.dr_join = 1;
      */
    },
    getViewData: async function(date){
      var summaryDate = date.toLocaleDateString("sv-SE");
      var summary = await getData(config.url.get.summary + "?graphDisplayTargetDate=" + summaryDate);
      // {hourly : { electricEnergyAddTime, electricEnergyAverage },
      // daily : { electricEnergyDate, electricEnergyDatePast, electricEnergyAmountDate, electricEnergyAmountDatePast, electricEnergyAmountAddDate, billing_amount },
      // monthly : { electricEnergyMonth, electricEnergyMonthPast, electricEnergyAmountMonth, electricEnergyAmountAddMonth, billing_amount }}
      return summary;
    }
  },
  mounted: function () {
    this.$nextTick(
     function () {
      execute();
      }.bind(this)
    );
  }
};
</script>
<style scoped>
.notes p {
  margin : 0;
}
.dr_participation {
  text-decoration: none;
}
</style>
<style scoped src="../assets/css/condition.css" />

<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-939 node-type-billdl responsive-menus-load-processe">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">料金明細一覧</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">料金明細一覧</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq_category_list/5" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <article class="node node-billdl node-promoted" data-nid="939">
              <header><span property="dc:title" content="料金明細一覧" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded">
                      請求金額をクリックすると料金明細（PDFファイル）が表示されます。
                      <br>（最大で過去3年分の料金明細が確認できます）<br />
                      <br />
                      ◆料金明細の掲載について
                      <ul class="ul1">
                        <li><b>毎月10日前後</b>に掲載します。</li>
                        <li>「でんき案内板」にご登録いただいたメールアドレス（ポータルサイトメールアドレス、およびサブメールアドレス）に、電気料金のお知らせメールをお送りしご案内します。</li>
                      </ul>
                      <br>
                      ◆支払方法について
                      <ul class="ul1">
                        <li><b>毎月6日前後</b>に掲載します。</li>
                        <li>初回請求などで複数利用月を合算でお支払いいただいた場合、料金明細は請求月ごとに表示されますが、支払状況が表示されるのは合算請求分の一番新しい月のみとなります。<br>※合算請求について詳しくは<router-link to="/mansion/faq/1175" target="_blank">こちら</router-link>。</li>
                      </ul>
                      <br>
                      <ul class="ul2">
                        <li>年末年始や大型連休は、料金明細の掲載や支払情報の更新が遅くなりますので、あらかじめご了承ください。</li>
                        <li>電気料金のお支払方法、お支払方法ごとのご案内については、<router-link to="/mansion/faq/oshietepaymethod" target="_blank">こちら</router-link>。</li>
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
            <div class="block block-ennevision-mypage claim_list_block" data-bid="790">
              <div class="item-list">
                <ul>
                  <li class="first">
                    <template v-if="pages.length != 0">
                      <template v-if="pages.length > 1">
                        <h2 class="element-invisible">ページ</h2>
                        <div class="item-list">
                            <ul class="pager">
                              <template v-for="p in createPage()" :key="p">
                                <template v-if="p != 0">
                                  <li>|</li>
                                </template>
                                <template v-if="p + 1 == current">
                                  <li>
                                    {{ p + 1 }}
                                  </li>
                                </template>
                                <template v-else>
                                  <li>
                                    <a href="#" @click="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                                  </li>
                                </template>
                              </template>
                            </ul>
                          </div>
                        </template>
                      <table class="claim_list_table">
                        <thead>
                          <tr>
                            <th class="billing_month">請求月</th>
                            <th class="charge">請求金額（税込）<br />※PDFファイル</th>
                            <th class="payment_status">支払状況</th>
                            <th class="csv">CSV形式のファイルは<br />こちらから</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="info in pages[current - 1]" :key="info">
                            <tr>
                              <td>{{ info.billing_year }}年{{ info.billing_month }}月請求分<br />({{ info.use_year }}年{{ info.use_month }}月利用分)</td>
                              <td>
                                <a href="#" @click.prevent.stop="download(dir + '&blobName=/' + info.pdf, true)">{{ Number(info.billing_amount).toLocaleString() }}円</a>
                              </td>
                              <td>
                                <template v-if="info.payment_status == '済' && info.payment != 'カード'">お支払済</template>
                                <template v-else-if="info.payment_status == '未' && info.payment != 'カード'">お支払が確認できません</template>
                                <template v-else-if="info.payment_status != '' && info.payment == 'カード'">※クレジットカード会社の明細をご確認ください</template>
                                <template v-else></template>
                              </td>
                              <td>
                                <a href="#" @click.prevent.stop="download(dir + '&blobName=/' + info.csv, false)">
                                  <img src="../assets/images/csv.jpg" alt="" />
                                </a>
                              </td>
                            </tr>
                          </template>
                          <template v-if="pages[0].length == 0">
                            <tr><td colspan="4">明細情報がありません</td></tr>
                          </template>
                        </tbody>
                      </table>
                      <template v-if="pages.length > 1">
                        <h2 class="element-invisible">ページ</h2>
                        <div>
                            <ul class="pager">
                              <template v-for="p in createPage()" :key="p">
                                <template v-if="p != 0">
                                  <li>|</li>
                                </template>
                                <template v-if="p + 1 == current">
                                  <li>
                                    {{ p + 1 }}
                                  </li>
                                </template>
                                <template v-else>
                                  <li>
                                    <a href="#" @click="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                                  </li>
                                </template>
                              </template>
                            </ul>
                          </div>
                        </template>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /block -->
            <div class="block block-block claim_description" data-bid="820">
              <p>※PDF形式のファイルをご覧いただく場合には、Adobe Reader(R) が必要です。Adobe Reader をお持ちでない方は、<a href="https://get.adobe.com/jp/reader/" target="_blank">こちら</a>からダウンロードしてください。</p>
            </div>
            <!-- /block -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { getData, downloadFile } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return {
      dir: config.url.get.claim,
      current: 1,
      num: 1,
      pages : []
    };
  },
  async created() {
    var payment_info = await getData(config.url.get.payment_info);
    // [ { customer_id, billing_date, payment, payment_status }]
    var details_info = await getData(config.url.get.details_info);
    // [ { billing_amount, usage_period_to, usage_period_from, free_field_5, pdf, csv }]
    var list = new Array();
    for(var row in details_info){
      var param = {};
      var year = details_info[row].usage_period_to.slice(0, 4)
      var month = details_info[row].usage_period_to.slice(4, 6)
      var useMonth = new Date(year, Number(month) - 1, 1);
      var billingMonth = new Date(year, Number(month) - 1, 1);
      billingMonth.setMonth(billingMonth.getMonth() + 1);
      param.billing_amount = details_info[row].billing_amount;
      param.usage_period_to = details_info[row].usage_period_to;
      param.usage_period_from = details_info[row].usage_period_from;
      param.csv = details_info[row].csv;
      param.pdf = details_info[row].pdf;
      param.billing_year = billingMonth.getFullYear();
      param.billing_month = billingMonth.getMonth() + 1;
      param.use_year = useMonth.getFullYear();
      param.use_month = useMonth.getMonth() + 1;
      param.payment_status = "";
      param.payment = "";
      param.value = useMonth.getFullYear() * 12 + useMonth.getMonth();
      for (var index in payment_info) {
        var billing_year = Number(payment_info[index].billing_date.slice(0, 4));
        var billing_month = Number(payment_info[index].billing_date.slice(-2));
        if(billingMonth.getFullYear() == billing_year && billingMonth.getMonth() + 1 == billing_month) {
          param.payment = payment_info[index].payment;
          param.payment_status = payment_info[index].payment_status;
          break;
        }
      }
      list.push(param);
    }
    list.sort(function(a, b){
      return b.value - a.value;
    })
    var pages = new Array();
    var td = new Array();
    pages.push(td);
    for (var info in list) {
      if (td.length == 12) {
        td = new Array();
        pages.push(td);
      }
      td.push(list[info]);
    }
    this.$data.pages = pages;
  },
  methods: {
    download: async function (path, pdf) {
      if (pdf || confirm("CSVデータをダウンロードします。よろしいですか？")) {
        var split = path.split("/")
        await downloadFile(path, split[split.length - 1]);
      }
    },
    createPage: function(){
      var page = new Array();
      for(var i = 0; i < this.$data.pages.length; i++){
        page.push(i);
      }
      return page;
    },
    changePage: function (index) {
      this.$data.current = index / this.$data.num + 1;  
    },
  },
};
</script>
<style scoped>
#content-area div.field-item li::before {
  content: none;
}
#content-area div.field-item ul {
  margin-top:0;
}
#content-area div.field-item ul.ul1 li {
  list-style-type:'・';
  list-style-position:outside;
  margin-left:24px;
}
#content-area div.field-item ul.ul2 li {
  list-style-type:'※';
  list-style-position:outside;
  margin-left:12px;
}
#content-area div.field-item li b {
  font-weight: bolder;
}

@media screen and (min-width: 769px) {
  #content-area div.field-item ul li {
    padding-right:50px;
  }
}

ul.pager li {
  background: initial !important;
  padding: 0 !important;
}
ul.pager li:hover {
  background: initial !important;
  padding: 0 !important;
}
ul.pager li a {
  background: initial !important;
  padding: 0 !important;
}
</style>
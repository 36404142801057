<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-column-list responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">コラム一覧</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">コラム一覧</h1>
          <!-- </div> /#content-header -->
          <div id="content-area">
            <div class="view view-column-list view-id-column_list view-display-id-page_1">
              <div class="view-header" v-show="category">
                <div class="category">
                  <span class="label">カテゴリ</span>
                  <span class="value" v-show="category == 1">やってみよう！</span>
                  <span class="value" v-show="category == 2">困ったときに</span>
                  <span class="value" v-show="category == 3">カラダにいいこと</span>
                  <span class="value" v-show="category == 4">暮らしにプラス</span>
                  <span class="value" v-show="category == 5">便利なモノ</span>
                </div>
              </div>
              <div class="view-content">
                <table class="views-view-grid cols-3">
                  <tbody>
                    <tr class="row">
                      <td v-for="c in columns1" :key="c.ColumnId" class="col">
                        <div class="views-field views-field-path">
                          <span class="field-content">
                            <router-link :to="'/mansion/column_list/' + c.ColumnId" target="_blank">
                              <img :src="c.ImageUrl" alt="" width="220" height="147" />
                            </router-link>
                          </span>
                        </div>
                        <div class="views-field views-field-title">
                          <span class="field-content">
                            <router-link :to="'/mansion/column_list/' + c.ColumnId" target="_blank">{{ c.Title }}</router-link>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr class="row-2">
                      <td v-for="c in columns2" :key="c.ColumnId" class="col">
                        <div class="views-field views-field-path">
                          <span class="field-content">
                            <router-link :to="'/mansion/column_list/' + c.ColumnId" target="_blank">
                              <img :src="c.ImageUrl" alt="" width="220" height="147" />
                            </router-link>
                          </span>
                        </div>
                        <div class="views-field views-field-title">
                          <span class="field-content">
                            <router-link :to="'/mansion/column_list/' + c.ColumnId" target="_blank">{{ c.Title }}</router-link>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr class="row-3 row-last">
                      <td v-for="c in columns3" :key="c.ColumnId" class="col">
                        <div class="views-field views-field-path">
                          <span class="field-content">
                            <router-link v-bind:to="'/mansion/column_list/' + c.ColumnId" target="_blank">
                              <img :src="c.ImageUrl" alt="" width="220" height="147" />
                            </router-link>
                          </span>
                        </div>
                        <div class="views-field views-field-title">
                          <span class="field-content">
                            <router-link :to="'/mansion/column_list/' + c.ColumnId" target="_blank">{{ c.Title }}</router-link>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item-list">
                <ul class="pager">
                  <template v-for="(p, index) in createPage()" :key="p">
                    <template v-if="index == 0 && 1 != current">
                      <li class="page-item">
                        <a href="#" @click="changePage(0)"> ≪ </a>
                      </li>
                      <li class="page-item">
                        <a href="#" @click="changePage((current - 2) * num)"> &lt; </a>
                      </li>
                    </template>
                    <template v-if="p + 1 == current">
                      <li class="pager-current">
                        {{ p + 1 }}
                      </li>
                    </template>
                    <template v-else>
                      <li class="page-item">
                        <a href="#" @click.prevent.stop="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                      </li>
                    </template>
                      <template v-if="(index < 10 ? index == Math.ceil(pages.length / num) - 1 : index == 10) && current != Math.ceil(pages.length / num)">
                      <li class="page-item">
                        <a href="#" @click.prevent.stop="changePage(current * num)"> &gt; </a>
                      </li>
                      <li class="page-item">
                        <a href="#" @click.prevent.stop="changePage((Math.ceil(pages.length / num) - 1) * num)"> ≫ </a>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
            </div>
            <div class="block block-views" data-bid="797">
              <div class="view view-column-categories view-id-column_categories view-display-id-block view-dom-id-468b47ea7b9914fbf4521ff940e4d2cc ennevision">
                <div class="view-content">
                  <div class="item-list">
                    <ul>
                      <li class="views-row views-row-1 views-row-odd views-row-first">
                        <div class="views-field views-field-name">
                          <span class="field-content"><router-link :to="'/mansion/column_list?category=1&_=' + new Date().getTime()">やってみよう！</router-link></span>
                        </div>
                      </li>
                      <li class="views-row views-row-2 views-row-even">
                        <div class="views-field views-field-name">
                          <span class="field-content"><router-link :to="'/mansion/column_list?category=2&_=' + new Date().getTime()">困ったときに</router-link></span>
                        </div>
                      </li>
                      <li class="views-row views-row-3 views-row-odd">
                        <div class="views-field views-field-name">
                          <span class="field-content"><router-link :to="'/mansion/column_list?category=3&_=' + new Date().getTime()">カラダにいいこと</router-link></span>
                        </div>
                      </li>
                      <li class="views-row views-row-4 views-row-even">
                        <div class="views-field views-field-name">
                          <span class="field-content"><router-link :to="'/mansion/column_list?category=4&_=' + new Date().getTime()">暮らしにプラス</router-link></span>
                        </div>
                      </li>
                      <li class="views-row views-row-5 views-row-odd views-row-last">
                        <div class="views-field views-field-name">
                          <span class="field-content"><router-link :to="'/mansion/column_list?category=5&_=' + new Date().getTime()">便利なモノ</router-link></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- /block -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { getData } from "../assets/js/request.js";
import config from "../config.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var category = this.$route.query.category;
    return {
      isLogin: isLogin(),
      category: category,
      current: 1,
      num: 3,
      pages: [],
      columns1: [],
      columns2: [],
      columns3: [],
    }
  },
  async created(){
    this.beforeProcess();
  },
  watch: {
    $route () {
      this.beforeProcess();
    }
  },
  methods: {
    beforeProcess: async function() {
      var pages = new Array();
      var category = this.$route.query.category;
      var query = "";
      if(category){
        query = "?category=" + category;
      }
      var result = await getData(config.url.get.column_list + query);
      // [{ ColumnId, Title, ImageUrl }
      var td = new Array();
      pages.push(td);
      for (var colmuun in result) {
        if (td.length == 3) {
          td = new Array();
          pages.push(td);
        }
        td.push(result[colmuun]);
      }
      Object.assign(this.$data, {
        pages: pages,
        category: category,
        current: 1,
        columns1 : pages.length == 0 ? [] : pages[0],
        columns2 : pages.length <= 1 ? [] : pages[1],
        columns3 : pages.length <= 2 ? [] : pages[2],
      });
    },
    createPage: function () {
      var side = 5;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }

      return pangeNum;
    },
    changePage: function (index) {
      this.columns1 = this.pages[index];
      this.columns2 = this.pages[index + 1];
      this.columns3 = this.pages[index + 2];
      this.current = index / this.num + 1;
    },
  },
};
</script>
<style scoped src="../assets/css/ennevision_column_categories.css" />
<style scoped src="../assets/css/ennevision_column_list.css" />

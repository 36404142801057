<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-password page-password-setting responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/login">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">パスワードの変更</span>
        </div>
        <div class="messages status" v-if="green">
          <h2 class="element-invisible">ステータスメッセージ</h2>
          変更内容が保存されました。
        </div>
        <div class="messages error" v-if="auth_error">
            <h2 class="element-invisible">エラーメッセージ</h2>
            入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <div class="messages error" v-if="password_num">
            <h2 class="element-invisible">エラーメッセージ</h2>
            パスワードは{{ password_num }}文字以上を指定してください。
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">パスワードの変更</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq/oshietepw" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <form enctype="multipart/form-data" action="#" method="post" id="ennevision-member-password-form" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div id="edit-account" class="form-wrapper">
                  <div class="form-item form-type-password-confirm form-item-pass">
                    <span class="field-prefix"><div class="description">新しく設定するパスワードを入力してください。</div></span>
                    <div class="form-item form-type-password form-item-pass-pass1 password-parent">
                      <label for="edit-pass-pass1">新しいパスワード <span class="form-required" title="これは入力必須項目です。">*</span></label>
                      <input required autocomplete="off" class="password-field form-text required password-processed" type="password" id="edit-pass-pass1" name="pass[pass1]" size="25" maxlength="128" v-model="password1"/>
                    </div>
                    <div class="form-item form-type-password form-item-pass-pass2 confirm-parent">
                      <label for="edit-pass-pass2">新しいパスワード（確認用） <span class="form-required" title="これは入力必須項目です。">*</span></label>
                      <input required autocomplete="off" class="password-confirm form-text required" type="password" id="edit-pass-pass2" name="pass[pass2]" size="25" maxlength="128" v-model="password2"/>
                    </div>
                  </div>
                  <div class="form-item form-type-password form-item-current-pass">
                    <label for="edit-current-pass">現在のパスワード <span class="form-required" title="これは入力必須項目です。">*</span></label>
                    <input required autocomplete="off" type="password" id="edit-current-pass" name="current_pass" size="25" maxlength="128" class="form-text required" v-model="currentPassword"/>
                  </div>
                  <div class="description">現在のパスワードを入力し、「登録」をクリックするとパスワードが変更されます。</div>
                </div>
                <input type="submit" id="edit-submit" name="op" value="登録" class="form-submit" @click="check" :disabled="send"/>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import $ from "jquery";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { post } from "../assets/js/request.js";
import { initPassword } from '../assets/js/mems_show_password';
import MEMS from "../assets/js/mems.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return { 
      password_num: 0,
      green : false,
      auth_error : false,
      send: false,
      password1 : "",
      password2 : "",
      currentPassword : ""
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      initPassword();
      MEMS.behaviors.password.attach(document, MEMS.settings);
      $('input.password-field').trigger('password');
      $("div.show_pass label").css("display", "inline-block");
    });
  },
  methods: {
    check: function(){
      this.$data.green = false;
      this.$data.auth_error = false;
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password1 = document.querySelector("#edit-pass-pass1");
      if (this.$data.password1 == "") {
        password1.setCustomValidity("これは入力必須項目です。");
      }
      var password2 = document.querySelector("#edit-pass-pass2");
      if (this.$data.password2 == "") {
        password2.setCustomValidity("これは入力必須項目です。");
      }
      var password = document.querySelector("#edit-current-pass");
      if (this.$data.currentPassword == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
      if(this.$data.password1 != this.$data.password2){
        document.querySelector("#edit-pass-pass2").setCustomValidity("入力されたパスワードが一致しませんでした。");
      }
    },
    push: async function () {
      try{
        this.$data.password_num = 0;
        this.$data.send = true;
        await post(config.url.post.password, {
          newPassword : this.$data.password1,
          newPasswordReEnter : this.$data.password2,
          currentPassword : this.$data.currentPassword,
        });
        this.$data.green = true;
        this.$data.send = false;
        document.body.scrollIntoView();
      }catch(ex){
        if(ex.response.data.returnCode){
          if(ex.response.data.returnCode == 3){
            this.$data.send = false;
            // 文字数
            this.$data.password_num = ex.response.data.num;
          }else if(ex.response.data.returnCode == 4){
            this.$data.send = false;
            this.$data.auth_error = true;
            document.body.scrollIntoView();
          }else{
            throw ex;
          }
        }else{
          throw ex;
        }
      }
    },
  },
};

import "../assets/js/user.js";
</script>
<style scoped>
#edit-current-pass {
  width: 16em;
}
</style>

<style scoped src="../assets/css/ennevision_show_password.css" />
<style scoped src="../assets/css/webform.css" />

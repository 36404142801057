<template>
  <template v-if="$route.query.type == 'payer'">
      <Member_payer/>
  </template>
  <template v-else-if="$route.query.type == 'payer_confirm'">
      <Member_payer_confirm/>
  </template>
  <template v-else-if="$route.query.type == 'payer_complate'">
      <Member_payer_complete/>
  </template>
  <template v-else-if="$route.query.type == 'payer_tel'">
      <Member_payertel/>
  </template>
  <template v-else-if="$route.query.type == 'payer_tel_confirm'">
      <Member_payertel_confirm />
  </template>
  <template v-else-if="$route.query.type == 'payer_tel_complete'">
      <Member_payertel_complete />
  </template>
  <template v-else-if="$route.query.type == 'contractor'">
      <Member_contractor/>
  </template>
  <template v-else-if="$route.query.type == 'contractor_confirm'">
      <Member_contractor_confirm />
  </template>
  <template v-else-if="$route.query.type == 'contractor_complete'">
      <Member_contractor_complete />
  </template>
  <template v-else-if="$route.query.type == 'contractor_tel'">
      <Member_contractortel/>
  </template>
  <template v-else-if="$route.query.type == 'contractor_tel_confirm'">
      <Member_contractortel_confirm />
  </template>
  <template v-else-if="$route.query.type == 'contractor_tel_complete'">
      <Member_contractortel_complete />
  </template>
  <template v-else-if="$route.query.type == 'rate_plan'">
      <Member_rateplan/>
  </template>
  <template v-else-if="$route.query.type == 'rate_plan_confirm'">
      <Member_rateplan_confirm/>
  </template>
  <template v-else-if="$route.query.type == 'rate_plan_complete'">
      <Member_rateplan_complete/>
  </template>
  <template v-else-if="$route.query.type == 'fee_detail'">
      <Member_feedetail/>
  </template>
  <template v-else-if="$route.query.type == 'fee_detail_confirm'">
      <Member_feedetail_confirm/>
  </template>
  <template v-else-if="$route.query.type == 'fee_detail_complete'">
      <Member_feedetail_complete/>
  </template>
  <template v-else-if="$route.query.type == 'departure'">
      <Member_departure/>
  </template>
  <template v-else-if="$route.query.type == 'departure_confirm'">
      <Member_departure_confirm/>
  </template>
  <template v-else-if="$route.query.type == 'departure_complete'">
      <Member_departure_complete/>
  </template>
  <template v-else>
      <Page404 />
  </template>
</template>
<script>
import Member_payer from "@/pages/Member_payer";
import Member_payer_confirm from "@/pages/Member_payer_confirm";
import Member_payer_complete from "@/pages/Member_payer_complete";
import Member_payertel from "@/pages/Member_payertel";
import Member_payertel_confirm from "@/pages/Member_payertel_confirm";
import Member_payertel_complete from "@/pages/Member_payertel_complete";
import Member_contractor from "@/pages/Member_contractor";
import Member_contractor_confirm from "@/pages/Member_contractor_confirm";
import Member_contractor_complete from "@/pages/Member_contractor_complete";
import Member_contractortel from "@/pages/Member_contractortel";
import Member_contractortel_confirm from "@/pages/Member_contractortel_confirm";
import Member_contractortel_complete from "@/pages/Member_contractortel_complete";
import Member_rateplan from "@/pages/Member_rateplan";
import Member_rateplan_confirm from "@/pages/Member_rateplan_confirm";
import Member_rateplan_complete from "@/pages/Member_rateplan_complete";
import Member_feedetail from "@/pages/Member_feedetail";
import Member_feedetail_confirm from "@/pages/Member_feedetail_confirm";
import Member_feedetail_complete from "@/pages/Member_feedetail_complete";
import Member_departure from "@/pages/Member_departure";
import Member_departure_confirm from "@/pages/Member_departure_confirm";
import Member_departure_complete from "@/pages/Member_departure_complete";
import Page404 from "@/pages/Top_page";
export default {
    components: {
        Member_payer,
        Member_payer_confirm,
        Member_payer_complete,
        Member_payertel,
        Member_payertel_confirm,
        Member_payertel_complete,
        Member_contractor,
        Member_contractor_confirm,
        Member_contractor_complete,
        Member_contractortel,
        Member_contractortel_confirm,
        Member_contractortel_complete,
        Member_rateplan,
        Member_rateplan_confirm,
        Member_rateplan_complete,
        Member_feedetail,
        Member_feedetail_confirm,
        Member_feedetail_complete,
        Member_departure,
        Member_departure_confirm,
        Member_departure_complete,
        Page404
    }
}
</script>

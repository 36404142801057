<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-node responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">{{ title }}</span>
        </div>
        <section id="content">
          <h1 class="title">{{ title }}</h1>
          <div id="content-area">
            <article class="node node-page" data-nid="6531">
              <header><span property="dc:title" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div v-html="body" id="main-contents" class="field-item even" property="content:encoded"></div>
                    <template v-if="isShowCategory">
                      <br /><br />
                      <div class="field field-name-field-faq-category field-type-taxonomy-term-reference field-label-above">
                        <div class="field-label">カテゴリ:&nbsp;</div>
                        <div class="field-items">
                          <template v-for="(tag, index) in tag" :key="tag">
                            <template v-if="tag == 1">
                              <div class="field-item">{{ category[index] }}<br /></div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from '../components/Header_info.vue';
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from '../components/Sidebar_first_login.vue';
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    var content = {
      isLogin: isLogin(),
      category: "",
      title: "",
      body: "",
      tag: [],
      isShowCategory: false,
    };
    return content;
  },
  async created() {
    var category = [
      "燃料費調整額の変更について",
      "電気の契約・手続きについて",
      "でんき案内板のログインについて",
      "料金プラン（契約容量・アンペア数）について",
      "電気料金の支払について",
      "料金明細・請求書について",
      "停電・故障・法定点検について",
      "退去について",
      "部屋のオーナー・仲介会社・リフォーム会社等の手続きについて",
      "節電ポイントサービス（DR）について",
      "電気以外の手続きについて",
      "その他サービス等について",
      "共用部・店舗のお客様の「よくあるご質問」はこちら",
      "NTTアノードエナジーのマンション電力提供サービス事業の譲渡について",
    ];
    var result = await getData(config.url.get.faq + this.$route.params.id);
    // { Title, Body, Tag }
    var isShowCategory = !result.Tag.every((x) => x == 0);
    var content = {
      category: category,
      title: result.Title,
      body: result.Body,
      tag: result.Tag,
      isShowCategory: isShowCategory,
    };
    document.title = result.Title + " | でんき案内板";
    Object.assign(this.$data, content);
  }
};
</script>

import $ from 'jquery'
export const init = function(){
  $("#edit-submitted-webform-group-contractor-info-field-contractor-name," + 
      "#edit-submitted-webform-group-contractor-info-field-contractor-kana," +
      "#edit-submitted-webform-group-payer-info-field-payer-name," +
      "#edit-submitted-webform-group-payer-info-field-payer-kana").on("blur", function(){
    if ($(this).val().match(/ /g)) {
      $(this).val($(this).val().replace(/ /g,"　"));
    }
  });
}
//$(document).on("ready", init);

<template>
  <div :class="'html not-front page-node node-type-webform responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even last"><router-link to="/mansion/inquiry">お問い合わせ</router-link></span> <span class="delimiter"> »</span> <span class="inline even last">お問い合わせ</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->

          <h1 class="title">お問い合わせ</h1>

          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq_top" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->

          <!-- </div> /#content-header -->

          <div id="content-area">
            <article class="node node-webform" data-nid="77">
              <header><span property="dc:title" content="お問い合わせ" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>

              <div class="content">
                <form class="webform-client-form webform-client-form-77 preview" action="#" method="post" id="webform-client-form-77" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="messages warning"><p>入力内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>

                    <fieldset class="webform-component-fieldset webform-component--webform-group-inquiry form-wrapper">
                      <legend><span class="fieldset-legend">お問い合わせ</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-inquiry--field-inquiry-type">
                          <label>種別 </label>
                          <template v-if="kind == '1'">お問い合わせ</template>
                          <template v-if="kind == '3'">でんき案内板のログインID・初期パスワードを確認したい</template>
                          <template v-if="kind == '7'">電気の利用申込みが手続きされているか確認したい</template>
                          <template v-if="kind == '4'">紙の申込書を送って欲しい</template>
                          <template v-if="kind == '5'">料金支払方法申込書を送って欲しい（支払方法の変更）</template>
                          <template v-if="kind == '6'">退去の手続きをしたい</template>
                          <template v-if="kind == '8'">支払証明書を発行して欲しい</template>
                          <template v-if="kind == '9'">返信用の封筒を送って欲しい</template>
                        </div>
                        <template v-if="kind == '4' && !isLogin">
                          <div class="form-item webform-component webform-component-display webform-component--webform-group-inquiry--field-inquiry-application-type">
                            <label>お手続きの内容 </label>
                            <div class="item-list">
                              <ul>
                                <template v-if="inquiry_type.find((t) => t == 1)">
                                  <li>電気の利用申込み</li>
                                </template>
                                <template v-if="inquiry_type.find((t) => t == 2)">
                                  <li>支払方法の変更</li>
                                </template>
                              </ul>
                            </div>
                          </div>
                        </template>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-inquiry--field-inquiry-content" v-if="kind == '1'">
                          <label>内容 </label>
                          {{ content }}
                        </div>
                      </div>
                    </fieldset>
                    <template v-if="!isLogin">
                    <fieldset class="webform-component-fieldset webform-component--webform-group-private-corporation form-wrapper">
                      <legend><span class="fieldset-legend">個人／法人</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-private-corporation--field-private-corporation">
                          <label>個人／法人 </label>
                          <template v-if="individual_corporation == 0">個人</template> <template v-if="individual_corporation == 1">法人</template>
                        </div>
                        <template v-if="individual_corporation == 1">
                          <div class="form-item webform-component webform-component-display">
                            <label>会社名</label>
                            {{ company_name }}
                          </div>
                          <div class="form-item webform-component webform-component-display">
                            <label>部署名</label>
                            {{ department_name }}
                          </div>
                          <div class="form-item webform-component webform-component-display">
                            <label>役職</label>
                            {{ director }}
                          </div>
                        </template>
                      </div>
                    </fieldset>
                    </template>
                    <template v-if="!isLogin">
                    <fieldset class="webform-component-fieldset webform-component--webform-group-contact-info form-wrapper">
                      <legend><span class="fieldset-legend">ご連絡先</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contact-info--field-contact-name">
                          <label>お名前 </label>
                          <span class="field-prefix"></span> {{ name }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contact-info--field-contact-kana">
                          <label>フリガナ </label>
                          <span class="field-prefix"></span> {{ name_kana }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contact-info--field-mansion-name-contact">
                          <label>マンション名 </label>
                          <span class="field-prefix"></span> {{ building_name }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contact-info--field-room-number-contact">
                          <label>部屋番号&nbsp;または店舗名・管理組合名 </label>
                          <span class="field-prefix"></span> {{ room_number_etc }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contact-info--field-contact-email">
                          <label>E-mail </label>
                          {{ e_mail }}
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contact-info--field-inquiry-tel webform-container-inline">
                          <label>日中ご連絡の取れる電話番号 </label>
                          <span class="field-prefix"></span> {{ day_contact_tel_num }} <span class="field-suffix"></span>
                        </div>
                      </div>
                    </fieldset>
                    </template>
                    <template v-if="kind == '8'">
                      <fieldset class="webform-component-fieldset form-wrapper">
                        <div class="fieldset-wrapper">
                          <div class="form-item webform-component webform-component-display">
                            <label>証明が必要な電気料金</label>
                            {{ need_proof_electric_bill_from_year }}年{{ need_proof_electric_bill_from_month }}月利用分（
                            {{ addMonth(new Date(need_proof_electric_bill_from_year, need_proof_electric_bill_from_month - 1, 1), 1).getFullYear() }}年
                            {{ addMonth(new Date(need_proof_electric_bill_from_year, need_proof_electric_bill_from_month - 1, 1), 1).getMonth() + 1 }}月請求分）~ 
                            {{ need_proof_electric_bill_to_year }}年{{ need_proof_electric_bill_to_month }}月利用分（
                            {{ addMonth(new Date(need_proof_electric_bill_to_year, need_proof_electric_bill_to_month - 1, 1), 1).getFullYear() }}年
                            {{ addMonth(new Date(need_proof_electric_bill_to_year, need_proof_electric_bill_to_month - 1, 1), 1).getMonth() + 1 }}月請求分）
                          </div>
                          <div class="form-item webform-component webform-component-display">
                            <label>発行手数料</label>
                            {{ issuance_fee }}円
                          </div>
                        </div>
                      </fieldset>
                    </template>
                    <template v-if="kind == '4' || kind == '5' || kind == '8' || kind == '9'">
                      <fieldset class="webform-component-fieldset webform-component--webform-group-contact-info form-wrapper">
                        <legend><span class="fieldset-legend">電気を利用しているマンションと異なる送付先をご希望の場合</span></legend>
                        <div class="fieldset-wrapper">
                          <div class="form-item webform-component webform-component-display">
                            <label>郵便番号 </label>
                            {{ post_code }}
                          </div>
                          <div class="form-item webform-component webform-component-display">
                            <label>住所&nbsp;または店舗名・管理組合名 </label>
                            {{ delivery_address }}
                          </div>
                          <div class="form-item webform-component webform-component-display">
                            <label>宛名 </label>
                            {{ delivery_name }}
                          </div>
                        </div>
                      </fieldset>
                    </template>
                    <template v-if="kind == '6'">
                      <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info form-wrapper">
                        <legend><span class="fieldset-legend">お引越しの情報</span></legend>
                        <div class="fieldset-wrapper">
                          <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--field-moving-schedule webform-container-inline">
                            <label>お引越し予定日 </label>
                            {{ move_scheduled_date_year + "年" + move_scheduled_date_month + "月" + move_scheduled_date_date + "日" }}
                          </div>
                          <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--field-next-key-delivery webform-container-inline">
                            <label>鍵のお引渡（ご返却）日 </label>
                            {{ key_delivery_return_day_year + "年" + key_delivery_return_day_month + "月" + key_delivery_return_day_date + "日" }}
                          </div>
                          <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info--webform-group-moving-address form-wrapper">
                            <legend><span class="fieldset-legend">お引越し先住所</span></legend>
                            <div class="fieldset-wrapper">
                              <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-zipcode">
                                <label>郵便番号 </label>
                                <span class="field-prefix"></span> {{ move_address_post_code }} <span class="field-suffix"></span>
                              </div>
                              <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-pref">
                                <label>都道府県 </label>
                                <span class="field-prefix"></span> {{ move_address_prefectures }} <span class="field-suffix"></span>
                              </div>
                              <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-city">
                                <label>市区町村 </label>
                                <span class="field-prefix"></span> {{ move_address_municipalities }} <span class="field-suffix"></span>
                              </div>
                              <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-town">
                                <label>町名 </label>
                                <span class="field-prefix"></span> {{ move_address_townname }} <span class="field-suffix"></span>
                              </div>
                              <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-house-number">
                                <label>番地 </label>
                                <span class="field-prefix"></span> {{ move_address_housenum }} <span class="field-suffix"></span>
                              </div>
                              <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-address-other">
                                <label>マンション名・部屋番号等 </label>
                                <span class="field-prefix"></span> {{ move_address_builname_etc }} <span class="field-suffix"></span>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </fieldset>
                    </template>
                    <template v-if="!isLogin">
                    <fieldset class="webform-component-fieldset webform-component--webform-group-other-info form-wrapper">
                      <legend><span class="fieldset-legend">その他</span></legend>
                      <div class="fieldset-wrapper">
                        <template v-if="kind == '' || kind == '1'">
                          <div class="form-item webform-component webform-component-display webform-component--webform-group-other-info--field-question">
                            <label>弊社を知ったきっかけを教えてください。（複数回答可） </label>
                            <div class="item-list">
                              <ul>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 1)">新聞／雑誌</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 2)">WEB検索サイト</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 3)">TV番組／CM</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 4)">展示会／セミナー</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 5)">メルマガ</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 6)">パンフレット／DM</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 7)">ご紹介</li>
                                <li v-if="how_did_you_know_about_us_list.find((e) => e == 8)">入居者説明会</li>
                              </ul>
                            </div>
                          </div>
                        </template>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-other-info--field-question-other">
                          <label>その他 </label>
                          <span class="field-prefix"></span> {{ other }} <span class="field-suffix"></span>
                        </div>
                      </div>
                    </fieldset>
                    </template>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->

              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
            <div class="block block-block" data-bid="617">
              <div class="section_contact clearfix" id="contact_body">
                <h4><img alt="各種申込・変更手続き" src="../assets/images/contact_tellform_t.png" style="height: 45px; width: 820px" /></h4>
                <div class="col2_contact btm_2 clearfix">
                  <div class="inner clearfix">
                    <p><img alt="0120-33-0057" src="../assets/images/ntt-f_logo.png" style="height: 63px; width: 260px" /></p>
                    <p>NTTアノードエナジー&nbsp;マンション電力提供サービス&nbsp;カスタマーセンター</p>
                    <p><img alt="フリーダイヤル" src="../assets/images/free-dial.png" style="float: left; height: 20px; margin-right: 5px; width: 35px" /></p>
                    <p>0120-33-0057</p>
                    <p>受付時間 9:00～17:00（年中無休）</p>
                  </div>
                </div>
                <p>
                  ※番号をよくお確かめのうえ、おかけ間違えのないようにお願いいたします。<br />
                  ※お客様との通話は、お申出内容の正確な確認とサービス向上のために録音させていただいております。<br />
                  ※緊急のご用件について、回線障害などの予期せぬ障害で電話が切れた時に、こちらからかけ直しをさせていただくため、全てのお客様に発信者番号通知をお願いしております。<br />
                  ※0120番号へは、国際電話ならびに一部のIP電話等からご利用いただけない場合があります。
                </p>
              </div>
            </div>
            <!-- /block -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { inquiryStore, isLogin } from "../stores/store.js";
import config from "../config.js";
import { post } from "../assets/js/request.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    var store = inquiryStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push({ path: "/mansion/inquiry_document" });
    }else{
      var update = store.update;
      store.$reset();
      update.auth_error = false;
      update.isLogin = isLogin();
      update.password = "";
      update.send = false;
      return update;
    }
  },
  methods : {
    addMonth : function (date, add) {
      date.setDate(1);
      date.setMonth(date.getMonth() + add);
      return date;
    },
    getDateString: function (year, month, day) {
      var date = new Date();
      date.setFullYear(date.getFullYear() + year);
      date.setMonth(date.getMonth() + month);
      date.setDate(date.getDate() + day);
      return date.toLocaleDateString("sv-SE");
    },
    back: function () {
      var store = inquiryStore();
      store.update = this.$data;
      this.$router.push({ path: "/mansion/inquiry_document" });
    },
    push: async function () {
      var table = {
        1 : 0, // お問い合わせ
        3 : 6, // でんき案内板のログインID・初期パスワードを確認したい
        7 : 7, // 電気の利用申込みが手続きされているか確認したい
        4 : 1, // 紙の申込書を送って欲しい
        5 : 2, // 料金支払方法申込書を送って欲しい（支払方法の変更）
        6 : 3, // 退去の手続きをしたい
        8 : 4, // 支払証明書を発行してほしい
        9 : 5, // 返信用の封筒を送って欲しい
      };
      var update = structuredClone(this.$data);
      update["type"] = table[update["kind"]];
      if(update["kind"] == 4){
        var contents = new Array();
        if(update.inquiry_type.find((t) => t == 1)){
          contents.push("電気の利用申込み");
        }
        if(update.inquiry_type.find((t) => t == 2)){
          contents.push("支払方法の変更");
        }
        update["content"] = contents.join(',');
      }
      if(!(update["type"] == 0 || update["type"] == 4)){
        update["content"] = "";
      }
      if(isLogin()){
        update["individual_corporation"] = 0;
        update["name"] = "";
        update["name_kana"] = "";
        update["building_name"] = "";
        update["room_number_etc"] = "";
        update["e_mail"] = "";
        update["day_contact_tel_num"] = "";
      }
      if(update["individual_corporation"] != 1){
        update["company_name"] = "";
        update["department_name"] = "";
        update["director"] = "";
      }
      if(update["type"] != 4){
        update["need_proof_electric_bill_from"] = "";
        update["need_proof_electric_bill_to"] = "";
        update["issuance_fee"] = "";
      }
      if(update["type"] != 1 && update["type"] != 4 && update["type"] != 5){
        update["post_code"] = "";
        update["delivery_address"] = "";
        update["delivery_name"] = "";
      }
      if(update["type"] != 3){
        update["move_scheduled_date"] = "";
        update["key_delivery_return_day"] = "";
        update["move_address_post_code"] = "";
        update["move_address_prefectures"] = "";
        update["move_address_municipalities"] = "";
        update["move_address_townname"] = "";
        update["move_address_housenum"] = "";
        update["move_address_builname_etc"] = "";
      }
      if(isLogin() || update["type"] != 1){
        update["how_did_you_know_about_us"] = "";
      }else{      
        update["how_did_you_know_about_us"] =  update.how_did_you_know_about_us_list.join(",");
      }
      if(isLogin()){
        update["other"] = "";
      }
      try{
        this.$data.send = true;
        this.$data.auth_error = false;
        await post(config.url.post.inquiry,{ 
            webform_inquiry : update
        });
        this.$router.push({ path: "/mansion/inquiry_complete" });
      }catch(ex){
        if(this.data.kind == 4){
          this.$data.send = false;
          this.data.content = "";
        }
        if(ex.response.data == "PasswordError"){
          this.$data.send = false;
          this.$data.auth_error = true;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
      return false;
    }
  }
};
</script>
<style scoped>
.form-item.webform-component.webform-component-display.webform-component--webform-group-inquiry--field-inquiry-content {
  white-space: pre-wrap;
}
</style>

<template>
  <div class="html not-front not-logged-in no-sidebars page-node page-node- page-node-416 node-type-page responsive-menus-load-processe">
  <Header />
  <div id="highlighted">
    <Header_info/>
    <div class="block block-menu highlight-menu" data-bid="273">
      <ul class="menu">
        <li class="first leaf"><router-link to="/mansion/faq_top">よくあるご質問</router-link></li>
        <li class="leaf"><router-link to="/mansion/inquiry">お問い合わせ</router-link></li>
        <li class="last leaf active-trail"><router-link to="/mansion/entry">電気の利用申込み</router-link></li>
      </ul>
    </div>
  </div>
  <!-- ______________________ MAIN _______________________ -->
  <div id="main">
    <div class="container">
      <h2 class="element-invisible">現在地</h2>
      <div class="breadcrumb">
        <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">ＮＴＴアノードエナジーの「マンション電力提供サービス」へようこそ</span>
      </div>
      <section id="content">
        <!-- <div id="content-header"> -->
        <h1 class="title">ＮＴＴアノードエナジーの「マンション電力提供サービス」へようこそ</h1>
        <!-- </div> /#content-header -->
        <div id="content-area">
          <article class="node node-page" data-nid="416">
            <header><span property="dc:title" content="ＮＴＴアノードエナジーの「マンション電力提供サービス」へようこそ" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
            <div class="content">
              <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                <div class="field-items">
                  <div class="field-item even" property="content:encoded">
                    <div class="entry-guidance-top">
                      <p>皆様のお宅に電気を提供しているＮＴＴアノードエナジーです。</p>
                      <p>電気のご利用には、必ず申込みのお手続きをお願いいたします。</p>
                    </div>
                    <p>&nbsp;</p>
                    <div class="entry-guidance-bottom">
                      <p><strong>◆ＳＴＥＰ１</strong>：</p>
                      <p>画面下の「電気の利用申込み」をクリックして必要事項をご記入ください。</p>
                      <p><strong>◆ＳＴＥＰ２</strong>：</p>
                      <p>1週間ほどで登録いただいた「お支払者様情報」のメールアドレスへログインIDと初期パスワードが届きますので、お客様のマイページを登録してください。</p>
                      <p><strong>◆ＳＴＥＰ３</strong>：</p>
                      <p>お客様のマイページより、ご希望のお支払方法（クレジットカード、口座振替等）をお選びください。</p>
                    </div>
                    <p>&nbsp;</p>
                    <div class="f-smalltext">
                      <p>
                        ※ご利用料金は、部屋のお引渡日から適用となります。 （入居前でもインターフォンや防火設備・換気設備等の電気料金が発生いたします）通常は鍵のお引渡日や部屋の所有権移転日となりますので、部屋の売買契約書等をご確認ください。<br />
                        ※お申し込みがないまま電気を継続して使用した場合は、電気のご利用ができなくなりますのでご注意ください。<br />
                        ※お申し込みが遅れた場合でも、電気料金（基本料金等含む）は、部屋のお引渡日から遡って請求されます。
                      </p>
                    </div>
                    <div class="static__half_colmn_1">
                      <p>ご利用ガイド</p>
                      <a :href="dir + '/guide.pdf'" target="_blank">
                        <img src="../assets/images/guide_PN_2_2016101.jpg" />
                      </a>
                    </div>
                    <input type="button" value="電気の利用申込み" @click.prevent.stop="$router.push({path : '/mansion/confirm_mail'})" />
                  </div>
                </div>
              </div>
            </div>
            <!-- /content -->
            <div class="links"></div>
            <!-- /links -->
          </article>
          <!-- /article #node -->
        </div>
      </section>
      <!-- /content-inner /content -->
      <!-- /sidebar-second -->
      <!-- /sidebar-first -->
    </div>
  </div>
  <!-- /main -->
  <Footer />
  </div>
</template>
<script>
import Header from '../components/Header.vue';
import Header_info from '../components/Header_info.vue';
import Footer from '../components/Footer.vue';
import config from "../config.js";
//import { downloadFile } from "../assets/js/request.js";
export default {
  components: {
    Header,
    Header_info,
    Footer
  },
  data(){
    return { dir : config.url.get.download }
  },
}
</script>
<style scoped src="../assets/css/style.css" />
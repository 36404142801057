<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
  <Header_login />
  <!-- ______________________ MAIN _______________________ -->
  <div id="main">
    <div class="container">
      <h2 class="element-invisible">現在地</h2>
      <div class="breadcrumb">
        <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
          »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">
          »</span> <span class="inline odd last">料金明細確認方法の変更</span>
      </div>
      <div class="messages error" v-if="error && now.DetailFlag == 0">
        <h2 class="element-invisible">エラーメッセージ</h2>
        現在、【WEB明細】でお申込みいただいているため、変更の必要はございません。
      </div>
      <div class="messages error" v-if="error && now.DetailFlag == 1">
        <h2 class="element-invisible">エラーメッセージ</h2>
        現在、【紙明細】でお申込みいただいているため、変更の必要はございません。
      </div>
      <section id="content">
        <!-- <div id="content-header"> -->
        <h1 class="title">料金明細確認方法の変更</h1>
        <div class="block block-system" data-bid="255">
          <div class="enne_help">
            <router-link to="/mansion/faq_category_list/5" target="_blank">FAQ</router-link>
          </div>
        </div>
        <!-- /block -->
        <!-- </div> /#content-header -->
        <div id="content-area">
          <article class="node node-webform" data-nid="82">
            <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
            <div class="content">
              <form class="webform-client-form webform-client-form-82 webform-conditional-processed" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                <div>
                  <div class="webform-progressbar"></div>
                  <div class="form-item webform-component webform-component-hidden webform-component--url" style="display: none">
                  </div>
                  <div class="form-item webform-component webform-component-hidden webform-component--req-id" style="display: none">
                  </div>
                  <div class="form-item webform-component webform-component-markup webform-component--change-specification-description">
                    <p>
                      お支払方法が<strong>クレジットカード</strong>または<strong>口座振替</strong>の方は、<br />
                      WEB明細・紙明細（有料）のいずれかをお選びいただけます。<br />
                      ご希望の料金明細確認方法をクリックし、確認ボタンを押してください。<br />
                      ※紙明細の場合は、発行手数料として毎月136円（税込）が利用料金に加算されます。
                    </p>
                  </div>
                  <div class="form-item webform-component webform-component-radios webform-component--field-specification-2">
                    <label for="edit-submitted-field-specification-2">料金明細書 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                    <div class="description">現在の料金明細確認方法：
                      <template v-if="now.DetailFlag == '-'">-</template>
                      <template v-else-if="now.DetailFlag">紙明細（有料）</template>
                      <template v-else>WEB明細</template>
                    </div>
                    <div id="edit-submitted-field-specification-2" class="form-radios">
                      <div class="form-item form-type-radio form-item-submitted-field-specification-2">
                        <input required="required" type="radio" id="edit-submitted-field-specification-2-1" name="submitted[field_specification_2]" value="0" checked="checked" class="form-radio" v-model="update.DetailFlag" />
                        <label class="option" for="edit-submitted-field-specification-2-1">WEB明細に変更する </label>
                      </div>
                      <div class="form-item form-type-radio form-item-submitted-field-specification-2">
                        <input required="required" type="radio" id="edit-submitted-field-specification-2-2" name="submitted[field_specification_2]" value="1" class="form-radio" v-model="update.DetailFlag" />
                        <label class="option" for="edit-submitted-field-specification-2-2">紙明細（有料）に変更する </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" />
                  </div>
                </div>
              </form>
            </div>
            <!-- /content -->
            <div class="links"></div>
            <!-- /links -->
          </article>
          <!-- /article #node -->
        </div>
      </section>
      <!-- /content-inner /content -->
      <Sidebar_first_login />
    </div>
  </div>
  <!-- /main -->
  <Footer />
  </div>
</template>
<script>
import Header_login from '../components/Header_login.vue'
import Sidebar_first_login from '../components/Sidebar_first_login.vue'
import Footer from '../components/Footer.vue'
import config from "../config.js";
import { feedetailStore } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer
  },
  data() {
    return { 
      now : { DetailFlag : 0 },
      update : { DetailFlag : 0 },
      error : 0   
    };
  },
  async created(){
    var store = feedetailStore();
    if(Object.keys(store.update).length != 0){
      var now = store.now;
      var update = store.update;
      store.$reset();
      Object.assign(this.$data, {
        now : now,
        update : update
      });
    }else{
      var result = await getData(config.url.get.fee);
      // { DetailFlag }
      Object.assign(this.$data, { 
        now : result,
      });
    }
  },
  methods: {
    push : function(){
      this.$data.error = 0;
      if(this.now.DetailFlag == this.update.DetailFlag){
        this.$data.error = 1;
        return;
      }
      var store = feedetailStore();
      store.update = this.$data.update;
      store.now = this.$data.now;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "fee_detail_confirm",
        },
      });
    }
  }
}
</script>

<style scoped src="../assets/css/webform.css" />
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">退去手続き完了</span>
        </div>
        <section id="content">
          <h1 class="title">退去手続き完了</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq/oshietetaikyo" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_11 view-dom-id-b9fc098899f9480539a8b640fb23b648">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (isOpen1 ? '': ' collapsed')" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen1 = !isOpen1"><span class="fieldset-legend-prefix element-invisible">非表示</span> お引越しの情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="isOpen1">
                      <div class="views-field views-field-value-26"><strong class="views-label views-label-value-26">お引越し予定日: </strong><span class="field-content">{{ webform_inquiry.move_scheduled_date_year + "年" + webform_inquiry.move_scheduled_date_month + "月" + webform_inquiry.move_scheduled_date_date + "日" }}</span></div>
                      <div class="views-field views-field-value-2"><strong class="views-label views-label-value-2">鍵のお引渡（ご返却）日: </strong><span class="field-content">{{ webform_inquiry.key_delivery_return_day_year + "年" + webform_inquiry.key_delivery_return_day_month + "月" + webform_inquiry.key_delivery_return_day_date + "日" }}</span></div>
                      <div class="views-field views-field-value"><strong class="views-label views-label-value">日中ご連絡の取れる電話番号: </strong><span class="field-content">{{ webform_inquiry.day_contact_tel_num }}</span></div>
                      <fieldset :class="'views-fieldset collapsible collapse-processed' + (isOpen2 ? '': ' collapsed')" data-module="views_fieldsets">
                        <legend>
                          <span class="fieldset-legend"
                            ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen2 = !isOpen2"><span class="fieldset-legend-prefix element-invisible">非表示</span> お引越し先住所</a><span class="summary"></span
                          ></span>
                        </legend>
                        <div class="fieldset-wrapper" v-show="isOpen2">
                          <div class="views-field views-field-value-5"><strong class="views-label views-label-value-5">郵便番号: </strong><span class="field-content">{{ webform_inquiry.move_address_post_code }}</span></div>
                          <div class="views-field views-field-value-6"><strong class="views-label views-label-value-6">都道府県: </strong><span class="field-content">{{ webform_inquiry.move_address_prefectures }}</span></div>
                          <div class="views-field views-field-value-7"><strong class="views-label views-label-value-7">市区町村: </strong><span class="field-content">{{ webform_inquiry.move_address_municipalities }}</span></div>
                          <div class="views-field views-field-value-8"><strong class="views-label views-label-value-8">町名: </strong><span class="field-content">{{ webform_inquiry.move_address_townname }}</span></div>
                          <div class="views-field views-field-value-9"><strong class="views-label views-label-value-9">番地: </strong><span class="field-content">{{ webform_inquiry.move_address_housenum }}</span></div>
                          <div class="views-field views-field-value-10"><strong class="views-label views-label-value-10">マンション名・部屋番号等: </strong><span class="field-content">{{ webform_inquiry.move_address_builname_etc }}</span></div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <p></p>
                <fieldset class="other views-fieldset" data-module="views_fieldsets" sytle="padding-top: 15px !important">
                  <div class="fieldset-wrapper">
                    <div>お引越しの当日は、分電盤の各ブレーカー・遮断器を全て下げて、退去をお願いいたします。万が一、ブレーカー・遮断器を下げないまま退去されますと、退去後に電気の利用が確認された場合は料金が発生してしまいますのでご注意ください。</div>
                  </div>
                  <p></p>
                </fieldset>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { departureStore } from "../stores/store.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = departureStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push("/mansion/");
    } else {
      var update = store.update;
      store.$reset();
      update.isOpen1 = true;
      update.isOpen2 = true;
      return update;
    }
  }
};
</script>

<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-search page-search-node responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">検索</span>
        </div>
        <section id="content">
          <h1 class="title">検索</h1>

          <div id="content-area">
            <form class="search-form" action="#" method="post" id="search-form" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div class="container-inline form-wrapper" id="edit-basic">
                  <div class="form-item form-type-textfield form-item-keys">
                    <label for="edit-keys">検索キーワードを入力してください </label>
                    <input type="text" id="edit-keys" name="keys" size="40" maxlength="255" class="form-text"  v-model="keyword" />
                  </div>
                  <input type="submit" id="edit-submit" name="op" value="検索" class="form-submit" />
                </div>
              </div>
            </form>
            <template v-if="contents.length > 0">
              <h2>検索結果</h2>
              <template v-if="contents[0].length > 0">
                <ol class="search-results node-results">
                  <template v-for="f in contents[current - 1]" :key="f">
                    <li class="search-result">
                      <h3 class="title">
                        <router-link v-if="f.PageKind == 'FAQ'" :to="'/mansion/faq/' + f.UrlRequest"> {{ f.Title }}</router-link>
                        <router-link v-if="f.PageKind == 'コラム'" :to="'/mansion/column_list/' + f.UrlRequest"> {{ f.Title }}</router-link>
                        <router-link v-if="f.PageKind == 'お知らせ'" :to="'/mansion/info/' + f.UrlRequest"> {{ f.Title }}</router-link>
                        <div class="faq">{{ f.PageKind }}</div>
                      </h3>
                      <div class="search-snippet-info">
                        <p class="search-snippet" v-html="f.BodySummary" />
                      </div>
                    </li>
                  </template>
                </ol>
                <h2 class="element-invisible">ページ</h2>
                <div class="item-list">
                  <ul class="pager">
                    <template v-for="(p, index) in createPage()" :key="p">
                      <template v-if="index == 0 && 1 != current">
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage(0)"> ≪ </a>
                        </li>
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage((current - 2) * num)"> &lt; </a>
                        </li>
                      </template>
                      <template v-if="p + 1 == current">
                        <li class="pager-current">
                          {{ p + 1 }}
                        </li>
                      </template>
                      <template v-else>
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                        </li>
                      </template>
                        <template v-if="(index < 10 ? index == Math.ceil(pages.length / num) - 1 : index == 10) && current != Math.ceil(pages.length / num)">
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage(current * num)"> &gt; </a>
                        </li>
                        <li class="page-item">
                          <a href="#" @click.prevent.stop="changePage((Math.ceil(pages.length / num) - 1) * num)"> ≫ </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
              </template>
              <template v-else>
                <h2>キーワードに該当するページは見つかりませんでした</h2>
                <ul>
                  <li>綴りが正しいかチェックしてください。</li>
                  <li>単語がそれぞれ別に検索できるように引用符を取り除いてください。 大抵の場合、<em>bike shed</em> の方が、<em>&quot;bike shed&quot;</em>よりも多くの検索結果が得られます。</li>
                  <li>検索条件を緩めるために<em>OR</em>の利用を検討ください。 大抵の場合、<em>bike OR shed</em> の方が <em>bike shed</em>よりも多くの検索結果が得られます。</li>
                </ul>
              </template>
            </template>
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import $ from "jquery";
import { getData } from "../assets/js/request.js";
import { isLogin } from "../stores/store.js";
import { mainMessage } from '../stores/store.js';
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    if(!isLogin()){
      //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').attr('disabled', 'disabled');
      $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
      $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
    }
    if(this.$route.query["keyword"] == ""){
      var message = mainMessage();
      message.green = -1;
      this.$router.push({
        path : "/mansion/",
      });
    }
    var query = decodeURIComponent(this.$route.query["keyword"] || "");
    return {
      keyword: query,
      contents: [],
      current: 1,
      num: 1,
      pages: [],
      isLogin: isLogin(),
    };
  },
  async created() {
    this.view();
  },
  watch: {
    '$route': function () {
      this.view();
    }
  },
  methods: {
    view: async function (){
      var pages = new Array();
      var result = await getData(config.url.get.search + "?keyword=" + this.$route.query.keyword);
      // { UrlRequest, Title, BodySummary, PageKind }
      var td = new Array();
      pages.push(td);
      var keyword = decodeURIComponent(this.$route.query.keyword);
      keyword.replace(/\s/g," ")
      var split = keyword.split(" ");
      split = split.filter(x => x != "");
      for (var info in result) {        
        var body = $("<div>" + result[info].BodySummary + "</div>");
        body.find("style").remove();
        body.find("script").remove();
        body = body.text();
        var min = body.length + 1;
        var isMatchTitle = false;
        for(var i = 0; i < split.length; i++){
          if(split[i] == "OR" && i != 0 && i != split.length - 1){
            i++;
          }
          var index = body.indexOf(split[i]);
          if(index != -1 && min > index){
            min = index;
          }
          if(result[info].Title.indexOf(split[i]) != -1){
            isMatchTitle = true;
          }
        }
        if(min == body.length + 1){
          if(isMatchTitle){
            // タイトルのみに一致
            min = 0;
          }else{
            // htmlタグで一致してしまったものは取り除く
            continue;
          }
        }
        var start = min - 10 < 0 ? 0 : min - 10;
        body = body.slice(start, body.length);
        for(var j = 0; j < split.length; j++){
          if(split[i] == "OR" && j != 0 && j != split.length - 1){
            j++;
          }
          body = body.replaceAll(split[j], "<b>"+ split[j] + "</b>");
        }
        body = "…" + body + "…";
        result[info].BodySummary = body;
        if (td.length == 30) {
          td = new Array();
          pages.push(td);
        }
        td.push(result[info]);
      }
      var query = decodeURIComponent(this.$route.query["keyword"] || "");
      this.$data.keyword = query;
      this.$data.contents = pages;
      this.$data.pages = pages;
      this.$data.current = 1;
    },
    push: function () {
      if (this.$data.keyword.trim() == "") {
        this.$router.push({
          path : "/mansion/",
        });
      } else {
        this.$router.push({
          path : "/mansion/search",
          query : {
            keyword : encodeURIComponent(this.$data.keyword),
            _ : new Date().getTime()
          }
        });
      }
    },
    createPage: function () {
      var side = 5;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }

      return pangeNum;
    },
    changePage: function (index) {
      this.$data.current = index / this.$data.num + 1;
      document.body.scrollIntoView();
    },
  },
};
</script>
<style scoped>
.search-snippet {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
html li {
  list-style-type: none;
}
input[type='text'] {
  width : auto;
}
</style>

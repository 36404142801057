<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html front not-logged-in no-sidebars page-node responsive-menus-load-processed" v-show="!loading">
    <template v-if="!loading">
      <Header_top />
    </template>
    <div class="messages status" v-if="green == 1">
      <h2 class="element-invisible"></h2>
      手続きはまだ完了しておりません。引き続きパスワードの設定をお願いします。現在、開いている画面を全て閉じてから、届いたメールの文面に記載されているリンクをクリックしてください。
    </div>
    <div class="messages status" v-if="green == 2">
      <h2 class="element-invisible"></h2>
      手続きを受け付けました。3分程度でメールが届かない場合は<router-link to="/mansion/inquiry">こちら</router-link>よりお問い合わせください。
    </div>
    <div class="messages status" v-if="green == 3">
      <h2 class="element-invisible"></h2>
      ログアウトしました。ご利用ありがとうございました。
    </div>
    <div class="messages error" v-if="auth_error == 1">
    <h2 class="element-invisible">エラーメッセージ</h2>
      ログインIDまたはパスワードが承認されませんでした。<br /><router-link to="/mansion/user/password">パスワードをお忘れですか？ 再発行を希望する場合はここをクリックしてください。</router-link>
    </div>
    <div class="messages error" v-if="auth_error == 2">
    <h2 class="element-invisible">エラーメッセージ</h2>
      {{ fail_count }}回以上ログインに失敗しているため、このアカウントは一時的にブロックされます。しばらく後でもう一度試すか パスワードを再発行してください。
    </div>
    <div class="messages error" v-if="green == -1">
      <h2 class="element-invisible">エラーメッセージ</h2>
      いくつかキーワードを入力してください。
    </div>
    <div id="highlighted">
      <Header_info  v-if="!loading" />
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-block top-main-copy" data-bid="312">
        <h2>「でんき案内板」へようこそ！</h2>
        <p>レジル株式会社マンション電力提供サービス（旧NTTアノードエナジー）のお客様専用ポータルサイトです。</p>
      </div>
      <!-- /block -->
      <div class="block block-user" data-bid="267">
        <form action="#" method="post" id="user-login-form" accept-charset="UTF-8" @submit.prevent="push({userid : userid, password: password})">
          <div>
            <div class="enne_help">
              <router-link to="/mansion/faq/oshietelogin">FAQ</router-link>
            </div>
            <div class="information">
              <p class="login-notice">レジル株式会社マンション電力提供サービスのお客様専用ポータルサイトです。</p>
            </div>
            <div class="form-item form-type-textfield form-item-name">
              <label for="edit-name">ログインIDまたはメールアドレス <span class="form-required" title="これは入力必須項目です。">*</span></label>
              <input type="text" id="edit-name" name="name" size="15" maxlength="60" class="form-text required" v-model="userid" />
            </div>
            <div class="form-item form-type-password form-item-pass">
              <label for="edit-pass">パスワード <span class="form-required" title="これは入力必須項目です。">*</span></label>
              <input type="password" id="edit-pass" name="pass" size="15" maxlength="128" class="form-text required" v-model="password" />
            </div>
            <div class="form-actions form-wrapper" id="edit-actions--3">
              <button type="submit" id="edit-submit--3" name="op" class="form-submit"><p>&nbsp;ログインIDまたは</p><p>メールアドレスでログイン</p></button>
            </div>
            <div class="item-list">
              <ul>
                <li class="first last">
                  <router-link to="/mansion/user/password" title="パスワード再発行のメールをリクエスト">ID・パスワードをお忘れの方へ</router-link>
                </li>
              </ul>
            </div>
            <p class="login-notice">※ログインIDは、あらかじめお知らせしています。パスワードはお客様ご自身で設定したものです。</p>
            <div class="form-item form-type-textfield form-item-name">
              <label for="edit-name">ソーシャルアカウントでログイン</label>
              <div id="api" data-name="IdpSelections" />
                <div class="form-actions exchange">
                  <a :href="config.auth.google_url" id="GoogleExchange"></a>
                </div>
                <div class="form-actions exchange">
                  <a :href="config.auth.microsoft_url" id="MicrosoftAccountExchange"></a>
                </div>
                <div class="form-actions exchange">
                  <a :href="config.auth.amazon_url" id="AmazonExchange"></a>
                </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /block -->
      <div class="block block-menu login-pre-menu" data-bid="297">
        <ul class="menu">
          <li class="first last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-block entry_description" data-bid="785">
        <p>電気の新規ご利用開始の手続きは、この「電気の利用申込み」をクリックしてください。</p>
      </div>
      <!-- /block -->
      <div class="block block-block link-mansion-menu" data-bid="236">
        <ul>
          <template v-for="b in banner" :key="b">
            <li :style="'background: #fff url(' + b.image_path + ') no-repeat center center;'">
              <a :href="b.link_path" style="height:60px">&nbsp;</a>
            </li>
          </template>
          <!--
          <li class="link-mansion">
            <router-link to="/mansion/setsuden2023">マンション電力提供サービスとは</router-link>
          </li>
          <li class="link-gekihen">
            <router-link to="/mansion/gekihen">「電気・ガス価格激変緩和対策事業」電気料金の値引きについて</router-link>
          </li>
          <li class="link-power-cut">
            <router-link to="/mansion/faq/oshietedenki#outage">停電したときは？</router-link>
          </li>
          <li class="safety">
            <router-link to="/mansion/safety">電気設備を安全にご使用いただくために</router-link>
          </li>
          -->
        </ul>
      </div>
      <!-- /block -->
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <section id="content">
          <!-- <div id="content-header"> -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <div class="block block-views" data-bid="257">
              <div class="view view-column-list view-id-column_list view-display-id-block">
                <div class="view-content">
                </div>
              </div>
            </div>
            <!-- /block -->
            <div class="block block-block under-inqarea" data-bid="263">
              <div class="inq-menu">
                <dl>
                  <dt><router-link to="/mansion/faq_top">よくあるご質問</router-link></dt>
                </dl>
                <dl>
                  <dt><router-link to="/mansion/inquiry">お問い合わせ</router-link></dt>
                </dl>
                <dl>
                  <dt>
                    <router-link to="/mansion/document_download">資料ダウンロード</router-link>
                  </dt>
                </dl>
                <dl>
                  <dt><router-link to="/mansion/nencho">燃料費調整単価</router-link></dt>
                </dl>
              </div>
            </div>
            <!-- /block -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <!-- /sidebar-second -->
        <!-- /sidebar-first -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import $ from "jquery";
import Header_top from "../components/Header_top.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import { loginStore, mainMessage } from "../stores/store.js";
import { isLogin, saveData, removeData, loadData } from "../stores/store.js";
import config from "../config.js";
import { getData, post } from "../assets/js/request.js";
import { initPassword } from '../assets/js/mems_show_password';
export default {
  components: {
    Header_top,
    Header_info,
    Footer,
  },
  data: function () {
      var message = mainMessage();
      var green = message.green;
      message.green = 0;
      return  {
        loading: true,
        config: config,
        userid: "",
        password: "",
        auth_error: 0,
        fail_count: 0,
        green: green,
        current: 1,
        num: 2,
        pages : [],
        columns1: [],
        columns2: [],
        banner: []
      };
  },
  async created(){ 
    $('link[href="mansion.css"][class!="css"]').attr('disabled', 'disabled');
    this.$data.loading = true;
    var query = this.$route.query;
    var hashdata = this.$router.currentRoute._rawValue.hash;
    if(hashdata.length != 0){
      hashdata = hashdata.slice(1) ;
    }
    var token_string = hashdata.split('&');
    var param = {};
    for(var index in token_string){
      var data = token_string[index].split("=");
      if(data.length == 2){
        param[data[0]] = data[1];
      }
    }
    var store = loginStore();
    var newToken = undefined;
    if(param["id_token"] != null){
      if(param["state"] == "login"){
        // ログインのときは消す
        removeData("x-token");
        // 認証トークンあり
        saveData("token", param["id_token"]);
      }else{
        // 認証トークンあり
        newToken = param["id_token"];
      }
      try{
        await this.authNotFirst({state : param["state"]}, newToken);
        removeData("x-token");
      }catch(ex){
        removeData("token");
        this.$data.auth_error = 1;
      }
    }else if(param["error"] != null){
      // エラー
      removeData("token");
      removeData("x-token");
      removeData("last_login_datetime");
      store.$reset();
      this.$data.auth_error = 1;
    }
    if (isLogin() && store.code < 1) {
        // ログイン済み
        try{
          var rooms = await getData(config.url.get.building);
          // [{customer_id, contractor_name, building_id, building_name, building_zip_postal_code, building_state, building_city, building_address_line, building_address_line_2,
          // room_id, room_number, room_type, room_note, form_move_date}]
          store.code = 0;
          store.rooms = rooms;
          var id = loadData("id");
          var room = store.rooms.find((r) => r.customer_id == id);
          if(store.room == {} || !room){
            store.room = rooms[0];
            store.customer_id = rooms[0]["customer_id"];
            saveData("id", store.customer_id);
          }else{
            store.room = room;
            store.customer_id = room["customer_id"];
          }
          if(query["jump"] == undefined){
            var message = mainMessage();
            message.green = this.$data.green;
            query = { jump : "/mansion/login"};
          }
        }catch(ex){
          // 認証期限切れ
          removeData("token");
          removeData("x-token");
          removeData("last_login_datetime");
          store.$reset();
        }
    }else{
      removeData("token");
      removeData("x-token");
      removeData("last_login_datetime");
      store.$reset();
    }
    if (query["jump"] != undefined) {
        // 遷移先指定あり
        var path = decodeURIComponent(query["jump"]);
        delete query["jump"];
        if(hashdata != undefined && hashdata != ""){
          hashdata = "#" + hashdata;
        }
        this.$router.replace({
          path: path,
          query : query,
          hash: hashdata
        });
        return;
    }
    
    try{
      // 未ログイン
      var banner = await getData(config.url.get.banner);
      this.$data.banner = banner;
      var pages = new Array();
      var result = [];
      var td = new Array();
      pages.push(td);
      for (var colmuun in result) {
        if (td.length == 3) {
          td = new Array();
          pages.push(td);
        }
        td.push(result[colmuun]);
      }
      var userid = "";
      if(query.id){
        userid = decodeURIComponent(query.id);
      }
      Object.assign(this.$data, {
        userid : userid,
        pages : pages,
        columns1 : pages.length == 0 ? [] : pages[0],
        columns2 : pages.length <= 1 ? [] : pages[1]
      });
    }catch(ex){
      removeData("token");
      removeData("x-token");
      removeData("last_login_datetime");
      store.$reset();
    }
    this.$data.loading = false;
  },
  methods: {
    createPage: function () {
      var side = 5;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }

      return pangeNum;
    },
    changePage: function (index) {
      this.$data.columns1 = this.$data.pages[index];
      this.$data.columns2 = this.$data.pages[index + 1];
      this.$data.current = index / this.$data.num + 1;
    },
    authNotFirst: async function (params, newToken){
      var response = await post(config.url.post.certification, params, newToken);
      // [ { customer_id, last_login_datetime, code } ]
      if(response.token){
        saveData("x-token", response.token);
      }
      if(response.last_login_datetime){
        saveData("last_login_datetime", response.last_login_datetime);
      }
      var login = loginStore();
      login.code = response.code;
      if(response.code != 0){
        login.customer_id = response.customer_id;
        return response;
      }
      if(params["state"] != "login"){
        saveData("token", newToken);
      }
      return response;
    },
    push: async function (params) {
      try{
        this.$data.green = 0;
        this.$data.auth_error = 0;
        var response = await this.authNotFirst(params);
        if(response.code != 0)
        {
          if(response.first_guidance_skip_flag == 1){
            this.$router.push({
              path: "/mansion/create/password"
            });
          }else{
            this.$router.push({
              path: "/mansion/create/mailaddress"
            });
          }
          return;
        }
      }catch(ex){
        if(ex.response.status == 401){
          if(ex.response.data.startsWith("AccountLock")){
            this.$data.fail_count = ex.response.data.replace("AccountLock:", "");
            this.$data.auth_error = 2;
          }else{
            this.$data.auth_error = 1;
          }
          return;
        }else{
          throw ex;
        }
      }
      var rooms = await getData(config.url.get.building);
      // [{customer_id, contractor_name, building_id, building_name, building_zip_postal_code, building_state, building_city, building_address_line, building_address_line_2,
      // room_id, room_number, room_type, room_note, form_move_date}]
      var store = loginStore();
      store.$reset();
      store.code = 0;
      store.rooms = rooms;
      store.room = rooms[0];
      store.customer_id = rooms[0]["customer_id"];
      var query = this.$route.query;
      var path = "/mansion/login";
      if (query["destination"] != undefined) {
        path = decodeURIComponent(query["destination"]);
        delete query["destination"];
      }
      this.$router.push({
        path: path,
        query : query
      });
    }
  },
  watch: {
    '$route': function () {
      var message = mainMessage();
      var green = message.green;
      message.green = 0;
      this.$data.green = green;
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      
      initPassword();
    });
  },
};
</script>
<style scoped>
.html {
  background-color: white !important;
}
form ul {
  margin-top: 0px;
}
.exchange:hover {
	opacity: 0.8;
}
.exchange a {
  background-repeat: no-repeat;
  color: white;
  width: 156px;
  height: 32px;
  border-radius: 3px;
  font-size: 15px;
  margin-right: 40px;
  display: inline-block;
  text-decoration: none;
}
.exchange a:visited {
  color: white;
}
#MicrosoftAccountExchange {
  background-image: url(../../public/common/ms-symbollockup_signin.png);
  background-size: 100% 100%;
}
#GoogleExchange {
  background-image: url(../../public/common/web_light_sq_SI@1x.png);
  background-size: 100% 100%;
}
#AmazonExchange {
  background-image: url(https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png);
  background-color: rgb(255,255,255,255);
  border: none;
}
@media screen and (max-width:768px){
  .exchange button {
    margin-right: 0px !important;
  }
}
@media screen and (max-width:768px){
  div#highlighted {
    min-width: initial;
  }
}
#highlighted {
  min-width: 1080px;
}
.form-item.form-type-textfield.form-item-name .form-actions.exchange {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
</style>
<style scoped src="../assets/css/style.css" />

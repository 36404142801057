<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node-82 node-type-webform responsive-menus-load-processed">
  <Header_login />
  <!-- ______________________ MAIN _______________________ -->
  <div id="main">
    <div class="container">
      <h2 class="element-invisible">現在地</h2>
      <div class="breadcrumb">
        <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">退去手続き</span>
      </div>
      <div class="messages error" v-if="search_error">
        <h2 class="element-invisible">エラーメッセージ</h2>
        郵便番号検索に失敗しました。
      </div>
      <section id="content">
        <!-- <div id="content-header"> -->
        <h1 class="title">退去手続き</h1>
        <div class="block block-system" data-bid="255">
          <div class="enne_help">
            <router-link to="/mansion/faq/oshietetaikyo" target="_blank">FAQ</router-link>
          </div>
        </div>
        <!-- /block -->
        <!-- </div> /#content-header -->
        <div id="content-area">
          <article class="node node-webform" data-nid="82">
            <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
            <div class="content">
              <form class="webform-client-form webform-client-form-82 webform-conditional-processed" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                <div>
                  <div class="webform-progressbar"></div>
                  <div class="form-item webform-component webform-component-markup webform-component--field-required-message webform-conditional-hidden" style="display: none"><span class="form-required">*</span>は必須項目です。</div>
                  <div class="form-item webform-component webform-component-markup webform-component--markup-departure-description">
                    <p>
                      お引越しの予定日が決まりましたら、必ず退去の手続きをお願いします。<br />
                      お手続きがないと、継続して電気料金が発生してしまいますのでご注意ください。<br />
                      もし、手続きがないまま退去された場合は、下記カスタマーセンターまで急ぎご連絡ください。
                    </p>
                    <p>
                      ※※※ 最終の電気料金について ※※※<br />
                      ◆最終の電気料金は「鍵を引渡す日の前日まで」の利用分となります。（基本料金は日割り計算になります）<br />
                      &nbsp;鍵の引渡日<br />
                      ・分譲の場合：次の入居者または部屋のオーナーに鍵を引渡す日<br />
                      ・賃貸でお住まいの場合：管理会社等に鍵を引渡す日<br />
                      ◆引越しをした後も、鍵の引渡日前日までの電気料金はお客様のお支払いとなりますのでご注意ください。<br />
                      ◆鍵の引渡日以降の電気料金は、次の入居者または部屋のオーナーのお支払いとなります。<br />
                      ◆退去に伴う解約金やその他手数料はかかりません。
                    </p>
                    <p>
                      ※※※ 手続きに関するお願い ※※※<br />
                      ◆鍵の引渡日が未定の場合は、お手数ですが下記カスタマーセンターまでご連絡ください。<br />
                      ◆退去の予定（鍵の引渡日など）が変更になった場合は、再度退去手続きをすれば、新しい申請内容にて承ります。<br />
                      ◆退去手続きをキャンセルされる場合は、必ずカスタマーセンターまでご連絡ください。<br />
                      ◆内容のご確認のため、カスタマーセンターより利用状況等の確認のご連絡をする場合がございますのでご了承ください。<br />
                      ◆海外へ転居する場合、「お引越し先住所」欄には、国内在住のご親族様や代理人様などの住所を入力ください。
                    </p>
                    <p>
                      ※※※ でんき案内板へのアクセスについて ※※※<br />
                      ◆鍵の引渡日前日の翌々月の末日までは、でんき案内板にログインすることができます。<br />
                      ◆料金明細も過去3年分を確認することができます。<br />
                      ◆各種手続きも可能ですが、鍵の引渡日（退去日）以降は電気使用量や電気料金のグラフは表示されません。<br />
                      ◆でんき案内板・マイページ（会員情報）のお客様ご自身での解除手続きは不要です。<br />
                      鍵の引渡日前日の翌々月の末日まではでんき案内板にログインすることができますが、それ以降は解除手続きをしなくてもログインできない状態となります。<br />
                      ◆鍵の引渡日前日の翌々月の末日を過ぎるとでんき案内板にログインできなくなるため、確定申告等で料金明細が必要になる場合は、早めにPDFファイルのダウンロードや印刷をしておくようお願いします。
                    </p>
                    <p>退去手続き後に送信されるメールもあわせてご確認ください。</p>
                    <p>
                      レジル株式会社&nbsp;マンション電力提供サービス&nbsp;カスタマーセンター<br />
                      （0120-33-0057 受付時間9：00～17：00&nbsp;年中無休）
                    </p>
                  </div>
                  <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info form-wrapper">
                    <legend>
                      <span class="fieldset-legend">お引越しの情報</span>
                    </legend>
                    <div class="fieldset-wrapper">
                      <div class="form-item webform-component webform-component-markup webform-component--webform-group-departure-info--markup-departure-info-notice">
                        <p>
                          <small>※「お引越しの情報」は退去後のお客様へのご連絡のためにご入力いただいています。</small>
                        </p>
                        <p>
                          <small> こちらではお引越し先の電気の利用申込みはできませんので、改めて転居先でご利用になる電力会社等にて手続きください。</small>
                        </p>
                      </div>
                      <div class="form-item webform-component webform-component-markup webform-component--webform-group-departure-info--markup-contractor-info-2">
                        <p><strong>ご契約者名：&nbsp;</strong>{{ customer.Name }}</p>
                        <p><strong>マンション名：</strong>&nbsp;{{ customer.BuildingName }}</p>
                        <p><strong>部屋番号：</strong>&nbsp;{{ customer.RoomNo }}</p>
                      </div>
                      <div class="form-item webform-component webform-component-date webform-component--webform-group-departure-info--field-moving-schedule webform-container-inline">
                        <label>お引越し予定日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                        <div class="webform-container-inline webform-datepicker">
                          <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-moving-schedule-year">
                            <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-moving-schedule-year">年 </label>
                            <select class="year form-select" required="required" id="edit-submitted-webform-group-departure-info-field-moving-schedule-year" name="submitted[webform_group_departure_info][field_moving_schedule][year]" v-model="webform_inquiry.move_scheduled_date_year">
                              <template v-for="n in 3" :key="n">
                                <option :value="n + new Date().getFullYear() - 1" >{{ n + new Date().getFullYear() - 1 }}</option>
                              </template>
                            </select>
                          </div>
                          <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-moving-schedule-month">
                            <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-moving-schedule-month">月 </label>
                            <select class="month form-select" required="required" id="edit-submitted-webform-group-departure-info-field-moving-schedule-month" name="submitted[webform_group_departure_info][field_moving_schedule][month]" v-model="webform_inquiry.move_scheduled_date_month">
                              <template v-for="n in 12" :key="n">
                                <option :value="n" >{{ n }}</option>
                              </template>
                            </select>
                          </div>
                          <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-moving-schedule-day">
                            <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-moving-schedule-day">日 </label>
                            <select class="day form-select" required="required" id="edit-submitted-webform-group-departure-info-field-moving-schedule-day" name="submitted[webform_group_departure_info][field_moving_schedule][day]" v-model="webform_inquiry.move_scheduled_date_date">
                              <template v-for="n in 31" :key="n">
                                <option :value="n" >{{ n }}</option>
                              </template>
                            </select>
                          </div>
                          <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(0, 0, 0) + ' webform-calendar-end-' + getDateString(2, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                          <input type="image" aria-hidden="true" role="presentation" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                        </div>
                      </div>
                      <div class="form-item webform-component webform-component-date webform-component--webform-group-departure-info--field-next-key-delivery webform-container-inline">
                        <label>鍵のお引渡（ご返却）日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                        <div class="webform-container-inline webform-datepicker">
                          <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-next-key-delivery-year">
                            <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-next-key-delivery-year">年 </label>
                            <select class="year form-select" required="required" id="edit-submitted-webform-group-departure-info-field-next-key-delivery-year" name="submitted[webform_group_departure_info][field_next_key_delivery][year]" v-model="webform_inquiry.key_delivery_return_day_year">
                              <template v-for="n in 4" :key="n">
                                <option :value="n + new Date().getFullYear() - 1">{{ n + new Date().getFullYear() - 1 }}</option>
                              </template>
                            </select>
                          </div>
                          <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-next-key-delivery-month">
                            <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-next-key-delivery-month">月 </label>
                            <select class="month form-select" required="required" id="edit-submitted-webform-group-departure-info-field-next-key-delivery-month" name="submitted[webform_group_departure_info][field_next_key_delivery][month]" v-model="webform_inquiry.key_delivery_return_day_month">
                              <template v-for="n in 12" :key="n">
                                <option :value="n">{{ n }}</option>
                              </template>
                            </select>
                          </div>
                          <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-next-key-delivery-day">
                            <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-next-key-delivery-day">日 </label>
                            <select class="day form-select" required="required" id="edit-submitted-webform-group-departure-info-field-next-key-delivery-day" name="submitted[webform_group_departure_info][field_next_key_delivery][day]" v-model="webform_inquiry.key_delivery_return_day_date">
                              <template v-for="n in 31" :key="n">
                                <option :value="n">{{ n }}</option>
                              </template>
                            </select>
                          </div>
                          <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(0, 0, 0) + ' webform-calendar-end-' + getDateString(2, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                          <input type="image" aria-hidden="true" role="presentation" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                        </div>
                        <div class="description">※鍵のお引渡（ご返却）日前日までの電気料金をお支払いいただきます。（基本料金は日割り計算）</div>
                      </div>
                      <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--field-contact-information">
                        <label for="edit-submitted-webform-group-departure-info-field-contact-information">日中ご連絡の取れる電話番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                        <input required="required"  type="tel" pattern="[0-9]*" id="edit-submitted-webform-group-departure-info-field-contact-information" name="submitted[webform_group_departure_info][field_contact_information]"  size="15" maxlength="15" class="form-text required" v-model="webform_inquiry.day_contact_tel_num" />
                        <div class="description">
                          （例：09012345678） 退去手続きに関して、カスタマーセンターからご連絡する場合がございます。<br />
                          ※手続きに関するご連絡をショートメッセージでお送りする場合もあります。<br />
                          ※海外へ転居するお客様で、海外の電話のみお持ちの場合は、国番号と電話番号(入力できる桁まで)を入力ください。
                        </div>
                      </div>
                      <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info--webform-group-moving-address form-wrapper">
                        <legend>
                          <span class="fieldset-legend">お引越し先住所</span>
                        </legend>
                        <div class="fieldset-wrapper">
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-zipcode">
                            <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-zipcode">郵便番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" pattern="[0-9]*" style="width:auto" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-zipcode" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_zipcode]"  size="7" maxlength="7" class="form-text required" v-model="webform_inquiry.move_address_post_code" />
                            <span class="field-suffix"><button type="button" id="btn_moving_zipcode" @click.prevent.stop="getZipcode()">郵便番号から住所を自動入力</button></span>
                            <div class="description">
                              （例：0000000）※半角数字のみ「－」なし
                              <br />お住まいの郵便番号がわからない場合は<a href="https://www.post.japanpost.jp/zipcode/" target="_blank">日本郵政の公式サイト</a>よりご確認ください。
                            </div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-pref">
                            <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-pref">都道府県 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-pref" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_pref]"  size="10" maxlength="10" class="form-text required" v-model="webform_inquiry.move_address_prefectures" />
                            <div class="description">（例：東京都）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-city">
                            <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-city">市区町村 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-city" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_city]"  size="20" maxlength="10" class="form-text required" v-model="webform_inquiry.move_address_municipalities" />
                            <div class="description">（例：港区）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-town">
                            <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-town">町名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-town" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_town]"  size="20" maxlength="10" class="form-text required" v-model="webform_inquiry.move_address_townname" />
                            <div class="description">（例：芝浦）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-house-number">
                            <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-house-number">番地 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                            <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-house-number" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_house_number]"  size="20" maxlength="10" class="form-text required" v-model="webform_inquiry.move_address_housenum" />
                            <div class="description">（例：0-00-00）</div>
                          </div>
                          <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-address-other">
                            <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-address-other">マンション名・部屋番号等 </label>
                            <input type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-address-other" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_address_other]"  size="50" maxlength="25" class="form-text" v-model="webform_inquiry.move_address_builname_etc" />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                  <div class="form-actions">
                    <input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" @click="check"/>
                  </div>
                </div>
              </form>
            </div>
            <!-- /content -->
            <div class="links"></div>
            <!-- /links -->
          </article>
          <!-- /article #node -->
        </div>
      </section>
      <!-- /content-inner /content -->
      <Sidebar_first_login />
    </div>
  </div>
  <!-- /main -->
  <Footer />
  </div>
</template>
<script>
import Header_login from '../components/Header_login.vue'
import Sidebar_first_login from '../components/Sidebar_first_login.vue'
import Footer from '../components/Footer.vue'
import moment from "moment";
import { getData } from "../assets/js/request.js";
import config from "../config.js";
import $ from "jquery";
import { departureStore } from "../stores/store.js";
import { loginStore } from "../stores/store.js";
import MEMS from "../assets/js/mems.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer
  },
  data(){
    var login = loginStore();
    if(!config.access_allow_room_type.includes(login.room.room_type)){
      this.$router.push({ path :"/mansion/access_error" });
    }
    var store = departureStore();
    if (Object.keys(store.update).length != 0) {
      return store.update;
    } else {
      var today = new Date();
      store.$reset();
      var customer = {
        Name: login.room.contractor_name,
        BuildingName: login.room.building_name,
        RoomNo: login.room.room_number,
      };
      return  { 
        customer : customer,
        webform_inquiry: {
          move_scheduled_date_year : today.getFullYear(),
          move_scheduled_date_month : today.getMonth() + 1,
          move_scheduled_date_date : today.getDate(),
          key_delivery_return_day_year : today.getFullYear(),
          key_delivery_return_day_month : today.getMonth() + 1,
          key_delivery_return_day_date : today.getDate(),
          day_contact_tel_num: "",
          move_address_post_code : "",
          move_address_prefectures : "",
          move_address_municipalities : "",
          move_address_townname : "",
          move_address_housenum : "",
          move_address_builname_etc : "",
        },
        search_error : 0
      }
    }
  },
  mounted: function () {
    this.$nextTick(
      function () {
          MEMS.behaviors.webform.attach(document);
          $("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").on("change", this.changeDay1);
          $("#edit-submitted-webform-group-departure-info-field-moving-schedule-month").on("change", this.changeDay1);
          $("#edit-submitted-webform-group-departure-info-field-moving-schedule-day").on("change", this.changeDay1);

          $("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").on("change", this.changeDay2);
          $("#edit-submitted-webform-group-departure-info-field-next-key-delivery-month").on("change", this.changeDay2);
          $("#edit-submitted-webform-group-departure-info-field-next-key-delivery-day").on("change", this.changeDay2);
      }.bind(this)
    );
  },
  methods : {
    getDateString: function (year, month, day) {
      var date = new Date();
      date.setFullYear(date.getFullYear() + year);
      date.setMonth(date.getMonth() + month);
      date.setDate(date.getDate() + day);
      return date.toLocaleDateString("sv-SE");
    },
    getZipcode: async function(){
      this.$data.search_error = 0;
      if(!this.$data.webform_inquiry.move_address_post_code.match(/^\d{7}$/)){
        return;
      }
      try{
        var result = await getData(config.url.get.zipcode + "?postCode="  + this.$data.webform_inquiry.move_address_post_code);
        // [{ pref, city, town }]
      }catch(ex){
        this.$data.search_error = 1;
        return;
      }
      if(result.length == 0){
        return;
      }
      this.$data.webform_inquiry.move_address_prefectures = result[0]["pref"];
      this.$data.webform_inquiry.move_address_municipalities = result[0]["city"];
      this.$data.webform_inquiry.move_address_townname = result[0]["town"];
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("select").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("[required]").forEach(function(input) {
        if(input.validity.valueMissing){
          input.setCustomValidity("これは入力必須項目です。");
        }
      });
      var phone = document.querySelector("#edit-submitted-webform-group-departure-info-field-contact-information");
      if(this.$data.webform_inquiry.day_contact_tel_num.startsWith("080") || this.$data.webform_inquiry.day_contact_tel_num.startsWith("090") || this.$data.webform_inquiry.day_contact_tel_num.startsWith("050") || this.$data.webform_inquiry.day_contact_tel_num.startsWith("070")){
        if(this.$data.webform_inquiry.day_contact_tel_num.length != 11){
          phone.setCustomValidity("日中ご連絡の取れる電話番号に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      }else{
        if(this.$data.webform_inquiry.day_contact_tel_num.length != 0 && this.$data.webform_inquiry.day_contact_tel_num.length != 10){
          phone.setCustomValidity("日中ご連絡の取れる電話番号に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
        }
      }
      if(phone.validity.patternMismatch){
        phone.setCustomValidity("日中ご連絡の取れる電話番号に、050,070,080,090で始まる番号を入力される方は、11桁で入力してください。");
      }
      var zip1 = document.querySelector("#edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-zipcode");
      if (this.$data.webform_inquiry.move_address_post_code.length != 0 && this.$data.webform_inquiry.move_address_post_code.length < 7) {
        zip1.setCustomValidity("郵便番号は、7桁でご入力ください。");
      }
      if (zip1.validity.patternMismatch) {
        zip1.setCustomValidity("郵便番号は、半角整数のみで入力して下さい。");
      }
      if(this.webform_inquiry.move_address_prefectures.length != 0 && this.webform_inquiry.move_address_prefectures.length > 4){
        document.querySelector("#edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-pref").setCustomValidity("都道府県は、4文字以下で入力して下さい。");
      }

      this.$data.search_error = 0;
      var lastday = new Date();
      lastday.setDate(lastday.getDate() - 1);
      if (!moment(this.$data.webform_inquiry.move_scheduled_date_year + "/" + this.$data.webform_inquiry.move_scheduled_date_month + "/" + this.$data.webform_inquiry.move_scheduled_date_date, "YYYY/M/D").isValid()){
        document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").setCustomValidity("入力された お引越し予定日 は正しい日時ではありません。");
        return;
      }
      if(lastday.getTime() > new Date(this.$data.webform_inquiry.move_scheduled_date_year, this.$data.webform_inquiry.move_scheduled_date_month - 1, this.$data.webform_inquiry.move_scheduled_date_date).getTime()){
        document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").setCustomValidity(new Date().toLocaleDateString("sv-SE").replaceAll("-", "/")  + "以降の日付を指定して下さい。");
        return;
      }
      if (!moment(this.$data.webform_inquiry.key_delivery_return_day_year + "/" + this.$data.webform_inquiry.key_delivery_return_day_month + "/" + this.$data.webform_inquiry.key_delivery_return_day_date, "YYYY/M/D").isValid()){
        document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").setCustomValidity("入力された 鍵のお引渡（ご返却）日 は正しい日時ではありません。");
        return;
      }
      if(lastday.getTime() > new Date(this.$data.webform_inquiry.key_delivery_return_day_year, this.$data.webform_inquiry.key_delivery_return_day_month - 1, this.$data.webform_inquiry.key_delivery_return_day_date).getTime()){
        document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").setCustomValidity(new Date().toLocaleDateString("sv-SE").replaceAll("-", "/") + "以降の日付を指定して下さい。");
        return;
      }
    },
    changeDay1: function(){
      this.$data.webform_inquiry.move_scheduled_date_year = document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").value;
      this.$data.webform_inquiry.move_scheduled_date_month = document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-month").value;
      this.$data.webform_inquiry.move_scheduled_date_date = document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-day").value;
    },
    changeDay2: function(){
      this.$data.webform_inquiry.key_delivery_return_day_year = document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").value;
      this.$data.webform_inquiry.key_delivery_return_day_month = document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-month").value;
      this.$data.webform_inquiry.key_delivery_return_day_date = document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-day").value;
    },
    push : function(){
      this.$data.webform_inquiry.move_scheduled_date = 
        this.$data.webform_inquiry.move_scheduled_date_year
         + "/" + String(this.$data.webform_inquiry.move_scheduled_date_month).padStart(2, '0') 
         + "/" + String(this.$data.webform_inquiry.move_scheduled_date_date) .padStart(2, '0');
      this.$data.webform_inquiry.key_delivery_return_day =
         this.$data.webform_inquiry.key_delivery_return_day_year
          + "/" + String(this.$data.webform_inquiry.key_delivery_return_day_month).padStart(2, '0')
          + "/" + String(this.$data.webform_inquiry.key_delivery_return_day_date).padStart(2, '0');
      var store = departureStore();
      store.update = this.$data;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "departure_confirm",
        },
      });
    }
  }
}
import "../assets/js/webform.js";
import "../assets/js/mems_webform_replace_half_space.js";
</script>

<style scoped src="../assets/css/webform.css" />
<style scoped>
.webform-calendar:focus {
  outline: none;
}
input[type='text'], input[type='tel'] {
  width : auto;
}
</style>
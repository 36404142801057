<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">確認画面</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">確認画面</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq/oshietekeiyakusya" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 preview" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="messages warning"><p>変更内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>
                    <fieldset class="webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed">
                      <legend><span class="fieldset-legend">ご利用マンション情報</span></legend>
                      <div class="fieldset-wrapper" >
                        <p><strong>マンション名：&nbsp;</strong>{{ now.BuildingName }}</p>
                        <p><strong>部屋番号：&nbsp;</strong>{{ now.RoomNo }}</p>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset collapsible webform-component--webform-group-contractor-info form-wrapper collapse-processed">
                      <legend><span class="fieldset-legend">ご契約者様情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-name-2 webform-container-inline">
                          <label>お名前 </label>
                          <span class="field-prefix"></span> {{ update.contractor_name }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-kana-2 webform-container-inline">
                          <label>フリガナ </label>
                          <span class="field-prefix"></span> {{ update.contractor_kana }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-birthday webform-container-inline">
                          <label>生年月日 </label>
                          {{ update.birthday }}
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-zipcode-2 webform-container-inline">
                          <label>郵便番号 </label>
                          <span class="field-prefix"></span> {{ update.contractor_zip_postal_code }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-pref-2 webform-container-inline">
                          <label>都道府県 </label>
                          <span class="field-prefix"></span> {{ update.contractor_state }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-city-2 webform-container-inline">
                          <label>市区町村 </label>
                          <span class="field-prefix"></span> {{ update.contractor_city }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-town-2 webform-container-inline">
                          <label>町名 </label>
                          <span class="field-prefix"></span> {{ update.contractor_address_line  }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-house-number-2 webform-container-inline">
                          <label>番地 </label>
                          <span class="field-prefix"></span><template v-if="update.delete1">削除</template>
                          <template v-else>{{ update.contractor_address_line_2}}</template>  <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-address-other-2 webform-container-inline">
                          <label>マンション名・部屋番号等 </label>
                          <span class="field-prefix"></span><template v-if="update.delete2">削除</template>
                          <template v-else>{{ update.contractor_address_other}}</template><span class="field-suffix"></span>
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-item form-type-password form-item-auth-pass">
                      <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                      <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password"/>
                      <div class="description">パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link></div>
                    </div>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" @click="check" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { contractorStore } from "../stores/store.js";
import { post } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    var store = contractorStore();
    if(Object.keys(store.update).length == 0){
      this.$router.push({
        path: '/mansion/member/procedure',
          query: {
            type : "contractor"
          }
      });
    }
    var now = store.now;
    var update = store.update;
    store.$reset();
    return {
      auth_error: false,
      send : false,
      now : now,
      update : update,
      password : ""
    };
  },
  methods : {
    back : function(){
      var store = contractorStore();
      store.update = this.$data.update;
      store.now = this.$data.now;
      this.$router.push({
        path: '/mansion/member/procedure',
        query: {
          type : "contractor"
        }
      }) 
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push : async function(){
      var regist = {}
      var now = this.$data.now;
      var update = this.$data.update;
      regist["contractor_name"] = now["Name"];
      regist["contractor_kana"] = now["NameKana"];
      regist["contractor_birthday"] = now["Birthday"];
      regist["contractor_zip_postal_code"] = now["ZipPostalCode"];
      regist["contractor_state"] = now["State"];
      regist["contractor_city"] = now["City"];
      regist["contractor_address_line"] = now["Line"];
      regist["contractor_address_line_2"] = now["Line2"];
      regist["contractor_address_other"] = now["LineOther"];
      regist["contractor_tel"] = now["TelSub"];
      regist["contractor_phone"] = now["Phone"];
      regist["contractor_email"] = now["Email"];
      for(var key in regist){
        if(update[key] != undefined){
          if(typeof update[key] == "string"){
            if(update[key].trim() != ""){
              regist[key] = update[key].trim();
            }
          }else{
            regist[key] = update[key];
          }
        }
      }
      var delete12 = new Array();
      if(update.delete1){
        regist["contractor_address_line_2"] = "";
        delete12.push(1);
      }
      if(update.delete2){
        regist["contractor_address_other"] = "";
        delete12.push(2);
      }
      try{
        this.$data.send = true;
        this.$data.auth_error = false;
        await post(config.url.post.contractor, {
            contractor : regist,
            password : this.$data.password,
            delete : delete12,
            option : 0
        });
        this.$router.push({
            path: '/mansion/member/procedure',
            query: {
              type : "contractor_complete"
            }
        });
        var store = contractorStore();
        store.update = this.$data.update;
        store.now = this.$data.now;
      }catch(ex){
        if(ex.response.data == "PasswordError"){
          this.$data.send = false;
          this.$data.auth_error = true;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
      return false;
    }
  }
};
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />
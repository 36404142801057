<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-member page-member-procedure page-member-procedure-completepayer responsive-menus-load-processe">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">お支払者様情報の変更申請完了</span>
        </div>
        <section id="content">
          <h1 class="title">お支払者様情報の変更申請完了</h1>
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_8 view-dom-id-09d519f00623cb368009e7aef219ab67">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed' + (buildingIsOpen ? '': ' collapsed') " data-module="views_fieldsets" >
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="buildingIsOpen = !buildingIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="buildingIsOpen">
                      <div class="views-field views-field-value">
                        <span class="field-content"
                          ><p><strong>部屋番号：&nbsp;</strong>{{ now.RoomNo }}</p>
                        </span>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed' + (payerIsOpen ? '': ' collapsed') " data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="payerIsOpen = !payerIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払者様情報</a><span class="summary"></span></span>
                    </legend>

                    <div class="fieldset-wrapper" v-show="payerIsOpen">
                      <div class="views-field views-field-value-14"><strong class="views-label views-label-value-14">お名前: </strong><span class="field-content">{{ update.payer_name }}</span></div>
                      <div class="views-field views-field-value-15"><strong class="views-label views-label-value-15">フリガナ: </strong><span class="field-content">{{ update.payer_kana }}</span></div>
                      <div><strong>生年月日: </strong><span><template v-if="update.birthday != '1900年1月1日'">{{ update.birthday }}</template></span></div>
                      <div class="views-field views-field-value-3"><strong class="views-label views-label-value-3">ご契約者様との続柄: </strong><span class="field-content">
                        <template v-if="update.payer_relationship == 0">本人</template>
                        <template v-if="update.payer_relationship == 1">配偶者</template>
                        <template v-if="update.payer_relationship == 2">その他</template>
                      </span></div>
                      <div class="views-field views-field-value-4"><strong class="views-label views-label-value-4">その他続柄: </strong><span class="field-content">{{ update.payer_relationship_etc }}</span></div>
                      <div class="views-field views-field-value-16"><strong class="views-label views-label-value-16">郵便番号: </strong><span class="field-content">{{ update.payer_zip_postal_code }}</span></div>
                      <div class="views-field views-field-value-17"><strong class="views-label views-label-value-17">都道府県: </strong><span class="field-content">{{ update.payer_state }}</span></div>
                      <div class="views-field views-field-value-18"><strong class="views-label views-label-value-18">市区町村: </strong><span class="field-content">{{ update.payer_city }}</span></div>
                      <div class="views-field views-field-value-19"><strong class="views-label views-label-value-19">町名: </strong><span class="field-content">{{ update.payer_address_line }}</span></div>
                      <div class="views-field views-field-value-20"><strong class="views-label views-label-value-20">番地: </strong><span class="field-content">
                      <template v-if="update.delete1">削除</template><template v-else>{{ update.payer_address_line_2 }}</template>
                      </span></div>
                      <div class="views-field views-field-value-21"><strong class="views-label views-label-value-21">マンション名・部屋番号等: </strong><span class="field-content">
                      <template v-if="update.delete2">削除</template><template v-else>{{ update.payer_address_other }}</template>
                      </span></div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <div id="top_link"><router-link to="/mansion/member/profile">会員情報ページへ</router-link></div>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <Sidebar_first_login />
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { payerStore } from "../stores/store.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    var store = payerStore();
    if(Object.keys(store.update).length == 0){
      this.$router.push({
        path: '/mansion/member/procedure',
          query: {
            type : "payer"
          }
      });
    }
    var now = store.now;
    var update = store.update;
    store.$reset();
    return {
      buildingIsOpen : true,
      payerIsOpen : true,
      now: now,
      update : update
    };
  }
};
import "../assets/js/webform.js";
</script>

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">確認画面</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">確認画面</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq_category_list/5" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 preview" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="messages warning"><p>変更内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>

                    <div class="form-item webform-component webform-component-display webform-component--field-specification-2">
                      <label>料金明細書 </label>
                      <template v-if="update.DetailFlag == 0">Web明細に変更する</template>
                      <template v-else>紙明細（有料）に変更する</template>
                    </div>
                    <div class="form-item form-type-password form-item-auth-pass">
                      <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                      <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password" />
                      <div class="description">パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link></div>
                    </div>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" @click="check" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { feedetailStore } from "../stores/store.js";
import { post } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = feedetailStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "fee_detail",
        },
      });
    }else{
      var now = store.now;
      var update = store.update;
      store.$reset();
      return {
        auth_error: false,
        send: false,
        now : now,
        update: update,
        password: ""
      };
    }
  },
  methods: {
    back: function () {
      var store = feedetailStore();
      store.update = this.$data.update;
      store.now = this.$data.now;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "fee_detail",
        },
      });
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push: async function () {
      var store = feedetailStore();
      try{
        this.$data.send = true;
        this.$data.auth_error = false;
        await post(config.url.post.fee, {
          contract : {
            detail_flag : this.$data.update.DetailFlag
          },
          password : this.$data.password
        });
        this.$router.push({
          path: "/mansion/member/procedure",
          query: {
            type: "fee_detail_complete",
          }
        });
        store.update = this.$data.update;
        store.now = this.$data.now;
      }catch(ex){
        if(ex.response.data == "PasswordError"){
          this.$data.send = false;
          this.$data.auth_error = true;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
      return false;
    }
  }
};
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front not-logged-in no-sidebars page-node node-type-page">
    <Header />
    <div id="highlighted">
      <Header_info/>
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
            »</span> <span class="inline odd last">システムエラーが発生しました</span>
        </div>
        <section id="content">
          <h1 class="title">システムエラーが発生しました</h1>
          <div id="content-area">
            <article class="node node-page" data-nid="69">
              <header><span property="dc:title" content="エラー" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded">
                      <div class="error-message">
                        <div class="txt-message">
                          <p>お手数ですが、以下のいずれかをお試しください。</p>
                          <ul>
                            <li>前のページに戻って再度やり直ししてください。</li>
                            <li>URLを再度確認してください。</li>
                            <li>しばらく時間をおいてから再度やり直してください。</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import { removeData, loginStore } from '../stores/store.js';
export default {
  components: {
    Header,
    Header_info,
    Footer,
  },
  data(){
    removeData("token");
    removeData("x-token");
    removeData("last_login_datetime");
    loginStore().$reset();
    sessionStorage.clear();
    return {};
  }
};
</script>

<style scoped src="../assets/css/style.css" />
<style scoped src="../assets/css/webform.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node node-type-info responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">{{ Title }}</span>
        </div>
        <section id="content">
          <h1 class="title">{{ Title }}</h1>
          <div id="content-area">
            <article class="node node-info" data-nid="6362">
              <header><span property="dc:title" :content="title" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-field-category field-type-taxonomy-term-reference field-label-above">
                  <div class="field-label">カテゴリ:&nbsp;</div>
                  <div class="field-items">
                    <div class="field-item">{{Category}}</div>
                  </div>
                </div>
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded" v-html="BodySummary" />
                  </div>
                </div>
                <div class="field field-name-field-area field-type-taxonomy-term-reference field-label-above">
                  <div class="field-label">表示エリア:&nbsp;</div>
                  <div class="field-items">
                    <div class="field-item" v-if="Region[0] == 1">北海道地区</div>
                    <div class="field-item" v-if="Region[1] == 1">東北地区</div>
                    <div class="field-item" v-if="Region[2] == 1">関東地区</div>
                    <div class="field-item" v-if="Region[3] == 1">北陸地区</div>
                    <div class="field-item" v-if="Region[4] == 1">中部地区</div>
                    <div class="field-item" v-if="Region[5] == 1">関西地区</div>
                    <div class="field-item" v-if="Region[6] == 1">中国地区</div>
                    <div class="field-item" v-if="Region[7] == 1">四国地区</div>
                    <div class="field-item" v-if="Region[8] == 1">九州地区</div>
                    <div class="field-item" v-if="Region[9] == 1">沖縄地区</div>
                  </div>
                </div>
              </div>
              <!-- /content -->

              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js"
import { getData } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return {
      Title: "",
      BodySummary: "",
      Category: "",
      Region: []
    }
  },
  async created() {
    var result = await getData(config.url.get.info + this.$route.params.id);
    // { Title, BodySummary, Category, Region}
    Object.assign(this.$data, result);
    document.title = result.Title + " | でんき案内板";
  },
};
</script>
<template>
  
  <div :class="'html not-front sidebar-first page-info responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <template v-if="title != ''">
          <h2 class="element-invisible">現在地</h2>
          <div class="breadcrumb">
            <span class="inline first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
              »</span> <span id="page-title" class="inline last">{{ title }}</span>
          </div>
        </template>
        <section id="content">
          <div v-html="contents" />
        </section>
        <template v-if="isLogin">
          <template v-if="error">
            <Sidebar_first_login_min />
          </template>
          <template v-else>
            <Sidebar_first_login />
          </template>
        </template>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Sidebar_first_login_min from "../components/Sidebar_first_login_min.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import $ from 'jquery'
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Sidebar_first_login_min,
    Footer,
  },
  data() {
    if(!isLogin()){
      //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').removeAttr('disabled');
      $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
      $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
    }
    return {
      isLogin: isLogin(),
      title: "",
      contents: "",
      error: false
    };
  },
  async created(){
    this.beforeProcess();
    $('link[rel="stylesheet"][href="/mansion/blue/style3.css"].css').removeAttr('disabled');
  },
  methods : {
    beforeProcess : async function(){
      var path = this.$router.currentRoute._rawValue.path;
      var query = this.$route.query;
      var url = this.$route.path;
      var content = undefined;
      if(url == "/mansion/404"){
        url = query["destination"] ? decodeURIComponent(query["destination"]) : url;
        delete query["destination"];
      }else{
        var file = await getData(config.url.get.content + "?url=" + encodeURIComponent(path));
        content = $(file).filter("section").html();
      }
      if(content){
        this.$data.error = false;
        this.$data.contents = content;
        document.title = $(file).filter("section").children(".title").text() + " | でんき案内板";
        this.$data.title = $(file).filter("section").children(".title").text();
      }else if(!this.$data.isLogin){
        query["destination"] = url;
        var hash = (this.$route.hash == "") ?  "": "#" + this.$route.hash;
        this.$router.push({path : '/mansion/', query : query, hash: hash});
      }else{
        this.$data.error = true;
        var array = [];
        for(var key in this.$route.query){
          array.push(key + "=" + this.$route.query[key]);
        }
        if(array.length != 0){ 
          url = url + "?" + array.join('&');
        }
        history.replaceState('','', url);
        url = encodeURI(url);
        this.$data.contents = "<h1 class='title'>ページが見つかりません</h1><div id='content-area'>リクエストされたページ \"" + url + "@\" は見つかりませんでした。 </div>";
        document.title = "ページが見つかりません | でんき案内板";
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.$route.hash.match(/^#/)) {
        setTimeout(() => {
          var el = document.getElementById(this.$route.hash.replace(/^#/, ''));
          if(el){
            el.scrollIntoView();
          }
        }, 1000);
      }
    })
  },
  watch: {
    $route () {
      this.beforeProcess();
      if (this.$route.hash.match(/^#/)) {
        setTimeout(() => {
          var el = document.getElementById(this.$route.hash.replace(/^#/, ''));
          if(el){
            el.scrollIntoView();
          }
        }, 1000);
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    $('link[rel="stylesheet"][href="/mansion/blue/style3.css"].css').attr('disabled', 'disabled');
    next()
  },
};
</script>

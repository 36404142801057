<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-node responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">お問い合わせ</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">お問い合わせ</h1>
          <!-- </div> /#content-header -->
          <div id="content-area">
            <article class="node node-page" data-nid="440">
              <header><span property="dc:title" content="お問い合わせ" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded">
                      <div class="f-tbox">メールフォームによるお問い合わせ</div>
                      <div class="f-ubox">
                        <p>各種申込み・変更手続き・サービスに関するお問い合わせは、以下のメールフォームよりお問い合わせ願います。</p>
                        <p class="f-center">
                          <img alt="レジル株式会社" src="../assets/images/rezil_logo.png" style="height: 63px; width: 260px" />
                        </p>
                        <p class="f-center">レジル株式会社&nbsp;マンション電力提供サービス&nbsp;カスタマーセンター</p>
                        <p class="f-center">
                          <input type="button" value="お問い合わせ" @click="$router.push('/mansion/inquiry_document')" />
                        </p>
                      </div>
                      <div class="f-tbox">電話によるお問い合わせ</div>
                      <div class="f-ubox">
                        <p>お引越し、容量変更、ご利用料金等、契約内容、その他サービスに関するお問い合わせは、以下のフリーダイヤルからお問い合わせ願います。</p>
                        <p class="f-center">
                          <img alt="レジル株式会社" src="../assets/images/rezil_logo.png" style="height: 63px; width: 260px" />
                        </p>
                        <p class="f-center">レジル株式会社&nbsp;マンション電力提供サービス&nbsp;カスタマーセンター</p>
                        <p class="f-center">
                          <span class="f-tel">0120-33-0057</span>
                        </p>
                        <p class="f-center">受付時間&nbsp;9:00～17:00（年中無休）</p>
                        <p>
                          ※受付時間外に停電や故障が発生した場合は、ガイダンスに従って操作（ガイダンスを聞き終わったら「3」を押す）すれば、「設備保守の受付窓口」につながります。<br />
                          ※番号をよくお確かめのうえ、おかけ間違えのないようにお願いいたします。<br />
                          ※お客様との通話は、お申出内容の正確な確認とサービス向上のために録音させていただいております。<br />
                          ※緊急のご用件について、回線障害などの予期せぬ障害で電話が切れた時に、こちらからかけ直しをさせていただくため、全てのお客様に発信者情報通知をお願いしております。<br />
                          ※0120番号へは、国際電話ならびに一部のIP電話等からご利用いただけない場合があります。
                        </p>
                      </div>
                      <div class="f-tbox">後払い.com請求書に関するお問い合わせ</div>
                      <div class="f-ubox">
                        <p>後払い.comのロゴマークの入った請求書に関する再発行、お支払方法のお問い合わせは、以下の電話番号からお問い合わせ願います。</p>
                        <p class="f-center">
                          <a href="https://atobarai-user.jp/" target="_blank">
                            <img alt="" src="../assets/images/atobarai.png" style="height: 66px; width: 208px" />
                          </a>
                        </p>
                        <p class="f-center">後払い.com&nbsp;カスタマーセンター</p>
                        <p>お問い合わせのボタンを押すと、後払い.comの問い合わせフォーム（外部サイト）に遷移します。<br>
                          ※お問い合わせサービスにて「後払い.com」を選択ください。</p>
                        <p class="f-center"><a href="https://atobarai-user.jp/contact/" target="_blank" style="text-decoration: none;"><input type="button" value="お問い合わせ"></a></p>
                        <p class="f-center">お電話が集中しており、繋がりにくくなっております。<br>是非、お問い合わせフォームをご活用ください。</p>
                        <p class="f-center">電話窓口　03-4326-3600<br>受付時間　9:00～18:00（年末年始休業）</p>
                        <p>※なお、請求書によるお支払いのお客様でも、各種お申込み、サービスに関するお問い合わせはレジル株式会社&nbsp;マンション電力提供サービス&nbsp;カスタマーセンターへお問い合わせください。</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from '../components/Header_info.vue';
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from '../components/Sidebar_first_login.vue';
import Footer from "../components/Footer.vue";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return {
      isLogin: isLogin()
    }
  }
};

</script>
<style scoped src="../assets/css/f-inquiry.css" />
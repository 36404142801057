<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">料金プラン変更手続き</span>
        </div>
        <section id="content">
          <h1 class="title">料金プラン変更手続き</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/service_02" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 webform-conditional-processed" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="form-item webform-component webform-component-markup webform-component--change-rate-plan-description">
                      <p>
                        ご希望の料金プランと電力容量を選択してください。<br />
                        ※お住まいのマンションで使用できないプランがございますので、あらかじめご了承ください。<br />
                        ※契約容量の変更は、お客様が変更申込み後、カスタマーセンターから電話やメールで変更内容・注意事項の確認をして手続きが完了となります。
                      </p>
                    </div>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-rate-plan-info form-wrapper collapse-processed' + (isOpen1 ? '' : ' collapsed')">
                      <legend>
                        <span class="fieldset-legend"
                          ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen1 = !isOpen1"><span class="fieldset-legend-prefix element-invisible">非表示</span> 料金プラン情報</a><span class="summary"></span
                        ></span>
                      </legend>
                      <div class="fieldset-wrapper" v-show="isOpen1">
                        <div class="form-item webform-component webform-component-hidden webform-component--webform-group-rate-plan-info--field-power-area">
                          <div class="form-item webform-component webform-component-select webform-component--webform-group-rate-plan-info--field-hokkaido-rate-plan webform-container-inline webform-conditional-hidden">
                            <div class="description">現在の設定値： {{ now.Contract.PlanName }}</div>
                            <label for="edit-submitted-webform-group-rate-plan-info-field-hokkaido-rate-plan">料金プラン{{ now.Contract.PowerAreaName }} </label>
                            <select required id="edit-submitted-webform-group-rate-plan-info-field-hokkaido-rate-plan" name="submitted[webform_group_rate_plan_info][field_hokkaido_rate_plan]" class="form-select webform-conditional-disabled" v-model="update.plan_code">
                              <option value="" selected="selected">- 選択してください -</option>
                              <template v-for="p in now.Plan" :key="p"><option :value="p.plan_code">{{ p.plan_name }}</option></template>
                            </select>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-select webform-component--webform-group-rate-plan-info--field-power-capacity-a webform-container-inline">
                          <div class="description">現在の設定値：{{ (now.Contract.CapacityNumeric == '-' || now.Contract.CapacityNumeric > 0) ? now.Contract.CapacityNumeric : "お部屋の最大容量(契約書による)"}}</div>
                          <label for="edit-submitted-webform-group-rate-plan-info-field-power-capacity-a">電力容量 </label>
                          <select required id="edit-submitted-webform-group-rate-plan-info-field-power-capacity-a" name="submitted[webform_group_rate_plan_info][field_power_capacity_a]" class="form-select" v-model="update.capacity_numeric" v-if="update.capacity_unit == 0">
                            <option value="" selected="selected">- なし -</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                          </select>
                          <template v-if="update.capacity_unit != 0">
                            <input required type="text" pattern="[0-9]*" id="edit-submitted-webform-group-rate-plan-info-field-power-capacity-kva-kw" name="submitted[webform_group_rate_plan_info][field_power_capacity_kva_kw]" size="5" min="0" max="9999" step="1" class="form-text form-number" maxLength="4" v-model="update.capacity_numeric">
                          </template>
                        </div>
                        <div class="form-item webform-component webform-component-number webform-component--webform-group-rate-plan-info--field-power-capacity-kva-kw webform-container-inline webform-conditional-hidden">
                          <div class="description">
                            現在の設定値：
                            <template v-if="now.Contract.CapacityUnit == 0">A</template>
                            <template v-if="now.Contract.CapacityUnit == 1">kVA</template>
                            <template v-if="now.Contract.CapacityUnit == 2">kW</template>
                            <template v-if="now.Contract.CapacityUnit == '-'">-</template>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-radios webform-component--webform-group-rate-plan-info--field-capacity-unit webform-container-inline">
                          <label for="edit-submitted-webform-group-rate-plan-info-field-capacity-unit">単位 </label>
                          <div id="edit-submitted-webform-group-rate-plan-info-field-capacity-unit" class="form-radios">
                            <div class="form-item form-type-radio form-item-submitted-webform-group-rate-plan-info-field-capacity-unit">
                              <input type="radio" id="edit-submitted-webform-group-rate-plan-info-field-capacity-unit-1" name="submitted[webform_group_rate_plan_info][field_capacity_unit]" value="0" checked="checked" class="form-radio" v-model="update.capacity_unit" @chnage="update.capacity_numeric=''"/>
                              <label class="option" for="edit-submitted-webform-group-rate-plan-info-field-capacity-unit-1">A </label>
                            </div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-rate-plan-info-field-capacity-unit">
                              <input type="radio" id="edit-submitted-webform-group-rate-plan-info-field-capacity-unit-2" name="submitted[webform_group_rate_plan_info][field_capacity_unit]" value="1" class="form-radio" v-model="update.capacity_unit" />
                              <label class="option" for="edit-submitted-webform-group-rate-plan-info-field-capacity-unit-2">kVA </label>
                            </div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-rate-plan-info-field-capacity-unit">
                              <input type="radio" id="edit-submitted-webform-group-rate-plan-info-field-capacity-unit-3" name="submitted[webform_group_rate_plan_info][field_capacity_unit]" value="2" class="form-radio" v-model="update.capacity_unit" />
                              <label class="option" for="edit-submitted-webform-group-rate-plan-info-field-capacity-unit-3">kW </label>
                            </div>
                          </div>
                          <div class="description">
                            上記にないプランへの変更は、カスタマーセンターまでお問い合わせください<br />
                            ※料金プラン変更時に工事費がかかる場合があります<br />
                            ※エネビジョンプランは、システム利用割引が適用されません
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed' + (isOpen2 ? '' : ' collapsed')">
                      <legend>
                        <span class="fieldset-legend"
                          ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen2 = !isOpen2">
                            <span class="fieldset-legend-prefix element-invisible">非表示</span>
                            ご利用マンション情報</a
                          >
                          <span class="summary"></span>
                        </span>
                      </legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-use-mansion-info--field-mansion-name webform-conditional-hidden" v-shonw="isOpen2">
                          <div class="form-item webform-component webform-component-markup webform-component--webform-group-use-mansion-info--mansion-name">
                            <p><strong>マンション名：&nbsp;</strong>{{ now.Customer.BuildingName }}</p>
                          </div>
                          <div class="form-item webform-component webform-component-markup webform-component--webform-group-use-mansion-info--room-number">
                            <p><strong>部屋番号：&nbsp;</strong>{{ now.Customer.RoomNo }}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset collapsible webform-component--webform-group-contractor-info form-wrapper collapse-processed">
                      <legend>
                        <span class="fieldset-legend"
                          ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen3 = !isOpen3"><span :class="'fieldset-legend-prefix element-invisible' + (isOpen3 ? '' : ' collapsed')">非表示</span> ご契約者様情報</a><span class="summary"></span
                        ></span>
                      </legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contractor-info--field-contractor-name webform-conditional-hidden" v-show="isOpen3">
                          <div class="form-item webform-component webform-component-markup webform-component--webform-group-contractor-info--markup-contractor-info">
                            <p><strong>お名前：&nbsp;</strong>{{ now.Contractor.Name }}</p>
                            <p><strong>フリガナ：&nbsp;</strong>{{ now.Contractor.NameKana }}</p>
                            <p><strong>郵便番号：&nbsp;</strong>{{ now.Contractor.ZipPostalCode }}</p>
                            <p><strong>住所：&nbsp;</strong>{{ now.Contractor.State }}{{ now.Contractor.City }}{{ now.Contractor.Line }}{{ now.Contractor.Line2 }}{{ now.Contractor.LineOther }}</p>
                            <p><strong>電話番号：&nbsp;</strong>{{ now.Contractor.Phone }}</p>
                            <p>
                              <strong>契約者メールアドレス：&nbsp;</strong><a :href="'mailto:' + now.Contractor.Email">{{ now.Contractor.Email }}</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed' + (isOpen4 ? '' : ' collapsed')">
                      <legend>
                        <span class="fieldset-legend">
                          <a class="fieldset-title" href="#" @click.prevent.stop="isOpen4 = !isOpen4">
                            <span class="fieldset-legend-prefix element-invisible">非表示</span>
                            お支払者様情報
                          </a>
                          <span class="summary"></span>
                        </span>
                      </legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-name webform-conditional-hidden" v-show="isOpen4">
                          <div class="form-item webform-component webform-component-markup webform-component--webform-group-payer-info--markup-payer-info">
                            <p><strong>お名前：&nbsp;</strong>{{ now.Payer.Name }}</p>
                            <p><strong>フリガナ：&nbsp;</strong>{{ now.Payer.NameKana }}</p>
                            <p><strong>郵便番号：&nbsp;</strong>{{ now.Payer.ZipPostalCode }}</p>
                            <p><strong>住所：&nbsp;</strong>{{ now.Payer.State }}{{ now.Payer.City }}{{ now.Payer.Line }}{{ now.Payer.Line2 }}{{ now.Payer.LineOther }}</p>
                            <p><strong>電話番号：&nbsp;</strong>{{ now.Payer.Phone }}</p>
                            <p>
                              <strong>支払者メールアドレス：&nbsp;</strong><a :href="'mailto:' + now.Payer.Email">{{ now.Payer.Email }}</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-actions">
                      <input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" @click="check"/>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { rateplanStore } from "../stores/store.js";
import { loginStore } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var login = loginStore();
    var customer = {
      FormMoveDate: login.room.form_move_date,
      BuildingName: login.room.building_name,
      RoomNo: login.room.room_number,
    };
    return {
      now: {
        Customer: customer,
        Contractor: {
          Name: "",
          NameKana: "",
          Birthday: "",
          ZipPostalCode: "",
          State: "",
          City: "",
          Line: "",
          Line2: "",
          LineOther: "",
          Phone: "",
          TelSub: "",
          Email: "",
        },
        Payer: {
          Name: "",
          NameKana: "",
          Birthday: "",
          ZipPostalCode: "",
          State: "",
          City: "",
          Line: "",
          Line2: "",
          LineOther: "",
          Relationship: 0,
          RelationshipEtc: "",
          Phone: "",
          TelSub: "",
          Email: "",
        },
        Contract: {
          PlanCode: "",
          PlanName: "",
          PowerAreaId: "",
          PowerAreaName: "",
          DetailFlag: 0,
          CapacityNumeric: 0,
          CapacityUnit: 0
        },
        Plan: []
      },
      update: {
          capacity_numeric: "",
          capacity_unit: "0",
          plan_code: "",
          plan_name: ""
      },
      isOpen1: true,
      isOpen2: true,
      isOpen3: true,
      isOpen4: true,
    };
  },
  async created() {
    var store = rateplanStore();
    if (Object.keys(store.update).length != 0) {
      var update = store.update;
      var now = store.now;
      store.$reset();
      Object.assign(this.$data, {
        now: now,
        update: update,
        isOpen1: true,
        isOpen2: true,
        isOpen3: true,
        isOpen4: true,
      });
    } else {
      var contractor = await getData(config.url.get.contractor);
      // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Phone, TelSub }
      var payer = await getData(config.url.get.payer);
      // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Relationship, RelationshipEtc, Phone, TelSub }
      var contract = await getData(config.url.get.contract);
      // { PlanCode, PlanName, PowerAreaId, DetailFlag, CapacityNumeric, CapacityUnit }
      var plan = await getData(config.url.get.plan);
      var powerAreaName = {
        8: "（北海道地区）",
        9: "（東北地区）",
        10: "（東京地区）",
        11: "（中部地区）",
        12: "（北陸地区）",
        13: "（関西地区）",
        14: "（四国地区）",
        15: "（中国地区）",
        16: "（九州地区）",
        17: "（沖縄地区）"
      }
      contract["PowerAreaName"] = powerAreaName[contract.PowerAreaId];
      Object.assign(this.$data.now.Contractor, contractor);
      Object.assign(this.$data.now.Payer, payer);
      Object.assign(this.$data.now.Contract, contract);
      this.$data.now.Plan = plan[contract.PowerAreaId];
    }
  },
  methods: {
    check: function() {
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("select").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("[required]").forEach(function (input) {
        if (input.validity.valueMissing) {
          input.setCustomValidity("これは入力必須項目です。");
        }
      });
      var unit = document.querySelector("#edit-submitted-webform-group-rate-plan-info-field-power-capacity-kva-kw");
      if(unit != undefined && unit.validity.patternMismatch){
        unit.setCustomValidity('電力容量 は"12346"のフォーマットでなければなりません。')
      }
    },
    push: function () {
      for(var plan in this.$data.now.Plan){
        if(this.$data.update.plan_code == this.$data.now.Plan[plan].plan_code){
          this.$data.update.plan_name = this.$data.now.Plan[plan].plan_name;
          break;
        }
      }
      var store = rateplanStore();
      store.update = this.$data.update;
      store.now = this.$data.now;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "rate_plan_confirm",
        },
      });
    },
  },
};
import "../assets/js/mems_webform_replace_half_space.js";
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

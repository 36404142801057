import $ from 'jquery'
export const initPassword = function(){
    $('input[type=password]').each(function () {
        var passwordInput = $(this);
        var $id = passwordInput.attr('id');
        var $showPassId = $id.replace('edit', 'show');
        var $showPass = '<div class="show_pass"><input type="checkbox" id = "' + $showPassId + '" class="show_password"><label for="' + $showPassId + '">パスワードの入力文字を表示する</label></div>';
        passwordInput.after($showPass);
    });
    $('.show_password').on('click', function() {
      var $showPassId = $(this).attr('id');
      var $id = $showPassId.replace('show', 'edit');
      if ($(this).prop('checked') == true) {
        $('#' + $id).prop('type', 'text');
      }
      else {
        $('#' + $id).prop('type', 'password');
      }
    });
}
//$(document).on("ready", init);
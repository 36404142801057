<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-member page-member-procedure page-member-procedure-completerate-plan responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">料金プラン変更申請完了</span>
        </div>
        <section id="content">
          <h1 class="title">料金プラン変更申請完了</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/service_02" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_10 view-dom-id-4c93dec993d8b614af8dae7edeff1340">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset class="料金プラン情報 views-fieldset collapsible collapse-processed" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen = !isOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> 料金プラン情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="isOpen">
                      <div class="views-field views-field-php">
                        <strong class="views-label views-label-php">料金プラン{{ now.Contract.PowerAreaName }}</strong>
                        <span class="field-content">{{ update.plan_name }}</span>
                      </div>
                      <div class="views-field views-field-value-2">
                        <span class="field-content"><strong>電力容量:</strong>{{ update.capacity_numeric }} </span>
                      </div>
                      <div class="views-field views-field-value"><strong class="views-label views-label-value">単位: </strong><span class="field-content">
                        <template v-if="update.capacity_unit == 0">A</template>
                        <template v-if="update.capacity_unit == 1">kVA</template>
                        <template v-if="update.capacity_unit == 2">kW</template>
                      </span></div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <div id="top_link"><router-link to="/mansion/member/profile">会員情報ページへ</router-link></div>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { rateplanStore } from "../stores/store.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = rateplanStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push("/mansion/");
    } else {
      var update = store.update;
      var now = store.now;
      store.$reset();
      return {
        update : update,
        now : now,
        isOpen : true
      };
    }
  }
};
</script>

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-member page-member-procedure page-member-procedure-completepayment-method responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">ご契約者様情報の変更申請完了</span>
        </div>
        <section id="content">
          <h1 class="title">ご契約者様情報の変更申請完了</h1>
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_7 view-dom-id-4ae218568a6c47dc193c73a32e00c988">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (buildingIsOpen ? '': ' collapsed') " data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="buildingIsOpen = !buildingIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span
                      ></span>
                    </legend>

                    <div class="fieldset-wrapper" v-show="buildingIsOpen">
                      <div class="views-field views-field-value">
                        <span class="field-content"
                          ><p><strong>部屋番号：&nbsp;</strong>{{ now.RoomNo }}</p>
                        </span>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (constractorIsOpen ? '': ' collapsed') " data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend" 
                        ><a class="fieldset-title" href="#" @click.prevent.stop="constractorIsOpen = !constractorIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご契約者様情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="constractorIsOpen">
                      <div class="views-field views-field-value-3"><strong class="views-label views-label-value-3">お名前: </strong><span class="field-content">{{ update.contractor_name }} </span></div>
                      <div class="views-field views-field-value-4"><strong class="views-label views-label-value-4">フリガナ: </strong><span class="field-content">{{ update.contractor_kana }}</span></div>
                      <div class="views-field views-field-value-1"><strong class="views-label views-label-value-1">生年月日: </strong><span class="field-content">{{ update.birthday }}</span></div>
                      <div class="views-field views-field-value-5"><strong class="views-label views-label-value-5">郵便番号: </strong><span class="field-content">{{ update.contractor_zip_postal_code }}</span></div>
                      <div class="views-field views-field-value-6"><strong class="views-label views-label-value-6">都道府県: </strong><span class="field-content">{{ update.contractor_state }}</span></div>
                      <div class="views-field views-field-value-7"><strong class="views-label views-label-value-7">市区町村: </strong><span class="field-content">{{ update.contractor_city }}</span></div>
                      <div class="views-field views-field-value-8"><strong class="views-label views-label-value-8">町名: </strong><span class="field-content">{{ update.contractor_address_line  }}</span></div>
                      <div class="views-field views-field-value-9"><strong class="views-label views-label-value-9">番地: </strong><span class="field-content"><template v-if="update.delete1">削除</template><template v-else>{{ update.contractor_address_line_2}}</template></span></div>
                      <div class="views-field views-field-value-10"><strong class="views-label views-label-value-10">マンション名・部屋番号等: </strong><span class="field-content"><template v-if="update.delete2">削除</template><template v-else>{{ update.contractor_address_other}}</template></span></div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <div id="top_link"><router-link to="/mansion/member/profile">会員情報ページへ</router-link></div>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { contractorStore } from "../stores/store.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = contractorStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "contractor",
        },
      });
    }
    var now = store.now;
    var update = store.update;
    store.$reset();
    return {
      buildingIsOpen: true,
      constractorIsOpen: true,
      now: now,
      update: update
    };
  },
};
import "../assets/js/webform.js";
</script>

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-node responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div class="messages error" v-if="!isLogin && search_error">
        <h2 class="element-invisible">エラーメッセージ</h2>
        郵便番号検索に失敗しました。
      </div>
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/inquiry">お問い合わせ</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">お問い合わせ</span>
        </div>
        <div class="messages error" v-if="isLogin && search_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          郵便番号検索に失敗しました。
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->

          <h1 class="title">お問い合わせ</h1>

          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq_top" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->

          <!-- </div> /#content-header -->

          <div id="content-area">
            <article class="node node-webform" data-nid="77">
              <header><span property="dc:title" content="お問い合わせ" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>

              <div class="content">
                <form class="webform-client-form webform-client-form-77 webform-conditional-processed" action="#" method="post" id="webform-client-form-77" accept-charset="UTF-8" @submit.prevent.stop="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-inquiry form-wrapper">
                      <legend><span class="fieldset-legend">お問い合わせ</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-departure-button" v-if="!isLogin && (kind =='' || kind == 1 || kind == 3 || kind == 4 || kind == 8)">
                          <p><input type="button" value="退去はこちら（ログイン要）" @click.prevent="$router.push({ path: '/mansion/member/procedure', query: { type: 'departure' } })" /></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-faq-top" v-if="kind == ''">
                          <p><router-link to="/mansion/faq_top" target="_blank">「よくあるご質問」で探す</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-faq-link webform-conditional-hidden" v-if="kind == '1'">
                          <p><router-link to="/mansion/faq_top" target="_blank">よくあるご質問を見る</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-idpass-link webform-conditional-hidden" v-if="kind == '3'">
                          <p><router-link to="/mansion/faq/600" target="_blank">関連のご質問を見る</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-application-link webform-conditional-hidden" v-if="kind == '4'">
                          <p><router-link to="/mansion/faq/2005" target="_blank">関連のご質問を見る</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-application-link webform-conditional-hidden" v-if="kind == '5'">
                          <p><router-link to="/mansion/change/payment" target="_blank">すぐに手続きしたい時は</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-application-confirm-link webform-conditional-hidden" v-if="kind == '7'">
                          <p><router-link to="/mansion/faq_category_list/1" target="_blank">関連のご質問を見る</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-payment-certificate-link webform-conditional-hidden" v-if="kind == '8'">
                          <p><router-link to="/mansion/faq/4149" target="_blank">関連のご質問を見る</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-return-envelope webform-conditional-hidden" v-if="kind == '9'">
                          <p><router-link to="/mansion/faq/4411" target="_blank">関連のご質問を見る</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-select webform-component--webform-group-inquiry--field-inquiry-type">
                          <label for="edit-submitted-webform-group-inquiry-field-inquiry-type">種別 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <select required="required" id="edit-submitted-webform-group-inquiry-field-inquiry-type" name="submitted[webform_group_inquiry][field_inquiry_type]" class="form-select required" v-model="kind" @change="chnageType">
                            <option value="">- 選択してください -</option>
                            <option value="1">お問い合わせ</option>
                            <option value="3" v-show="!isLogin">でんき案内板のログインID・初期パスワードを確認したい</option>
                            <option value="7" v-show="!isLogin">電気の利用申込みが手続きされているか確認したい</option>
                            <option value="5" v-show="isLogin">料金支払方法申込書を送って欲しい（支払方法の変更）</option>
                            <option value="4" v-show="!isLogin">紙の申込書を送って欲しい</option>
                            <option value="6">退去の手続きをしたい</option>
                            <option value="8">支払証明書を発行して欲しい</option>
                            <option value="9">返信用の封筒を送って欲しい</option>
                          </select>
                        </div>
                        <div class="form-item webform-component webform-component-textarea webform-component--webform-group-inquiry--field-inquiry-content" v-if="kind == '' || kind == '1'">
                          <label for="edit-submitted-webform-group-inquiry-field-inquiry-content">内容 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="form-textarea-wrapper resizable textarea-processed resizable-textarea">
                            <textarea required="required" id="edit-submitted-webform-group-inquiry-field-inquiry-content" name="submitted[webform_group_inquiry][field_inquiry_content]" cols="60" rows="5" class="form-textarea required" maxlength="8000" v-model="content"></textarea>
                          </div>
                          <div class="description">お問い合わせ内容を上記にできるだけ具体的にお書きください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-inquiry-change-payment-link webform-conditional-hidden" style="display: none">
                          <p><router-link to="/mansion/change/payment" target="_blank">すぐに手続きしたい時は</router-link></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-idpass webform-conditional-hidden" v-if="kind == '3'">
                          <div>
                            <div>
                              マイページ登録がお済みでないお客様は、下記の必要事項を記入の上確認ボタンをクリックしてください。<br />
                              お客様の情報が確認でき次第、ログインID・初期パスワードをメールにてお知らせします。<br />
                              &nbsp;
                            </div>
                            <div>※マイページ登録がお済みのお客様（毎月電気料金のお知らせメールが届いているお客様）でログインID・パスワードが不明な場合は、<router-link to="/mansion/user/password">ID・パスワードをお忘れの方へ</router-link>からパスワードを再発行願います。</div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-checkboxes webform-component--webform-group-inquiry--field-inquiry-application-type webform-conditional-hidden" v-if="kind == '4'">
                          <label for="edit-submitted-webform-group-inquiry-field-inquiry-application-type">お手続きの内容 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div id="edit-submitted-webform-group-inquiry-field-inquiry-application-type" class="form-checkboxes">
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-inquiry-field-inquiry-application-type-1">
                              <input type="checkbox" id="edit-submitted-webform-group-inquiry-field-inquiry-application-type-1" name="submitted[webform_group_inquiry][field_inquiry_application_type][1]" value="1" class="form-checkbox webform-conditional-disabled" :disabled="isLogin || kind != '4'" v-model="inquiry_type" /> <label class="option" for="edit-submitted-webform-group-inquiry-field-inquiry-application-type-1">電気の利用申込み </label>
                            </div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-inquiry-field-inquiry-application-type-2">
                              <input type="checkbox" id="edit-submitted-webform-group-inquiry-field-inquiry-application-type-2" name="submitted[webform_group_inquiry][field_inquiry_application_type][2]" value="2" class="form-checkbox webform-conditional-disabled" :disabled="isLogin || kind != '4'" v-model="inquiry_type" /> <label class="option" for="edit-submitted-webform-group-inquiry-field-inquiry-application-type-2">支払方法の変更 </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-application webform-conditional-hidden" v-if="kind == '4'">
                          <div class="webform_inquiry">下記にご記入いただくご連絡先に申込書をお送りします。1週間程度でお届けします。<br />※電気を利用しているマンションと異なる送付先をご希望の場合は別途記入欄があります。</div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-application" v-if="kind == '5'">
                          <div class="webform_inquiry">
                            電気をご利用の部屋宛に申込書をお送りします。1週間程度でお届けします。<br />電気を利用しているマンションと異なる送付先をご希望の場合は下記に記入の上、返信ください。
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-departure-button-logged-in2 webform-conditional-hidden" v-if="kind == '6' && isLogin">
                          <p><input type="button" value="退去手続きへ進む" class="webform-conditional-disabled" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query: { type: 'departure' } })" /></p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-departure webform-conditional-hidden" v-if="kind == '6' && !isLogin">
                          <div class="webform_inquiry">
                            <div>お引越しの予定日が決まりましたら、必ず退去の手続きをお願いします。</div>
                            <div>お手続きがないと、継続して電気料金が発生してしまいますのでご注意ください。</div>
                            <div>もし、手続きがないまま退去された場合は、下記カスタマーセンターまで急ぎご連絡ください。</div>
                            <div>&nbsp;</div>
                            <div>※※※ 最終の電気料金について ※※※</div>
                            <div>◆最終の電気料金は「鍵を引渡す日の前日まで」の利用分となります。（基本料金は日割り計算になります）</div>
                            <div>鍵の引渡日</div>
                            <div>・分譲の場合：次の入居者または部屋のオーナーに鍵を引渡す日</div>
                            <div>・賃貸でお住まいの場合：管理会社等に鍵を引渡す日</div>
                            <div>◆引越しをした後も、鍵の引渡日前日までの電気料金はお客様のお支払いとなりますのでご注意ください。</div>
                            <div>◆鍵の引渡日以降の電気料金は、次の入居者または部屋のオーナーのお支払いとなります。</div>
                            <div>◆退去に伴う解約金やその他手数料はかかりません。</div>
                            <div>&nbsp;</div>
                            <div>※※※ 手続きに関するお願い ※※※</div>
                            <div>◆鍵の引渡日が未定の場合は、お手数ですが下記カスタマーセンターまでご連絡ください。</div>
                            <div>◆退去の予定（鍵の引渡日など）が変更になった場合は、再度退去手続きをすれば、新しい申請内容にて承ります。</div>
                            <div>◆退去手続きをキャンセルされる場合は、必ずカスタマーセンターまでご連絡ください。</div>
                            <div>◆内容のご確認のため、カスタマーセンターより利用状況等の確認のご連絡をする場合がございますのでご了承ください。</div>
                            <div>◆海外へ転居する場合、「お引越し先住所」欄には、国内在住のご親族様や代理人様などの住所を入力ください。</div>
                            <div>&nbsp;</div>
                            <div>※※※ でんき案内板へのアクセスについて ※※※</div>
                            <div>◆鍵の引渡日前日の翌々月の末日までは、でんき案内板にログインすることができます。</div>
                            <div>◆料金明細も過去3年分を確認することができます。</div>
                            <div>◆各種手続きも可能ですが、鍵の引渡日（退去日）以降は電気使用量や電気料金のグラフは表示されません。</div>
                            <div>
                              ◆でんき案内板・マイページ（会員情報）のお客様ご自身での解除手続きは不要です。<br />
                              鍵の引渡日前日の翌々月の末日まではでんき案内板にログインすることができますが、それ以降は解除手続きをしなくてもログインできない状態となります。
                            </div>
                            <div>◆鍵の引渡日前日の翌々月の末日を過ぎるとでんき案内板にログインできなくなるため、確定申告等で料金明細が必要になる場合は、早めにPDFファイルのダウンロードや印刷をしておくようお願いします。</div>
                            <div>&nbsp;</div>
                            <div>退去手続き後に送信されるメールもあわせてご確認ください。</div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-application-confirm webform-conditional-hidden" v-if="kind == '7'">
                          <div class="webform_inquiry">
                            <div>すでに電気の利用申込みを手続きされているかどうかは、下記内容をご確認いただくとわかる場合がございます。</div>
                            <div>お問い合わせ前に再度ご確認ください。</div>
                            <div>&nbsp;</div>
                            <div>
                              ◆ WEB（でんき案内板）から手続きされたお客様<br />
                              NTTアノードエナジー（でんき案内板）から「【NTTアノードエナジー】電気の利用申込みを受け付けました」というメールが届いていませんか？
                            </div>
                            <div>&nbsp;</div>
                            <div>
                              ◆紙の申込書で手続きされたお客様<br />
                              申込書のお客様控えがお手元にありませんか？
                            </div>
                            <div>&nbsp;</div>
                            <div>手続き完了のメールやお客様控えが見当たらない場合は、カスタマーセンターにてお客様の申込み状況をお調べします。</div>
                            <div>
                              下記の必要事項を記入の上、「確認」をクリックしてください。<br />
                              確認ができ次第、メールにてご連絡いたします。
                            </div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-payment-certificate webform-conditional-hidden" v-if="kind == '8'">
                          <p><span style="color: #ff0000">下記の必要事項を記入の上確認ボタンをクリックしてください。</span>目安として2週間程度で発行し、ご指定の連絡先にお送りします。</p>
                          <p>※電気を利用しているマンションと異なる送付先をご希望の場合は別途記入欄があります。</p>
                          <p>&nbsp;・対象となる請求月&nbsp;※直近15ヶ月以内の請求まで証明可能です</p>
                          <p>&nbsp;・発行手数料：1枚あたり516円（税込）※1枚に記載できる電気料金は最大12か月分までです</p>
                          <p>&nbsp;</p>
                          <p>◇クレジットカード払いの場合は、「利用料金証明書」として発行いたします。支払証明としては、お客様がそのクレジットカード会社へ支払いを行ったものに限り、有効となります。（公的機関等で支払証明として利用できるかどうかは、事前にお客様にてご確認ください。）</p>
                          <p>◇証明書は必要なく請求金額のみ知りたい場合は、<router-link to="/mansion/claim" target="_blank">料金明細一覧</router-link>から直近3年分の明細を無料で確認できます。</p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-return-envelope webform-conditional-hidden" v-if="kind == '9' && !isLogin">
                          <p>
                            下記にご記入いただくご連絡先に返信用封筒をお送りします。1週間程度でお届けします。<br />
                            ※電気を利用しているマンションと異なる送付先をご希望の場合は別途記入欄があります。
                          </p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-inquiry--field-description-return-envelope-loggedin webform-conditional-hidden" v-if="kind == '9' && isLogin">
                          <p>
                            電気をご利用の部屋宛に返信用封筒をお送りします。1週間程度でお届けします。<br />
                            電気を利用しているマンションと異なる送付先をご希望の場合は、下記に記入の上返信ください。
                          </p>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-private-corporation form-wrapper" v-if="!isLogin">
                      <legend><span class="fieldset-legend">個人／法人</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-radios webform-component--webform-group-private-corporation--field-private-corporation">
                          <label for="edit-submitted-webform-group-private-corporation-field-private-corporation">個人／法人 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div id="edit-submitted-webform-group-private-corporation-field-private-corporation" class="form-radios">
                            <div class="form-item form-type-radio form-item-submitted-webform-group-private-corporation-field-private-corporation">
                              <input required="required" type="radio" id="edit-submitted-webform-group-private-corporation-field-private-corporation-1" name="submitted[webform_group_private_corporation][field_private_corporation]" value="0" checked="checked" class="form-radio" v-model="individual_corporation" :disabled="isLogin"/> <label class="option" for="edit-submitted-webform-group-private-corporation-field-private-corporation-1">個人 </label>
                            </div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-private-corporation-field-private-corporation">
                              <input required="required" type="radio" id="edit-submitted-webform-group-private-corporation-field-private-corporation-2" name="submitted[webform_group_private_corporation][field_private_corporation]" value="1" class="form-radio" v-model="individual_corporation" :disabled="isLogin"/> <label class="option" for="edit-submitted-webform-group-private-corporation-field-private-corporation-2">法人 </label>
                            </div>
                          </div>
                        </div>
                        <fieldset class="webform-component-fieldset webform-component--webform-group-private-corporation--webform-group-corporation-info-1 form-wrapper webform-conditional-hidden" v-if="individual_corporation == 1">
                          <legend><span class="fieldset-legend">法人情報</span></legend>
                          <div class="fieldset-wrapper">
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-private-corporation--webform-group-corporation-info-1--field-company-name">
                              <label for="edit-submitted-webform-group-private-corporation-webform-group-corporation-info-1-field-company-name">会社名 <span class="form-optional"></span></label>
                              <input type="text" id="edit-submitted-webform-group-private-corporation-webform-group-corporation-info-1-field-company-name" name="submitted[webform_group_private_corporation][webform_group_corporation_info_1][field_company_name]" size="60" maxlength="128" class="form-text webform-conditional-disabled" :disabled="isLogin || individual_corporation == 0" v-model="company_name" />
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-private-corporation--webform-group-corporation-info-1--field-department-name">
                              <label for="edit-submitted-webform-group-private-corporation-webform-group-corporation-info-1-field-department-name">部署名 </label>
                              <input type="text" id="edit-submitted-webform-group-private-corporation-webform-group-corporation-info-1-field-department-name" name="submitted[webform_group_private_corporation][webform_group_corporation_info_1][field_department_name]" size="60" maxlength="128" class="form-text webform-conditional-disabled" :disabled="isLogin || individual_corporation == 0" v-model="department_name" />
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-private-corporation--webform-group-corporation-info-1--field-position">
                              <label for="edit-submitted-webform-group-private-corporation-webform-group-corporation-info-1-field-position">役職 </label>
                              <input type="text" id="edit-submitted-webform-group-private-corporation-webform-group-corporation-info-1-field-position" name="submitted[webform_group_private_corporation][webform_group_corporation_info_1][field_position]" size="60" maxlength="128" class="form-text webform-conditional-disabled" :disabled="isLogin || individual_corporation == 0" v-model="director" />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-contact-info form-wrapper" v-if="!isLogin">
                      <legend><span class="fieldset-legend">ご連絡先</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contact-info--field-contact-name">
                          <label for="edit-submitted-webform-group-contact-info-field-contact-name">お名前 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-contact-info-field-contact-name" name="submitted[webform_group_contact_info][field_contact_name]" v-model="name" size="60" maxlength="128" class="form-text required" :disabled="isLogin" />
                          <div class="description">（例：電力　花子）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contact-info--field-contact-kana">
                          <label for="edit-submitted-webform-group-contact-info-field-contact-kana">フリガナ <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-contact-info-field-contact-kana" name="submitted[webform_group_contact_info][field_contact_kana]" v-model="name_kana" size="60" maxlength="128" class="form-text required" :disabled="isLogin"/>
                          <div class="description">（例：デンリョク　ハナコ）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contact-info--field-mansion-name-contact">
                          <label for="edit-submitted-webform-group-contact-info-field-mansion-name-contact">マンション名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-contact-info-field-mansion-name-contact" name="submitted[webform_group_contact_info][field_mansion_name_contact]" v-model="building_name" size="60" maxlength="30" class="form-text required" :disabled="isLogin" />
                          <div class="description">（例：○○マンション ○○棟）※棟がある場合は必ず棟名までご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contact-info--field-room-number-contact">
                          <label for="edit-submitted-webform-group-contact-info-field-room-number-contact">部屋番号&nbsp;または店舗名・管理組合名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" type="text" id="edit-submitted-webform-group-contact-info-field-room-number-contact" name="submitted[webform_group_contact_info][field_room_number_contact]" v-model="room_number_etc" size="60" maxlength="30" class="form-text required" :disabled="isLogin" />
                          <div class="description">（例：1001）※棟がある場合は上記マンション名欄にご記入ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-email webform-component--webform-group-contact-info--field-contact-email">
                          <label for="edit-submitted-webform-group-contact-info-field-contact-email">E-mail <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required" class="email form-text form-email required" type="email" id="edit-submitted-webform-group-contact-info-field-contact-email" name="submitted[webform_group_contact_info][field_contact_email]" size="60" v-model="e_mail" :disabled="isLogin" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-contact-info--field-inquiry-tel webform-container-inline">
                          <label for="edit-submitted-webform-group-contact-info-field-inquiry-tel">日中ご連絡の取れる電話番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <input required="required"  type="tel" id="edit-submitted-webform-group-contact-info-field-inquiry-tel" name="submitted[webform_group_contact_info][field_inquiry_tel]" v-model="day_contact_tel_num" size="15" maxlength="11" class="form-text required" :disabled="isLogin" />
                          <div class="description">（例：09012345678）※半角数字のみ「－」なし</div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-contact-info--field-inquiry-tel-description">
                          <p>カスタマーセンターから手続きや契約内容の確認のためにご連絡する場合があります。ショートメッセージでお送りする場合もあります。</p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-contact-info--field-tel-description-departure webform-conditional-hidden" style="display: none">
                          <div class="description" style="margin-top: -20px; margin-left: 10px">※海外へ転居するお客様で、海外の電話のみお持ちの場合は、国番号と電話番号(入力できる桁まで)を入力ください。</div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-payment-certificate form-wrapper webform-conditional-hidden" v-if="kind == '8'">
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-payment-certificate--field-payment-certificate-term-from">
                          <label>証明が必要な電気料金 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline">
                            <div class="form-item form-type-select form-item-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year">年 </label>
                              <select class="year form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year" name="submitted[webform_group_payment_certificate][field_payment_certificate_term_from][year]" :disabled="kind != '8'" v-model="need_proof_electric_bill_from_year">
                                <template v-for="y in need_proof_electric_bill_list" :key="y">
                                  <option :value="y">{{ y }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-month">月 </label>
                              <select class="month form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-month" name="submitted[webform_group_payment_certificate][field_payment_certificate_term_from][month]" :disabled="kind != '8'" v-model="need_proof_electric_bill_from_month">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                          </div>
                          <div class="description">
                            利用分（<span class="billing_year_from">{{ addMonth(new Date(need_proof_electric_bill_from_year, need_proof_electric_bill_from_month - 1), 1).getFullYear() }}</span
                            >年<span class="billing_month_from">{{ addMonth(new Date(need_proof_electric_bill_from_year, need_proof_electric_bill_from_month - 1, 1), 1).getMonth() + 1 }}</span
                            >月請求分）~
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-payment-certificate--field-payment-certificate-term-to">
                          <label class="element-invisible">証明が必要な電気料金(TO) <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline">
                            <div class="form-item form-type-select form-item-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year">年 </label>
                              <select class="year form-select" required="required" id="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year" name="submitted[webform_group_payment_certificate][field_payment_certificate_term_to][year]" :disabled="kind != '8'" v-model="need_proof_electric_bill_to_year">
                                <template v-for="y in need_proof_electric_bill_list" :key="y">
                                  <option :value="y">{{ y }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-month">月 </label>
                              <select class="month form-select" required="required" id="edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-month" name="submitted[webform_group_payment_certificate][field_payment_certificate_term_to][month]"  :disabled="kind != '8'" v-model="need_proof_electric_bill_to_month">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                          </div>
                          <div class="description">利用分（<span class="billing_year_to">{{ addMonth(new Date(need_proof_electric_bill_to_year, need_proof_electric_bill_to_month - 1), 1).getFullYear() }}</span
                            >年<span class="billing_month_to">{{ addMonth(new Date(need_proof_electric_bill_to_year, need_proof_electric_bill_to_month - 1, 1), 1).getMonth() + 1 }}</span
                            >月請求分）
                          </div>
                          <span id="term_info"></span>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-payment-certificate--field-description-payment-certificate-term">
                          <p>
                            ※直近15ヶ月以内の請求まで証明可能です<br />
                            ※電気料金は利用月の翌月に請求しております
                          </p>
                        </div>
                        <div class="form-item webform-component webform-component-number webform-component--webform-group-payment-certificate--field-issuance-fee">
                          <label for="edit-submitted-webform-group-payment-certificate-field-issuance-fee">発行手数料 </label>
                          <input readonly="readonly" type="text" id="edit-submitted-webform-group-payment-certificate-field-issuance-fee" name="submitted[webform_group_payment_certificate][field_issuance_fee]" step="any" class="form-text form-number webform-conditional-disabled" :disabled="kind != '8'" /> <span class="field-suffix">円（税込）</span>
                          <div class="description">※1枚あたり税込516円となります。1枚に記載できる電気料金は最大12か月分までです</div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-payment-certificate--calculate-button">
                          <p><input id="calculate_button" type="button" value="計算する" /></p>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--field-address-other form-wrapper webform-conditional-hidden" v-if="kind == '4' || kind == '5' || kind == '8' || kind == '9'">
                      <legend><span class="fieldset-legend">電気を利用しているマンションと異なる送付先をご希望の場合</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-textfield webform-component--field-address-other--field-inquiry-zipcode">
                          <label for="edit-submitted-field-address-other-field-inquiry-zipcode">郵便番号 </label>
                          <input type="text" style="width:auto" pattern="[0-9]*" id="edit-submitted-field-address-other-field-inquiry-zipcode" name="submitted[field_address_other][field_inquiry_zipcode]" v-model="post_code" size="7" maxlength="7" class="form-text webform-conditional-disabled" :disabled="!(kind == '4' || kind == '5' || kind == '8' || kind == '9')" /> <span class="field-suffix"><button type="button" id="btn_inquiry_zipcode" @click.prevent.stop="getZipcode2()">郵便番号から住所を自動入力</button></span>
                          <div class="description">（例：0000000）※半角数字のみ「－」なし<br />お住まいの郵便番号がわからない場合は<a href="https://www.post.japanpost.jp/zipcode/" target="_blank">日本郵政の公式サイト</a>よりご確認ください。</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--field-address-other--field-inquiry-delivery-address">
                          <label for="edit-submitted-field-address-other-field-inquiry-delivery-address">住所 </label>
                          <input type="text" id="edit-submitted-field-address-other-field-inquiry-delivery-address" name="submitted[field_address_other][field_inquiry_delivery_address]" v-model="delivery_address" size="60" maxlength="128" class="form-text webform-conditional-disabled" :disabled="!(kind == '4' || kind == '5' || kind == '8' || kind == '9')" />
                          <div class="description">（例：東京都港区芝浦0-00-00）</div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--field-address-other--field-inquiry-delivery-name">
                          <label for="edit-submitted-field-address-other-field-inquiry-delivery-name">宛名 </label>
                          <input type="text" id="edit-submitted-field-address-other-field-inquiry-delivery-name" name="submitted[field_address_other][field_inquiry_delivery_name]" v-model="delivery_name" size="40" maxlength="20" class="form-text webform-conditional-disabled" :disabled="!(kind == '4' || kind == '5' || kind == '8' || kind == '9')" />
                          <div class="description">（例：電力&nbsp;花子）※２０文字以内・環境依存文字以外でご記入ください。</div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info form-wrapper webform-conditional-hidden" v-if="!isLogin && kind == '6'">
                      <legend><span class="fieldset-legend">お引越しの情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-departure-info--field-moving-schedule webform-container-inline">
                          <label>お引越し予定日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline webform-datepicker">
                            <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-moving-schedule-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-moving-schedule-year">年 </label>
                              <select class="year form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-departure-info-field-moving-schedule-year" name="submitted[webform_group_departure_info][field_moving_schedule][year]" :disabled="kind != '6'" v-model="move_scheduled_date_year">
                                <template v-for="n in 3" :key="n">
                                  <option :value="n + new Date().getFullYear() - 1">{{ n + new Date().getFullYear() - 1 }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-moving-schedule-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-moving-schedule-month">月 </label>
                              <select class="month form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-departure-info-field-moving-schedule-month" name="submitted[webform_group_departure_info][field_moving_schedule][month]" :disabled="kind != '6'" v-model="move_scheduled_date_month">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-moving-schedule-day">
                              <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-moving-schedule-day">日 </label>
                              <select class="day form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-departure-info-field-moving-schedule-day" name="submitted[webform_group_departure_info][field_moving_schedule][day]" :disabled="kind != '6'" v-model="move_scheduled_date_date">
                                <template v-for="n in 31" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(0, 0, 0) + ' webform-calendar-end-' + getDateString(2, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                            <input type="image" aria-hidden="true" role="presentation" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-departure-info--field-next-key-delivery webform-container-inline">
                          <label>鍵のお引渡（ご返却）日 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                          <div class="webform-container-inline webform-datepicker">
                            <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-next-key-delivery-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-next-key-delivery-year">年 </label>
                              <select class="year form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-departure-info-field-next-key-delivery-year" name="submitted[webform_group_departure_info][field_next_key_delivery][year]" :disabled="kind != '6'" v-model="key_delivery_return_day_year">
                                <template v-for="n in 3" :key="n">
                                  <option :value="n + new Date().getFullYear() - 1">{{ n + new Date().getFullYear() - 1 }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-next-key-delivery-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-next-key-delivery-month">月 </label>
                              <select class="month form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-departure-info-field-next-key-delivery-month" name="submitted[webform_group_departure_info][field_next_key_delivery][month]" :disabled="kind != '6'" v-model="key_delivery_return_day_month">
                                <template v-for="n in 12" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-departure-info-field-next-key-delivery-day">
                              <label class="element-invisible" for="edit-submitted-webform-group-departure-info-field-next-key-delivery-day">日 </label>
                              <select class="day form-select webform-conditional-disabled" required="required" id="edit-submitted-webform-group-departure-info-field-next-key-delivery-day" name="submitted[webform_group_departure_info][field_next_key_delivery][day]" :disabled="kind != '6'" v-model="key_delivery_return_day_date">
                                <template v-for="n in 31" :key="n">
                                  <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(0, 0, 0) + ' webform-calendar-end-' + getDateString(2, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                            <input type="image" aria-hidden="true" role="presentation" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                          </div>
                          <div class="description">※鍵のお引渡（ご返却）日前日までの電気料金をお支払いいただきます。（基本料金は日割り計算）</div>
                        </div>
                        <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info--webform-group-moving-address form-wrapper">
                          <legend><span class="fieldset-legend">お引越し先住所</span></legend>
                          <div class="fieldset-wrapper">
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-zipcode">
                              <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-zipcode">郵便番号 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                              <input required="required" type="text" pattern="[0-9]*" style="width:auto" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-zipcode" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_zipcode]" v-model="move_address_post_code" size="7" maxlength="7" class="form-text required webform-conditional-disabled" :disabled="kind != '6'" />
                              <span class="field-suffix"><button type="button" id="btn_moving_zipcode" @click.prevent.stop="getZipcode1()">郵便番号から住所を自動入力</button></span>
                              <div class="description">（例：0000000）※半角数字のみ「－」なし <br />お住まいの郵便番号がわからない場合は<a href="https://www.post.japanpost.jp/zipcode/" target="_blank">日本郵政の公式サイト</a>よりご確認ください。</div>
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-pref">
                              <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-pref">都道府県 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                              <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-pref" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_pref]" v-model="move_address_prefectures" size="10" maxlength="10" class="form-text required webform-conditional-disabled" :disabled="kind != '6'" />
                              <div class="description">（例：東京都）</div>
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-city">
                              <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-city">市区町村 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                              <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-city" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_city]" v-model="move_address_municipalities" size="20" maxlength="10" class="form-text required webform-conditional-disabled" :disabled="kind != '6'" />
                              <div class="description">（例：港区）</div>
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-town">
                              <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-town">町名 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                              <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-town" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_town]" v-model="move_address_townname" size="20" maxlength="10" class="form-text required webform-conditional-disabled" :disabled="kind != '6'" />
                              <div class="description">（例：芝浦）</div>
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-house-number">
                              <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-house-number">番地 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                              <input required="required" type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-house-number" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_house_number]" v-model="move_address_housenum" size="20" maxlength="10" class="form-text required webform-conditional-disabled" :disabled="kind != '6'" />
                              <div class="description">（例：0-00-00）</div>
                            </div>
                            <div class="form-item webform-component webform-component-textfield webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-address-other">
                              <label for="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-address-other">マンション名・部屋番号等 </label>
                              <input type="text" id="edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-address-other" name="submitted[webform_group_departure_info][webform_group_moving_address][field_moving_address_other]" v-model="move_address_builname_etc" size="50" maxlength="25" class="form-text webform-conditional-disabled" :disabled="kind != '6'" />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-other-info form-wrapper" v-if="!isLogin">
                      <legend><span class="fieldset-legend">その他</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-checkboxes webform-component--webform-group-other-info--field-question" v-if="kind == '' || kind == '1'">
                          <label for="edit-submitted-webform-group-other-info-field-question">弊社を知ったきっかけを教えてください。（複数回答可） </label>
                          <div id="edit-submitted-webform-group-other-info-field-question" class="form-checkboxes">
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-1"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-1" name="submitted[webform_group_other_info][field_question][1]" value="1" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-1">新聞／雑誌 </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-2"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-2" name="submitted[webform_group_other_info][field_question][2]" value="2" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-2">WEB検索サイト </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-3"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-3" name="submitted[webform_group_other_info][field_question][3]" value="3" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-3">TV番組／CM </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-4"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-4" name="submitted[webform_group_other_info][field_question][4]" value="4" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-4">展示会／セミナー </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-5"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-5" name="submitted[webform_group_other_info][field_question][5]" value="5" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-5">メルマガ </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-6"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-6" name="submitted[webform_group_other_info][field_question][6]" value="6" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-6">パンフレット／DM </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-7"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-7" name="submitted[webform_group_other_info][field_question][7]" value="7" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-7">ご紹介 </label></div>
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-other-info-field-question-8"><input type="checkbox" id="edit-submitted-webform-group-other-info-field-question-8" name="submitted[webform_group_other_info][field_question][8]" value="8" class="form-checkbox" v-model="how_did_you_know_about_us_list" /> <label class="option" for="edit-submitted-webform-group-other-info-field-question-8">入居者説明会 </label></div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-other-info--field-question-other">
                          <label for="edit-submitted-webform-group-other-info-field-question-other">その他 </label>
                          <input type="text" id="edit-submitted-webform-group-other-info-field-question-other" name="submitted[webform_group_other_info][field_question_other]"  size="60" maxlength="128" class="form-text" v-model="other" />
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-actions" v-if="!(isLogin && kind == '6')"><input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" @click="check" /></div>
                  </div>
                </form>
              </div>
              <!-- /content -->

              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
            <div class="block block-block" data-bid="617">
              <div class="section_contact clearfix" id="contact_body">
                <h4><img alt="各種申込・変更手続き" src="../assets/images/contact_tellform_t.png" style="height: 45px; width: 820px" /></h4>
                <div class="col2_contact btm_2 clearfix">
                  <div class="inner clearfix">
                    <p><img alt="0120-33-0057" src="../assets/images/ntt-f_logo.png" style="height: 63px; width: 260px" /></p>
                    <p>NTTアノードエナジー&nbsp;マンション電力提供サービス&nbsp;カスタマーセンター</p>
                    <p><img alt="フリーダイヤル" src="../assets/images/free-dial.png" style="float: left; height: 20px; margin-right: 5px; width: 35px" /></p>
                    <p>0120-33-0057</p>
                    <p>受付時間 9:00～17:00（年中無休）</p>
                  </div>
                </div>
                <p>
                  ※番号をよくお確かめのうえ、おかけ間違えのないようにお願いいたします。<br />
                  ※お客様との通話は、お申出内容の正確な確認とサービス向上のために録音させていただいております。<br />
                  ※緊急のご用件について、回線障害などの予期せぬ障害で電話が切れた時に、こちらからかけ直しをさせていただくため、全てのお客様に発信者番号通知をお願いしております。<br />
                  ※0120番号へは、国際電話ならびに一部のIP電話等からご利用いただけない場合があります。
                </p>
              </div>
            </div>
            <!-- /block -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import $ from "jquery";
import moment from "moment";
import { inquiryStore } from "../stores/store.js";
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import { isLogin } from "../stores/store.js";
import { init } from "../assets/js/mems_inquiry.js";
import MEMS from "../assets/js/mems.js";
import "../assets/js/webform.js";
import "../assets/js/textarea.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = inquiryStore();
    if (Object.keys(store.update).length != 0) {
      store.update.isLogin = isLogin();
      var update = store.update;
      store.$reset();
      return update;
    } else {
      var today = new Date();
      var lastMonth = this.addMonth(new Date(), -1);
      var minYear = this.addMonth(new Date(), -16).getFullYear();
      var maxYear = this.addMonth(new Date(), -1).getFullYear();
      var year_list = [];
      for(var i = minYear; i <= maxYear; i++){
        year_list.push(i);
      }
      var data = {
        kind: "",
        content: "",
        inquiry_type: [],
        individual_corporation: 0,
        company_name: "",
        department_name: "",
        director: "",
        name: "",
        name_kana: "",
        building_name: "",
        room_number_etc: "",
        e_mail: "",
        day_contact_tel_num: "",
        need_proof_electric_bill_from: "",
        need_proof_electric_bill_to: "",
        issuance_fee: "",
        post_code: "",
        delivery_address: "",
        delivery_name: "",
        move_scheduled_date: "",
        key_delivery_return_day: "",
        move_address_post_code: "",
        move_address_prefectures: "",
        move_address_municipalities: "",
        move_address_townname: "",
        move_address_housenum: "",
        move_address_builname_etc: "",
        how_did_you_know_about_us_list: [],
        other: "",
        need_proof_electric_bill_from_year: lastMonth.getFullYear(),
        need_proof_electric_bill_from_month: lastMonth.getMonth() + 1,
        need_proof_electric_bill_to_year: lastMonth.getFullYear(),
        need_proof_electric_bill_to_month: lastMonth.getMonth() + 1,
        need_proof_electric_bill_list: year_list,
        move_scheduled_date_year: today.getFullYear(),
        move_scheduled_date_month: today.getMonth() + 1,
        move_scheduled_date_date: today.getDate(),
        key_delivery_return_day_year: today.getFullYear(),
        key_delivery_return_day_month: today.getMonth() + 1,
        key_delivery_return_day_date: today.getDate(),
        isLogin: isLogin(),
        search_error : 0
      };
      return data;
    }
  }, 
  mounted: function () {
    this.chnageType();
  },
  methods: {
    check: function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("select").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("[required]").forEach(function(input) {
        if(input.validity.valueMissing){
          input.setCustomValidity("これは入力必須項目です。");
        }
      });
      if(!this.$data.isLogin){
        var email1 = document.querySelector("#edit-submitted-webform-group-contact-info-field-contact-email");
        var splited1 = this.$data.e_mail.split("@");
        if (splited1.length == 2 && (splited1[1].length == 0 || splited1[1].length == splited1[1].replace(".", "").length || splited1[1].endsWith("."))) {
          email1.setCustomValidity('@の後に、アドレスの一部を入力してください。"' + splited1[1] + '"は不完全です。');
        }else if(email1.validity.typeMismatch || splited1[0].length > 64) {
          email1.setCustomValidity("入力されたメールアドレス " + this.$data.e_mail + " が有効ではありません。");
        }
      }
      
      if (this.$data.kind == 4){
        if(this.inquiry_type.length == 0){
          document.querySelector("#edit-submitted-webform-group-inquiry-field-inquiry-application-type-1").setCustomValidity("お手続きの内容 は必須です。");
        }
      }
      if (this.$data.kind == 8) {
        var last = this.addMonth(new Date(), -1);
        var before = this.addMonth(new Date(), -15);
        var beforeCheck = this.addMonth(new Date(), -16);
        var fromdate = new Date(this.$data.need_proof_electric_bill_from_year,  this.$data.need_proof_electric_bill_from_month - 1, 1);
        if(fromdate.getTime() < beforeCheck.getTime()){
          document.querySelector("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-from-year").setCustomValidity(before.getFullYear() + "年"+ (before.getMonth() + 1) + "月以降の日付を指定して下さい。");
          return;
        }
        var todate = new Date(this.$data.need_proof_electric_bill_to_year,  this.$data.need_proof_electric_bill_to_month - 1, 1);
        if(todate.getTime() > last.getTime()){
          document.querySelector("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year").setCustomValidity(last.getFullYear() + "年"+ (last.getMonth() + 1) + "月以前の日付を指定して下さい。");
          return;
        }
        var from = 12 * this.$data.need_proof_electric_bill_from_year + this.$data.need_proof_electric_bill_from_month;
        var to = 12 * this.$data.need_proof_electric_bill_to_year + this.$data.need_proof_electric_bill_to_month;
        if((to - from) < 0){
          document.querySelector("#edit-submitted-webform-group-payment-certificate-field-payment-certificate-term-to-year").setCustomValidity("証明書の発行期間が不正です。");
          return;
        }
      }
      if (this.$data.kind == 4 || this.$data.kind == 5 || this.$data.kind == 8 || this.$data.kind == 9) {
        var zip1 = document.querySelector("#edit-submitted-field-address-other-field-inquiry-zipcode");
        if (this.$data.post_code.length != 0 && this.$data.post_code.length < 7) {
          zip1.setCustomValidity("郵便番号は、7桁でご入力ください。");
        }
        if (zip1.validity.patternMismatch) {
          zip1.setCustomValidity("郵便番号は、半角整数のみで入力して下さい。");
        }
      }
      if (this.$data.kind == 6) {
        var zip2 = document.querySelector("#edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-zipcode");
        if (this.$data.move_address_post_code.length != 0 && this.$data.move_address_post_code.length < 7) {
          zip2.setCustomValidity("郵便番号は、7桁でご入力ください。");
        }
        if (zip2.validity.patternMismatch) {
          zip2.setCustomValidity("郵便番号は、半角整数のみで入力して下さい。");
        }
        if(this.$data.move_address_prefectures.length > 4){
          document.querySelector("#edit-submitted-webform-group-departure-info-webform-group-moving-address-field-moving-pref").setCustomValidity("都道府県は、4文字以下で入力して下さい。");
        }
        var lastday = new Date();
        lastday.setDate(lastday.getDate() - 1);
        if (!moment(this.$data.move_scheduled_date_year + "/" +  this.$data.move_scheduled_date_month + "/" +  this.$data.move_scheduled_date_date, "YYYY/M/D").isValid()){
          document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").setCustomValidity("入力された お引越し予定日 は正しい日時ではありません。");
          return;
        }
        if(lastday.getTime() > new Date(this.$data.move_scheduled_date_year, this.$data.move_scheduled_date_month - 1, this.$data.move_scheduled_date_date).getTime()){
          document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").setCustomValidity(new Date().toLocaleDateString("sv-SE").replaceAll("-", "/")  + "以降の日付を指定して下さい。");
          return;
        }
        if (!moment(this.$data.key_delivery_return_day_year + "/" + this.$data.key_delivery_return_day_month + "/" + this.$data.key_delivery_return_day_date, "YYYY/M/D").isValid()){
          document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").setCustomValidity("入力された 鍵のお引渡（ご返却）日 は正しい日時ではありません。");
          return;
        }
        if(lastday.getTime() > new Date(this.$data.key_delivery_return_day_year, this.$data.key_delivery_return_day_month - 1, this.$data.key_delivery_return_day_date).getTime()){
          document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").setCustomValidity(new Date().toLocaleDateString("sv-SE").replaceAll("-", "/") + "以降の日付を指定して下さい。");
          return;
        }
      }
    },
    push: function () {
      this.$data.search_error = 0;
      if (this.$data.kind == 8) {
        this.$data.need_proof_electric_bill_from = this.$data.need_proof_electric_bill_from_year + "/" + this.$data.need_proof_electric_bill_from_month;
        this.$data.need_proof_electric_bill_to = this.$data.need_proof_electric_bill_to_year + "/" + this.$data.need_proof_electric_bill_to_month;
        var from = 12 * this.$data.need_proof_electric_bill_from_year + this.$data.need_proof_electric_bill_from_month;
        var to = 12 * this.$data.need_proof_electric_bill_to_year + this.$data.need_proof_electric_bill_to_month;
        var month = Math.abs(to - from) + 1;
        if (month % 12 == 0) {
          this.$data.issuance_fee = 516 * Math.trunc(month / 12);
        } else {
          this.$data.issuance_fee = 516 * (Math.trunc(month / 12) + 1);
        }
      }
      if (this.$data.kind == 6) {
        this.$data.move_scheduled_date = new Date(this.$data.move_scheduled_date_year, this.$data.move_scheduled_date_month - 1, this.move_scheduled_date_date).toLocaleDateString("sv-SE").replaceAll("-", "/");
        this.$data.key_delivery_return_day = new Date(this.$data.key_delivery_return_day_year, this.$data.key_delivery_return_day_month - 1, this.key_delivery_return_day_date).toLocaleDateString("sv-SE").replaceAll("-", "/");
      }
      var store = inquiryStore();
      store.update = this.$data;
      this.$router.push({ path: "/mansion/inquiry_confirm" });
    },
    changeDay1 : function(){
      this.$data.key_delivery_return_day_year = document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").value;
      this.$data.key_delivery_return_day_month = document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-month").value;
      this.$data.key_delivery_return_day_date = document.querySelector("#edit-submitted-webform-group-departure-info-field-next-key-delivery-day").value;
    },
    changeDay2 : function(){
      this.$data.move_scheduled_date_year = document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").value;
      this.$data.move_scheduled_date_month = document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-month").value;
      this.$data.move_scheduled_date_date = document.querySelector("#edit-submitted-webform-group-departure-info-field-moving-schedule-day").value;
    },
    addMonth: function (date, add) {
      date.setDate(1);
      date.setMonth(date.getMonth() + add);
      return date;
    },
    getDateString: function (year, month, day) {
      var date = new Date();
      date.setFullYear(date.getFullYear() + year);
      date.setMonth(date.getMonth() + month);
      date.setDate(date.getDate() + day);
      return date.toLocaleDateString("sv-SE");
    },
    getZipcode1: async function () {
      this.$data.search_error = 0;
      if(!this.$data.move_address_post_code.match(/^\d{7}$/)){
        return;
      }
      try {
        var result = await getData(config.url.get.zipcode + "?postCode="  + this.$data.move_address_post_code);
        // [{ pref, city, town }]
      }catch(ex){
        this.$data.search_error = 1;
        document.body.scrollIntoView();
        return;
      }
      if(result.length == 0){
        return;
      }
      this.$data.move_address_prefectures = result[0]["pref"];
      this.$data.move_address_municipalities = result[0]["city"];
      this.$data.move_address_townname = result[0]["town"];
    },
    getZipcode2: async function () {
      this.$data.search_error = 0;
      if(!this.$data.post_code.match(/^\d{7}$/)){
        return;
      }
      try{
        var result = await getData(config.url.get.zipcode + "?postCode="  + this.$data.post_code);
        // [{ pref, city, town }]
      }catch(ex){
        this.$data.search_error = 1;
        document.body.scrollIntoView();
        return;
      }
      if(result.length == 0){
        return;
      }
      this.$data.delivery_address = result[0]["pref"] + result[0]["city"] + result[0]["town"];
    },
    chnageType: function() {
      if(this.$data.kind == '' || this.$data.kind == 1){
        this.$nextTick(
          function () {
            MEMS.behaviors.textarea.attach();
            if ($(".grippie").length == 0) {
              $(".form-textarea-wrapper.resizable").trigger("textarea");
            }
          }.bind(this)
        );
      }else if(this.$data.kind == 8){
        this.$nextTick(
          function () {
            init();
        });
      }else if(this.$data.kind == 6){
        this.$nextTick(
          function () {
            MEMS.behaviors.webform.attach(document);
            $("#edit-submitted-webform-group-departure-info-field-next-key-delivery-year").on("change", this.changeDay1);
            $("#edit-submitted-webform-group-departure-info-field-next-key-delivery-month").on("change", this.changeDay1);
            $("#edit-submitted-webform-group-departure-info-field-next-key-delivery-day").on("change", this.changeDay1);
      
            $("#edit-submitted-webform-group-departure-info-field-moving-schedule-year").on("change", this.changeDay2);
            $("#edit-submitted-webform-group-departure-info-field-moving-schedule-month").on("change", this.changeDay2);
            $("#edit-submitted-webform-group-departure-info-field-moving-schedule-day").on("change", this.changeDay2);
        });
      }
    }
  },
};
</script>
<style scoped>
.webform-calendar:focus {
  outline: none;
}
</style>

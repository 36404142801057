import $ from 'jquery'
export const init = function() {
    $("#views-exposed-form-faq-search-page-1 #edit-title, #views-exposed-form-faq-search-page-2 #edit-title").on("blur",function(){
      if ($(this).val().match(/\s/g)) {
        // 全角スペースを半角にする
        $(this).val($(this).val().replace(/\s/g," "));
      }
    });

    $("#views-exposed-form-faq-search-page-1 input, #views-exposed-form-faq-search-page-2 input").on('keydown', function(e) {
      if (e.code && e.code === 13) {
        if ($(this).val().match(/\s/g)) {
          // 全角スペースを半角にする
          $(this).val($(this).val().replace(/\s/g," "));
        }
      } else {
          return true;
      }
    });
}
$(document).on("ready", init);
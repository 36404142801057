<template>
  <div class="html not-front not-logged-in no-sidebars page-node page-node- page-node-3507 node-type-page responsive-menus-load-processe">
    <Header />
    <div id="highlighted">
      <Header_info />
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf"><router-link to="/mansion/faq_top">よくあるご質問</router-link></li>
          <li class="leaf"><router-link to="/mansion/inquiry">お問い合わせ</router-link></li>
          <li class="last leaf active-trail"><router-link to="/mansion/entry">電気の利用申込み</router-link></li>
        </ul>
      </div>
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">ご確認ください！</span>
        </div>
        <section id="content">
          <h1 class="title">ご確認ください！</h1>
          <div id="content-area">
            <article class="node node-page" data-nid="3507">
              <header><span property="dc:title" content="ご確認ください！" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded">
                      <h1>お客様が申請したメールアドレスは既に登録されています。</h1>
                      <br />
                      <div class="wrap1">
                        <p class="message_test">
                          お客様の電気の利用申込み手続きは完了しています。<br />
                          （別の部屋の電気の利用を申込むお客様は本ページ下部より手続き画面に進んでください）<br /><br />
                          下記の「登録済の会員情報を確認する」からでんき案内板にログインし、お客様のご契約内容をご確認ください。
                        </p>
                        ＜でんき案内板へのログイン方法がわからない場合＞<br />
                        ・ログインID：登録済のメールアドレスをそのまま入力ください。<br />
                        ・パスワード：パスワードをお忘れの方は、<router-link to="/mansion/user/password" target="_blank">こちら</router-link>よりパスワードを再発行できます。<br />
                        <br />
                        <input type="button" value="登録済の会員情報を確認する" @click.prevent.stop="$router.push({path : '/mansion/member/profile', query: { id : mail}})" class="buttons" style="font-weight: bold" />
                        <br />
                      </div>
                      <br />
                      <div class="wrap2">
                        <h3 class="heading" id="001">新たに別の部屋の電気の利用申込みを手続きするお客様へ</h3>
                        <p class="message_test">別の部屋の電気の利用申込みを新たに手続きする場合は、このまま下記の「別の部屋の電気の利用を申込む」をクリックして、手続き画面に進んでください。<br /></p>
                      </div>
                      <input type="button" value="別の部屋の電気の利用を申込む" @click.prevent.stop="$router.push({path : '/mansion/newentry', query : query})" class="buttons" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <!-- /sidebar-second -->
        <!-- /sidebar-first -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from '../components/Header.vue';
import Header_info from '../components/Header_info.vue';
import Footer from '../components/Footer.vue';
export default {
  components: {
    Header,
    Header_info,
    Footer
  },
  data(){
    var query = this.$route.query;
    var mail = decodeURIComponent(query.m)
    return { query : query, mail: mail }
  },
}
</script>
<style scoped src="../assets/css/style.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front not-logged-in no-sidebars page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header />
    <div class="messages error" v-if="error">
        <h2 class="element-invisible">エラーメッセージ</h2>
        URLの有効期限が過ぎているか、入力したURLに誤りがあります。
    </div>
    <div id="highlighted">
      <Header_info/>
      <div class="block block-menu highlight-menu" data-bid="273">    
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main" :style="send ? 'cursor: wait' : ''">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/entry">ＮＴＴアノードエナジーの「マンション電力提供サービス」へようこそ</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">確認画面</span>
        </div>
        <section id="content">
          <h1 class="title">確認画面</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq_top" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 preview" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-use-mansion-info form-wrapper">
                      <legend><span class="fieldset-legend">ご利用マンション情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-use-mansion-info--field-mansion-name">
                          <label>電気をご利用のマンション名 </label>
                          <span class="field-prefix"></span> {{ entry_form.form_mansion_name }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-use-mansion-info--field-room-number">
                          <label>部屋番号 </label>
                          <span class="field-prefix"></span> {{ entry_form.form_room_no }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-use-mansion-info--field-start-date webform-container-inline">
                          <label>部屋のお引渡日 </label>
                          {{ entry_form.move_date_year + "年" + entry_form.move_date_month + "月" + entry_form.move_date_date + "日" }}
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-contractor-info form-wrapper">
                      <legend><span class="fieldset-legend">ご契約者様情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-name">
                          <label>お名前 </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_name }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-kana">
                          <label>フリガナ </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_kana }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-birthdate">
                          <label>生年月日 </label>
                          <span class="field-prefix"></span> {{ contractor.birthday_year + "年" + contractor.birthday_month + "月" + contractor.birthday_date + "日" }} <span class="field-suffix"></span>
                        </div>
                        <template v-if="entry_form.same">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-mansion-address">
                          <label>ご住所 </label>
                          <span class="field-prefix"></span> 電気をご利用のマンションと同じ <span class="field-suffix"></span>
                        </div>
                        </template>
                        <template v-else>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-zipcode-2 webform-container-inline">
                          <label>郵便番号 </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_zip_postal_code }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-pref-2 webform-container-inline">
                          <label>都道府県 </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_state }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-city-2 webform-container-inline">
                          <label>市区町村 </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_city }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-town-2 webform-container-inline">
                          <label>町名 </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_address_line  }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-house-number-2 webform-container-inline">
                          <label>番地 </label>
                          <span class="field-prefix"></span>{{ contractor.contractor_address_line_2}} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-address-other-2 webform-container-inline">
                          <label>マンション名・部屋番号等 </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_address_other}} <span class="field-suffix"></span>
                        </div>
                        </template>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-tel">
                          <label>日中ご連絡のつく電話番号（携帯電話等） </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_phone }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-mobile-phone">
                          <label>上記以外の連絡先（ご自宅の電話番号等） </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_tel }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-contractor-info--field-contractor-email">
                          <label>メールアドレス </label>
                          <span class="field-prefix"></span> {{ contractor.contractor_email }} <span class="field-suffix"></span>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-payer-info form-wrapper">
                      <legend><span class="fieldset-legend">お支払者様情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-name">
                          <label>お名前 </label>
                          <span class="field-prefix"></span> {{ payer.payer_name }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-kana">
                          <label>フリガナ </label>
                          <span class="field-prefix"></span> {{ payer.payer_kana }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-birthday">
                          <label>生年月日 </label>
                          <span class="field-prefix"></span> {{ payer.birthday_year + "年" + payer.birthday_month + "月" + payer.birthday_date + "日" }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--relationship">
                          <label>ご契約者様との続柄 </label>
                          <span class="field-prefix"></span> 
                          <template v-if="payer.same">本人</template>
                          <template v-else-if="payer.payer_relationship == 0">本人</template>
                          <template v-else-if="payer.payer_relationship == 1">配偶者</template>
                          <template v-else-if="payer.payer_relationship == 2">その他</template>
                           <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--relationship_etc">
                          <label>その他続柄 </label>
                          <span class="field-prefix"></span> {{ payer.payer_relationship_etc }} <span class="field-suffix"></span>
                        </div>
                        <template v-if="payer.same">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-zipcode">
                          <label>ご住所 </label>
                          <span class="field-prefix"></span> ご契約者様と同じ <span class="field-suffix"></span>
                        </div>
                        </template> 
                        <template v-else>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-zipcode-2 webform-container-inline">
                          <label>郵便番号 </label>
                          <span class="field-prefix"></span> {{ payer.payer_zip_postal_code }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-pref-2 webform-container-inline">
                          <label>都道府県 </label>
                          <span class="field-prefix"></span> {{ payer.payer_state }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-city-2 webform-container-inline">
                          <label>市区町村 </label>
                          <span class="field-prefix"></span> {{ payer.payer_city }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-town-2 webform-container-inline">
                          <label>町名 </label>
                          <span class="field-prefix"></span> {{ payer.payer_address_line  }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-house-number-2 webform-container-inline">
                          <label>番地 </label>
                          <span class="field-prefix"></span> {{ payer.payer_address_line_2}} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-address-other-2 webform-container-inline">
                          <label>マンション名・部屋番号等 </label>
                          <span class="field-prefix"></span> {{ payer.payer_address_other}} <span class="field-suffix"></span>
                        </div>
                        </template>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-tel">
                          <label>日中ご連絡のつく電話番号（携帯電話等） </label>
                          <span class="field-prefix"></span> {{ payer.payer_phone }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-mobile-phone">
                          <label>上記以外の連絡先（ご自宅の電話番号等） </label>
                          <span class="field-prefix"></span> {{ payer.payer_tel }} <span class="field-suffix"></span>
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-payer-info--field-payer-email">
                          <label>メールアドレス </label>
                          <span class="field-prefix"></span> {{ payer.payer_email }} <span class="field-suffix"></span>
                        </div>
                      </div>
                    </fieldset>
                    <div class="form-item webform-component webform-component-display webform-component--field-contract-type">
                      <label>料金プラン・契約容量 </label>
                      <span class="field-prefix"></span> 
                      <template v-if="entry_form.form_plan == 0">変更なし（初期契約容量のまま）</template>
                      <template v-if="entry_form.form_plan == 1">変更 </template> 
                      <span class="field-suffix"></span>
                    </div>
                    <div class="form-item webform-component webform-component-display webform-component--field-contract-kind" v-show="entry_form.form_plan == 1">
                      <label>希望種別 </label>
                      <span class="field-prefix"></span> {{ entry_form.form_kind }} <span class="field-suffix"></span>
                    </div>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import { entryStore } from "../stores/store.js";
import { post } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header,
    Header_info,
    Footer,
  },
  data() {
    var store = entryStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push("/mansion/");
    } else {
      var update = store.update;
      if(update.entry_form.same){
        update.contractor.contractor_zip_postal_code = "";
        update.contractor.contractor_state = "";
        update.contractor.contractor_city = "";
        update.contractor.contractor_address_line = "";
        update.contractor.contractor_address_line_2 = "";
        update.contractor.contractor_address_other = "";
      }
      if(update.payer.same){
        update.payer.payer_name = update.contractor.contractor_name;
        update.payer.payer_kana = update.contractor.contractor_kana;
        update.payer.payer_zip_postal_code = update.contractor.contractor_zip_postal_code;
        update.payer.payer_state = update.contractor.contractor_state;
        update.payer.payer_city = update.contractor.contractor_city;
        update.payer.payer_birthday = update.contractor.contractor_birthday;
        update.payer.birthday_year = update.contractor.birthday_year;
        update.payer.birthday_month = update.contractor.birthday_month;
        update.payer.birthday_date = update.contractor.birthday_date;
        update.payer.payer_address_line = update.contractor.contractor_address_line;
        update.payer.payer_address_line_2 = update.contractor.contractor_address_line_2;
        update.payer.payer_address_other = update.contractor.contractor_address_other;
        update.payer.payer_phone= update.contractor.contractor_phone;
        update.payer.payer_tel = update.contractor.contractor_tel;
        update.payer.payer_relationship = 0;
        update.payer.payer_relationship_etc = "";
        update.payer.payer_email = update.contractor.contractor_email;
      }
      if(update.payer.payer_relationship != "2"){
        update.payer.payer_relationship_etc = "";
      }
      if(update.entry_form.form_plan == "0"){
        update.entry_form.form_kind = "";
      }
      update.error = false;
      update.send = false;
      store.$reset();
      return update;
    }
  },
  methods: {
    back: function(){
      this.$data.error = false;
      var store = entryStore();
      store.update = this.$data;
      this.$router.push({ path: "/mansion/newentry" });
    },
    push: async function(){
      this.$data.send = true;
      this.$data.error = false;
      var store = entryStore();
      try{
        await post(config.url.post.contract_all, {
          contractor: this.$data.contractor,
          payer: this.$data.payer,
          entry_form: this.$data.entry_form, 
          token: this.$data.onetime_token
        });
        store.update = this.$data;
        this.$router.push({ path: "/mansion/newentry_complete" });
        return false;
      }catch(ex){
        this.$data.send = false;
        this.$data.error = true;
        document.body.scrollIntoView();
      }
    },
  },
};
</script>
<style scoped src="../assets/css/style.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div v-show="finish" class="html not-front logged-in one-sidebar sidebar-first page-member page-member-procedure page-member-procedure-completepayment-method responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
            »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">
            »</span> <span class="inline odd last">お支払方法の変更手続き</span>
        </div>
        <section id="content">
          <h1 class="title">お支払方法の変更手続き</h1>
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_12 view-dom-id-e21676659357e17b5338a1848486e288">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset class="お支払方法の変更手続きを受け付けました。 views-fieldset" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend">お支払方法の変更手続きを受け付けました。</span>
                    </legend>

                    <div class="fieldset-wrapper">
                      <div class="views-field views-field-value-25"><strong class="views-label views-label-value-25">ご希望のお支払方法: </strong>
                        <span v-if="payment == 0" class="field-content">請求書（後払い.com）</span>
                        <span v-if="payment == 1" class="field-content">口座振替</span>
                        <span v-if="payment == 2" class="field-content">クレジットカード</span>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <p></p>
                <fieldset class="other views-fieldset" data-module="views_fieldsets">
                  <div class="fieldset-wrapper">
                    <div>
                      <span class="field-content">変更のお手続きが完了した翌月から、お選びいただいた支払方法に切り替わります。<br />※変更のお手続きには、 １～２ヶ月程度かかる場合がございます。</span>
                    </div>
                  </div>
                  <p></p>
                </fieldset>
                <div id="top_link"><router-link to="/mansion/member/profile">会員情報ページへ</router-link></div>
                <div id="top_link"><router-link to="/mansion/">マイページへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { paymentStore, paymentTimestamp } from "../stores/store.js";
import { post } from "../assets/js/request.js";
import config from "../config.js"
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    paymentStore().$reset();
    var store = paymentTimestamp();
    var payment = this.$route.query.payment;
    if(!payment){
      payment = 0;
    }
    var finish = false;
    var timestamp = this.$route.query.timestamp;
    if(timestamp == undefined || store.value.indexOf(timestamp) != -1){
      // 二重実行防止
      finish = true;
    }else{
      store.value.push(timestamp);
    }
    return {
      payment : payment,
      finish : finish
    }
  },
  async created() {
    if(this.$data.finish){
      history.replaceState('','','/mansion/change/payment');
      return;
    }
    var paymentMethod = "";
    if(this.$data.payment == 1){
      // 口座振替
      paymentMethod = "04002-0";
    }else if(this.$data.payment == 2){
      // クレジットカード
      paymentMethod = "04003-0";
    }else{
      this.$data.finish = true;
      history.replaceState('','','/mansion/change/payment');
      return;
    }
    await post(config.url.post.payment + "?paymentMethod=" + this.$data.payment, {
          payment: paymentMethod,
    })
    this.$data.finish = true;
    history.replaceState('','','/mansion/change/payment');
  }

};
</script>

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-change page-change-payment responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
            »</span> <span class="inline odd last">お申し込み処理に失敗しました</span>
        </div>
        <section id="content">
          <h1 class="title">お申し込み処理に失敗しました</h1>
          <div id="content-area">
            <article class="node node-page" data-nid="69">
              <header><span property="dc:title" content="お申し込み処理に失敗しました" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded">
                      <div class="error-message">
                        <div class="txt-message">
                          <p>
                            お申し込み処理に失敗しました。<br />
                            再度お申し込みをお願いいたします。<br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  }
};
</script>

<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <footer id="footer">
    <div class="container">
      <div class="block block-menu" data-bid="274">
        <ul class="menu">
          <li class="first leaf"><router-link to="/mansion/user_terms">サイトご利用にあたって</router-link></li>
          <li class="leaf"><router-link to="/mansion/User_clause">各種利用契約約款</router-link></li>
          <li class="leaf"><a href="https://rezil.co.jp/privacypolicy/" target="_blank">個人情報保護方針</a></li>
          <li class="leaf"><router-link to="/mansion/accessibility" target="_blank">アクセシビリティ</router-link></li>
          <li class="last leaf"><router-link to="/mansion/inquiry">お問い合わせ</router-link></li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-block footer-copyright" data-bid="287">
        <p class="copyright">© Rezil Inc.</p>
      </div>
      <!-- /block -->
    </div>
  </footer>
  <!-- /footer -->
</template>
<script>
import { init } from "../assets/js/responsive_menus_simple.js"
export default {
  name: "Footer",
  mounted: function () {
    this.$nextTick(
      function () {
        init();
      }.bind(this)
    );
  },
  watch: {
    $route () {
      init();
    }
  },
};
</script>
<style scoped src="../assets/css/style.css" />
<template>
  <div :class="'html not-front page-node page-node- page-node-175 node-type-page responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  <!-- ______________________ MAIN _______________________ -->
  <div id="main">
    <div class="container">
      <h2 class="element-invisible">現在地</h2>
      <div class="breadcrumb">
        <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">資料ダウンロード</span>
      </div>
      <section id="content">
        <h1 class="title">資料ダウンロード</h1>
        <div id="content-area">
          <article class="node node-page" data-nid="175">
            <header><span property="dc:title" content="資料ダウンロード" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
            <div class="content">
              <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                <div class="field-items">
                  <div class="field-item even" property="content:encoded">
                    <div class="static__inner">
                      <p class="static__text">本サイトから資料を閲覧・ダウンロードすることができます。</p>
                      <div class="clearfix">
                        <div class="static__half_colmn">
                          <p>ご利用ガイド</p>
                          <a :href="dir + '/guide.pdf'" target="_blank">
                            <img src="../assets/images/guide_PN_2_2016101.jpg" target="_blank" style="width:166px; height: 220px;"/>
                          </a>
                        </div>
                        <div class="static__half_colmn" style="margin-bottom: 50px">
                          <p>料金メニュー『ＬＬプラン』のご案内</p>
                          <a :href="dir + '/ll_plan_202212.pdf'" target="_blank">
                            <img src="../assets/images/ll_plan_202006.jpg" target="_blank" style="width:166px; height: 220px;"/>
                          </a>
                        </div>
                        <div class="static__half_colmn">
                          <p>でんき案内板のご紹介</p>
                          <a :href="dir + '/denki_pr.pdf'" target="_blank">
                            <img src="../assets/images/denki_pr.jpg" target="_blank" style="width:166px; height: 220px;" />
                          </a>
                        </div>
                        <div class="static__half_colmn">
                          <p>お支払いはクレジットカードがお勧め！</p>
                          <a :href="dir + '/creca_recommend_202212.pdf'" target="_blank">
                            <img src="../assets/images/creca_recommend_202002.jpg" target="_blank" style="width:166px; height: 220px;" />
                          </a>
                        </div>
                      </div>
                      <br />
                      <br />
                      <p class="static__text">PDF形式のファイルをご覧いただく場合には、Adobe Reader（R）が必要です。Adobe Readerをお持ちでない方は、リンク先からダウンロードしてください</p>
                      <p class="static__text">
                        <a href="https://get.adobe.com/jp/reader/" target="_blank">
                          <img src="../assets/images/ic_acrobat_reader.png" />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </div>
          </article>
          <!-- /article #node -->
        </div>
      </section>
      <!-- /content-inner /content -->
      <template v-if="isLogin">
        <Sidebar_first_login />
      </template>
    </div>
  </div>
  <!-- /main -->
  <Footer />
  </div>
</template>
<script>
import Header from '../components/Header.vue';
import Header_info from '../components/Header_info.vue';
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from '../components/Sidebar_first_login.vue';
import Footer from '../components/Footer.vue';
import config from "../config.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer
  },
  data(){
    return { 
      isLogin: isLogin(),
      dir : config.url.get.download
     }
  },
}
</script>
<style scoped src="../assets/css/style3.css" />
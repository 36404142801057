<template>
  <div class="html not-front not-logged-in no-sidebars page-user page-user-reset page-user-reset- responsive-menus-load-processed">
    <Header />
    <div class="messages error" v-if="password_num">
        <h2 class="element-invisible">エラーメッセージ</h2>
        パスワードは{{ password_num }}文字以上を指定してください。
    </div>
    <div class="messages error" v-if="error">
        <h2 class="element-invisible">エラーメッセージ</h2>
        URLの有効期限が過ぎているか、入力したURLに誤りがあります。
    </div>
    <div id="highlighted">
      <Header_info/>
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
      <!-- /block -->
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">パスワード変更</span>
        </div>
        <section id="content">
          <h1 class="title">パスワード変更</h1>
          <div id="content-area">
            <form enctype="multipart/form-data" action="#" method="post" id="user-profile-form" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div class="required-fields group-user-info field-group-fieldset">
                  <div id="edit-account" class="form-wrapper">
                    <div class="form-item form-type-password-confirm form-item-pass">
                      <div class="form-item form-type-password form-item-pass-pass1 password-parent">
                        <label for="edit-pass-pass1">パスワード <span class="form-required" title="これは入力必須項目です。">*</span></label>
                        <input required class="password-field form-text required password-processed" type="password" id="edit-pass-pass1" name="pass[pass1]" size="25" maxlength="128"  v-model="password1"/>
                      </div>
                      <div class="form-item form-type-password form-item-pass-pass2 confirm-parent">
                        <label for="edit-pass-pass2">パスワードの確認 <span class="form-required" title="これは入力必須項目です。">*</span></label>
                        <input required class="password-confirm form-text required" type="password" id="edit-pass-pass2" name="pass[pass2]" size="25" maxlength="128" v-model="password2"/>
                      </div>
                      <div class="description">現在のパスワードを変更したい場合は、両方のフィールドに新しいパスワードを入力してください。</div>
                    </div>
                  </div>
                </div>
                <div class="form-actions form-wrapper" id="edit-actions"><input type="submit" id="edit-submit" name="op" value="ログイン" class="form-submit" @click="check" :disabled="send"/></div>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import $ from "jquery";
import MEMS from "../assets/js/mems.js";
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { post, getData } from "../assets/js/request.js";
import { saveData, loginStore } from "../stores/store.js";
import { initPassword } from '../assets/js/mems_show_password';
import "../assets/js/user.js";
export default {
  components: {
    Header,
    Header_info,
    Footer,
  },
  data(){
    return {
      password1 : "",
      password2 : "",
      password_num: 0,
      error : 0,
      send : false
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      initPassword();
      MEMS.behaviors.password.attach(document, MEMS.settings);
      $('input.password-field').trigger('password');
      $("div.show_pass label").css("display", "inline-block");
    });
  },
  methods: {
    check: function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("[required]").forEach(function (input) {
        if (input.validity.valueMissing) {
          input.setCustomValidity("これは入力必須項目です。");
        }
      });
      document.querySelector("#edit-pass-pass2").setCustomValidity("");
      if(this.$data.password1 != this.$data.password2){
        document.querySelector("#edit-pass-pass2").setCustomValidity("入力されたパスワードが一致しませんでした。");
      }
    },
    push: async function () {
      try{
        var token = this.$route.query.token;
        this.$data.send = true;
        var response = await post(config.url.post.password , {
          newPassword : this.$data.password1,
          newPasswordReEnter : this.$data.password2,
          oneTimeToken : token
        });
        // [ { token, customer_id, last_login_datetime } ]
        if(response.token){
          saveData("x-token", response.token);
          saveData("last_login_datetime", response.last_login_datetime);
        }
        var store = loginStore();
        store.code = 0;
        var rooms = await getData(config.url.get.building);
        // [{customer_id, contractor_name, building_id, building_name, building_zip_postal_code, building_state, building_city, building_address_line, building_address_line_2,
        // room_id, room_number, room_type, room_note, form_move_date}]
        store.rooms = rooms;
        store.room = rooms[0];
        store.customer_id = rooms[0]["customer_id"];
        this.$router.push({path : "/mansion/login"});
      }catch(ex){
        if(ex.response.data.returnCode){
          if(ex.response.data.returnCode == 3){
            // 文字数
            this.$data.password_num = ex.response.data.num;
            this.$data.send = false;
          }else if(ex.response.data.returnCode == 4){
            // 認証期限切れ
            this.$data.error = 1;
            this.$data.send = false;
          }else{
            throw ex;
          }
        }else{
          throw ex;
        }
      }
    }
  }
};
</script>
<style>
@media screen and (max-width:768px){
	.confirm-parent, .password-parent,
	div.form-item div.password-suggestions {
		width: auto;
	}
	.page-user-reset .password-strength,
	.page-user-reset div.password-confirm {
		float: inherit !important;
		margin: 0;
		position: absolute;
		top: 6.3em !important;
		width: 100% !important;
	}
  .page-user-reset .show_pass {
    margin-bottom: 30px;
  }
	div.password-confirm {
		top: 4.5em;
	}
	div.password-suggestions ul {
		margin: 0;
	}
	#edit-field-contracts-notice #edit-field-contracts-notice-und,
	#edit-field-questionnaire-6 #edit-field-questionnaire-6-und {
		width: 100%;
	}
}
</style>

<style scoped src="../assets/css/ennevision_show_password.css" />
<style scoped src="../assets/css/style.css" />
<style scoped src="../assets/css/webform.css" />

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html front logged-in two-sidebars page-node responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->

    <div id="main">
      <div class="container">
        <div class="messages error" v-if="green == -1">
          <h2 class="element-invisible">エラーメッセージ</h2>
          いくつかキーワードを入力してください。
        </div>
        <section id="content">
          <div id="content-area">
            <div class="block block-ennevision-mypage" data-bid="259">
              <h3 class="title">今月の電気利用</h3>
              <form action="/mansion/" method="post" id="ennevision-mypage-monthly-usage-form" accept-charset="UTF-8">
                <div>
                  <div class="score">
                    <template v-if="newData.billing_year">
                      <h3 class="sttl">{{ newData.billing_year }}年{{ newData.billing_month }}月請求分({{ newData.usage_period_from }}～{{ newData.usage_period_to }})</h3>
                    </template>
                    <template v-else><h3 class="sttl">初回のお支払&nbsp;部屋の引渡日の翌月になります</h3></template>
                    <div class="price">
                      <dl>
                        <dt>電気料金</dt>
                        <router-link to="/mansion/claim" target="_blank" class="expanded-area">
                          <dd>
                            <span class="unit" v-if="newData.billing_amount == '-'">{{ newData.billing_amount }}</span>
                            <span class="unit" v-else>{{ newData.billing_amount.toLocaleString() }}</span>
                            <span class="unit">円</span>
                          </dd>
                        </router-link>
                        <router-link to="/mansion/claim" target="_blank" class="expanded-area expanded-area-unlink">
                          <dd><span class="expanded-area-display"></span><span class="expanded-area-link">料金明細一覧</span></dd>
                        </router-link>
                      </dl>
                    </div>
                    <div class="kwh">
                      <dl>
                        <dt>使用電力量</dt>
                        <dd>
                            <span class="unit" v-if="newData.free_field_5 == '-'">{{ newData.free_field_5 }}</span>
                            <span class="unit" v-else>{{ newData.free_field_5.toLocaleString() }}</span>
                          <span class="unit">kWh</span>
                        </dd>
                      </dl>
                    </div>
                    <div class="comparison">
                      <dl>
                        <dt>前月比 ({{ monthData.billing_year }}年{{ monthData.billing_month }}月請求分)</dt>
                        <dd>
                          <span :class="monthData.billing_amount_class">{{ monthData.billing_amount.toLocaleString() }}<span class="txt-nor">円</span></span>
                          /
                          <span :class="monthData.free_field_5_class">{{ monthData.free_field_5.toLocaleString() }}<span class="txt-nor">kwh</span></span>
                        </dd>
                      </dl>
                      <dl>
                        <dt>前年同月比 ({{ yearData.billing_year }}年{{ yearData.billing_month }}月請求分)</dt>
                        <dd>
                          <span :class="yearData.billing_amount_class">{{ yearData.billing_amount.toLocaleString() }}<span class="txt-nor">円</span></span>
                          /
                          <span :class="yearData.free_field_5_class">{{ yearData.free_field_5.toLocaleString() }}<span class="txt-nor">kwh</span></span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- /block -->
              <div class="block block-ennevision-graph" data-bid="367">
                <h3 class="title">直近7日間の使用電力量</h3>
                <form action="/mansion/" method="post" id="ennevision-graph-mypage-block-form" accept-charset="UTF-8">
                  <div>
                    <div class="comment">
                      <p>{{ comment }}</p>
                      <div id="container">
                        <div id="mainContent">
                          <div class="contentBox">
                            <div id="graphViewArea">
                              <!-- month tabContent -->
                              <div class="tabContent" id="month">
                                <div id="graphView">
                                  <canvas width="400px" height="260px"></canvas>
                                  <div class="loader" style="display: none"></div>
                                  <div class="inBox" style="display: none">
                                    <div class="inner">
                                      <div class="inner2">
                                        <dl class="power"></dl>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="plannning">
                      <p class="button">
                        <router-link to="/mansion/condition">もっと詳しく見る</router-link>
                      </p>
                    </div>
                    <!--
                <script type="text/template" id="selectComparisonTmpl"></script>
                -->
                  </div>
                </form>
              </div>
            <!-- /block -->
            <template v-if="drJoin">
              <div class="block block-views" data-bid="332" v-show="drJoin">
                <h3 class="title">節電協力状況</h3>
                <div class="view view-dr-point view-id-dr_point view-display-id-block_1 jquery-once-1-processed">
                  <div class="view-content">
                    <table class="views-table cols-4">
                      <thead>
                        <tr>
                          <th class="views-field views-field-php" scope="col">実施日</th>
                          <th class="views-field views-field-php-1" scope="col">開始時間</th>
                          <th class="views-field views-field-php-2" scope="col">終了時間</th>
                          <th class="views-field views-field-used-amount" scope="col">取得した節電ポイント</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr v-for="point in pages[current - 1]" :key="point">
                            <td class="views-field views-field-php">{{ point.endDay }}</td>
                            <td class="views-field views-field-php-1">{{ point.startTime }}</td>
                            <td class="views-field views-field-php-2">{{ point.endTime }}</td>
                            <td class="views-field views-field-used-amount">{{ point.point }}</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <h2 class="element-invisible">ページ</h2>

                  <div class="item-list">
                    <ul class="pager">
                      <template v-for="(p, index) in createPage()" :key="p">
                        <template v-if="index == 0 && 1 != current">
                          <li class="page-item">
                            <a href="javascript:void(0)" @click.prevent.stop="changePage(0)"> ≪ </a>
                          </li>
                          <li class="page-item">
                            <a href="javascript:void(0)" @click.prevent.stop="changePage((current - 2) * num)"> &lt; </a>
                          </li>
                        </template>
                        <template v-if="p + 1 == current">
                          <li class="pager-current">
                            {{ p + 1 }}
                          </li>
                        </template>
                        <template v-else>
                          <li class="page-item">
                            <a href="javascript:void(0)" @click.prevent.stop="changePage(p * num)" :title="p + 1 + 'ページへ'"> {{ p + 1 }}</a>
                          </li>
                        </template>
                        <template v-if="(index < 6 ? index == Math.ceil(pages.length / num) - 1 : index == 6) && current != Math.ceil(pages.length / num)">
                          <li class="page-item">
                            <a href="javascript:void(0)" @click.prevent.stop="changePage(current * num)"> &gt; </a>
                          </li>
                          <li class="page-item">
                            <a href="javascript:void(0)" @click.prevent.stop="changePage((Math.ceil(pages.length / num) - 1) * num)"> ≫ </a>
                          </li>
                        </template>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <!-- /block -->
          </div>
        </section>
        <Sidebar_second_login />
        <Sidebar_first_login />
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <!-- modal -->
    <div class="ui-widget-overlay ui-front" v-show="showExpirationDateContent" style="height:200%;width:100%;top:0;left:0;position: absolute"></div>
    <div class="dialog_creditcard_expire ui-dialog" v-show="showExpirationDateContent" style="background: white;width: 460px; padding: 10px;">
      <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
        <span class="ui-dialog-title">クレジットカードの有効期限が切れています</span><button class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close" v-on:click="closeExpirationDateModal()"><span class="ui-icon-closethick"></span></button>
      </div>
      <div id="creditcard_expire">ご登録のクレジットカード有効期限は「{{ "20" + expiration_year }}年{{ expiration_month }}月」までとなっています。お客様ご自身にて更新手続きが必要ですので、お手持ちのクレジットカードに記載の有効期限に速やかに更新願います。
        <br><br>※更新手続きの完了には3日程度かかる場合があります。すでに手続きがお済みの場合は「後で」をクリックしてください。
      </div>
      <div class="ui-dialog-buttonpane">
        <div class="ui-dialog-buttonset">
          <button class="btn-close ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" v-on:click="closeExpirationDateModal()">後で</button>
          <button class="btn-change ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
            <router-link to="/mansion/change/payment" style="color:white;text-decoration: none;">有効期限情報を更新する</router-link>
          </button>
        </div>
      </div>
    </div>
    <!-- /modal -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Sidebar_second_login from "../components/Sidebar_second_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { mainMessage } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
import { execute } from "../assets/js/app_top.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Sidebar_second_login,
    Footer,
  },
  data(){
    var message = mainMessage();
    var green = message.green;
    message.$reset();
    return {
      showExpirationDateContent: false, /* クレジットカード有効期限モーダルウィンドウ */
      green: green,
      newData: { 
        billing_year: "",
        billing_month: "",
        usage_period_from: "",
        usage_period_to: "",
        billing_amount: "-", 
        free_field_5: "-" 
      },
      monthData: { 
        billing_year: "",
        billing_month: "",
        billing_amount: "-", 
        free_field_5: "-",
        billing_amount_class: "",
        free_field_5_class: ""
      },
      yearData: { 
        billing_year: "",
        billing_month: "",
        billing_amount: "-", 
        free_field_5: "-",
        billing_amount_class: "",
        free_field_5_class: ""
      },
      expiration_month: "",
      expiration_year: "",
      drJoin: 0,
      comment: "電気の使用状況を、こまめにチェックしましょう！",
      current: 1,
      num: 1,
      pages: [[]],
    }
  },
  async created() {
    var customer = await getData(config.url.get.customer);
    // { dr }
    var payment = await getData(config.url.get.payment);
    // [{ payment, credit_card_number, credit_card_expiration_date, account_transfer_bank_name, account_transfer_branch_name, account_transfer_account_number, account_transfer_account_holder, account_transfer_sign }]
    // "credit_card_expiration_date" only use
    var details_info = await getData(config.url.get.details_info);
    // [{ billing_amount, usage_period_to, usage_period_from, free_field_5 }]
    var dr_info = await getData(config.url.get.dr_info);
    // [ dr : {dr_start_datetime, dr_end_datetime, dr_point}, comment ]
    var newData = undefined;
    var monthData = undefined;
    var yearData = undefined;
    var yearDate = undefined;
    var monthDate = undefined;
    for (var index in details_info) {
      var use_year = Number(details_info[index].usage_period_to.slice(0, 4));
      var use_month = Number(details_info[index].usage_period_to.slice(4, 6));
      var billing_date = new Date(use_year, use_month - 1, 1);
      billing_date.setMonth(billing_date.getMonth() + 1);
      details_info[index].billing_year = billing_date.getFullYear();
      details_info[index].billing_month = billing_date.getMonth() + 1;
      details_info[index].free_field_5 = Number(details_info[index].free_field_5);
      if (!newData) {
        newData = details_info[index];
        monthDate = new Date(use_year, use_month - 1, 1);
        yearDate = new Date(use_year - 1, use_month, 1);

        var to_year_string = details_info[index].usage_period_to.slice(0, 4);
        var to_month_string = details_info[index].usage_period_to.slice(4, 6);
        var to_day_string = details_info[index].usage_period_to.slice(6, 8);
        details_info[index].usage_period_to = to_year_string + "年" + to_month_string + "月" + to_day_string + "日";

        var from_year_string = details_info[index].usage_period_from.slice(0, 4);
        var from_month_string = details_info[index].usage_period_from.slice(4, 6);
        var from_day_string = details_info[index].usage_period_from.slice(6, 8);
        details_info[index].usage_period_from = from_year_string + "年" + from_month_string + "月" + from_day_string + "日";
        details_info[index].billing_amount = Number(details_info[index].billing_amount);
      } else if (billing_date.getFullYear() == monthDate.getFullYear() && billing_date.getMonth() == monthDate.getMonth()) {
        details_info[index].billing_amount = Number(details_info[index].billing_amount);
        monthData = details_info[index];
      } else if (billing_date.getFullYear() == yearDate.getFullYear() && billing_date.getMonth() == yearDate.getMonth()) {
        details_info[index].billing_amount = Number(details_info[index].billing_amount);
        yearData = details_info[index];
        break;
      }
    }
    var today = new Date();
    var expiration_month = undefined;
    var expiration_year = undefined;
    if(String(payment.payment).startsWith("04003-") && payment.credit_card_expiration_date != ""){
      expiration_month = Number(payment.credit_card_expiration_date.slice(0, 2));
      expiration_year = Number(payment.credit_card_expiration_date.slice(3, 5));
      if(((2000 + expiration_year) * 12 + expiration_month) < (today.getFullYear() * 12 + (today.getMonth() + 1))){
        this.showExpirationDateContent = true;
      }else{
        this.showExpirationDateContent = false;
      }
    }
    if (monthData == undefined) {
      var lastMonth = new Date();
      if(newData != undefined) {
        lastMonth = new Date(newData.billing_year, newData.billing_month - 1, 1);
      }
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      monthData = { billing_amount: "-", free_field_5: "-", billing_year: lastMonth.getFullYear(), billing_month: lastMonth.getMonth() + 1 };
    }
    if (yearData == undefined) {
      var lastYear = new Date();
      if(newData != undefined) {
        lastYear = new Date(newData.billing_year, newData.billing_month - 1, 1);
      }
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      yearData = { billing_amount: "-", free_field_5: "-", billing_year: lastYear.getFullYear(), billing_month: lastYear.getMonth() + 1 };
    }
    if (newData == undefined) {
      newData = { billing_amount: "-", free_field_5: "-" };
      monthData["billing_amount"] = "-";
      monthData["free_field_5"] = "-";
      lastYear["billing_amount"] = "-";
      lastYear["free_field_5"] = "-";
    }
    else
    {
      if(monthData["billing_amount"] != "-"){
        monthData["billing_amount"] = Number(monthData["billing_amount"]) - Number(newData["billing_amount"]);
      }
      if(monthData["free_field_5"] != "-"){
        monthData["free_field_5"] = Number(monthData["free_field_5"]) - Number(newData["free_field_5"]);
      }
      if(yearData["billing_amount"] != "-"){
        yearData["billing_amount"] = Number(yearData["billing_amount"]) - Number(newData["billing_amount"]);
      }
      if(yearData["free_field_5"] != "-"){
        yearData["free_field_5"] = Number(yearData["free_field_5"]) - Number(newData["free_field_5"]);
      }
    }
    monthData.billing_amount_class = monthData.billing_amount == '-' ? '' : 0 == monthData.billing_amount ? 'zero' : 0 > monthData.billing_amount ? 'up' : 'under';
    monthData.free_field_5_class = monthData.free_field_5 == '-' ? '' : 0 == monthData.free_field_5 ? 'zero' : 0 > monthData.free_field_5 ? 'up' : 'under';
    yearData.billing_amount_class = yearData.billing_amount == '-' ? '' : 0 == yearData.billing_amount ? 'zero' : 0 > yearData.billing_amount ? 'up' : 'under';
    yearData.free_field_5_class = yearData.free_field_5 == '-' ? '' : 0 == yearData.free_field_5 ? 'zero' : 0 > yearData.free_field_5 ? 'up' : 'under';
    if(monthData["billing_amount"] != "-"){
      monthData["billing_amount"] = Math.abs(monthData["billing_amount"]);
    }
    if(monthData["free_field_5"] != "-"){
      monthData["free_field_5"] = Math.abs(monthData["free_field_5"]);
    }
    if(yearData["billing_amount"] != "-"){
      yearData["billing_amount"] = Math.abs(yearData["billing_amount"]);
    }
    if(yearData["free_field_5"] != "-"){
      yearData["free_field_5"] = Math.abs(yearData["free_field_5"]);
    }

    var drJoin = customer.dr == 1;
    var dr_points = [];
    if (drJoin) {
      for (var dr in dr_info.dr) {
        var endDay = dr_info.dr[dr].dr_endtime.replaceAll("/", "");
        var year_string = endDay.slice(0, 4);
        var month_string = endDay.slice(4, 6);
        var day_string = endDay.slice(6, 8);
        endDay = year_string + "年" + month_string + "月" + day_string + "日";
        var endtime = dr_info.dr[dr].dr_endtime.slice(10, 16);
        endtime = endtime.replaceAll(":", "時");
        endtime = endtime + "分";
        var starttime = dr_info.dr[dr].dr_starttime.slice(10, 16);
        starttime = starttime.replaceAll(":", "時");
        starttime = starttime + "分";
        dr_points.push({
          endDay:  endDay,
          endTime: endtime,
          startTime: starttime,
          point: dr_info.dr[dr].dr_point
        });
      }
    }
    var comment = this.$data.comment;
    if(drJoin && dr_info.comment != ""){
      // DR期間内
      comment = dr_info.comment;
    }
    var pages = [];
    var td = new Array();
    pages.push(td);
    for (var p in dr_points) {
      if (td.length == 10) {
        td = new Array();
        pages.push(td);
      }
      td.push(dr_points[p]);
    }
    if(dr_info.dr.length == 0){
      drJoin = false;
    }
    Object.assign(this.$data, {
      newData: newData,
      monthData: monthData,
      yearData: yearData,
      expiration_month: expiration_month,
      expiration_year: expiration_year,
      drJoin: drJoin,
      comment: comment,
      pages: pages
    });
  },
  methods: {
    createPage: function () {
      var side = 3;
      var total = Math.ceil(this.$data.pages.length / this.$data.num) - 1;
      var index = this.$data.current - 1;
      var left = index - side;
      var right = index + side;
      if (left < 0) {
        right = index - left + side;
        left = 0;
      }
      if (right > total) {
        left = left - (right - total);
        right = total;
      }
      if (left < 0) {
        left = 0;
      }
      var pangeNum = [];
      for (var i = left; i <= right; i++) {
        pangeNum.push(i);
      }

      return pangeNum;
    },
    changePage: function (index) {
      this.current = index / this.num + 1;
    },
    closeExpirationDateModal() {
      this.showExpirationDateContent = false;
    },
  },
  watch: {
    '$route': function () {
      var message = mainMessage();
      var green = message.green;
      message.green = 0;
      this.$data.green = green;
    }
  },
  mounted: function () {
    this.$nextTick(
     function () {
      execute();
      }.bind(this)
    );
  }
};
</script>
<style scoped>
.inner {
	margin: 0 !important;
}
.dialog_creditcard_expire.ui-dialog{
  top:30%;
  left:30%;
}
@media screen and (max-width:768px){
  div#main {
    min-width: initial;
  }
  .dialog_creditcard_expire.ui-dialog{
    top:0;
    left:0;
  }
}
#main {
  min-width: 1080px;
}
</style>
<template>
    <div :class="'html not-front page-node node-type-webform responsive-menus-load-processed  ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even last"><router-link to="/mansion/inquiry">お問い合わせ</router-link></span> <span class="delimiter"> »</span> <span class="inline even last">お問い合わせ</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">お問い合わせ</h1>
          <!-- </div> /#content-header -->
          <div id="content-area">
            <article class="node node-page" data-nid="4269">
              <header><span property="dc:title" content="お問い合わせ" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <div class="field field-name-body field-type-text-with-summary field-label-hidden">
                  <div class="field-items">
                    <div class="field-item even" property="content:encoded">
                      <div class="static__inner">送信ありがとうございます。あなたの送信内容を受け付けました。<br /><br /></div>
                    </div>
                  </div>
                </div>
                <!-- /content -->
                <div class="links"><router-link to="/mansion/inquiry_document">入力画面に戻る</router-link></div>
                <!-- /links -->
              </div>
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data(){
    return { isLogin : isLogin() }
  }
};
</script>

import $ from "jquery"
export const selectView = function(){
    $('.selectArea').each(function(intdex, element){
        $(element).children(".selectTxt").text($(element).find("option:selected").text());
        $(element).children('.selectObj').on("change", function(){
            $(this).parent().children(".selectTxt").text($(this).find("option:selected").text());
        });
    })
}
//$(document).on("ready", selectView);
window.selectView = selectView;
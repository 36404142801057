import MEMS from './mems'
import $ from 'jquery'
/**
 * @file
 * Simple responsification of menus.
 */
export const init = function(){
  /**
   * Handle clicks & toggling the menu.
   */
  var toggler_click = function() {
    $(this).parent().toggleClass('responsive-toggled');
  };
  /**
   * Unbind other mouse events on the menu items.
   *
   * @todo
   *   Not sure if it works 100%.
   *   Doesn't restore binds when out-of-responsive (if window dragging).
   */
  function remove_mouse_events(menuElement) {
    $(menuElement).off('hover');
    $(menuElement + ' li').off('hover');
    $(menuElement + ' li a').off('hover');

    $(menuElement).off('mouseover mouseout mouseenter mouseleave');
    $(menuElement + ' li').off('mouseover mouseout mouseenter mouseleave');
    $(menuElement + ' li a').off('mouseover mouseout mouseenter mouseleave');
  }

  /**
   * Store classes & IDs for restoring later (if window dragging).
   */
  function store_classes_ids(menuElement) {
    if (!$(menuElement).attr('id')) {
      $(menuElement).attr('id', 'rm-no-id');
    }
    if (!$(menuElement).attr('class')) {
      $(menuElement).attr('class', 'rm-no-class');
    }
    $(menuElement).data('removeattr', true)
      .data('rmids', $(menuElement).attr('id'))
      .data('rmclasses', $(menuElement).attr('class'));
    // Handle ULs if selector is parent div.
    $(menuElement).find('ul').each(function() {
      // Prevent error if there is no id.
      if (!$(this).attr('id')) {
        $(this).attr('id', 'rm-no-id');
      }
      // Prevent error if there is no class.
      if (!$(this).attr('class')) {
        $(this).attr('class', 'rm-no-class');
      }
      $(this).data('removeattr', true)
        .data('rmids', $(this).attr('id'))
        .data('rmclasses', $(this).attr('class'));
    });
    // Finally, add our class to the parent.
    $(menuElement).addClass('responsive-menus-simple');
  }

  /**
   * Remove classes & IDs from original menu for easier theming.
   */
  function remove_classes_ids(menuElement) {
    // Handle ULs if selector is parent div.
    $(menuElement).find('ul').each(function() {
      $(this).attr('class', 'rm-removed').attr('id', 'rm-removed');
    });
    // Remove classes/IDs.
    $(menuElement).attr('class', 'responsive-menus-simple').attr('id', 'rm-removed');
  }

  // Iterate through selectors, check window sizes, add some classes.
  MEMS.behaviors.responsive_menus = {
    attach: function (context, settings) {
      settings.responsive_menus = settings.responsive_menus || {};
      $('body').on('responsive-menus-load', function() {
        $('body').off('responsive-menus-load');
        // Only doing this themes that don't include a viewport attribute.
        // e.g. Bartik for testing out-of-the-box... yeah, stupid.
        if (!$('meta[name=viewport]').length > 0) {
          $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0">');
        }
        // Window width with legacy browsers.
        var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $.each(settings.responsive_menus, function(ind, iteration) {
          if (iteration.responsive_menus_style != 'responsive_menus_simple') {
            return true;
          }
          if (!iteration.selectors.length) {
            return true;
          }
          var $media_unit = iteration.media_unit || 'px';
          var $media_size;
          if ($media_unit === 'em') {
            var $base_font_size = parseFloat($('body').css('font-size'));
            $media_size = iteration.media_size * $base_font_size || 768;
          }
          else {
            $media_size = iteration.media_size || 768;
          }

          // Handle clicks & toggling.
          var toggler_class = '';
          var toggler_text = iteration.toggler_text;
          // Iterate through our selectors.
          $.each(iteration.selectors, function(index, value) {
              // Single level menus.
              if (!$(value).hasClass('responsive-menus-simple')) {
                toggler_class = 'responsive-menus-' + ind + '-' + index;
                // Store classes & IDs before removing.
                if (iteration.remove_attributes) {
                  store_classes_ids(value);
                }
                $(value).wrap('<div data-mediasize="' + $media_size + '" class="responsive-menus ' + toggler_class + '" />');
                $('.' + toggler_class).prepend('<span class="toggler">' + toggler_text + '</span>');
                $('.' + toggler_class + ' .toggler').on('click', toggler_click);
                // Unbind other mouse events.
                if (iteration.disable_mouse_events) {
                  // @todo For rebinding mouse events.
                  /*if ($(value + ' li a').data('events')) {
                    $(value).data('tmpevents', $(value + ' li a').data('events'));
                  }*/
                  remove_mouse_events(value);
                }
                // Use absolute positioning.
                if (iteration.absolute) {
                  $('.' + toggler_class).addClass('absolute');
                }
                // Handle first size check.
                if (windowWidth <= $media_size) {
                  // Remove attributes setting.
                  if (iteration.remove_attributes) {
                    remove_classes_ids(value);
                  }
                  $('.' + toggler_class).addClass('responsified');
                }
              }
          });
       });
        // Handle window resizing.
        $(window).on('resize', function() {
          // Window width with legacy browsers.
          windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          $('.responsive-menus').each(function() {
            var mediasize = $(this).data('mediasize') || 768;
            // Prevent menu from going off the screen.  This only happens in
            // non-responsive themes (like Bartik default), but it looks bad.
            if ($(this).width() > windowWidth) {
              $(this).data('nonresponsive', true);
              $(this).width(windowWidth);
            }
            var menuElement = $(this).find('.responsive-menus-simple');
            if (windowWidth >= mediasize) {
              if (menuElement.data('removeattr')) {
                menuElement.addClass(menuElement.data('rmclasses'));
                menuElement.attr('id', menuElement.data('rmids'));
                menuElement.find('ul').each(function() {
                  $(this).addClass($(this).data('rmclasses'));
                  $(this).attr('id', $(this).data('rmids'));
                });
              }
              $(this).removeClass('responsified');
            }
            if (windowWidth <= mediasize) {
              // Now fix repercussions for handling non-responsive themes above.
              // Stretch width back out w/ the screen.
              if ($(this).data('nonresponsive') && $(this).width() < windowWidth) {
                $(this).width(windowWidth);
              }
              if (menuElement.data('removeattr')) {
                remove_classes_ids(menuElement);
              }
              $(this).addClass('responsified');
            }
          });
        });
      });
    }
  };
}
//$(document).on("ready", init);


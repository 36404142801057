import $ from "jquery";
import _ from "underscore";
import createjs from "createjs-module";
import Backbone from "backbone";
import "./select.js";
import config from "../../config.js";
import { loginStore, loadData } from "@/stores/store.js";
var BackboneSyncOrg = Backbone.sync;

var ennet = {};
ennet.Conf = {
  DEBUG: false, //デバッグモード有効
  imgBasePath: "/mansion/common/",
  getFPS: function () {
    var ua = window.navigator.userAgent;
    if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
      return 6;
    } else {
      return 30;
    }
  },
};

ennet.Conf.webRoot = (function () {
  var dirs = window.location.pathname.split("/");
  return "/" + dirs[1];
})();

ennet.Conf.ANIMATION_RESET_TIME = 5000;
/**
 * Utility
 */
ennet.Util = {
  /**
   * 数字を千位毎にグループ化してフォーマットする
   * @param {Number} num フォーマットする数値
   * @returns {String} numをフォーマットした結果
   */
  numberFormat: function (num) {
    var numStr = String(num);
    var intStr = String(parseInt(num));
    var indexOfdecimalPoint = numStr.indexOf(".");
    var afterTheDecimalPoint = "";

    if (indexOfdecimalPoint >= 0) {
      afterTheDecimalPoint = numStr.substring(indexOfdecimalPoint);
    }

    var formatStr = intStr.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

    return formatStr + afterTheDecimalPoint;
  },
  /**
   * 改行コードをbrタグに変換する
   * @param {String} str 変換する文字列
   * @returns {String} 変換した文字列
   */
  nlToBr: function (str) {
    return str.replace(/\r\n|\n|\r/g, "<br>");
  },
};
/**
 * GraphUtility
 */
ennet.GraphUtil = {
  /**
   * search max value in the array
   */
  maxInArray: function (array, key) {
    if (!array) {
      return 0;
    }
    var max = 0,
      value = 0;
    for (var i = 0; i < array.length; i++) {
      value = array[i][key];
      if (max < value) {
        max = value;
      }
    }
    return max;
  },
  /**
   * filter out 30min data object
   */
  filterOut30min: function (array, isHalfHour) {
    if (!array) {
      return [];
    }
    if (isHalfHour) {
      return array;
    } else {
      //json.dataの参照なので注意
      var newArray = [];
      $.extend(true, newArray, array);
      for (var i = 0; i < newArray.length - 1; i += 2) {
        newArray[i].rate = ennet.GraphUtil.roundDecimals(newArray[i].rate + newArray[i + 1].rate, 2);
        if ((newArray[i].status === "fail" && newArray[i + 1].status === "normal") || (newArray[i].status === "normal" && newArray[i + 1].status === "fail")) {
          newArray[i].status = "cover";
        }
      }
      return _.filter(newArray, function (d) {
        if (d.minute === 30) {
          return false;
        }
        return true;
      });
    }
  },
  /**
   * calculate graph max scale
   * @see http://d.hatena.ne.jp/fskkoba/20110805/1312535402
   */
  calculateScaleInfo: function (max) {
    var base = max * 1.05;
    var lg = Math.log(base) / Math.log(10);
    var bias = Math.pow(10, Math.floor(lg));
    var num = parseInt(base.toString().slice(0, 1), 10);
    var scale = num < 2 ? bias * 0.2 : num < 5 ? bias * 0.5 : bias;
    var scaleNum = Math.floor(base / scale) + 1;

    //最大値が１以下のとき
    // var n = scaleNum * scale;
    if (max <= 0.5 || max === 0) {
      return {
        max: 0.5,
        scaleNum: 5,
        scale: 0.1,
        labels: [0.5, 0.4, 0.3, 0.2, 0.1],
      };
    } else if (max <= 1.0) {
      return {
        max: 1,
        scaleNum: 4,
        scale: 0.25,
        labels: [1, 0.75, 0.5, 0.25],
      };
    }

    return {
      max: ennet.GraphUtil.roundDecimals(scaleNum * scale, 2),
      scaleNum: scaleNum,
      scale: ennet.GraphUtil.roundDecimals(scale, 2),
    };
  },
  /**
   * calculate Position
   */
  calculatePosition: function (array, key, scaleInfo, baseInfo) {
    if (!array) {
      return;
    }

    var obj;
    for (var i = 0; i < array.length; i++) {
      obj = array[i];

      if (scaleInfo.max !== 0) {
        obj.percent = obj[key] / scaleInfo.max;
      } else {
        obj.percent = 0;
      }
      ennet.GraphUtil.calculatePositionOne(obj, scaleInfo, baseInfo, i);
    }
  },
  calculatePositionOne: function (obj, scaleInfo, baseInfo, i) {
    obj.width = Math.floor(baseInfo.rowWidth * 0.5);
    obj.height = Math.floor(obj.percent * (baseInfo.baseY - baseInfo.maxY));
    obj.left = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth * 0.25); //bar left x
    obj.right = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth * 0.75); //bar right x
    obj.top = Math.floor(baseInfo.baseY - obj.height);
    obj.bottom = baseInfo.baseY;
  },
  calculatePercentage: function (array, key, max) {
    if (!array) {
      return;
    }

    for (var i = 0; i < array.length; i++) {
      array[i].percent = array[i][key] / max;
    }
  },
  /**
   * make text label
   */
  makeLabel: function (text, size, color, textAlign, x, y) {
    var textLabel = new createjs.Text(text, size + "px Arial", color);
    textLabel.textAlign = textAlign;
    textLabel.text = text;
    textLabel.x = x ? x : 0;
    textLabel.y = y ? y : 0;
    return textLabel;
  },
  makeRect: function (stage, padding) {
    return new createjs.Rectangle(padding.left, padding.top, stage.canvas.width - (padding.left + padding.right), stage.canvas.height - (padding.top + padding.bottom));
  },
  recursiveRemoveEvents: function (container) {
    var child;
    for (var i = 0; i < container.children.length; i++) {
      child = container.getChildAt(i);
      if (_.isUndefined(child.children)) {
        // console.log('before:'+child.hasEventListener('mouseover'));
        child.removeAllEventListeners();
        // console.log('after:'+child.hasEventListener('mouseover'));
      } else {
        ennet.GraphUtil.recursiveRemoveEvents(child);
      }
    }
  },
  /**
   * round Decimal
   */
  roundDecimals: function (num, digitNum) {
    var array = num.toString().split(".");
    if (array.length > 1) {
      var integer = array[0];
      var decimals = array[1];

      decimals = decimals.slice(0, digitNum);

      return (integer + "." + decimals) * 1;
    } else {
      return num;
    }
  },
  /**
   * 小数点第二位を四捨五入して小数点第一位にまるめる
   */
  roundFloat: function (num) {
    var array = num.toString().split(".");
    if (array.length > 1) {
      var integer = array[0];
      var decimals = array[1];

      var digitNum = decimals.length - 1;
      decimals = Math.round(parseInt(decimals, 10) * Math.pow(0.1, digitNum));

      return (integer + "." + decimals) * 1;
    } else {
      return num;
    }
  },
  /**
   * その月の日数を返す
   */
  getDaysInMonth: function (year, month) {
    return new Date(year, month, 0).getDate();
  },
  calculateWeekDays: function (year, month, total) {
    var weekdays = [],
      wid;
    for (var i = 1; i <= total; i++) {
      wid = new Date(year, month - 1, i).getDay();
      switch (wid) {
        case 0:
          weekdays.push("日");
          break;
        case 1:
          weekdays.push("月");
          break;
        case 2:
          weekdays.push("火");
          break;
        case 3:
          weekdays.push("水");
          break;
        case 4:
          weekdays.push("木");
          break;
        case 5:
          weekdays.push("金");
          break;
        case 6:
          weekdays.push("土");
          break;
      }
    }

    return weekdays;
  },
  makeBaseInfo: function (graphRect, total) {
    var baseInfo = {};
    baseInfo.rowWidth = graphRect.width / (total + 1); //include bar width and bar left/right padding
    baseInfo.baseX = Math.floor(graphRect.x + baseInfo.rowWidth * 0.5); //include left margin
    baseInfo.baseY = Math.floor(graphRect.y + graphRect.height);
    baseInfo.maxY = Math.floor(graphRect.y + 40); //upper padding for button
    return baseInfo;
  },
};
/**
 * Request class.
 */
ennet.Request = {};

const init1 = function () {

  ennet.Request = (function () {
    var parameters;

    function _initialize() {
      // initialize parameter
      parameters = [];
      var queryString = window.location.search.slice(1, window.location.search.length);
      var queries = queryString.split("&");

      for (var i = 0; i < queries.length; i++) {
        var keyAndValue = queries[i].split("=");

        parameters[keyAndValue[0]] = keyAndValue[1];
      }
    }

    function _getParameter(name, def) {
      return parameters[name] ? parameters[name] : def;
    }

    _initialize();

    return {
      getParameter: _getParameter,
    };
  })();

  var baseApp = (ennet.BaseApp = function () {
    this.initialize();
  });

  _.extend(baseApp.prototype, Backbone.Events, {
    initialize: function () {
      this.listenToOnce(this, "stop", this._stop);

      // 画面遷移によるajax中断→エラーページリダイレクトの回避
      var isUnload = true;

      $(document).on("click", "a", function (e) {
        isUnload = true;

        // 一部IEではhref="javascript:void(0)"などでもbeforeunloadイベントが発火する
        var href = $(e.currentTarget).attr("href");
        if (href.indexOf("javascript:;") !== -1 || href.indexOf("javascript:void(0)") !== -1) {
          isUnload = false;
        }
      });

      /**
       * 確認ダイアログを出す場合は選択結果による制御ができないので別の方法を検討
       * なおonunloadイベントでは間に合わない
       */
      $(window).on(
        "beforeunload",
        function () {
          if (isUnload) {
            this.trigger("stop");
          } else {
            isUnload = true;
          }
          // no return
        }.bind(this)
      );

      // Backbone.syncのオーバーライド
      //var BackboneSyncOrg = Backbone.sync;
      var app = this;

      Backbone.sync = function (method, model, addOptions) {
        var xhr;
        var options = {
          cache: false, //IEでキャッシュ回避用
          timeout: 180 * 1000,
          complete: function () {
            model.loadComplate();
            
            // 発火しないとイベント監視が重複していくのでstopする
            app.stopListening(model, "error", app.apiError);
            app.stopListening(app, "stop", xhr.abort);
          },
        };

        $.extend(true, options, addOptions);
        if(model.isLoading) { return;}
        model.loading();

        app.listenToOnce(model, "error", app.apiError);

        xhr = BackboneSyncOrg(method, model, options);

        app.listenToOnce(app, "stop", xhr.abort);

        return xhr;
      };
    },
    run: function () {
      this._preExecute();
      this._execute();
      this._postExecute();
    },
    _preExecute: function () {},
    _execute: function () {},
    _postExecute: function () {},
    _isStop: false,
    isStop: function () {
      return this._isStop;
    },
    _stop: function () {
      this._isStop = true;
    },
    apiError: function (object, response) {
      if (response.statusText === "abort") {
        return;
      } else if (response.status === 401) {
        return this.redirectLogin();
      } else if (response.status === 404) {
        return this.redirect404();
      }

      return this.redirect500();
    },
    redirect: function (url) {
      if (this.isStop()) {
        return;
      }

      this.trigger("stop");
      window.location.href = url;
    },
    redirectLogin: function () {},
    redirect404: function () {},
    redirect500: function () {},
  });

  // @see Backbone.js extend()
  baseApp.extend = function (protoProps, staticProps) {
    var parent = this;
    var child;

    // The constructor function for the new subclass is either defined by you
    // (the "constructor" property in your `extend` definition), or defaulted
    // by us to simply call the parent's constructor.
    if (protoProps && _.has(protoProps, "constructor")) {
      child = protoProps.constructor;
    } else {
      child = function () {
        return parent.apply(this, arguments);
      };
    }

    // Add static properties to the constructor function, if supplied.
    _.extend(child, parent, staticProps);

    // Set the prototype chain to inherit from `parent`, without calling
    // `parent`'s constructor function.
    var Surrogate = function () {
      this.constructor = child;
    };
    Surrogate.prototype = parent.prototype;
    child.prototype = new Surrogate();

    // Add prototype properties (instance properties) to the subclass,
    // if supplied.
    if (protoProps) {
      _.extend(child.prototype, protoProps);
    }

    // Set a convenience property in case the parent's prototype is needed
    // later.
    child.__super__ = parent.prototype;

    return child;
  };
};
//$(document).on("ready", init1);

ennet.ResourceLoader = {
  resources: {},
  load: function (list, callback) {
    if (!list || !callback) {
      throw new Error("ERROR:need 2 arguments.");
    }

    this.callback = callback;
    this.count = 0;
    this.max = list.length;

    var img;
    for (var i = 0; i < list.length; i++) {
      img = document.createElement("img");
      img.onload = this.countUp.bind(this);
      img.src = list[i];
      this.resources[list[i]] = img;
    }
  },
  countUp: function () {
    this.count++;
    if (this.count === this.max) {
      this.callback();
    }
  },
};

ennet.GraphDrawer = {
  drawDayBackground: function (vo) {
    var mc = new createjs.MovieClip();
    var graphics = new createjs.Graphics();
    var shape = new createjs.Shape(graphics);

    var graphRect = vo.getGraphRect();
    var baseInfo = vo.getBaseInfo();
    var scaleInfo = vo.getScaleInfo();
    var demmand = vo.getDemmand();
    var total = vo.getTotal();
    var values = vo.getSelectValues();
    var tou = vo.getTou();
    //var touType     = vo.getTouType();

    shape.name = "content";
    mc.addChild(shape);

    ennet.GraphDrawer.drawHourlyBackground(mc, tou, baseInfo, graphRect);
    ennet.GraphDrawer.drawDayHorizontalScale(mc, baseInfo, graphRect, total);
    ennet.GraphDrawer.drawVerticalScale(mc, baseInfo, scaleInfo, graphRect, demmand);
    if (demmand.length > 0 && values.type !== "co2") {
      ennet.GraphDrawer.drawDayDemmand(mc, baseInfo, graphRect, demmand);
    }

    return mc;
  },

  drawBaseLine: function (vo) {
    var mc = new createjs.MovieClip();
    var graphics = new createjs.Graphics();
    var shape = new createjs.Shape(graphics);

    var graphRect = vo.getGraphRect();
    var baseInfo = vo.getBaseInfo();
    var scaleInfo = vo.getScaleInfo();
    var demmand = vo.getDemmand();
    //var total       = vo.getTotal();
    //var values      = vo.getSelectValues();
    //var tou         = vo.getTou();
    //var touType     = vo.getTouType();

    shape.name = "content";
    mc.addChild(shape);

    ennet.GraphDrawer.drawVerticalScaleBaseLine(mc, baseInfo, scaleInfo, graphRect, demmand);

    return mc;
  },

  drawMonthBackground: function (vo) {
    var mc = new createjs.MovieClip();
    var graphics = new createjs.Graphics();
    var shape = new createjs.Shape(graphics);

    var graphRect = vo.getGraphRect();
    var baseInfo = vo.getBaseInfo();
    var scaleInfo = vo.getScaleInfo();
    var demmand = [];
    var data = vo.getBarGraph();
    var total = vo.getTotal();
    var weekdays = vo.getWeekDays();

    shape.name = "content";
    mc.addChild(shape);

    ennet.GraphDrawer.drawMonthHorizontalScale(mc, baseInfo, graphRect, data, total, weekdays);
    ennet.GraphDrawer.drawVerticalScale(mc, baseInfo, scaleInfo, graphRect, demmand);

    return mc;
  },

  drawYearBackground: function (vo) {
    var mc = new createjs.MovieClip();
    var graphics = new createjs.Graphics();
    var shape = new createjs.Shape(graphics);

    var graphRect = vo.getGraphRect();
    var baseInfo = vo.getBaseInfo();
    var scaleInfo = vo.getScaleInfo();
    var demmand = [];
    var data = vo.getBarGraph();
    var total = vo.getTotal();

    shape.name = "content";
    mc.addChild(shape);

    ennet.GraphDrawer.drawYearHorizontalScale(mc, baseInfo, graphRect, data, total);
    ennet.GraphDrawer.drawVerticalScale(mc, baseInfo, scaleInfo, graphRect, demmand);

    return mc;
  },
  drawBackground: function (mc, graphRect, color) {
    var graphics = mc.getChildByName("content").graphics;

    graphics.beginFill(color).drawRect(graphRect.x, graphRect.y, graphRect.width, graphRect.height).endFill();
  },
  drawHourlyBackground: function (mc, tous, baseInfo, graphRect) {
    var graphics = mc.getChildByName("content").graphics;
    var tou, color, w, h, pX1, pX2;
    // var rowWidth = Math.floor((graphRect.width)/total);
    for (var i = 0; i < tous.length; i++) {
      tou = tous[i];

      if (tou.type === 1) {
        color = "#fdf6d9";
      } else if (tou.type === 2) {
        color = "#feec96";
      } else if (tou.type === 3) {
        color = "#fbcf4e";
      }

      // percent = demmand[index].percent;
      h = Math.floor(baseInfo.baseY);
      pX1 = Math.floor(baseInfo.rowWidth + graphRect.x + baseInfo.rowWidth * tou.startIndex); //bar left x
      pX2 = Math.floor(baseInfo.rowWidth + graphRect.x + baseInfo.rowWidth * tou.endIndex); //bar right x
      w = Math.floor(pX2 - pX1);
      //draw color
      graphics.beginFill(color).drawRect(pX1, 0, w, h).endFill();
    }
  },
  drawDayHorizontalScale: function (mc, baseInfo, graphRect, total) {
    var strokeWidth = 2;
    var graphics = mc.getChildByName("content").graphics;

    // horizontal axis line
    graphics.setStrokeStyle(strokeWidth);
    graphics.beginStroke("#ababa9");
    graphics.moveTo(graphRect.x, graphRect.y + graphRect.height + strokeWidth / 2).lineTo(graphRect.x + graphRect.width, graphRect.y + graphRect.height + strokeWidth / 2);

    // horizontal axis scale label
    // h=hour
    var text, h;
    if (total === 24) {
      for (h = 0; h < total; h++) {
        text = ennet.GraphUtil.makeLabel(h, 12, "#5c5c5c", "left", 0, baseInfo.baseY + 4);
        text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * h + baseInfo.rowWidth / 2 - text.getMeasuredWidth() / 2);
        mc.addChild(text);
      }
    } else {
      for (h = 0; h < total; h++) {
        if (h % 2 === 0) {
          text = ennet.GraphUtil.makeLabel(h / 2, 12, "#5c5c5c", "left", 0, baseInfo.baseY + 4);
          text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * h + baseInfo.rowWidth / 2 - text.getMeasuredWidth() / 2);
          mc.addChild(text);
        }
      }
    }
  },
  drawMonthHorizontalScale: function (mc, baseInfo, graphRect, data, total, weekdays) {
    var strokeWidth = 2;
    var graphics = mc.getChildByName("content").graphics;

    // horizontal axis line
    graphics.setStrokeStyle(strokeWidth);
    graphics.beginStroke("#ababa9");
    graphics.moveTo(graphRect.x, graphRect.y + graphRect.height + strokeWidth / 2).lineTo(graphRect.x + graphRect.width, graphRect.y + graphRect.height + strokeWidth / 2);

    // horizontal axis scale label
    var text, i;
    for (i = 0; i < total; i++) {
      //make label
      text = ennet.GraphUtil.makeLabel(i + 1, 12, "#5c5c5c", "left", 0, baseInfo.baseY + 4);
      text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth / 2 - text.getMeasuredWidth() / 2);
      mc.addChild(text);

      //make weekday
      text = ennet.GraphUtil.makeLabel(weekdays[i], 12, "#5c5c5c", "left", 0, baseInfo.baseY + 16);
      text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth / 2 - text.getMeasuredWidth() / 2);
      mc.addChild(text);
    }
  },
  drawYearHorizontalScale: function (mc, baseInfo, graphRect, data, total) {
    var strokeWidth = 2;
    var graphics = mc.getChildByName("content").graphics;

    // horizontal axis line
    graphics.setStrokeStyle(strokeWidth);
    graphics.beginStroke("#ababa9");
    graphics.moveTo(graphRect.x, graphRect.y + graphRect.height + strokeWidth / 2).lineTo(graphRect.x + graphRect.width, graphRect.y + graphRect.height + strokeWidth / 2);

    // horizontal axis scale label
    var text, i;
    var year;
    // var pad;
    var image = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath + "/icon_achivement.png"];
    var bitmap;
    var dt;
    for (i = 0; i < total; i++) {
      dt = new Date();
      dt.setDate(1);
      dt.setMonth(dt.getMonth() - total + i + 1);
      if (i == 0 || dt.getMonth() + 1 == 1) {
        year = "\r\n" + dt.getFullYear() + "年";
        // pad = 0;
      } else {
        year = "";
        // pad = baseInfo.rowWidth/2 - text.getMeasuredWidth()/2;
      }
      //make label
      text = ennet.GraphUtil.makeLabel(dt.getMonth() + 1 + "月" + year, 12, "#5c5c5c", "left", 0, baseInfo.baseY + 4);

      text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + 10);
      mc.addChild(text);

      //make achivement
      if (data[i] && data[i].achivement) {
        bitmap = new createjs.Bitmap(image);
        bitmap.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth / 2 - 8); //8=bitmap.width
        bitmap.y = baseInfo.baseY + 4 + 12;
        mc.addChild(bitmap);
      }
    }
  },
  drawVerticalScale: function (mc, baseInfo, scaleInfo, graphRect) {
    var graphics = mc.getChildByName("content").graphics;

    //vertical axis scale
    graphics.setStrokeStyle(1);
    graphics.beginStroke("#c8c8c8");

    /**
     * dashedLineTo extend createjs.Graphics prototype
     * @see Util.js
     */
    var y, text, labels, i;
    if (scaleInfo.labels) {
      labels = scaleInfo.labels;
    } else {
      labels = [];
      for (i = scaleInfo.max; i > 0; i = ennet.GraphUtil.roundFloat(i - scaleInfo.scale)) {
        labels.push(i);
      }
    }
    var label;
    for (i = 0; i < labels.length; i++) {
      label = labels[i];
      y = baseInfo.maxY + ((scaleInfo.max - label) * (baseInfo.baseY - baseInfo.maxY)) / scaleInfo.max;

      //draw dashed line
      dashedLineTo(graphics, graphRect.x, y, graphRect.x + graphRect.width, y, 2);

      text = ennet.GraphUtil.makeLabel(label.toLocaleString(), 12, "#5c5c5c", "left");
      text.x = 0;
      text.y = Math.floor(y - text.getMeasuredHeight() / 2);
      mc.addChild(text);
    }
  },
  drawVerticalScaleBaseLine: function (mc, baseInfo, scaleInfo, graphRect, demmand) {
    var graphics = mc.getChildByName("content").graphics;

    for (var demmand_index = 0; demmand_index < demmand.length; demmand_index++) {
      var current_demmand = demmand[demmand_index];

      // DR基準値を描画
      graphics.setStrokeStyle(3);
      graphics.beginStroke("#00ff00");
      var start_x, start_y, end_x, end_y;

      for (var time_index = 0; time_index < current_demmand.baseLine.length; time_index++) {
        var current_baseLine = current_demmand.baseLine[time_index];
        var next_baseLine = current_demmand.baseLine[time_index + 1];
        var x;
        if (current_demmand.isHalfHour) {
          x = Math.floor(current_baseLine["key"] / 50) + 1;
          if (current_baseLine["key"] % 100 > 0) {
            x++;
          }
        } else {
          x = Math.floor(current_baseLine["key"] / 100) + 1;
        }
        start_y = baseInfo.maxY + ((scaleInfo.max - current_baseLine["value"]) * (baseInfo.baseY - baseInfo.maxY)) / scaleInfo.max;
        start_x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * x - baseInfo.rowWidth / 2);

        if (next_baseLine) {
          end_y = baseInfo.maxY + ((scaleInfo.max - next_baseLine["value"]) * (baseInfo.baseY - baseInfo.maxY)) / scaleInfo.max;
          end_x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * (x + 1) - baseInfo.rowWidth / 2);
        } else {
          // DR期間中最終コマに対しては、水平で長さ0.5のベースライン線を描画する
          end_y = baseInfo.maxY + ((scaleInfo.max - current_baseLine["value"]) * (baseInfo.baseY - baseInfo.maxY)) / scaleInfo.max;
          end_x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * (x + 0.5) - baseInfo.rowWidth / 2);
        }
        dashedLineTo(graphics, start_x, start_y, end_x, end_y, 3);
      }

      graphics.setStrokeStyle(1);
      graphics.beginStroke("#c8c8c8");
    }
  },
  drawBarGraph: function (data) {
    if (!data) {
      return;
    }

    var mc = new createjs.MovieClip();

    //local variables
    var graphic, shape, wrapper;

    //draw bar
    var obj;
    var missingImg = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath + "/graph_missing_complement.png"];

    for (var index = 0; index < data.length; index++) {
      obj = data[index];

      if (obj.status === "fail") {
        var icon = new createjs.Bitmap(ennet.Conf.imgBasePath + "/icon_missing.png");
        icon.x = Math.floor(obj.left + obj.width / 2) - 7; //7=icon.width/2
        icon.y = obj.bottom - 14 - 5; //14=icon.height,5=marginBottom
        mc.addChild(icon);
      } else {
        //draw color
        graphic = new createjs.Graphics();
        if (obj.status === "cover") {
          graphic.beginBitmapFill(missingImg, "repeat");
        } else {
          graphic.lf(["#f54514", "#f54514"], [1, 0], 0, 0, 0, obj.height);
        }
        graphic.drawRoundRectComplex(0, 0, obj.width, obj.height, 2, 2, 0, 0).endFill();

        shape = new createjs.Shape(graphic);
        shape.x = -obj.width / 2;
        shape.y = -obj.height;

        //package movieclip
        wrapper = new createjs.MovieClip();
        wrapper.addChild(shape);
        wrapper.scaleY = 0;
        wrapper.x = Math.floor(obj.left + obj.width / 2);
        wrapper.y = obj.bottom;
        // wrapper.data = data[index];
        // wrapper.top = pY1;

        mc.addChild(wrapper);
        createjs.Tween.get(wrapper)
          .wait(index * 50)
          .to(
            {
              scaleY: 1,
            },
            200,
            createjs.Ease.quadOut()
          );
      }
    }

    return mc;
  },
  drawMixingBarGraph: function (data) {
    if (!data) {
      return;
    }

    var mc = new createjs.MovieClip();

    //local variables
    var graphic, shape, wrapper;

    //draw bar
    var obj;
    var missingImg = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath + "/graph_missing_complement.png"];
    for (var index = 0; index < data.length; index++) {
      obj = data[index];

      if (obj.status === "fail") {
        var icon = new createjs.Bitmap(ennet.Conf.imgBasePath + "/icon_missing.png");
        icon.x = Math.floor(obj.left + obj.width / 2) - 7; //7=icon.width/2
        icon.y = obj.bottom - 14 - 5; //14=icon.height,5=marginBottom
        mc.addChild(icon);
      } else {
        graphic = new createjs.Graphics();
        if (obj.status === "cover") {
          graphic.beginBitmapFill(missingImg, "repeat").drawRoundRectComplex(0, 0, obj.width, obj.height, 2, 2, 0, 0).endFill();
        } else {
          //draw color
          var totalY = 0,
            tou,
            height,
            cnt = 0;

          if (obj.tou_type === "basic") {
            for (var i = 0; i <= obj.tou.length - 1; i++) {
              tou = obj.tou[i];
              height = obj.height * tou.percent * 0.01;
              if (tou.type === 0) {
                graphic.lf(["#77bb2e", "#77bb2e"], [1, 0], 0, 0, 0, obj.height);
              } else if (tou.type === 1) {
                cnt++;
                graphic.lf(["#61abc9", "#61abc9"], [1, 0], 0, 0, 0, obj.height);
              } else if (tou.type === 2) {
                cnt++;
                graphic.lf(["#eaab40", "#eaab40"], [1, 0], 0, 0, 0, obj.height);
              } else if (tou.type === 3) {
                cnt++;
                graphic.lf(["#ed819b", "#ed819b"], [1, 0], 0, 0, 0, obj.height);
              }
              if (cnt > 1) {
                totalY--;
              }

              graphic.drawRoundRectComplex(0, totalY, obj.width, height, 2, 2, 0, 0).endFill();
              totalY += height;
            }
          } else {
            graphic.lf(["#77bb2e", "#77bb2e"], [1, 0], 0, 0, 0, obj.height);
            graphic.drawRoundRectComplex(0, 0, obj.width, obj.height, 2, 2, 0, 0).endFill();
          }
        }

        shape = new createjs.Shape(graphic);
        shape.x = -obj.width / 2;
        shape.y = -obj.height;

        //package movieclip
        wrapper = new createjs.MovieClip();
        wrapper.addChild(shape);
        wrapper.scaleY = 0;
        wrapper.x = Math.floor(obj.left + obj.width / 2);
        wrapper.y = obj.bottom;

        mc.addChild(wrapper);

        createjs.Tween.get(wrapper)
          .wait(index * 50)
          .to(
            {
              scaleY: 1,
            },
            200,
            createjs.Ease.quadOut()
          );
      }
    }

    return mc;
  },
  drawBlueLineGraph: function (data) {
    return ennet.GraphDrawer.drawLineGraph(
      data,
      {
        outer: "#5b8cd1",
        inner: ["#ffffff", "#ffffff"],
      },
      {
        stroke: "#5b8cd1",
      }
    );
  },
  drawGreenLineGraph: function (data) {
    return ennet.GraphDrawer.drawLineGraph(
      data,
      {
        outer: "#ffde87",
        inner: ["#ffb034", "#c47f41"],
      },
      {
        stroke: "#f8cc7a",
      }
    );
  },
  drawLineGraph: function (data, circleColor, lineColor) {
    if (!data) {
      return;
    }

    var mc = new createjs.MovieClip();

    //--animation container
    var container = new createjs.MovieClip();
    mc.addChild(container);

    var line = new createjs.Graphics();
    mc.addChild(new createjs.Shape(line));

    var circle = new createjs.Graphics();
    //draw outer circle
    circle.beginFill(circleColor.outer).drawCircle(5, 5, 5).endFill();
    //draw inner circle
    circle.beginLinearGradientFill(circleColor.inner, [0, 1], 0, 0, 10, 10).drawCircle(5, 5, 3).endFill();

    //local variables
    var cx,
      cy,
      shape,
      points = [];
    var interval = 200;
    var wait = 50;

    var changePoints = function () {
      line.clear();
      line.setStrokeStyle(3, "round").beginStroke(lineColor.stroke);

      // var point;
      for (var index = 0; index < points.length; index++) {
        // point = points[index];

        if (index === 0) {
          line.moveTo(points[index].x, points[index].y);
        } else {
          line.lineTo(points[index].x, points[index].y);
        }
      }
    };

    //draw line and circle
    var obj;
    for (var index = 0; index < data.length; index++) {
      obj = data[index];

      cx = Math.floor(obj.left + obj.width / 2);
      cy = obj.top;

      points[index] = {
        x: cx,
        y: obj.bottom,
        h: obj.bottom - obj.top,
        wait: (index * wait) / createjs.Ticker.interval,
        end: false,
      };

      shape = new createjs.Shape(circle);
      shape.x = cx - 5;
      shape.y = obj.bottom - 5;
      mc.addChild(shape);

      createjs.Tween.get(shape)
        .wait(index * wait)
        .to(
          {
            y: cy - 5,
          },
          interval,
          createjs.Ease.quadOut()
        );

      createjs.Tween.get(points[index], {
        onChange: changePoints,
      })
        .wait(index * wait)
        .to(
          {
            y: cy,
          },
          interval,
          createjs.Ease.quadOut()
        );
    }

    return mc;
  },

  drawHitarea: function (vo, overCallback, outCallback, clickCallback) {
    var data = vo.getBarGraph();
    var baseInfo = vo.getBaseInfo();

    if (!data) {
      return;
    }

    var mc = new createjs.MovieClip();

    //local variables
    var graphic, shape;
    var pX1, w, h, barH, barTop;

    //draw bar
    var percent;
    for (var index = 0; index < data.length; index++) {
      percent = data[index].percent;

      w = Math.floor(baseInfo.rowWidth);
      h = Math.floor(baseInfo.baseY);
      pX1 = Math.floor(baseInfo.baseX + baseInfo.rowWidth * index); //bar left x
      // pY1     = Math.floor(baseInfo.baseY - h);
      barH = Math.floor(percent * (baseInfo.baseY - baseInfo.maxY));
      barTop = Math.floor(baseInfo.baseY - barH);

      //draw color
      graphic = new createjs.Graphics();
      graphic.beginFill("rgba(0,0,0,0.01)").drawRect(0, 0, w, -h).endFill();

      shape = new createjs.Shape(graphic);
      shape.x = pX1;
      shape.y = baseInfo.baseY;
      shape.data = data[index];
      shape.left = Math.floor(pX1 + w / 2);
      shape.top = barTop;
      if (vo.getYear) {
        shape.year = vo.getYear();
      }
      if (vo.getMonth) {
        shape.month = vo.getMonth();
      }

      mc.addChild(shape);

      shape.addEventListener("mouseover", overCallback);
      shape.addEventListener("mouseout", outCallback);
      if (clickCallback) {
        shape.addEventListener("click", clickCallback);
      }
    }

    return mc;
  },

  drawDayDemmand: function (mc, baseInfo, graphRect, demmand) {
    //local variables
    var graphic, shape;
    var img = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath + "/graph_dr.png"];

    var pX1, pX2, w, h;

    //draw bar
    var obj;
    for (var index = 0; index < demmand.length; index++) {
      obj = demmand[index];

      h = Math.floor(baseInfo.baseY);
      pX1 = Math.floor(baseInfo.baseX + baseInfo.rowWidth * obj.startIndex + baseInfo.rowWidth / 2); //bar left x
      pX2 = Math.floor(baseInfo.baseX + baseInfo.rowWidth * obj.endIndex + baseInfo.rowWidth / 2); //bar right x
      w = Math.floor(pX2 - pX1);

      //draw color
      graphic = new createjs.Graphics();
      graphic.beginBitmapFill(img, "repeat").drawRect(0, 0, w, -h).endFill();

      shape = new createjs.Shape(graphic);
      shape.x = pX1;
      shape.y = baseInfo.baseY;

      mc.addChild(shape);
    }
  },
  drawMonthDemmand: function (data) {
    if (!data) {
      return;
    }

    var mc = new createjs.MovieClip();
    var img = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath + "/icon_dr.png"];

    //draw bar
    var obj, wrapper, bmp;
    for (var index = 0; index < data.length; index++) {
      obj = data[index];

      var offsetY = 0;
      if (obj.status === "fail") {
        offsetY = -20;
      }
      if (obj.demmand && obj.demmand.recommend) {
        //draw color
        bmp = new createjs.Bitmap(img);
        bmp.x = -14; //14 = bmp.width/2
        bmp.y = -30; //30=bmp.height;
        wrapper = new createjs.MovieClip();
        wrapper.addChild(bmp);
        wrapper.x = Math.ceil(obj.left + obj.width / 2);
        wrapper.y = Math.ceil(obj.top - 3 + offsetY); //3=marginBottom
        wrapper.scaleY = 0;
        createjs.Tween.get(wrapper)
          .wait(index * 50)
          .to(
            {
              scaleY: 1,
            },
            200,
            createjs.Ease.quadOut()
          );
        mc.addChild(wrapper);
      }
    }

    return mc;
  },
  drawBar: function (mc, obj, color, radius, options) {
    var option = options || {};

    var graphic, shape, wrapper;
    graphic = new createjs.Graphics();
    graphic.lf(color, [1, 0], 0, 0, 0, obj.height).drawRoundRectComplex(0, 0, obj.width, obj.height, radius, radius, 0, 0).endFill();

    shape = new createjs.Shape(graphic);
    shape.x = obj.left;
    shape.y = obj.top;
    if (option.filters) {
      shape.filters = options.filters;
      shape.cache(-30, -30, obj.width + 60, obj.height + 60);
    }

    //package movieclip
    wrapper = new createjs.MovieClip();
    wrapper.addChild(shape);
    wrapper.scaleY = 0;
    wrapper.x = Math.floor(obj.left + obj.width / 2);
    wrapper.y = obj.bottom;

    // wrapper.data = data[index];
    // wrapper.top = pY1;

    mc.addChild(shape);

    // createjs.Tween.get(wrapper).to({
    //     scaleY:1
    // },200,createjs.Ease.quadOut());
  },
  drawLastLabel: function (data, layer, labelColor) {
    var container = new createjs.MovieClip();
    var graphics = new createjs.Graphics();
    var shape = new createjs.Shape(graphics);
    container.addChild(shape);

    var lastObject = data[data.length - 1];
    var posX = lastObject.left + lastObject.width / 2;
    var posY = lastObject.top;
    var text = ennet.Util.numberFormat(lastObject.price) + "円";

    //背景描画（白枠）
    var r = 1;
    var width = 70;
    var height = 20;
    graphics.beginFill("#ffffff").drawRoundRect(0, 0, width, height, r).endFill();

    //label
    var label = ennet.GraphUtil.makeLabel(text, 11, labelColor, "left", 0, 0);
    label.x = width / 2 - label.getMeasuredWidth() / 2;
    label.y = height / 2 - label.getMeasuredHeight() / 2;
    container.addChild(label);

    var diff = lastObject.diff.flag ? -20 : 20;
    container.x = posX + 20;
    container.y = posY - height / 2 + diff;

    layer.addChild(container);

    container.alpha = 0;
    createjs.Tween.get(container)
      .wait(data.length * 50)
      .to(
        {
          alpha: 1,
        },
        200,
        createjs.Ease.quadOut()
      );
  },
};

/**
 * 点線描画拡張
 */
export const dashedLineTo = function (graphics, x1, y1, x2, y2, dashLen) {
  graphics.moveTo(x1, y1);

  var dX = x2 - x1;
  var dY = y2 - y1;
  var dashes = Math.floor(Math.sqrt(dX * dX + dY * dY) / dashLen);
  var dashX = dX / dashes;
  var dashY = dY / dashes;

  var q = 0;
  while (q++ < dashes) {
    x1 += dashX;
    y1 += dashY;
    graphics[q % 2 === 0 ? "moveTo" : "lineTo"](Math.floor(x1), Math.floor(y1));
  }
  graphics[q % 2 === 0 ? "moveTo" : "lineTo"](x2, y2);
};
function setHeader(xhr) {
  var token = loadData("token");
  var xtoken = loadData("x-token");
  if(token){
      xhr.setRequestHeader('Authorization', token);
  }
  if(xtoken){
      xhr.setRequestHeader('Token', xtoken);
  }
  var login = loginStore();
  xhr.setRequestHeader('Id', login.customer_id);
}

/**
 * User model.
 */
ennet.UserModel = Backbone.Model.extend({
  isLoading: false,
  urlRoot: config.url.get.user,
  loading: function(){},
  loadComplate: function(){},
  defaults: {
    price_menu_name: null, // 値が無い場合項目自体が消えてしまうので初期化
  },
  targetLevelUnset: "unset",
  targetLevelEasy: "easy",
  targetLevelNormal: "normal",
  targetLevelHard: "hard",
  touTypeBasic: "basic", // 三段料金
  touTypeTou: "tou", // 季節別料金
  isTargetLevel: function (targetLevel) {
    return this.get("target_level") === targetLevel;
  },
  isTouType: function (type) {
    return this.get("tou_type") === type;
  },
  /**
   * 月途中からの利用
   *
   * この月は料金タイプが取得できないので、それで判断する。
   */
  isUseFromMidlleOfMonth: function () {
    return this.get("tou_type") === null || this.get("tou_type") === undefined;
  },
  isJoiningDRService: function () {
    return this.get("dr_service_flag");
  },
  parse: function (response) {
    // 利用日の日付のみを抽出（時間は不要とのこと ENE_QA-163）
    response.start_date = response.start_date.substr(0, 10);
    response.end_date = response.end_date.substr(0, 10);

    response.start_date = new Date(response.start_date.replace(/-/g, "/"));
    response.end_date = new Date(response.end_date.replace(/-/g, "/"));

    // 互換性確保
    response.startDate = response.start_date;
    response.endDate = response.end_date;
    return response;
  },
  fetch: function (addOptions) {
    var options = {
      beforeSend : setHeader
    };

    if (ennet.Request.getParameter("u")) {
      options.data.u = ennet.Request.getParameter("u");
    }

    $.extend(true, options, addOptions);

    return Backbone.Model.prototype.fetch.call(this, options);
  },
  toJSON: function () {
    var json = Backbone.Model.prototype.toJSON.call(this);

    json.is_use_from_midlle_of_month = this.isUseFromMidlleOfMonth();

    // alias
    json.has_ranking_for_last_month = json.game_ranking_history_flag;

    return json;
  },
});

ennet.UserView = Backbone.View.extend({
  el: "#sideState #userState",
  initialize: function () {
    this.listenTo(this.model, "change", this.render);
  },
  render: function () {
    //this.$el.template(this.model.toJSON());
  },
});
const init2 = function () {
  ennet.MemberApp = ennet.BaseApp.extend({
    initialize: function () {
      ennet.BaseApp.prototype.initialize.call(this);

      this._user = new ennet.UserModel();
    },
  });
};
//$(document).on("ready", init2);

ennet.DayGraphVO = function () {
  this.initialize.apply(this, arguments);
};
ennet.DayGraphVO.prototype = {
  barGraph_: null,
  lineGraph_: null,
  scaleInfo_: null,
  baseInfo_: null,
  demmand_: null,
  total_: 0,
  selectValue_: null,
  graphRect_: null,
  tou_: null,
  touType_: null,
  initialize: function () {},
  getBarGraph: function () {
    return this.barGraph_;
  },
  setBarGraph: function (data) {
    this.barGraph_ = data;
  },
  getLineGraph: function () {
    return this.lineGraph_;
  },
  setLineGraph: function (data) {
    this.lineGraph_ = data;
  },
  getScaleInfo: function () {
    return this.scaleInfo_;
  },
  setScaleInfo: function (scaleInfo) {
    this.scaleInfo_ = scaleInfo;
  },
  getBaseInfo: function () {
    return this.baseInfo_;
  },
  setBaseInfo: function (baseInfo) {
    this.baseInfo_ = baseInfo;
  },
  getDemmand: function () {
    return this.demmand_;
  },
  setDemmand: function (demmand) {
    this.demmand_ = demmand;
  },
  getTotal: function () {
    return this.total_;
  },
  setTotal: function (total) {
    this.total_ = total;
  },
  getSelectValues: function () {
    return this.selectValues_;
  },
  setSelectValues: function (values) {
    this.selectValues_ = values;
  },
  getGraphRect: function () {
    return this.graphRect_;
  },
  setGraphRect: function (graphRect) {
    this.graphRect_ = graphRect;
  },
  getTou: function () {
    return this.tou_;
  },
  setTou: function (tou) {
    this.tou_ = tou;
  },
  getTouType: function () {
    return this.touType_;
  },
  setTouType: function (touType) {
    this.touType_ = touType;
  },
};

/**
 * DayGraph model
 */
ennet.DayGraphModel = Backbone.Model.extend({
  isLoading: false,
  tabView : null,
  urlRoot: config.url.get.day,
  loading: function(){
    this.isLoading = true;
    this.tabView.loading();
  },
  loadComplate: function(){
    this.isLoading = false;
    this.tabView.loadComplate();
  },
  parse: function (response) {
    response.date = new Date(response.year + "/" + response.month + "/" + response.day);

    // parse memo
    // var date = new Date(response.year+'/'+response.month+'/'+response.day);
    return response;
  },
  fetch: function (year, month, day, addOptions) {
    var options = {
      data : {},
      beforeSend : setHeader
    };

    // 渡されたパラメータを設定（一部でも）
    if (year) {
      options.data.year = year;
    }

    if (month) {
      options.data.month = month;
    }

    if (day) {
      options.data.day = day;
    }

    if (ennet.Request.getParameter("u")) {
      options.data.u = ennet.Request.getParameter("u");
    }

    if (addOptions) {
      $.extend(true, options, addOptions);
    }

    return Backbone.Model.prototype.fetch.call(this, options);
  },
  getGraphVO: function (isHalfHour, compare, type, isHourly, graphRect) {
    var vo = new ennet.DayGraphVO();
    var json = this.toJSON();
    var barGraph = null,
      lineGraph = null;

    //1h or halfHour
    barGraph = ennet.GraphUtil.filterOut30min(json.data, isHalfHour);
    lineGraph = ennet.GraphUtil.filterOut30min(json[compare].data, isHalfHour);

    if (json.demmand.length > 0) {
      this.adjustDemmandTime(json.demmand, isHalfHour);
    }

    //max
    var max = Math.max(ennet.GraphUtil.maxInArray(barGraph, type), ennet.GraphUtil.maxInArray(lineGraph, type));
    if (typeof json.demmand[0] !== "undefined") {
      for (var i = 0; i < json.demmand.length; i++) {
        const dr_info = json.demmand[i];
        max = Math.max(max, ennet.GraphUtil.maxInArray(dr_info.baseLine, "value"));
      }
    }

    //scaled max
    var scaleInfo = ennet.GraphUtil.calculateScaleInfo(max);

    var total = isHalfHour ? 48 : 24;
    /**
     * a bar width rate
     *      |////|
     *      |////|
     *      |////|
     *  --------------
     *   25%|50% |25%
     *   6px|12px|6px
     */
    var baseInfo = {};
    baseInfo.rowWidth = graphRect.width / (total + 1); //include bar width and bar left/right padding
    baseInfo.baseX = Math.floor(graphRect.x + baseInfo.rowWidth * 0.5); //include left margin
    baseInfo.baseY = Math.floor(graphRect.y + graphRect.height);
    baseInfo.maxY = Math.floor(graphRect.y + 40); //upper padding for button

    ennet.GraphUtil.calculatePosition(barGraph, type, scaleInfo, baseInfo);
    ennet.GraphUtil.calculatePosition(lineGraph, type, scaleInfo, baseInfo);

    if (json.tou.length > 1) {
      this.adjustTouTime(json.tou, isHalfHour);
    }

    vo.setBarGraph(barGraph);
    vo.setLineGraph(lineGraph);
    vo.setScaleInfo(scaleInfo);
    vo.setBaseInfo(baseInfo);
    vo.setDemmand(json.demmand);
    vo.setTotal(total);
    vo.setSelectValues({
      isHalfHour: isHalfHour,
      compare: compare,
      type: type,
      isHourly: isHourly,
    });
    vo.setGraphRect(graphRect);
    vo.setTou(json.tou);
    vo.setTouType(json.tou_type);

    return vo;
  },
  adjustDemmandTime: function (data, isHalfHour) {
    var i;
    if (isHalfHour) {
      //30分
      for (i = 0; i < data.length; i++) {
        data[i].startIndex = data[i].start_time.hour * 2 + Math.floor(data[i].start_time.minute / 30);
        data[i].endIndex = data[i].end_time.hour * 2 + Math.floor(data[i].end_time.minute / 30);
        if (data[i].base_line) {
          data[i].baseLine = data[i].base_line["30m"];
        } else {
          data[i].baseLine = [];
        }
        data[i].isHalfHour = true;
      }
    } else {
      //1時間
      for (i = 0; i < data.length; i++) {
        data[i].startIndex = data[i].start_time.hour;
        data[i].endIndex = data[i].end_time.hour;
        if (data[i].startIndex === data[i].endIndex && data[i].end_time.minute === 30) {
          data[i].endIndex++;
        }
        if (data[i].base_line) {
          data[i].baseLine = data[i].base_line["1h"];
        } else {
          data[i].baseLine = [];
        }
        data[i].isHalfHour = false;
      }
    }
  },
  /**
   * DRの24/48での値調整
   * @param  {[type]}  data       [description]
   * @param  {Boolean} isHalfHour [description]
   * @return {[type]}             [description]
   */
  adjustTouTime: function (data, isHalfHour) {
    var i;
    if (isHalfHour) {
      for (i = 0; i < data.length; i++) {
        if (data[i].start_time.hour === 0) {
          data[i].startIndex = -1;
        } else {
          data[i].startIndex = data[i].start_time.hour * 2 + Math.floor(data[i].start_time.minute / 30);
        }
        data[i].endIndex = data[i].end_time.hour * 2 + Math.floor(data[i].end_time.minute / 30);
      }
    } else {
      for (i = 0; i < data.length; i++) {
        if (data[i].start_time.hour === 0) {
          data[i].startIndex = -1;
        } else {
          data[i].startIndex = data[i].start_time.hour;
        }
        data[i].endIndex = data[i].end_time.hour;
      }
    }
  },
});

ennet.DayGraphController = Backbone.View.extend({
  initialize: function (options) {
    this.stage = options.stage;

    // popup
    this.$popup = this.$el.parent().find(".inBox.info");
    this.hidePopup();
  },
  update: function (vo) {
    this.vo = vo;

    this.render();
  },
  render: function () {
    // first initialize stage
    ennet.GraphUtil.recursiveRemoveEvents(this.stage);
    this.stage.removeAllChildren();

    //this.drawBackground();
    this.stage.addChild(ennet.GraphDrawer.drawDayBackground(this.vo));

    // draw graph
    this.stage.addChild(ennet.GraphDrawer.drawBarGraph(this.vo.getBarGraph()));

    this.stage.addChild(ennet.GraphDrawer.drawBlueLineGraph(this.vo.getLineGraph()));

    this.stage.addChild(ennet.GraphDrawer.drawBaseLine(this.vo));

    // make hitarea
    this.stage.addChild(ennet.GraphDrawer.drawHitarea(this.vo, this.onMouseOver.bind(this), this.onMouseOut.bind(this)));

    this.showDRPopup();

    return this; //needs backbone
  },
  onMouseOver: function (event) {
    // this.stage.canvas.style.cursor = 'pointer';
    this.setContentPopup(event.currentTarget);
    this.showPopup();
  },
  onMouseOut: function () {
    // this.stage.canvas.style.cursor = 'default';
    this.hidePopup();
  },
  showPopup: function () {
    this.$popup.delay(100).show();
  },
  setContentPopup: function (mc) {
    if (this.vo.getSelectValues().type === "rate") {
      var rate = "--";
      if (mc.data.rate) {
        rate = mc.data.rate;
      }
      this.$popup.find(".inner").html("使用電力量：" + rate + "kWh");
    }

    var posX = mc.left - this.$popup.width() / 2;
    var posY = mc.top - this.$popup.height() - 10; //10 = bias

    this.$popup.css({ top: posY + "px", left: posX + "px" });
  },
  hidePopup: function () {
    this.$popup.hide();
  },
  showDRPopup: function () {
    var $caution = this.$el.parent().find(".inBox.drCaution");
    var $alert = this.$el.parent().find(".inBox.moderation");
    if (this.vo.getDemmand()[0] && this.vo.getSelectValues().type !== "co2") {
      // var duration    = 6000;
      var demmand = this.vo.getDemmand()[0];
      var baseInfo = this.vo.getBaseInfo();

      var left = Math.floor(baseInfo.baseX + baseInfo.rowWidth * demmand.endIndex);
      var top = demmand.top - $caution.height() + 12; //8=adjust

      $caution.css({ top: top + "px", left: left + 6 + "px" }).fadeIn(); //4=margin

      var index = demmand.startIndex + (demmand.endIndex - demmand.startIndex) / 2;
      left = Math.floor(baseInfo.baseX + baseInfo.rowWidth * index - $alert.width() / 2 - 5); //3=adjust
      top = -Math.floor($alert.height() / 2);

      $alert.css({ top: "0px", left: "50px" }).fadeIn(); //4=margin;
    } else {
      $caution.stop(true, true).hide();
      $alert.stop(true, true).hide();
    }
  },
});
/**
 * [initialize description]
 */
ennet.DayGraphView = Backbone.View.extend({
  el: "#day",
  events: {
    "change #unit select": "onChangeType",
    "change #time select": "onChangeTime",
    "change .compare select": "onChangeCompare",
    "change #userid": "onChangeDisplay",
  },
  initialize: function () {
    //setting value from outer control
    //1h or half hour
    this.isHalfHour = this.isHalfHourByHtml();
    //kWh or CO2
    this.type = this.getTypeByHtml();

    //時間帯別料金変更
    this.isHourly = this.isHourlyByHtml();

    //init stage
    this.stage = new createjs.Stage(this.$el.find("#graphView canvas").get(0));
    this.stage.enableMouseOver(10); //10 times per second

    this.graphRect = ennet.GraphUtil.makeRect(this.stage, {
      left: 50,
      top: 0,
      right: 60,
      bottom: 20,
    });

    this.graph = new ennet.DayGraphController({
      el: this.stage.canvas,
      stage: this.stage,
    });

    this.listenTo(this.model, "request", this.startLoading);
    this.listenTo(this.model, "sync", this.onSuccess);

    var template = "<li><dl><dt id='dayBill' class='txtColor1 txtS' style='margin:0'>使用電力量</dt><dd class='today txtColor1'><span class='txtXL'><%- power.user_average_rate %></span><span class='txtXS'>kwh</span></dd></dl></li><li><dl><dt class='txtColor2 txtXS' style='margin:0'>みんなの平均使用電力量</dt><dd class='all txtColor2'><span class='txtS'><%- power.all_average_rate %></span><span class='txtXS'>kwh</span></dd></dl></li>";
    this.amoutUseTmpl = _.template(template); // _.template($("#dayAmoutUseTmpl").html());
  },
  startLoading: function () {
    this.$el.find("#graphView .loader").show();
  },
  onSuccess: function () {
    this.$el.find("#graphView .loader").hide();
    this.render();
  },
  render: function () {
    //last_year or all
    this.compare = this.getCompareByHtml();
    //draw graph
    this.renderGraph();

    var json = this.model.toJSON();

    this.$el.find(".amountUse").html("");
    this.$el.find(".amountUse").append(this.amoutUseTmpl(json));

    if (json.tou_type === "tou") {
      this.$el.find(".priceCheck").css("display", "block");

      var $ul = this.$el.find(".priceCheck dd ul");
      $ul.html("");
      var lis = [];
      //touは重複して格納されている
      var l1 = false,
        l2 = false,
        l3 = false;
      for (var i = 0; i < json.tou.length; i++) {
        var $li = $("<li>");
        if (json.tou[i].type == 1 && !l1) {
          $li.addClass("priceLevel1").text(json.tou[i].price_per_hour + "円/kWh");
          l1 = true;
          lis[0] = $li;
        } else if (json.tou[i].type == 2 && !l2) {
          $li.addClass("priceLevel2").text(json.tou[i].price_per_hour + "円/kWh");
          lis[1] = $li;
          l2 = true;
        } else if (json.tou[i].type == 3 && !l3) {
          $li.addClass("priceLevel3").text(json.tou[i].price_per_hour + "円/kWh");
          lis[2] = $li;
          l3 = true;
        }
      }
      var li;
      for (i = 0; i < 3; i++) {
        li = lis[i];
        if (li) {
          $ul.append(li);
        }
      }
    } else if (json.tou_type === "basic") {
      this.$el.find(".priceCheck").css("display", "none");
    }

    return this;
  },
  renderGraph: function () {
    this.trigger("startRender");
    
    var vo = this.model.getGraphVO(this.isHalfHour, this.compare, this.type, this.isHourly, this.graphRect);
    this.graph.update(vo);
  },
  showDRPopup: function () {},
  onChangeType: function (event) {
    var select = event.currentTarget;

    this.type = this.getTypeByHtml(select);

    this.render();
  },
  onChangeTime: function (event) {
    var select = event.currentTarget;

    this.isHalfHour = this.isHalfHourByHtml(select);

    this.render();
  },
  onChangeCompare: function (event) {
    var select = event.currentTarget;

    this.compare = this.getCompareByHtml(select);

    this.render();
  },
  /**
   * 時間帯別料金変更
   */
  onChangeDisplay: function (event) {
    this.isHourly = this.isHourlyByHtml(event.currentTarget);

    this.render();
  },
  isHalfHourByHtml: function (target) {
    var val;
    if (target) {
      val = target.value;
    } else {
      val = $("#day #time select").val();
    }

    if (val === "時") {
      return false;
    } else if (val === "30分") {
      return true;
    }
    return null;
  },
  getTypeByHtml: function (target) {
    var val;
    if (target) {
      val = target.value;
    } else {
      val = $("#day #unit select").val();
    }

    if (val === "kWh") {
      return "rate";
    }
    return null;
  },
  getCompareByHtml: function (target) {
    var val;

    if (target) {
      val = target.value;
    } else {
      val = this.$el.find(".compare select").val();
    }

    if (val !== "last_year" && val !== "all") {
      val = null;
    }

    return val;
  },
  isHourlyByHtml: function (target) {
    if (target) {
      return $(target).is(":checked");
    } else {
      return $("#day #userid").is(":checked");
    }
  },
});

/**
 * [DayGraphVO description]
 */
ennet.MonthGraphVO = function () {
  this.initialize.apply(this, arguments);
};
ennet.MonthGraphVO.prototype = {
  barGraph_: null,
  lineGraph_: null,
  scaleInfo_: null,
  baseInfo_: null,
  total_: 0,
  selectValue_: null,
  graphRect_: null,
  weekdays_: null,
  year_: null,
  month: null,
  initialize: function () {},
  getBarGraph: function () {
    return this.barGraph_;
  },
  setBarGraph: function (data) {
    this.barGraph_ = data;
  },
  getLineGraph: function () {
    return this.lineGraph_;
  },
  setLineGraph: function (data) {
    this.lineGraph_ = data;
  },
  getScaleInfo: function () {
    return this.scaleInfo_;
  },
  setScaleInfo: function (scaleInfo) {
    this.scaleInfo_ = scaleInfo;
  },
  getBaseInfo: function () {
    return this.baseInfo_;
  },
  setBaseInfo: function (baseInfo) {
    this.baseInfo_ = baseInfo;
  },
  getTotal: function () {
    return this.total_;
  },
  setTotal: function (total) {
    this.total_ = total;
  },
  getSelectValues: function () {
    return this.selectValues_;
  },
  setSelectValues: function (values) {
    this.selectValues_ = values;
  },
  getGraphRect: function () {
    return this.graphRect_;
  },
  setGraphRect: function (graphRect) {
    this.graphRect_ = graphRect;
  },
  getWeekDays: function () {
    return this.weekdays_;
  },
  setWeekDays: function (weekdays) {
    this.weekdays_ = weekdays;
  },
  getYear: function () {
    return this.year_;
  },
  setYear: function (year) {
    this.year_ = year;
  },
  getMonth: function () {
    return this.month_;
  },
  setMonth: function (month) {
    this.month_ = month;
  },
};

/**
 * MonthGraph model
 */
ennet.MonthGraphModel = Backbone.Model.extend({
  isLoading: false,
  tabView : null,
  urlRoot: config.url.get.month,
  loading: function(){
    this.isLoading = true;
    this.tabView.loading();
  },
  loadComplate: function(){
    this.isLoading = false;
    this.tabView.loadComplate();
  },
  parse: function (response) {
    //var year = response.year;
    //var month = response.month;

    return response;
  },
  fetch: function (year, month, addOptions) {
    var options = {
      data : {},
      beforeSend : setHeader
    };

    // 渡されたパラメータを設定（一部でも）
    if (year) {
      options.data.year = year;
    }

    if (month) {
      options.data.month = month;
    }

    if (ennet.Request.getParameter("u")) {
      options.data.u = ennet.Request.getParameter("u");
    }

    if (addOptions) {
      $.extend(true, options, addOptions);
    }

    return Backbone.Model.prototype.fetch.call(this, options);
  },
  getGraphVO: function (compare, type, graphRect) {
    var vo = new ennet.MonthGraphVO();
    var barGraph = null,
      lineGraph = null;
    var json = this.toJSON();

    if (json.is_fixed) {
      // 確定金額の場合、円の選択しを無効化し、kWhを選択状態とする
      this.type = type = "rate";
      //          document.getElementById("month_yen").style.display="none";
      var options = document.getElementById("month_data_select").options;
      for (var i = 0; i < options.length; i++) {
        if (options[i].text === "kWh") {
          options[i].selected = true;
        } else {
          document.getElementById("month_data_select").options[i] = null;
          i--;
        }
      }
      document.getElementById("month_data_select_txt").innerHTML = "kWh";
    } else {
      //          document.getElementById("month_yen").style.display="block";
      if (!document.getElementById("month_yen")) {
        var option = document.createElement("option");
        option.setAttribute("id", "month_yen");
        option.setAttribute("value", "円");
        option.innerHTML = "円";
        document.getElementById("month_data_select").add(option, document.getElementById("month_data_select").options[0]);
      }
    }

    //1h or halfHour
    barGraph = json.data ? json.data : null;
    lineGraph = json[compare] && json[compare].data ? json[compare].data : null;

    var total = ennet.GraphUtil.getDaysInMonth(this.get("year"), this.get("month"));
    barGraph = this.fixGapBarGraph(barGraph, total);
    lineGraph = this.fixGapLineGraph(lineGraph, total);

    //max
    var max = Math.max(ennet.GraphUtil.maxInArray(barGraph, type), ennet.GraphUtil.maxInArray(lineGraph, type));

    //scaled max
    var scaleInfo = ennet.GraphUtil.calculateScaleInfo(max);
    var weekdays = ennet.GraphUtil.calculateWeekDays(this.get("year"), this.get("month"), total);

    /**
     * a bar width rate
     *      |////|
     *      |////|
     *      |////|
     *  --------------
     *  33% |33% |33%
     *  18px|18px|18px
     */
    var baseInfo = {};
    baseInfo.rowWidth = graphRect.width / (total + 1); //include bar width and bar left/right padding
    baseInfo.baseX = Math.floor(graphRect.x + baseInfo.rowWidth * 0.5); //include left margin
    baseInfo.baseY = Math.floor(graphRect.y + graphRect.height);
    baseInfo.maxY = Math.floor(graphRect.y + 40); //upper padding for button

    ennet.GraphUtil.calculatePosition(barGraph, type, scaleInfo, baseInfo);
    ennet.GraphUtil.calculatePosition(lineGraph, type, scaleInfo, baseInfo);

    vo.setBarGraph(barGraph);
    vo.setLineGraph(lineGraph);
    vo.setScaleInfo(scaleInfo);
    vo.setBaseInfo(baseInfo);
    vo.setTotal(total);
    vo.setSelectValues({
      compare: compare,
      type: type,
    });
    vo.setGraphRect(graphRect);
    vo.setWeekDays(weekdays);
    vo.setYear(this.get("year"));
    vo.setMonth(this.get("month"));

    return vo;
  },
  fixGapBarGraph: function (array, total) {
    return this.fixGapArray(array, total, {
      price: 0,
      rate: 0,
    });
  },
  fixGapLineGraph: function (array, total) {
    return this.fixGapArray(array, total, {
      price: 0,
      rate: 0,
    });
  },
  fixGapArray: function (array, total, emptyObject) {
    if (array.length === 0) {
      return array;
    }

    var fixedArray = [],
      flag,
      counter = 0;
    for (var i = 1; i <= total; i++) {
      flag = false;
      for (var j = 0; j < array.length; j++) {
        if (i === array[j].day) {
          counter++;
          flag = true;
          fixedArray.push(array[j]);
          break;
        }
      }
      if (!flag && counter === 0) {
        var clone = _.clone(emptyObject);
        clone.day = i;
        fixedArray.push(clone);
      }
    }
    return fixedArray;
  },
  setYear: function (year) {
    this.year = year;
  },
  setMonth: function (month) {
    this.month = month;
  },
});

ennet.MonthGraphController = Backbone.View.extend({
  initialize: function (options) {
    this.stage = options.stage;

    // popup
    this.$popup = this.$el.parent().find(".inBox");
    this.hidePopup();
  },
  update: function (vo) {
    this.vo = vo;

    this.render();
  },
  render: function () {
    // first initialize stage
    ennet.GraphUtil.recursiveRemoveEvents(this.stage);
    this.stage.removeAllChildren();

    //this.drawBackground();
    this.stage.addChild(ennet.GraphDrawer.drawMonthBackground(this.vo));

    // draw graph
    var layer;
    if (this.vo.getSelectValues().type === "price") {
      layer = ennet.GraphDrawer.drawMixingBarGraph(this.vo.getBarGraph());
    } else {
      layer = ennet.GraphDrawer.drawBarGraph(this.vo.getBarGraph());
    }
    this.stage.addChild(layer);

    this.stage.addChild(ennet.GraphDrawer.drawBlueLineGraph(this.vo.getLineGraph()));

    this.stage.addChild(ennet.GraphDrawer.drawMonthDemmand(this.vo.getBarGraph()));

    // make hitarea
    this.stage.addChild(ennet.GraphDrawer.drawHitarea(this.vo, this.onMouseOver.bind(this), this.onMouseOut.bind(this), this.onClick.bind(this)));

    return this; //needs backbone
  },
  onMouseOver: function (event) {
    // this.stage.canvas.style.cursor = 'pointer';
    this.setContentPopup(event.currentTarget);
    this.showPopup();
  },
  onMouseOut: function () {
    // this.stage.canvas.style.cursor = 'default';
    this.hidePopup();
  },
  onClick: function (event) {
    var mc = event.currentTarget;

    this.trigger("click_graph_bar", mc.year, mc.month, mc.data.day);
  },
  showPopup: function () {
    this.$popup.delay(100).show();
  },
  setContentPopup: function (mc) {
    if (this.vo.getSelectValues().type === "rate") {
      var rate = "--";
      if (mc.data.rate) {
        rate = mc.data.rate;
      }
      this.$popup.find(".power").html("<dt style='padding:0;margin:0'>使用電力量:</dt><dl>" + rate.toLocaleString() + "kWh</dl>");
      this.$popup.find(".power").css("margin", "0");
    } else if (this.vo.getSelectValues().type === "price") {
      var price = "--";
      if (mc.data.price) {
        price = mc.data.price;
      }
      this.$popup.find(".power").html("<dt style='padding:0;margin:0'>電気料金:</dt><dl>" + price.toLocaleString() + "円</dl>");
      this.$popup.find(".power").css("margin", "0");
    }

    var posX = mc.left - this.$popup.width() / 2;
    var posY = mc.top - this.$popup.height() - 10; //10 = bias

    this.$popup.css({ top: posY + "px", left: posX + "px" });
  },
  hidePopup: function () {
    this.$popup.hide();
  },
});

ennet.MonthGraphView = Backbone.View.extend({
  el: "#month",
  events: {
    "change #unit select": "onChangeType",
    "change .compare select": "onChangeCompare",
  },

  initialize: function () {
    //setting value from outer control
    //kWh or CO2
    this.type = this.getTypeByHtml();

    //init stage
    this.stage = new createjs.Stage(this.$el.find("#graphView canvas").get(0));
    this.stage.enableMouseOver(10); //10 times per second

    this.graphRect = ennet.GraphUtil.makeRect(this.stage, {
      left: 50,
      top: 0,
      right: 60,
      bottom: 30,
    });

    this.graph = new ennet.MonthGraphController({
      el: this.stage.canvas,
      stage: this.stage,
    });
    this.listenTo(this.graph, "click_graph_bar", this.selectDay);

    this.listenTo(this.model, "request", this.startLoading);
    this.listenTo(this.model, "sync", this.onSuccess);

    var template = "<li><dl><dt id='monthbill' class='txtColor1 txtS' style='margin:0'><%- power.price_label%></dt><dd class='txtColor1'><span class='txtXXL'><%- power.total_price.toLocaleString() %></span> <span class='txtXS'>円</span></dd></dl></li><li><dl><dt class='txtColor2 txtXS' style='margin:0'>みんなの平均電気料金</dt><dd class='txtColor2'><span class='txtS'><%- power.all_average_total_price.toLocaleString() %></span> <span class='txtXS'>円</span></dd></dl></li>";
    this.amoutUseTmpl = _.template(template); // _.template($("#monthAmoutUseTmpl").html());
  },
  startLoading: function () {
    this.$el.find("#graphView .loader").show();
  },
  onSuccess: function () {
    this.$el.find("#graphView .loader").hide();
    this.render();
  },
  render: function () {
    //last_year or all
    this.compare = this.getCompareByHtml();

    //draw graph
    this.renderGraph();

    // var json = this.model.toJSON();

    this.$el.find(".amountUse").html("");
    this.$el.find(".amountUse").append(this.amoutUseTmpl(this.model.toJSON()));

    return this;
  },
  renderGraph: function () {
    this.trigger("startRender");

    this.type = this.getTypeByHtml();
    var vo = this.model.getGraphVO(this.compare, this.type, this.graphRect);

    this.graph.update(vo);
  },
  onChangeType: function (event) {
    var select = event.currentTarget;

    this.type = this.getTypeByHtml(select);

    this.render();
  },
  onChangeCompare: function (event) {
    var select = event.currentTarget;

    this.compare = this.getCompareByHtml(select);

    this.render();
  },
  getTypeByHtml: function (target) {
    var val;
    if (target) {
      val = target.value;
    } else {
      val = $("#month #unit select").val();
    }

    if (val === "kWh") {
      return "rate";
    } else if (val === "円") {
      return "price";
    }
    return null;
  },
  getCompareByHtml: function (target) {
    var val;

    if (target) {
      val = target.value;
    } else {
      val = this.$el.find(".compare select").val();
    }

    if (val !== "last_year" && val !== "all") {
      val = null;
    }

    return val;
  },
  selectDay: function (year, month, day) {
    this.trigger("select_day", year, month, day);
  },
});

/**
 * [DayGraphVO description]
 */
ennet.YearGraphVO = function () {
  this.initialize.apply(this, arguments);
};
ennet.YearGraphVO.prototype = {
  barGraph_: null,
  lineGraph_: null,
  scaleInfo_: null,
  baseInfo_: null,
  total_: 0,
  selectValue_: null,
  graphRect_: null,
  initialize: function () {},
  getBarGraph: function () {
    return this.barGraph_;
  },
  setBarGraph: function (data) {
    this.barGraph_ = data;
  },
  getLineGraph: function () {
    return this.lineGraph_;
  },
  setLineGraph: function (data) {
    this.lineGraph_ = data;
  },
  getScaleInfo: function () {
    return this.scaleInfo_;
  },
  setScaleInfo: function (scaleInfo) {
    this.scaleInfo_ = scaleInfo;
  },
  getBaseInfo: function () {
    return this.baseInfo_;
  },
  setBaseInfo: function (baseInfo) {
    this.baseInfo_ = baseInfo;
  },
  getTotal: function () {
    return this.total_;
  },
  setTotal: function (total) {
    this.total_ = total;
  },
  getSelectValues: function () {
    return this.selectValues_;
  },
  setSelectValues: function (values) {
    this.selectValues_ = values;
  },
  getGraphRect: function () {
    return this.graphRect_;
  },
  setGraphRect: function (graphRect) {
    this.graphRect_ = graphRect;
  },
  getYear: function () {
    return this.year_;
  },
  setYear: function (year) {
    this.year_ = year;
  },
};

/**
 * @type {[type]}
 */
ennet.YearGraphModel = Backbone.Model.extend({
  isLoading: false,
  tabView : null,
  urlRoot: config.url.get.year,
  loading: function(){
    this.isLoading = true;
    this.tabView.loading();
  },
  loadComplate: function(){
    this.isLoading = false;
    this.tabView.loadComplate();
  },
  fetch: function (year, addOptions) {
    var options = {
      data : {},
      beforeSend : setHeader
    };

    if (year) {
      options.data.year = year;
    }

    if (ennet.Request.getParameter("u")) {
      options.data.u = ennet.Request.getParameter("u");
    }

    if (addOptions) {
      $.extend(true, options, addOptions);
    }

    return Backbone.Model.prototype.fetch.call(this, options);
  },
  getGraphVO: function (compare, type, graphRect) {
    var vo = new ennet.YearGraphVO();
    var json = this.toJSON();
    var barGraph = null,
      lineGraph = null;

    barGraph = json.data ? json.data : null;
    lineGraph = json[compare] && json[compare].data ? json[compare].data : null;

    //max
    var max = Math.max(ennet.GraphUtil.maxInArray(barGraph, type), ennet.GraphUtil.maxInArray(lineGraph, type));

    //scaled max
    var scaleInfo = ennet.GraphUtil.calculateScaleInfo(max);

    var total = 13;
    /**
     * a bar width rate
     *      |////|
     *      |////|
     *      |////|
     *  --------------
     *  33% |33% |33%
     *  18px|18px|18px
     */
    var baseInfo = {};
    baseInfo.rowWidth = graphRect.width / (total + 1); //include bar width and bar left/right padding
    baseInfo.baseX = Math.floor(graphRect.x + baseInfo.rowWidth * 0.5); //include left margin
    baseInfo.baseY = Math.floor(graphRect.y + graphRect.height);
    baseInfo.maxY = Math.floor(graphRect.y + 40); //upper padding for button

    ennet.GraphUtil.calculatePosition(barGraph, type, scaleInfo, baseInfo);
    ennet.GraphUtil.calculatePosition(lineGraph, type, scaleInfo, baseInfo);

    vo.setBarGraph(barGraph);
    vo.setLineGraph(lineGraph);
    vo.setScaleInfo(scaleInfo);
    vo.setBaseInfo(baseInfo);
    vo.setTotal(total);
    vo.setSelectValues({
      compare: compare,
      type: type,
    });
    vo.setGraphRect(graphRect);
    vo.setYear(this.get("year"));

    return vo;
  },
  setYear: function (year) {
    this.year = year;
  },
});

ennet.YearGraphController = Backbone.View.extend({
  initialize: function (options) {
    this.stage = options.stage;

    // popup
    this.$popup = this.$el.parent().find(".inBox");
    this.hidePopup();
  },
  update: function (vo) {
    this.vo = vo;

    this.render();
  },
  render: function () {
    // first initialize stage
    ennet.GraphUtil.recursiveRemoveEvents(this.stage);
    this.stage.removeAllChildren();

    //this.drawBackground();
    this.stage.addChild(ennet.GraphDrawer.drawYearBackground(this.vo));

    // draw graph
    this.stage.addChild(ennet.GraphDrawer.drawBarGraph(this.vo.getBarGraph()));

    this.stage.addChild(ennet.GraphDrawer.drawBlueLineGraph(this.vo.getLineGraph()));

    // make hitarea
    this.stage.addChild(ennet.GraphDrawer.drawHitarea(this.vo, this.onMouseOver.bind(this), this.onMouseOut.bind(this), this.onClick.bind(this)));

    return this; //needs backbone
  },
  onMouseOver: function (event) {
    // this.stage.canvas.style.cursor = 'pointer';
    this.setContentPopup(event.currentTarget);
    this.showPopup();
  },
  onMouseOut: function () {
    // this.stage.canvas.style.cursor = 'default';
    this.hidePopup();
  },
  onClick: function (event) {
    var mc = event.currentTarget;

    //this.trigger('click_graph_bar', mc.year, mc.data.month);
    this.trigger("click_graph_bar", mc.data.year, mc.data.month);
  },
  showPopup: function () {
    this.$popup.delay(100).show();
  },
  setContentPopup: function (mc) {
    if (this.vo.getSelectValues().type === "rate") {
      var rate = "--";
      if (mc.data.rate) {
        rate = mc.data.rate;
      }
      this.$popup.find(".power").html("<dt style='padding:0;margin:0'>使用電力量:</dt><dl>" + rate.toLocaleString() + "kWh</dl>");
    } else if (this.vo.getSelectValues().type === "price") {
      var price = "--";
      if (mc.data.price) {
        price = Number(mc.data.price);
      }
      this.$popup.find(".power").html("<dt style='padding:0;margin:0'>電気料金:</dt><dl>" + price.toLocaleString() + "円</dl>");
    }

    var posX = mc.left - this.$popup.width() / 2;
    var posY = mc.top - this.$popup.height() - 10; //10 = bias

    this.$popup.css({ top: posY + "px", left: posX + "px" });
  },
  hidePopup: function () {
    this.$popup.hide();
  },
});

ennet.YearGraphView = Backbone.View.extend({
  el: "#year",
  events: {
    "change #unit select": "onChangeType",
    "change .compare select": "onChangeCompare",
  },
  initialize: function () {
    //setting value from outer control
    //kWh or CO2
    this.type = this.getTypeByHtml();

    //init stage
    this.stage = new createjs.Stage(this.$el.find("#graphView canvas").get(0));
    this.stage.enableMouseOver(10); //10 times per second

    this.graphRect = ennet.GraphUtil.makeRect(this.stage, {
      left: 50,
      top: 0,
      right: 60,
      bottom: 36,
    });

    this.graph = new ennet.YearGraphController({
      el: this.stage.canvas,
      stage: this.stage,
    });
    this.listenTo(this.graph, "click_graph_bar", this.selectMonth);

    this.listenTo(this.model, "request", this.startLoading);
    this.listenTo(this.model, "sync", this.onSuccess);

    var template = "<li><dl><dt id='yearBill' class='txtColor1 txtS' style='margin:0'>電気料金（概算）</dt><dd class='txtColor1'><span class='txtXXL'><%- power.total_price.toLocaleString() %></span> <span class='txtXS'>円</span></dd></dl></li><li><dl><dt class='txtColor2 txtXS' style='margin:0'>みんなの平均電気料金</dt><dd class='txtColor2'><span class='txtS'><%- power.all_average_total_price.toLocaleString() %></span> <span class='txtXS'>円</span></dd></dl></li>";
    this.amoutUseTmpl = _.template(template);// _.template($("#yearAmoutUseTmpl").html());
  },
  startLoading: function () {
    this.$el.find("#graphView .loader").show();
  },
  onSuccess: function () {
    this.$el.find("#graphView .loader").hide();
    this.render();
  },
  render: function () {
    //last_year or all
    this.compare = this.getCompareByHtml();
    //draw graph
    this.renderGraph();

    this.$el.find(".amountUse").html("");
    this.$el.find(".amountUse").append(this.amoutUseTmpl(this.model.toJSON()));

    return this;
  },
  renderGraph: function () {
    this.trigger("startRender");

    var vo = this.model.getGraphVO(this.compare, this.type, this.graphRect);

    this.graph.update(vo);
  },
  onChangeType: function (event) {
    var select = event.currentTarget;

    this.type = this.getTypeByHtml(select);

    this.render();
  },
  onChangeCompare: function (event) {
    var select = event.currentTarget;

    this.compare = this.getCompareByHtml(select);

    this.render();
  },
  getTypeByHtml: function (target) {
    var val;
    if (target) {
      val = target.value;
    } else {
      val = $("#year #unit select").val();
    }

    if (val === "kWh") {
      return "rate";
    } else if (val === "円") {
      return "price";
    }
    return null;
  },
  getCompareByHtml: function (target) {
    var val;

    if (target) {
      val = target.value;
    } else {
      val = this.$el.find(".compare select").val();
    }

    if (val !== "last_year" && val !== "all") {
      val = null;
    }

    return val;
  },
  selectMonth: function (year, month) {
    this.trigger("select_month", year, month);
  },
});

/**
 * ConditionTab view
 * @todo GraphControllerからのアクセスを調整
 */
ennet.ConditionTabView = Backbone.View.extend({
  el: "#mainContent .contentBox",
  events: {
    "click .selectUnit li": "onClickTab",
    "click #day .selectDate li": "onChangeDay",
    "click #month .selectDate li": "onChangeMonth",
    "click #year .selectDate li": "onChangeYear",
  },
  defaultTabName: "day",
  defaultDate: { year: null, month: null, day: null },
  currentTabName: null,
  currentDate: null, // Date
  dayModel: null,
  dayView: null,
  monthModel: null,
  monthView: null,
  yearModel: null,
  yearView: null,
  syncedUser: false,
  selectTmpl: null,
  tabTmp: null,
  initialize: function (options) {
    this.dayModel = options.models.dayGraph;
    this.dayModel.tabView = this;
    this.listenTo(this.dayModel, "sync", this.syncDayGraph);
    this.dayView = new ennet.DayGraphView({ model: this.dayModel });
    this.listenTo(this.dayView, "startRender", this.startRender);

    this.monthModel = options.models.monthGraph;
    this.monthModel.tabView = this;
    this.listenTo(this.monthModel, "sync", this.syncMonthGraph);
    this.monthView = new ennet.MonthGraphView({ model: this.monthModel });
    this.listenTo(this.monthView, "select_day", this.selectDay);
    this.listenTo(this.monthView, "startRender", this.startRender);

    this.yearModel = options.models.yearGraph;
    this.yearModel.tabView = this;
    this.listenTo(this.yearModel, "sync", this.syncYearGraph);
    this.yearView = new ennet.YearGraphView({ model: this.yearModel });
    this.listenTo(this.yearView, "select_month", this.selectMonth);
    this.listenTo(this.yearView, "startRender", this.startRender);

    this.userModel = options.models.user;
    this.listenTo(this.userModel, "sync", this.syncUser);

    var template = '<div id="selected_txt" class="selectTxt"></div><select id="comparison_select" class="selectObj"><% if(can_comparison_self) { %><option id="last_year" value="last_year" class="lastyear">去年との比較</option><% } %><option id="everyone" value="all" class="comp">みんなとの比較</option></select>';
    this.selectTmpl = _.template(template); // _.template($("#selectComparisonTmpl").html());

    if (options.defaultDate) {
      this.defaultDate = options.defaultDate;
    }

    if (options.defaltTabName) {
      this.defaultTabName = options.defaltTabName;
    }
  },
  loading: function() {
    $("#ennevision-graph-detail-block-form").css("cursor", "wait");
    $("#ennevision-graph-detail-block-form a").css("cursor", "wait");
  },
  loadComplate: function() {
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中はカーソルを元に戻さない
      return;
    }
    $("#ennevision-graph-detail-block-form").css("cursor", "auto");
    $("#ennevision-graph-detail-block-form a").css("cursor", "pointer");
    if(this.tabTmp == null) {
      // nullの場合は変更しない
      return;
    }
    this.changeTab(this.tabTmp);
    this.tabTmp = null;
  },
  startRender: function () {
    //各Viewがupdateした際に呼ばれる
    window.clearTimeout(this.timeId);

    //レンダリング再開
    createjs.Ticker.init();
    createjs.Ticker.framerate = ennet.Conf.getFPS();
    createjs.Ticker.addEventListener("tick", this.dayView.stage);
    createjs.Ticker.addEventListener("tick", this.monthView.stage);
    createjs.Ticker.addEventListener("tick", this.yearView.stage);
    createjs.Ticker.addEventListener("tick", createjs.Tween);

    //レンダリング実行停止
    this.timeId = window.setTimeout(function () {
      createjs.Ticker.reset();
      //easeljs0.7.1では以下は初期化されないので強制初期化
      createjs.Ticker._timerId = null;
      createjs.Ticker._inited = false;
    }, ennet.Conf.ANIMATION_RESET_TIME);
  },
  render: function () {
    this.changeTab(this.defaultTabName);

    return this;
  },
  onClickTab: function (event) {
    var tabName = $(event.currentTarget).data("tab");
    this.changeTab(tabName);
  },
  changeTab: function (tabName) {
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中は日を移動しない
      return;
    }
    if (false === this.validTabName(tabName)) {
      this.trigger("invalid");
      return;
    }

    this.$el.find(".selectUnit li").removeClass("current");
    this.$el.find('.selectUnit li[data-tab="' + tabName + '"]').addClass("current");

    this.$el.find(".tabContent").addClass("hide");
    this.$el.find("#" + tabName).removeClass("hide");

    this.currentTabName = tabName;
    this.trigger("tab_changed", tabName);
  },
  validTabName: function (tabName) {
    var tabs = ["day", "month", "year"];
    return tabs.indexOf(tabName) >= 0;
  },
  getCurrentTabName: function () {
    return this.currentTabName;
  },
  syncUser: function () {
    // 初回のみ想定
    if (this.syncedUser === false) {
      this.syncedUser = true;

      this.updateContractLabel();

      //比較対象select生成
      var html = this.selectTmpl({
        can_comparison_self: true,
      });
      $(".selectArea.compare").html(html);
      window.selectView($(".selectArea.compare").get());

      var date = this.defaultDate;
      this.fetchGraphData(date.year, date.month, date.day);
    }
  },
  updateContractLabel: function () {
    var user = this.userModel;

    var isDR = user.isJoiningDRService();
    var isTOU = user.isTouType(user.touTypeTou);
    var isBasicForTouType = user.isTouType(user.touTypeBasic);

    var classDR = isDR ? "current" : "";
    var classTOU = isTOU ? "current" : "";
    var displayPriceCheck = isBasicForTouType ? "none" : "block";

    $(".plan .hourly_service").removeClass("current");
    $(".plan .dr_service").removeClass("current");

    $(".plan .hourly_service").addClass(classTOU);
    $(".plan .dr_service").addClass(classDR);

    $("#month .priceCheck").css("display", displayPriceCheck);
  },
  fetchGraphData: function (year, month, day) {
    
    if(!this.currentDate || this.currentDate.getFullYear() != year || this.currentDate.getMonth() + 1 != month || this.currentDate.getDate() != day){
      this.dayModel.fetch(year, month, day);
    }

    if(!this.currentDate || this.currentDate.getFullYear() != year || this.currentDate.getMonth() + 1 != month){
      this.monthModel.fetch(year, month);
    }
      
    if(!this.currentDate || this.currentDate.getFullYear() != year){
      this.yearModel.fetch(year);
    }
  },
  onChangeDay: function (event) {
    event.preventDefault();
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中は日を移動しない
      return;
    }

    var $target = $(event.currentTarget);

    if ($target.hasClass("disable")) {
      return false;
    }

    var method = $target.attr("class");
    var bias = 0;

    if (method === "prev") {
      bias = -1;
    } else if (method === "next") {
      bias = 1;
    }

    var date = this.validateCurrentDate(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + bias, true);

    this.fetchGraphData(date.getFullYear(), date.getMonth() + 1, date.getDate());
  },
  syncDayGraph: function (dayGraph) {
    var date = new Date(dayGraph.get("date").getTime());
    //        var date = new Date();

    this.currentDate = date;

    this.updateDayLabel(date.getFullYear(), date.getMonth() + 1, date.getDate());
  },
  updateDayLabel: function (year, month, day) {
    $("#day .selectDate .current").text(year + "年" + month + "月" + day + "日");

    var serviceStartDate = this.userModel.get("start_date");
    var serviceEndDate = this.userModel.get("end_date");
    var date = new Date(year, month - 1, day);

    if (date.getTime() > serviceStartDate.getTime()) {
      $("#day .selectDate .prev").removeClass("disable");
    } else {
      $("#day .selectDate .prev").addClass("disable");
    }

    if (date.getTime() < serviceEndDate.getTime()) {
      $("#day .selectDate .next").removeClass("disable");
    } else {
      $("#day .selectDate .next").addClass("disable");
    }
  },
  onChangeMonth: function (event) {
    event.preventDefault();
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中は日を移動しない
      return;
    }

    var $target = $(event.currentTarget);
    if ($target.hasClass("disable")) {
      return false;
    }

    var method = $target.attr("class");
    var bias = 0;

    if (method === "prev") {
      bias = -1;
    } else if (method === "next") {
      bias = 1;
    }

    var date = this.validateCurrentDate(this.currentDate.getFullYear(), this.currentDate.getMonth() + bias, this.currentDate.getDate());

    this.fetchGraphData(date.getFullYear(), date.getMonth() + 1, date.getDate());
  },
  syncMonthGraph: function (monthGraph) {
    this.updateMonthLabel(monthGraph.get("year"), monthGraph.get("month"));
  },
  updateMonthLabel: function (year, month) {
    $("#month .selectDate .current").text(year + "年" + month + "月");

    var serviceStartDate = this.userModel.get("start_date");
    var serviceEndDate = this.userModel.get("end_date");

    if (year > serviceStartDate.getFullYear() || (year == serviceStartDate.getFullYear() && month > serviceStartDate.getMonth() + 1)) {
      $("#month .selectDate .prev").removeClass("disable");
    } else {
      $("#month .selectDate .prev").addClass("disable");
    }

    if (year < serviceEndDate.getFullYear() || (year == serviceEndDate.getFullYear() && month < serviceEndDate.getMonth() + 1)) {
      $("#month .selectDate .next").removeClass("disable");
    } else {
      $("#month .selectDate .next").addClass("disable");
    }
  },
  onChangeYear: function (event) {
    event.preventDefault();
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中は日を移動しない
      return;
    }

    var $target = $(event.currentTarget);
    if ($target.hasClass("disable")) {
      return false;
    }

    var method = $target.attr("class");
    var bias = 0;

    if (method === "prev") {
      bias = -1;
    } else if (method === "next") {
      bias = 1;
    }

    var date = this.validateCurrentDate(this.currentDate.getFullYear() + bias, this.currentDate.getMonth(), this.currentDate.getDate());

    this.fetchGraphData(date.getFullYear(), date.getMonth() + 1, date.getDate());
  },
  syncYearGraph: function () {},

  selectDay: function (year, month, day) {
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中は日を移動しない
      return;
    }
    if (!this.isWithinLimitDate(year, month, day)) {
      return;
    }

    //this.changeTab("day");
    this.tabTmp = "day";
    this.fetchGraphData(year, month, day);
  },
  selectMonth: function (year, month) {
    if (this.dayModel.isLoading || this.monthModel.isLoading || this.yearModel.isLoading) {
      // ロード中は日を移動しない
      return;
    }
    if (!this.isWithinLimitDate(year, month)) {
      return;
    }

    var date = this.validateCurrentDate(year, month - 1, this.currentDate.getDate());

    //this.changeTab("month");
    this.tabTmp = "month";
    this.fetchGraphData(date.getFullYear(), date.getMonth() + 1, date.getDate());
  },
  isWithinLimitDate: function (year, month, day) {
    //month === Date.getMonth()+1;
    var serviceStartDate = this.userModel.get("start_date");
    var serviceEndDate = this.userModel.get("end_date");
    var startDate = new Date(serviceStartDate);
    var endDate = new Date(serviceEndDate);

    if (day) {
      //日があるとき（日に遷移時）
      var currentDate = new Date(year, month - 1, day);
      if (currentDate.getTime() > endDate.getTime()) {
        return false;
      }
      if (currentDate.getTime() < startDate.getTime()) {
        return false;
      }
    } else {
      //日がないとき（月に遷移）
      var startYear = startDate.getFullYear();
      var startMonth = startDate.getMonth() + 1;
      var endYear = endDate.getFullYear();
      var endMonth = endDate.getMonth() + 1;
      if (startYear > year || (startYear === year && startMonth > month)) {
        return false;
      }
      if (endYear < year || (endYear === year && endMonth < month)) {
        return false;
      }
    }

    return true;
  },
  validateCurrentDate: function (year, month, day, isDay) {
    //month === Date.getMonth();
    var serviceStartDate = this.userModel.get("start_date");
    var serviceEndDate = this.userModel.get("end_date");
    var startDate = new Date(serviceStartDate);
    var endDate = new Date(serviceEndDate);

    var currentDate = new Date(year, month, day);

    if (currentDate.getTime() > endDate.getTime()) {
      return endDate;
    } else if (currentDate.getTime() < startDate.getTime()) {
      return startDate;
    }

    if (!isDay) {
      var maxDay = ennet.GraphUtil.getDaysInMonth(year, month + 1);
      if (day > maxDay) {
        day = maxDay;
      }
    }

    return new Date(year, month, day);
  },
});
/**
 * 電気を見る 電気代、電力量
 */
const init3 = function () {
  var request = ennet.Request;
  //    var actionModel = new ennet.ActionModel();

  var tabView;

  var ConditionApp = ennet.MemberApp.extend({
    dayGraphSynced: false,
    monthGraphSynced: false,
    yearGraphSynced: false,
    _execute: function () {
      // var user = this.getUser(); // @see MemberApp._postExecute()
      var user = new ennet.UserModel(); // イベント監視より先にsyncされる可能性があるため

      var dayGraph = new ennet.DayGraphModel();
      this.listenTo(dayGraph, "sync", this.dayGraphSync);

      var monthGraph = new ennet.MonthGraphModel();
      this.listenTo(monthGraph, "sync", this.monthGraphSync);

      var yearGraph = new ennet.YearGraphModel();
      this.listenTo(yearGraph, "sync", this.yearGraphSync);

      var date = {
        year: request.getParameter("year"),
        month: request.getParameter("month"),
        day: request.getParameter("day"),
      };

      var defaltTabName = request.getParameter("tab");
      if (defaltTabName) {
        // パラメータで指定されたタブ
      } else if (date.year && date.month && date.day) {
        defaltTabName = "day";
      } else if (date.year && date.month) {
        defaltTabName = "month";
      } else if (date.year) {
        defaltTabName = "year";
      }

      this.view = new ContentView({ model: user });
      //            new ModalView();

      ennet.ResourceLoader.load([
        ennet.Conf.imgBasePath + "/graph_missing_complement.png",
        ennet.Conf.imgBasePath + "/graph_dr.png",
        ennet.Conf.imgBasePath + "/icon_missing.png",
        ennet.Conf.imgBasePath + "/icon_dr.png",
        ennet.Conf.imgBasePath + "/icon_achivement.png"
      ],function () {
          tabView = new ennet.ConditionTabView({
            models: {
              user: user,
              dayGraph: dayGraph,
              monthGraph: monthGraph,
              yearGraph: yearGraph,
            },
            defaultDate: date,
            defaltTabName: defaltTabName,
          });
          this.listenTo(tabView, "invalid", this.redirect404);
          this.listenTo(tabView, "tab_changed", this.tabChanged);
          tabView.render();

          user.fetch();
        }.bind(this)
      );
    },
    dayGraphSync: function () {
      this.dayGraphSynced = true;
    },
    monthGraphSync: function () {
      this.monthGraphSynced = true;
    },
    yearGraphSync: function () {
      this.yearGraphSynced = true;
    },
  });

  var ContentView = Backbone.View.extend({
    el: "#mainContent",
    initialize: function () {
      this.listenTo(this.model, "sync", this.render);
    },
    render: function () {
      if (this.model.isUseFromMidlleOfMonth()) {
        this.$el.before($("#alertTp").html());
      }

      return this;
    },
  });

  var app = new ConditionApp();
  window.app = app;
  app.run();
};
//$(document).on("ready", init3);
export default ennet;
export const execute = function(){
  init1();
  init2();
  init3();
}

<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">確認画面</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <h1 class="title">確認画面</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/service_02" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>

              <div class="content">
                <form class="webform-client-form webform-client-form-82 preview" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="messages warning"><p>変更内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-rate-plan-info form-wrapper">
                      <legend><span class="fieldset-legend">料金プラン情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-rate-plan-info--field-tokyo-rate-plan webform-container-inline">
                          <label>料金プラン{{ now.Contract.PowerAreaName }}</label>
                          {{ update.plan_name }}
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-rate-plan-info--field-power-capacity-a webform-container-inline">
                          <label>電力容量 </label>
                          {{ update.capacity_numeric }}
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-rate-plan-info--field-capacity-unit webform-container-inline">
                          <label>単位 </label>
                            <template v-if="update.capacity_unit == 0">A</template>
                            <template v-if="update.capacity_unit == 1">kVA</template>
                            <template v-if="update.capacity_unit == 2">kW</template>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-use-mansion-info form-wrapper">
                      <legend><span class="fieldset-legend">ご利用マンション情報</span></legend>
                      <div class="fieldset-wrapper">
                        <p><strong>マンション名：&nbsp;</strong>{{ now.Customer.BuildingName }}</p>
                        <p><strong>部屋番号：&nbsp;</strong>{{ now.Customer.RoomNo }}</p>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-contractor-info form-wrapper">
                      <legend><span class="fieldset-legend">ご契約者様情報</span></legend>
                      <div class="fieldset-wrapper">
                        <p><strong>お名前：&nbsp;</strong>{{ now.Contractor.Name }}</p>
                        <p><strong>フリガナ：&nbsp;</strong>{{ now.Contractor.NameKana }}</p>
                        <p><strong>郵便番号：&nbsp;</strong>{{ now.Contractor.ZipPostalCode }}</p>
                        <p><strong>住所：&nbsp;</strong>{{ now.Contractor.State }}{{ now.Contractor.City }}{{ now.Contractor.Line }}{{ now.Contractor.Line2 }}{{ now.Contractor.LineOther }}</p>
                        <p><strong>電話番号：&nbsp;</strong>{{ now.Contractor.Phone }}</p>
                        <p>
                          <strong>契約者メールアドレス：&nbsp;</strong><a :href="'mailto:' + now.Contractor.Email">{{ now.Contractor.Email }}</a>
                        </p>
                      </div>
                    </fieldset>
                    <fieldset class="webform-component-fieldset webform-component--webform-group-payer-info form-wrapper">
                      <legend><span class="fieldset-legend">お支払者様情報</span></legend>
                      <div class="fieldset-wrapper">
                        <p><strong>お名前：&nbsp;</strong>{{ now.Payer.Name }}</p>
                        <p><strong>フリガナ：&nbsp;</strong>{{ now.Payer.NameKana }}</p>
                        <p><strong>郵便番号：&nbsp;</strong>{{ now.Payer.ZipPostalCode }}</p>
                        <p><strong>住所：&nbsp;</strong>{{ now.Payer.State }}{{ now.Payer.City }}{{ now.Payer.Line }}{{ now.Payer.Line2 }}{{ now.Payer.LineOther }}</p>
                        <p><strong>電話番号：&nbsp;</strong>{{ now.Payer.Phone }}</p>
                        <p>
                          <strong>支払者メールアドレス：&nbsp;</strong><a :href="'mailto:' + now.Payer.Email">{{ now.Payer.Email }}</a>
                        </p>
                      </div>
                    </fieldset>

                    <div class="form-item form-type-password form-item-auth-pass">
                      <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                      <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password"/>
                      <div class="description">パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link></div>
                    </div>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" @click="check" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { post } from "../assets/js/request.js";
import { rateplanStore } from "../stores/store.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = rateplanStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "rate_plan",
        },
      });
      return {};
    }else{
      var update = store.update;
      var now = store.now;
      store.$reset();
      return {
        auth_error: false,
        send: false,
        now: now,
        update: update,
        password: ""
      };
    }
  },
  methods: {
    back: function () {
      var store = rateplanStore();
      store.update = this.$data.update;
      store.now = this.$data.now;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "rate_plan",
        },
      });
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push: async function () {
      var store = rateplanStore();
      try{
        this.$data.auth_error = false;
        this.$data.send = true;
        await post(config.url.post.contract, {
          contract : this.$data.update,
          password: this.$data.password
        });
        this.$router.push({
          path: "/mansion/member/procedure",
          query: {
            type: "rate_plan_complete",
          },
        });
        store.update = this.$data.update;
        store.now = this.$data.now;
      }catch(ex){
        if(ex.response.data == "PasswordError"){
          this.$data.send = false;
          this.$data.auth_error = true;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
      return false;
    }
  }
};
import "../assets/js/mems_webform_replace_half_space.js";
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

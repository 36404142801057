import $ from 'jquery'
import _ from 'underscore'
import createjs from 'createjs-module'
import Backbone from 'backbone'
import config from "../../config.js"
import { loginStore, loadData } from '@/stores/store.js'
var BackboneSyncOrg = Backbone.sync;

/**
 * [ennet description]
 * @type {Object}
 */
		
var ennet = {};
ennet.Conf = {
    DEBUG : false, //デバッグモード有効
    imgBasePath: "/mansion/common/",
    getFPS : function(){
        var ua = window.navigator.userAgent;
        if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
            return 6;
        }else{
            return 30;
        }
    }
};

ennet.Conf.webRoot = (function(){
    var dirs = window.location.pathname.split('/');
    return '/' + dirs[1];
})();

ennet.Conf.ANIMATION_RESET_TIME = 5000;
/**
 * Utility
 */
ennet.Util = {
    /**
     * 数字を千位毎にグループ化してフォーマットする
     * @param {Number} num フォーマットする数値
     * @returns {String} numをフォーマットした結果
     */
    numberFormat: function(num) {
        var numStr = String(num);
        var intStr = String(parseInt(num));
        var indexOfdecimalPoint = numStr.indexOf('.');
        var afterTheDecimalPoint = '';

        if (indexOfdecimalPoint >= 0) {
            afterTheDecimalPoint = numStr.substring(indexOfdecimalPoint);
        }

        var formatStr = intStr.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

        return formatStr+afterTheDecimalPoint;
    },
    /**
     * 改行コードをbrタグに変換する
     * @param {String} str 変換する文字列
     * @returns {String} 変換した文字列
     */
    nlToBr: function(str) {
        return str.replace(/\r\n|\n|\r/g, '<br>');
    }
};
/**
 * GraphUtility
 */
ennet.GraphUtil = {
    /**
     * search max value in the array
     */
    maxInArray:function(array,key){
        if(!array){
            return 0;
        }
        var max=0,value=0;
        for(var i=0;i<array.length;i++){
            value = array[i][key];
            if(max < value){
                max = value;
            }
        }
        return max;
    },
    /**
     * calculate graph max scale
     * @see http://d.hatena.ne.jp/fskkoba/20110805/1312535402
     */
    calculateScaleInfo:function(max){

        var base        = max * 1.05;
        var lg          = Math.log(base) / Math.log(10);
        var bias        = Math.pow(10,Math.floor(lg));
        var num         = parseInt(base.toString().slice(0,1),10);
        var scale       = (num<2)?bias*0.2:((num<5)?bias*0.5:bias);
        var scaleNum    = Math.floor(base/scale)+1;

        //最大値が１以下のとき
        // var n = scaleNum * scale;
        if(max <= 0.5 || max === 0){
            return {
                max:0.5,
                scaleNum:5,
                scale:0.1,
                labels:[0.5,0.4,0.3,0.2,0.1]
            };
        }else if(max <= 1.0){
            return {
                max:1,
                scaleNum:4,
                scale:0.25,
                labels:[1,0.75,0.5,0.25]
            };
        }

        return {
            max:ennet.GraphUtil.roundDecimals(scaleNum * scale,2),
            scaleNum:scaleNum,
            scale:ennet.GraphUtil.roundDecimals(scale,2)
        };
    },
    /**
     * calculate Position
     */
    calculatePosition:function(array,key,scaleInfo,baseInfo){
        if(!array){
            return;
        }

        var obj;
        for(var i=0;i<array.length;i++){
            obj = array[i];

            if(scaleInfo.max !== 0){
                obj.percent = obj[key]/scaleInfo.max;
            }else{
                obj.percent = 0;
            }
            ennet.GraphUtil.calculatePositionOne(obj,scaleInfo,baseInfo,i);

        }
    },
    calculatePositionOne:function(obj,scaleInfo,baseInfo,i){
        obj.width   = Math.floor(baseInfo.rowWidth * 0.5);
        obj.height  = Math.floor(obj.percent * (baseInfo.baseY - baseInfo.maxY));
        obj.left    = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth * 0.25);//bar left x
        obj.right   = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth * 0.75);//bar right x
        obj.top     = Math.floor(baseInfo.baseY - obj.height);
        obj.bottom  = baseInfo.baseY;
    },
    calculatePercentage:function(array,key,max){
        if(!array){
            return;
        }

        for(var i=0;i<array.length;i++){
            array[i].percent = array[i][key]/max;
        }
    },
    /**
     * make text label
     */
    makeLabel:function(text,size,color,textAlign,x,y){
        var textLabel = new createjs.Text(text,size+'px Arial',color);
        textLabel.textAlign = textAlign;
        textLabel.text = text;
        textLabel.x = (x)?x:0;
        textLabel.y = (y)?y:0;
        return textLabel;
    },
    /**
     * [makeRect description]
     * @param  {[type]} padding [description]
     * @return {[type]}         [description]
     */
    makeRect:function(stage,padding){
        return new createjs.Rectangle(
            padding.left,
            padding.top,
            stage.canvas.width - ( padding.left + padding.right ),
            stage.canvas.height - ( padding.top + padding.bottom )
        );
    },
    recursiveRemoveEvents:function(container){
        var child;
        for(var i=0;i<container.children.length;i++){
            child = container.getChildAt(i);
            if(_.isUndefined(child.children)){
                // console.log('before:'+child.hasEventListener('mouseover'));
                child.removeAllEventListeners();
                // console.log('after:'+child.hasEventListener('mouseover'));
            }else{
                ennet.GraphUtil.recursiveRemoveEvents(child);
            }
        }
    },
    /**
     * round Decimal
     */
    roundDecimals:function(num,digitNum){
        var array   = num.toString().split('.');
        if(array.length > 1){
            var integer = array[0];
            var decimals= array[1];

            decimals = decimals.slice(0,digitNum);

            return (integer+'.'+decimals)*1;
        }else{
            return num;
        }
    },
    /**
     * 小数点第二位を四捨五入して小数点第一位にまるめる
     */
    roundFloat:function(num){
        var array   = num.toString().split('.');
        if(array.length > 1){
            var integer = array[0];
            var decimals= array[1];

            var digitNum = decimals.length-1;
            decimals = Math.round(parseInt(decimals,10)*Math.pow(0.1,digitNum));

            return (integer+'.'+decimals)*1;
        }else{
            return num;
        }
    },
    /**
     * その月の日数を返す
     */
    getDaysInMonth:function(year,month){
        return (new Date(year, month, 0)).getDate();
    },
    calculateWeekDays:function(year,month,total){
        var weekdays = [],wid;
        for(var i=1;i<=total;i++){
            wid = new Date(year,month-1,i).getDay();
            switch(wid){
                case 0:
                    weekdays.push('日');
                    break;
                case 1:
                    weekdays.push('月');
                    break;
                case 2:
                    weekdays.push('火');
                    break;
                case 3:
                    weekdays.push('水');
                    break;
                case 4:
                    weekdays.push('木');
                    break;
                case 5:
                    weekdays.push('金');
                    break;
                case 6:
                    weekdays.push('土');
                    break;
            }
        }

        return weekdays;
    },
    makeBaseInfo:function(graphRect,total){
        var baseInfo       = {};
        baseInfo.rowWidth  = graphRect.width / (total+1);//include bar width and bar left/right padding
        baseInfo.baseX     = Math.floor(graphRect.x + baseInfo.rowWidth*0.5);//include left margin
        baseInfo.baseY     = Math.floor(graphRect.y + graphRect.height);
        baseInfo.maxY      = Math.floor(graphRect.y + 40);//upper padding for button
        return baseInfo;
    }
};
/**
 * Request class.
 */
ennet.Request = {};

const init1 = function(){
    ennet.Request = (function(){
        var parameters;

        function _initialize() {

            // initialize parameter
            parameters = [];
            var queryString = window.location.search.slice(1, window.location.search.length);
            var queries = queryString.split('&');

            for(var i = 0; i < queries.length; i++) {
                var keyAndValue = queries[i].split('=');

                parameters[keyAndValue[0]] = keyAndValue[1];
            }
        }

        function _getParameter(name, def) {
            return (parameters[name]) ? parameters[name] : def;
        }

        _initialize();

        return {
            getParameter: _getParameter
        };
    }());

    /**
     * Base application.
     */
    var baseApp = ennet.BaseApp = function(){
        this.initialize();
    };

    _.extend(baseApp.prototype, Backbone.Events, {
        initialize: function() {
            this.listenToOnce(this, 'stop', this._stop);


            // 画面遷移によるajax中断→エラーページリダイレクトの回避
            var isUnload = true;

            $(document).on('click', 'a', function(e) {
                isUnload = true;

                // 一部IEではhref="javascript:void(0)"などでもbeforeunloadイベントが発火する
                var href = $(e.currentTarget).attr('href');
                if (
                    href.indexOf('javascript:;') !== -1 ||
                    href.indexOf('javascript:void(0)') !== -1
                ) {
                    isUnload = false;
                }
            });

            /**
             * 確認ダイアログを出す場合は選択結果による制御ができないので別の方法を検討
             * なおonunloadイベントでは間に合わない
             */
            $(window).on('beforeunload', function() {
                if (isUnload) {
                    this.trigger('stop');
                } else {
                    isUnload = true;
                }

                // no return
            }.bind(this));

            // Backbone.syncのオーバーライド
            //var BackboneSyncOrg = Backbone.sync;
            var app = this;

            Backbone.sync = function(method, model, addOptions) {
                var xhr;
                var options = {
                    cache:false,//IEでキャッシュ回避用
                    timeout: 180 * 1000,
                    complete: function() {
                        // 発火しないとイベント監視が重複していくのでstopする
                        app.stopListening(model, 'error', app.apiError);
                        app.stopListening(app, 'stop', xhr.abort);
                    },
                };

                $.extend(true, options, addOptions);

                app.listenToOnce(model, 'error', app.apiError);

                xhr = BackboneSyncOrg(method, model, options);

                app.listenToOnce(app, 'stop', xhr.abort);

                return xhr;
            };
        },
        run: function() {
            this._preExecute();
            this._execute();
            this._postExecute();
        },
        _preExecute: function() {},
        _execute: function() {},
        _postExecute: function() {},
        _isStop: false,
        isStop: function() {
            return this._isStop;
        },
        _stop: function() {
            this._isStop = true;
        },
        apiError: function(object, response) {
            if (response.statusText === 'abort') {
                return;
            } else if (response.status === 401) {
                return this.redirectLogin();
            } else if (response.status === 404) {
                return this.redirect404();
            }

            return this.redirect500();
        },
        redirect: function(url) {
            if (this.isStop()) {
                return;
            }

            this.trigger('stop');
            window.location.href = url;
        },
        redirectLogin: function() {
//            this.redirect(ennet.Conf.webRoot+'/login/');
        },
        redirect404: function() {
//            this.redirect(ennet.Conf.webRoot+'/error/notfound/');
        },
        redirect500: function() {
//            this.redirect(ennet.Conf.webRoot+'/error/system/');
        },
    });

    // @see Backbone.js extend()
    baseApp.extend = function(protoProps, staticProps) {
        var parent = this;
        var child;

        // The constructor function for the new subclass is either defined by you
        // (the "constructor" property in your `extend` definition), or defaulted
        // by us to simply call the parent's constructor.
        if (protoProps && _.has(protoProps, 'constructor')) {
            child = protoProps.constructor;
        } else {
            child = function(){ return parent.apply(this, arguments); };
        }

        // Add static properties to the constructor function, if supplied.
        _.extend(child, parent, staticProps);

        // Set the prototype chain to inherit from `parent`, without calling
        // `parent`'s constructor function.
        var Surrogate = function(){ this.constructor = child; };
        Surrogate.prototype = parent.prototype;
        child.prototype = new Surrogate();

        // Add prototype properties (instance properties) to the subclass,
        // if supplied.
        if (protoProps) {
            _.extend(child.prototype, protoProps);
        }

        // Set a convenience property in case the parent's prototype is needed
        // later.
        child.__super__ = parent.prototype;

        return child;
    };
};
// $(document).on("ready", init1);

ennet.ResourceLoader = {
    resources:{},
    load:function(list,callback){
        if(!list || !callback){
            throw new Error('ERROR:need 2 arguments.');
        }

        this.callback   = callback;
        this.count      = 0;
        this.max        = list.length;

        var img;
        for( var i=0; i<list.length; i++){
            img = document.createElement('img');
            img.onload = this.countUp.bind(this);
            img.src = list[i];
            this.resources[list[i]] = img;
        }
    },
    countUp:function(){
        this.count++;
        if(this.count === this.max){
            this.callback();
        }
    }
};
ennet.GraphDrawer = {
    drawMonthBackground:function(vo){
        var mc          = new createjs.MovieClip();
        var graphics    = new createjs.Graphics();
        var shape       = new createjs.Shape(graphics);

        var graphRect   = vo.getGraphRect();
        var baseInfo    = vo.getBaseInfo();
        var scaleInfo   = vo.getScaleInfo();
        var data        = vo.getBarGraph();
        var total       = vo.getTotal();
        var daylabels   = vo.getDayLabels();
        var weekdays    = vo.getWeekDays();

        shape.name = 'content';
        mc.addChild(shape);

        ennet.GraphDrawer.drawMonthHorizontalScale(mc,baseInfo,graphRect,data,total,daylabels,weekdays);
        ennet.GraphDrawer.drawVerticalScale(mc,baseInfo,scaleInfo,graphRect);

        return mc;
    },
    drawBackground:function(mc,graphRect,color){
        var graphics = mc.getChildByName('content').graphics;

        graphics.beginFill(color).drawRect(
            graphRect.x,
            graphRect.y,
            graphRect.width,
            graphRect.height).endFill();
    },
    drawHourlyBackground:function(mc,tous,baseInfo,graphRect){
        var graphics    = mc.getChildByName('content').graphics;
        var tou,color,w,h,pX1,pX2;
        // var rowWidth = Math.floor((graphRect.width)/total);
        for(var i=0;i<tous.length;i++){
            tou = tous[i];

            if(tou.type === 1){
                color = '#fdf6d9';
            }else if(tou.type === 2){
                color = '#feec96';
            }else if(tou.type === 3){
                color = '#fbcf4e';
            }

            // percent = demmand[index].percent;
            h       = Math.floor(baseInfo.baseY);
            pX1     = Math.floor(baseInfo.rowWidth + graphRect.x + baseInfo.rowWidth * (tou.startIndex));//bar left x
            pX2     = Math.floor(baseInfo.rowWidth + graphRect.x + baseInfo.rowWidth * (tou.endIndex));//bar right x
            w       = Math.floor(pX2 - pX1);
            //draw color
            graphics.beginFill(color).drawRect(pX1,0,w,h).endFill();
        }
    },
    drawMonthHorizontalScale:function(mc,baseInfo,graphRect,data,total,daylabels,weekdays){
        var strokeWidth = 2;
        var graphics    = mc.getChildByName('content').graphics;

        // horizontal axis line
        graphics.setStrokeStyle(strokeWidth);
        graphics.beginStroke('#ababa9');
        graphics
        .moveTo(graphRect.x,graphRect.y+graphRect.height+strokeWidth/2)
        .lineTo(graphRect.x+graphRect.width,graphRect.y+graphRect.height+strokeWidth/2);

        // horizontal axis scale label
        var text,i;
        for(i=0; i<total; i++){
            //make label
            text = ennet.GraphUtil.makeLabel(
                daylabels[i],
                12,
                '#5c5c5c',
                'left',
                0,
                baseInfo.baseY + 4
            );
            text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth/2 - text.getMeasuredWidth()/2);
            mc.addChild(text);

            //make weekday
            text = ennet.GraphUtil.makeLabel(
                weekdays[i],
                12,
                '#5c5c5c',
                'left',
                0,
                baseInfo.baseY + 16
            );
            text.x = Math.floor(baseInfo.baseX + baseInfo.rowWidth * i + baseInfo.rowWidth/2 - text.getMeasuredWidth()/2);
            mc.addChild(text);
        }
    },
    drawVerticalScale:function(mc,baseInfo,scaleInfo,graphRect){
        var graphics    = mc.getChildByName('content').graphics;

        //vertical axis scale
        graphics.setStrokeStyle(1);
        graphics.beginStroke('#c8c8c8');

        /**
         * dashedLineTo extend createjs.Graphics prototype
         * @see Util.js
         */
        var y,text,labels,i;
        if(scaleInfo.labels){
            labels = scaleInfo.labels;
        }else{
            labels = [];
            for(i = scaleInfo.max; i > 0; i=ennet.GraphUtil.roundFloat(i-scaleInfo.scale)){
                labels.push(i);
            }
        }

        var label;
        for(i = 0; i < labels.length; i++){
            label = labels[i];

            y = baseInfo.maxY + ((scaleInfo.max - label) * (baseInfo.baseY - baseInfo.maxY) / scaleInfo.max);

            //draw dashed line
            dashedLineTo(
                graphics,
                graphRect.x,
                y,
                graphRect.x+graphRect.width,
                y,
                2);

            label = label.toLocaleString();
            if (i ==  0) {
              label += ' kWh';
            }
            text = ennet.GraphUtil.makeLabel(
                label,
                12,
                '#5c5c5c',
                'left'
            );
            text.x = 0;
            text.y = Math.floor(y - text.getMeasuredHeight()/2);
            mc.addChild(text);
        }
    },

    /**
     * [drawBarGraph description]
     * @param  {[type]} data     [description]
     * @param  {[type]} baseInfo [description]
     * @return {[type]}          [description]
     */
    drawBarGraph:function(data){
        if(!data){
            return;
        }

        var mc = new createjs.MovieClip();

        //local variables
        var graphic,shape,wrapper;

        //draw bar
        var obj;
        var missingImg = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath+'/graph_missing_complement.png'];

        for(var index=0;index<data.length;index++){
            obj = data[index];

            if(obj.status === 'fail'){

                var icon = new createjs.Bitmap(ennet.Conf.imgBasePath+'/icon_missing.png');
                icon.x = Math.floor(obj.left+obj.width/2) - 7;//7=icon.width/2
                icon.y = obj.bottom - 14 - 5;//14=icon.height,5=marginBottom
                mc.addChild(icon);

            }else{

                //draw color
                graphic = new createjs.Graphics();

                if(obj.status === 'cover'){
                    graphic.beginBitmapFill(missingImg,'repeat');
                }else{
                    graphic.lf(['#f54514','#f54514'],[1,0],0,0,0,obj.height);
                }
                graphic.drawRoundRectComplex(0,0,obj.width,obj.height,2,2,0,0).endFill();

                shape   = new createjs.Shape(graphic);
                shape.x = -obj.width/2;
                shape.y = -obj.height;

                //package movieclip
                wrapper = new createjs.MovieClip();
                wrapper.addChild(shape);
                wrapper.scaleY = 0;
                wrapper.x = Math.floor(obj.left+obj.width/2);
                wrapper.y = obj.bottom;
                // wrapper.data = data[index];
                // wrapper.top = pY1;

                mc.addChild(wrapper);

                createjs.Tween.get(wrapper).wait(index*50).to({
                    scaleY:1
                },200,createjs.Ease.quadOut());

            }
        }

        return mc;
    },
    drawMixingBarGraph:function(data){
        if(!data){
            return;
        }

        var mc = new createjs.MovieClip();

        //local variables
        var graphic,shape,wrapper;

        //draw bar
        var obj;
        var missingImg = ennet.ResourceLoader.resources[ennet.Conf.imgBasePath+'/graph_missing_complement.png'];
        for(var index=0;index<data.length;index++){
            obj = data[index];

            if(obj.status === 'fail'){

                var icon = new createjs.Bitmap(ennet.Conf.imgBasePath+'/icon_missing.png');
                icon.x = Math.floor(obj.left+obj.width/2) - 7;//7=icon.width/2
                icon.y = obj.bottom - 14 - 5;//14=icon.height,5=marginBottom
                mc.addChild(icon);

            }else{

                graphic = new createjs.Graphics();
                if(obj.status === 'cover'){

                    graphic.beginBitmapFill(missingImg,'repeat').drawRoundRectComplex(0,0,obj.width,obj.height,2,2,0,0).endFill();

                }else{
                    //draw color
                    var totalY = 0,tou,height,cnt=0;

                    if(obj.tou_type === 'basic'){
                        for(var i=obj.tou.length-1;i>=0;i--){
                            tou     = obj.tou[i];
                            height  = obj.height * tou.percent * 0.01;
                            if(tou.type === 0){
                                graphic.lf(['#77bb2e','#9ed96d'],[1,0],0,0,0,obj.height);
                            }else if(tou.type === 1){
                                cnt++;
                                graphic.lf(['#61abc9','#a3d5e4'],[1,0],0,0,0,obj.height);
                            }else if(tou.type === 2){
                                cnt++;
                                graphic.lf(['#f4d581','#eaab40'],[1,0],0,0,0,obj.height);
                            }else if(tou.type === 3){
                                cnt++;
                                graphic.lf(['#ed819b','#f2c0cd'],[1,0],0,0,0,obj.height);
                            }
                            if(cnt > 1){
                                totalY--;
                            }

                            graphic.drawRoundRectComplex(0,totalY,obj.width,height,2,2,0,0).endFill();
                            totalY += height;
                        }
                    }else{
                        graphic.lf(['#77bb2e','#9ed96d'],[1,0],0,0,0,obj.height);
                        graphic.drawRoundRectComplex(0,0,obj.width,obj.height,2,2,0,0).endFill();
                    }


                }

                shape   = new createjs.Shape(graphic);
                shape.x = -obj.width/2;
                shape.y = -obj.height;

                //package movieclip
                wrapper = new createjs.MovieClip();
                wrapper.addChild(shape);
                wrapper.scaleY = 0;
                wrapper.x = Math.floor(obj.left+obj.width/2);
                wrapper.y = obj.bottom;

                mc.addChild(wrapper);

                createjs.Tween.get(wrapper).wait(index*50).to({
                    scaleY:1
                },200,createjs.Ease.quadOut());
            }
        }

        return mc;

    },
    drawBlueLineGraph:function(data){
        return ennet.GraphDrawer.drawLineGraph(data,{
            outer:'#d5f0f8',
            inner:['#b4dce0','#78bdc0']
        },
        {
            stroke:'#a3e0f2'
        });
    },
    drawGreenLineGraph:function(data){
        return ennet.GraphDrawer.drawLineGraph(data,{
            outer:'#ffde87',
            inner:['#ffb034','#c47f41']
        },
        {
            stroke:'#f8cc7a'
        });
    },
    drawLineGraph:function(data,circleColor,lineColor){
        if(!data){
            return;
        }

        var mc = new createjs.MovieClip();

        //--animation container
        var container = new createjs.MovieClip();
        mc.addChild(container);

        var line = new createjs.Graphics();
        mc.addChild(new createjs.Shape(line));

        var circle = new createjs.Graphics();
        //draw outer circle
        circle.beginFill(circleColor.outer).drawCircle(5,5,5).endFill();
        //draw inner circle
        circle
        .beginLinearGradientFill(circleColor.inner,[0,1],0,0,10,10)
        .drawCircle(5,5,3).endFill();

        //local variables
        var cx,cy,shape,points=[];
        var interval = 200;
        var wait     = 50;

        var changePoints = function(){
            line.clear();
            line.setStrokeStyle(3,'round').beginStroke(lineColor.stroke);
            for(var index=0;index<points.length;index++){

                if(index === 0){
                    line.moveTo(points[index].x,points[index].y);
                }else{
                    line.lineTo(points[index].x,points[index].y);
                }

            }
        };

        //draw line and circle
        var obj;
        for(var index=0;index<data.length;index++){
            obj = data[index];

            cx = Math.floor(obj.left + obj.width/2);
            cy = obj.top;

            points[index] = {
                x:cx,
                y:obj.bottom,
                h:(obj.bottom - obj.top),
                wait:index*wait/createjs.Ticker.interval,
                end:false
            };

            shape = new createjs.Shape(circle);
            shape.x = cx - 5;
            shape.y = obj.bottom - 5;
            mc.addChild(shape);

            createjs.Tween.get(shape).wait(index*wait).to({
                y:cy-5
            },interval,createjs.Ease.quadOut());

            createjs.Tween.get(points[index],{
                onChange:changePoints
            }).wait(index*wait).to({
                y:cy
            },interval,createjs.Ease.quadOut());
        }

        return mc;
    },

    drawHitarea:function(vo,overCallback,outCallback,clickCallback){
        var data = vo.getBarGraph();
        var baseInfo = vo.getBaseInfo();

        if(!data){
            return;
        }

        var mc = new createjs.MovieClip();

        //local variables
        var graphic,shape;
        var pX1,w,h,barH,barTop;

        //draw bar
        var percent;
        for(var index=0;index<data.length;index++){

            percent = data[index].percent;

            w       = Math.floor(baseInfo.rowWidth);
            h       = Math.floor(baseInfo.baseY);
            pX1     = Math.floor(baseInfo.baseX + baseInfo.rowWidth * index);//bar left x
            //pY1     = Math.floor(baseInfo.baseY - h);
            barH    = Math.floor(percent * (baseInfo.baseY - baseInfo.maxY));
            barTop  = Math.floor(baseInfo.baseY - barH);

            //draw color
            graphic = new createjs.Graphics();
            graphic.beginFill('rgba(0,0,0,0.01)').drawRect(0,0,w,-h).endFill();

            shape       = new createjs.Shape(graphic);
            shape.x     = pX1;
            shape.y     = baseInfo.baseY;
            shape.data  = data[index];
            shape.left  = Math.floor(pX1 + w / 2);
            shape.top   = barTop;
            if(vo.getYear){
                shape.year  = vo.getYear();
            }
            if(vo.getMonth){
                shape.month = vo.getMonth();
            }

            mc.addChild(shape);

            shape.addEventListener('mouseover',overCallback);
            shape.addEventListener('mouseout',outCallback);
            if(clickCallback){
                shape.addEventListener('click',clickCallback);
            }
        }

        return mc;
    },
    drawLastLabel:function(data,layer,labelColor){
        var container = new createjs.MovieClip();
        var graphics = new createjs.Graphics();
        var shape = new createjs.Shape(graphics);
        container.addChild(shape);

        var lastObject = data[data.length -1];
        var posX = lastObject.left + lastObject.width/2;
        var posY = lastObject.top;
        var text = ennet.Util.numberFormat(lastObject.price)+'円';

        //背景描画（白枠）
        var r = 1;
        var width = 70;
        var height = 20;
        graphics.beginFill('#ffffff').drawRoundRect(0,0,width,height,r).endFill();

        //label
        var label = ennet.GraphUtil.makeLabel(
                text,
                11,
                labelColor,
                'left',
                0,
                0
            );
        label.x = width/2 - label.getMeasuredWidth()/2;
        label.y = height/2 - label.getMeasuredHeight()/2;
        container.addChild(label);

        var diff = (lastObject.diff.flag)?-20:20;
        container.x = posX + 20;
        container.y = posY - height/2 + diff;

        layer.addChild(container);

        container.alpha = 0;
        createjs.Tween.get(container).wait(data.length*50).to({
            alpha:1
        },200,createjs.Ease.quadOut());
    }
};

/**
 * [dashedLineTo description] 点線描画拡張
 */
export const dashedLineTo = function(graphics, x1, y1, x2, y2, dashLen) {
    graphics.moveTo(x1, y1);

    var dX = x2 - x1;
    var dY = y2 - y1;
    var dashes = Math.floor(Math.sqrt(dX * dX + dY * dY) / dashLen);
    var dashX = dX / dashes;
    var dashY = dY / dashes;

    var q = 0;
    while (q++ < dashes) {
        x1 += dashX;
        y1 += dashY;
        graphics[q % 2 === 0 ? 'moveTo' : 'lineTo'](Math.floor(x1), Math.floor(y1));
    }
    graphics[q % 2 === 0 ? 'moveTo' : 'lineTo'](x2, y2);
};

function setHeader(xhr) {
    var token = loadData("token");
    var xtoken = loadData("x-token");
    if(token){
        xhr.setRequestHeader('Authorization', token);
    }
    if(xtoken){
        xhr.setRequestHeader('Token', xtoken);
    }
    var login = loginStore();
    xhr.setRequestHeader('Id', login.customer_id);
}

/**
 * User model.
 */
ennet.UserModel = Backbone.Model.extend({
    isLoading: false,
    urlRoot: config.url.get.user,
    loading: function(){},
    loadComplate: function(){},
    defaults: {
        price_menu_name: null, // 値が無い場合項目自体が消えてしまうので初期化
    },
    isUseFromMidlleOfMonth: function() {
        return this.get('tou_type') === null || this.get('tou_type') === undefined;
    },
    /**
     * 月途中からの利用
     *
     * この月は料金タイプが取得できないので、それで判断する。
     */
    parse:function(response){
        // 利用日の日付のみを抽出（時間は不要とのこと ENE_QA-163）
        response.start_date = response.start_date.substr(0, 10);
        response.end_date = response.end_date.substr(0, 10);

        response.start_date = new Date(response.start_date.replace(/-/g,'/'));
        response.end_date = new Date(response.end_date.replace(/-/g,'/'));

        // 互換性確保
        response.startDate = response.start_date;
        response.endDate = response.end_date;

        return response;
    },
    fetch: function(addOptions) {
        var options = {
            beforeSend : setHeader
        };

        $.extend(true, options, addOptions);

        return Backbone.Model.prototype.fetch.call(this, options);
    },
    toJSON: function() {
        var json = Backbone.Model.prototype.toJSON.call(this);

        json.is_use_from_midlle_of_month = this.isUseFromMidlleOfMonth();

        // alias
        json.has_ranking_for_last_month = json.game_ranking_history_flag;

        return json;
    }
});

ennet.UserView = Backbone.View.extend({
    el:'#sideState #userState',
    initialize:function(){
        this.listenTo(this.model,'change',this.render);
    },
    render:function(){
        // this.$el.template(this.model.toJSON());
    }
});

const init2 = function(){
    ennet.MemberApp = ennet.BaseApp.extend({
        initialize: function() {
            ennet.BaseApp.prototype.initialize.call(this);

            this._user = new ennet.UserModel();
        },
    });
}
// $(document).on("ready", init2);
/**
 * [DayGraphVO description]
 */
ennet.MonthGraphVO = function(){
    this.initialize.apply(this,arguments);
};
ennet.MonthGraphVO.prototype = {
    barGraph_:null,
    lineGraph_:null,
    scaleInfo_:null,
    baseInfo_:null,
    total_:0,
    selectValue_:null,
    graphRect_:null,
    daylabels_:null,
    weekdays_:null,
    year_:null,
    month:null,
    initialize:function(){

    },
    getBarGraph:function(){
        return this.barGraph_;
    },
    setBarGraph:function(data){
        this.barGraph_ = data;
    },
    getLineGraph:function(){
        return this.lineGraph_;
    },
    setLineGraph:function(data){
        this.lineGraph_ = data;
    },
    getScaleInfo:function(){
        return this.scaleInfo_;
    },
    setScaleInfo:function(scaleInfo){
        this.scaleInfo_ = scaleInfo;
    },
    getBaseInfo:function(){
        return this.baseInfo_;
    },
    setBaseInfo:function(baseInfo){
        this.baseInfo_ = baseInfo;
    },
    getTotal:function(){
        return this.total_;
    },
    setTotal:function(total){
        this.total_ = total;
    },
    getSelectValues:function(){
        return this.selectValues_;
    },
    setSelectValues:function(values){
        this.selectValues_ = values;
    },
    getGraphRect:function(){
        return this.graphRect_;
    },
    setGraphRect:function(graphRect){
        this.graphRect_ = graphRect;
    },
    getDayLabels:function(){
      return this.daylabels_;
    },
    setDayLabels:function(daylabels){
        this.daylabels_ = daylabels;
    },
    getWeekDays:function(){
        return this.weekdays_;
    },
    setWeekDays:function(weekdays){
        this.weekdays_ = weekdays;
    },
    getYear:function(){
        return this.year_;
    },
    setYear:function(year){
        this.year_ = year;
    },
    getMonth:function(){
        return this.month_;
    },
    setMonth:function(month){
        this.month_ = month;
    },
};

/**
 * MonthGraph model
 */
ennet.MonthGraphModel = Backbone.Model.extend({
    isLoading: false,
    urlRoot: config.url.get.week,
    loading: function(){},
    loadComplate: function(){},
    parse: function(response){
        //var year = response.year;
        //var month = response.month;

        return response;
    },
    fetch: function(year, month, addOptions) {
        var options = {
            data : {},
            beforeSend: setHeader
        };

        // 渡されたパラメータを設定（一部でも）
        if (year) {
            options.data.year = year;
        }

        if (month) {
            options.data.month = month;
        }


        if (addOptions) {
            $.extend(true, options, addOptions);
        }

        return Backbone.Model.prototype.fetch.call(this, options);
    },
    getGraphVO:function(
        compare,
        type,
        graphRect){

        var vo      = new ennet.MonthGraphVO();
        var barGraph=null;
        var json = this.toJSON();

        //1h or halfHour
        barGraph  = (json.data)?json.data:null;

        var total = 7;
        barGraph  = this.fixGapBarGraph(barGraph,total);

        //max
        var max = ennet.GraphUtil.maxInArray(barGraph,type);

        //scaled max
        var scaleInfo = ennet.GraphUtil.calculateScaleInfo(max);

        var daylabels = [];
        for(var i=0;i<total;i++){
          daylabels.push(barGraph[i].daylabel);
        }
        var weekdays = ennet.GraphUtil.calculateWeekDays(this.get('year'),this.get('month'),total);

        /**
         * a bar width rate
         *      |////|
         *      |////|
         *      |////|
         *  --------------
         *  33% |33% |33%
         *  18px|18px|18px
         */
        var baseInfo       = {};
        baseInfo.rowWidth  = graphRect.width / (total+1);//include bar width and bar left/right padding
        baseInfo.baseX     = Math.floor(graphRect.x + baseInfo.rowWidth*0.5);//include left margin
        baseInfo.baseY     = Math.floor(graphRect.y + graphRect.height);
        baseInfo.maxY      = Math.floor(graphRect.y + 40);//upper padding for button

        ennet.GraphUtil.calculatePosition(barGraph,type,scaleInfo,baseInfo);

        vo.setBarGraph(barGraph);
        vo.setScaleInfo(scaleInfo);
        vo.setBaseInfo(baseInfo);
        vo.setTotal(total);
        vo.setSelectValues({
            'compare':compare,
            'type':type
        });
        vo.setGraphRect(graphRect);
        vo.setDayLabels(daylabels);
        vo.setWeekDays(weekdays);
        vo.setYear(this.get('year'));
        vo.setMonth(this.get('month'));

        return vo;
    },
    fixGapBarGraph:function(array,total){
        return this.fixGapArray(array,total,{
            rate:0
        });
    },
    fixGapArray:function(array,total,emptyObject){
        if(array.length === 0){
            return array;
        }

        var fixedArray = [],flag,counter = 0;
        for(var i=1;i<=total;i++){
            flag = false;
            for(var j=0;j<array.length;j++){

                if(i === array[j].day){
                    counter++;
                    flag = true;
                    fixedArray.push(array[j]);
                    break;
                }

            }
            if(!flag && counter === 0){
                var clone = _.clone(emptyObject);
                clone.day = i;
                fixedArray.push(clone);
            }
        }
        return fixedArray;
    },
    setYear:function(year){
        this.year = year;
    },
    setMonth:function(month){
        this.month = month;
    },

});

ennet.MonthGraphController = Backbone.View.extend({
    initialize:function(options){
        this.stage = options.stage;

        // popup
        this.$popup = this.$el.parent().find('.inBox');
        this.hidePopup();
    },
    update:function(vo){
        this.vo = vo;

        this.render();
    },
    render:function(){

        // first initialize stage
        ennet.GraphUtil.recursiveRemoveEvents(this.stage);
        this.stage.removeAllChildren();

        //this.drawBackground();
        this.stage.addChild(
            ennet.GraphDrawer.drawMonthBackground(this.vo)
        );

        // draw graph
        var layer;
        if(this.vo.getSelectValues().type === 'price'){
            layer = ennet.GraphDrawer.drawMixingBarGraph(this.vo.getBarGraph());
        }else{
            layer = ennet.GraphDrawer.drawBarGraph(this.vo.getBarGraph());
        }
        this.stage.addChild(layer);

        this.stage.addChild(ennet.GraphDrawer.drawBlueLineGraph(
            this.vo.getLineGraph()
        ));

        // make hitarea
        this.stage.addChild(
            ennet.GraphDrawer.drawHitarea(
                this.vo,
                $.proxy(this.onMouseOver,this),
                $.proxy(this.onMouseOut,this),
                $.proxy(this.onClick,this)
            )
        );

        return this;//needs backbone
    },
    onMouseOver:function(event){
        // this.stage.canvas.style.cursor = 'pointer';
        this.setContentPopup(event.currentTarget);
        this.showPopup();
    },
    onMouseOut:function(){
        // this.stage.canvas.style.cursor = 'default';
        this.hidePopup();
    },
    onClick:function(event){
        var mc = event.currentTarget;

        this.trigger('click_graph_bar', mc.year, mc.month, mc.data.day);
    },
    showPopup:function(){
        this.$popup.delay(100).show();
    },
    setContentPopup:function(mc){
        var rate = '--';
        if(mc.data.rate){
            rate = mc.data.rate;
        }
        this.$popup.find('.power').html('<dt style="padding:0;margin:0">使用電力量:</dt><dl>'+rate.toLocaleString()+'kWh</dl>');
        this.$popup.find(".power").css("margin", "0");

        var posX = mc.left - this.$popup.width()/2;
        var posY = mc.top - this.$popup.height() - 10;//10 = bias

        this.$popup.css({'top':posY+'px','left':posX+'px'});
    },
    hidePopup:function(){
        this.$popup.hide();
    }
});

ennet.MonthGraphView = Backbone.View.extend({
    el:'#month',
    events:{
        //'change #unit select':'onChangeType',
        //'change .compare select':'onChangeCompare',
    },

    initialize:function(){
        //init stage
        this.stage = new createjs.Stage(this.$el.find('#graphView canvas').get(0));
        this.stage.enableMouseOver(10);//10 times per second

        this.graphRect = ennet.GraphUtil.makeRect(
            this.stage,
            {
                left:50,
                top:0,
                right:60,
                bottom:30
            }
        );

        this.graph = new ennet.MonthGraphController({
            el:this.stage.canvas,
            stage:this.stage
        });
        this.listenTo(this.graph, 'click_graph_bar', this.selectDay);

        this.listenTo(this.model,'request',this.startLoading);
        this.listenTo(this.model,'sync',this.onSuccess);

    },
    startLoading:function(){
        this.$el.find('#graphView .loader').show();
    },
    onSuccess:function(){
        this.$el.find('#graphView .loader').hide();
        this.render();
    },
    render:function(){
        //last_year or all

        //draw graph
        this.renderGraph();

        //var json = this.model.toJSON();

        return this;
    },
    renderGraph:function(){
        this.trigger('startRender');

        var vo = this.model.getGraphVO(
            this.compare,
            'rate',
            this.graphRect
        );

        this.graph.update(vo);
    },
    onChangeCompare:function(event){
        var select = event.currentTarget;

        this.compare = this.getCompareByHtml(select);

        this.render();
    },
    getCompareByHtml: function(target) {
        var val;

        if (target) {
            val = target.value;
        } else {
            val = this.$el.find('.compare select').val();
        }

        if (val !== 'last_year' && val !== 'all') {
            val = null;
        }

        return val;
    },
    selectDay: function(year, month, day) {
        this.trigger('select_day', year, month, day);
    }
});

/**
 * ConditionTab view
 * @todo GraphControllerからのアクセスを調整
 */
ennet.ConditionTabView = Backbone.View.extend({

    defaultDate: { year: null, month: null, day: null },
    currentDate: null, // Date
    monthModel: null,
    monthView: null,
    syncedUser: false,
    selectTmpl:null,
    initialize: function(options) {
        this.monthModel = options.models.monthGraph;
        this.listenTo(this.monthModel, 'sync', this.syncMonthGraph);
        this.monthView  = new ennet.MonthGraphView({ model: this.monthModel });
        this.listenTo(this.monthView, 'select_day', this.selectDay);
        this.listenTo(this.monthView,'startRender',this.startRender);

        this.userModel  = options.models.user;
        this.listenTo(this.userModel, 'sync', this.syncUser);

        this.selectTmpl = ""; // _.template($('#selectComparisonTmpl').html());

        if (options.defaultDate) {
            this.defaultDate = options.defaultDate;
        }

    },
    startRender:function(){

        //各Viewがupdateした際に呼ばれる
        window.clearTimeout(this.timeId);

        //レンダリング再開
        createjs.Ticker.init();
        createjs.Ticker.setFPS(ennet.Conf.getFPS());
        createjs.Ticker.addEventListener('tick',this.monthView.stage);
        createjs.Ticker.addEventListener('tick',createjs.Tween);

        //レンダリング実行停止
        this.timeId = window.setTimeout(function(){
            createjs.Ticker.reset();
            //easeljs0.7.1では以下は初期化されないので強制初期化
            createjs.Ticker._timerId = null;
            createjs.Ticker._inited = false;
        },ennet.Conf.ANIMATION_RESET_TIME);
    },
    render: function() {


        return this;
    },

    syncUser: function() {
        // 初回のみ想定
        if (this.syncedUser === false) {
            this.syncedUser = true;

            var date = this.defaultDate;
            this.fetchGraphData(date.year, date.month);
        }
    },
    fetchGraphData: function(year, month) {
        this.monthModel.fetch(year, month);
    },
    syncMonthGraph: function(monthGraph) {
        this.updateMonthLabel(monthGraph.get('year'), monthGraph.get('month'));
    },
    updateMonthLabel: function(year, month) {
        $('#month .selectDate .current').text(
            year+'年'+month+'月');

        var serviceStartDate = this.userModel.get('start_date');
        var serviceEndDate   = this.userModel.get('end_date');

        if (
            year > serviceStartDate.getFullYear() ||
            (year === serviceStartDate.getFullYear() && month > serviceStartDate.getMonth() + 1)
        ) {
            $('#month .selectDate .prev').removeClass('disable');

        } else {
            $('#month .selectDate .prev').addClass('disable');
        }

        if (
            year < serviceEndDate.getFullYear() ||
            (year === serviceEndDate.getFullYear() && month < serviceEndDate.getMonth() + 1)
        ){
            $('#month .selectDate .next').removeClass('disable');

        }else{
            $('#month .selectDate .next').addClass('disable');
        }
    },
    isWithinLimitDate:function(year,month,day){
        //month === Date.getMonth()+1;
        var serviceStartDate = this.userModel.get('start_date');
        var serviceEndDate   = this.userModel.get('end_date');
        var startDate = new Date(serviceStartDate);
        var endDate = new Date(serviceEndDate);

        if(day){
            //日があるとき（日に遷移時）
            var currentDate = new Date(year,month-1,day);

            if(currentDate.getTime() > endDate.getTime()){
                return false;
            }
            if(currentDate.getTime() < startDate.getTime()){
                return false;
            }

        }else{
            //日がないとき（月に遷移）
            var startYear = startDate.getFullYear();
            var startMonth = startDate.getMonth() + 1;
            var endYear = endDate.getFullYear();
            var endMonth = endDate.getMonth() + 1;
            if(startYear > year || (startYear === year && startMonth > month)){
                return false;
            }
            if(endYear < year || (endYear === year && endMonth < month)){
                return false;
            }
        }

        return true;
    },
    validateCurrentDate:function(year,month,day,isDay){
        //month === Date.getMonth();
        var serviceStartDate = this.userModel.get('start_date');
        var serviceEndDate   = this.userModel.get('end_date');
        var startDate = new Date(serviceStartDate);
        var endDate = new Date(serviceEndDate);

        var currentDate = new Date(year,month,day);

        if(currentDate.getTime() > endDate.getTime()){
            return endDate;
        }else if(currentDate.getTime() < startDate.getTime()){
            return startDate;
        }

        if(!isDay){
            var maxDay = ennet.GraphUtil.getDaysInMonth(year,month+1);

            if(day > maxDay){
                day = maxDay;
            }
        }

        return new Date(year,month,day);
    }
});
/**
 * 電気を見る 電気代、電力量
 */
const init3 = function () {
    var request = ennet.Request;

    var tabView;

    var ConditionApp = ennet.MemberApp.extend({
        monthGraphSynced: false,
        _execute:function(){
            // var user = this.getUser(); // @see MemberApp._postExecute()
            var user = new ennet.UserModel(); // イベント監視より先にsyncされる可能性があるため

            var monthGraph = new ennet.MonthGraphModel();
            this.listenTo(monthGraph, 'sync', this.monthGraphSync);

            var date = {
                year:  request.getParameter('year'),
                month: request.getParameter('month'),
                day:   request.getParameter('day')
            };

            this.view = new ContentView({ model: user });

            ennet.ResourceLoader.load([
                ennet.Conf.imgBasePath+'/graph_missing_complement.png',
                ennet.Conf.imgBasePath+'/graph_dr.png',
                ennet.Conf.imgBasePath+'/icon_missing.png',
                ennet.Conf.imgBasePath+'/icon_dr.png',
                ennet.Conf.imgBasePath+'/icon_achivement.png'
            ], function() {
                tabView = new ennet.ConditionTabView({
                    models: {
                        user: user,
                        monthGraph: monthGraph,
                    },
                    defaultDate: date
                });
                this.listenTo(tabView, 'invalid', this.redirect404);
                this.listenTo(tabView, 'tab_changed', this.tabChanged);
                tabView.render();

                user.fetch();
            }.bind(this));
        },
        monthGraphSync: function() {
            this.monthGraphSynced = true;
        },

    });

    var ContentView = Backbone.View.extend({
        el: '#mainContent',
        initialize: function() {
            this.listenTo(this.model, 'sync', this.render);
        },
        render: function() {

            return this;
        }
    });

    var app = new ConditionApp();
    window.app = app;
    app.run();
}
//$(document).on("ready", init3);
export default ennet;
export const execute = function(){
    init1();
    init2();
    init3();
}


<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="'html not-front page-faq-top responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu" data-bid="273">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">よくあるご質問</span>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">よくあるご質問</h1>
          <!-- </div> /#content-header -->
          <div id="content-area">
            <div class="block block-views" data-bid="352">
              <form action="/mansion/faq_search" method="get" id="views-exposed-form-faq-search-page-1" accept-charset="UTF-8"  @submit.prevent="$router.push({path: '/mansion/faq_search', query : {title : encodeURIComponent(keyword.trim())}})">
                <div>
                  <div class="views-exposed-form">
                    <div class="views-exposed-widgets clearfix">
                      <div id="edit-title-wrapper" class="views-exposed-widget views-widget-filter-title">
                        <div class="views-widget">
                          <div class="form-item form-type-textfield form-item-title">
                            <input placeholder="いずれかのキーワードを含む検索" type="text" id="edit-title" name="title" size="55" maxlength="128" class="form-text"  v-model="keyword"/>
                          </div>
                        </div>
                      </div>
                      <div class="views-exposed-widget views-submit-button">
                        <input type="submit" id="edit-submit-faq-search" name="" value="検索" class="form-submit" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- /block -->
            <div class="view view-faq-list view-id-faq_list view-display-id-page_4 view-dom-id-1228ba996922b7615c2c74cff7851597">
              <div class="view-header">
                <h3>今、注目のご質問をピックアップ！</h3>
              </div>
              <div class="view-content">
                <table class="views-view-grid cols-1">
                  <tbody>
                    <template v-for="f in pickup" :key="f">
                      <tr class="row">
                        <td class="col-1 col-first">
                          <div class="views-field views-field-title-1">
                            <span class="field-content"><router-link :to="'/mansion/faq/' + f.FaqID" target="_blank">{{ f.Title }}</router-link></span>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="view-footer">
                <div class="view view-faq-category-list view-id-faq_category_list view-display-id-block view-dom-id-46941c4cdfcb90def71da03cf49417ac">
                  <div class="view-header">
                    <h3 class="title">カテゴリから選ぶ</h3>
                  </div>
                  <div class="view-content">
                    <template v-for="(f, index) in category" :key="f">
                      <div class="views-row">
                        <div class="views-field views-field-name">
                          <span class="field-content"><router-link :to="'/mansion/faq_category_list/' + index" target="_blank">{{ f }}</router-link></span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(c, index) in category" :key="c">
            <div class="view view-faq-list view-id-faq_list view-display-id-block">
              <div class="view-header">
                <h2>{{ c }}</h2>
              </div>
              <div class="view-content">
                <table class="views-view-grid cols-1">
                  <tbody>
                    <template v-for="(f, i) in catelist[index]" :key="f">
                      <template v-if="i < 5">
                        <tr class="row row-first">
                          <td class="col col-first">
                            <div class="views-field views-field-php">
                              <span class="field-content">
                                <fieldset :class="'collapsible form-wrapper collapse-processed' + (f.IsOpen ? '': ' collapsed')">
                                  <legend>
                                    <span class="fieldset-legend">
                                      <a href="#" class="fieldset-title" @click.prevent.stop="f.IsOpen = !f.IsOpen">
                                        {{ f.Title }}
                                      </a>
                                      <span class="summary"></span>
                                    </span>
                                  </legend>
                                  <div class="fieldset-wrapper" v-show="f.IsOpen">
                                    <div v-html="f.Body" class="nid" >
                                    </div>
                                  </div>
                                </fieldset>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <template v-if="catelist[index].length > 5">
                <div class="more-link">
                  <router-link :to="'/mansion/faq_category_list/' + index" target="_blank">一覧へ </router-link >
                </div>
              </template>
            </div>
            </template>
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from '../components/Header_info.vue';
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from '../components/Sidebar_first_login.vue';
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { getData } from "../assets/js/request.js";
import "../assets/js/mems_faq_searchkey_half_space.js";
import { isLogin } from "../stores/store.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    // ピックアップ
    return {
      isLogin: isLogin(),
      keyword : "",
      pickup : [],
      catelist : [],
      category: []
    };
  },
  async created(){
    var category = [
      "燃料費調整額の変更について",
      "電気の契約・手続きについて",
      "でんき案内板のログインについて",
      "料金プラン（契約容量・アンペア数）について",
      "電気料金の支払について",
      "料金明細・請求書について",
      "停電・故障・法定点検について",
      "退去について",
      "部屋のオーナー・仲介会社・リフォーム会社等の手続きについて",
      "節電ポイントサービス（DR）について",
      "電気以外の手続きについて",
      "その他サービス等について",
      "共用部・店舗のお客様の「よくあるご質問」はこちら",
      "NTTアノードエナジーへの事業承継について",
    ];
    var pickup = await getData(config.url.get.faq_list + "?type=pickup");
    // [ { FaqID, Title, Body, Tag } ]
    // カテゴリ
    var catelist = [];
    // eslint-disable-next-line no-unused-vars
    for (var index in category) {
      var categoryResult = await getData(config.url.get.faq_list + "?type=category&category=" + index);
      // [ { FaqID, Title, Body, Tag } ]
      // eslint-disable-next-line no-unused-vars
      for (var d in categoryResult) {
        categoryResult[d]['isOpen'] =  true;
      }
      catelist.push(categoryResult);
    }
    Object.assign(this.$data, {
      pickup : pickup,
      catelist : catelist,
    });
    this.$data.category = category;
  }
};
</script>
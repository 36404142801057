<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front not-logged-in no-sidebars page-member page-member-procedure page-member-procedure-complete responsive-menus-load-processed">
    <Header />
    <div class="messages status">
      <h2 class="element-invisible">ステータスメッセージ</h2>
      でんきの利用申込みを受け付けました。
    </div>
    <div id="highlighted">
      <Header_info/>
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even"><router-link to="/mansion/entry">ＮＴＴアノードエナジーの「マンション電力提供サービス」へようこそ</router-link></span> <span class="delimiter">»</span> <span class="inline odd last">電気の利用申込み　受付完了</span>
        </div>
        <section id="content">
          <h1 class="title">電気の利用申込み　受付完了</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq/oshieteidpwtsuuchi" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page view-dom-id-1534f8fd451398bc5f55d83f293341d2">
              <div class="view-header">
                <div class="entry-complete-header-1">
                  電気の利用申込みを受け付けました。<br />
                  受付のお知らせメールを送信しましたのでご確認ください。<br />
                  ※届いていない場合は、カスタマーセンターまでお問い合わせください。お問い合わせは<router-link to="/mansion/inquiry" target="_blank">こちら</router-link>から<br />
                </div>
                <br />
                <div class="entry-complete-header-2">
                  【お願い】<br />
                  （１）1週間ほどで登録いただいた「お支払者情報」のメールアドレスへログインIDと初期パスワードが届き<br />
                  ますので、お客様のマイページを忘れずに登録してください。<br />
                  ※電気料金の明細、電気使用量などが確認できるようになります。<br />
                  <br />
                  （２）ご希望のお支払方法（クレジットカード、口座振替等）をお客様のマイページよりお選びください。<br />
                  ※お支払方法の登録がない場合は、請求書（請求代行サービス「後払い.com」を利用したお支払）でのお支払<br />
                  となります。<br />
                </div>
                <br />
                <div class="static__half_colmn_3">
                  <p>ご利用ガイド</p>
                  <a :href="dir + '/guide.pdf'" target="_blank">
                    <img src="../assets/images/guide_PN_2_2016101.jpg" />
                  </a>
                </div>
              </div>
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (isOpen1 ? '': ' collapsed')"  data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="isOpen1 = !isOpen1"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="isOpen1">
                      <div class="views-field views-field-value-1">
                        <strong class="views-label views-label-value-1">電気をご利用のマンション名: </strong><span class="field-content"> {{ entry_form.form_mansion_name }}</span>
                      </div>
                      <div class="views-field views-field-value-2">
                        <strong class="views-label views-label-value-2">部屋番号: </strong><span class="field-content">{{ entry_form.form_room_no }}</span>
                      </div>
                      <div class="views-field views-field-value">
                        <strong class="views-label views-label-value">部屋のお引渡日: </strong><span class="field-content">{{ entry_form.move_date_year + "年" + entry_form.move_date_month + "月" + entry_form.move_date_date + "日" }}</span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (isOpen2 ? '': ' collapsed')"  data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="isOpen2 = !isOpen2"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご契約者情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="isOpen2">
                      <div class="views-field views-field-value-3"><strong class="views-label views-label-value-3">お名前: </strong><span class="field-content">{{ contractor.contractor_name }}</span></div>
                      <div class="views-field views-field-value-4"><strong class="views-label views-label-value-4">フリガナ: </strong><span class="field-content">{{ contractor.contractor_kana }}</span></div>
                      <div class="views-field views-field-value-27"><strong class="views-label views-label-value-27">生年月日: </strong><span class="field-content">{{ contractor.birthday_year + "年" + contractor.birthday_month + "月" + contractor.birthday_date + "日" }}</span></div>
                      <template v-if="entry_form.same">
                        <div class="views-field views-field-value-28"><strong class="views-label views-label-value-28">ご住所: </strong><span class="field-content">電気をご利用のマンションと同じ</span></div>
                      </template>
                      <template v-else>
                        <div class="views-field views-field-value-5"><strong class="views-label views-label-value-5">郵便番号: </strong><span class="field-content">{{ contractor.contractor_zip_postal_code }}</span></div>
                        <div class="views-field views-field-value-6"><strong class="views-label views-label-value-6">都道府県: </strong><span class="field-content">{{ contractor.contractor_state }}</span></div>
                        <div class="views-field views-field-value-7"><strong class="views-label views-label-value-7">市区町村: </strong><span class="field-content">{{ contractor.contractor_city }}</span></div>
                        <div class="views-field views-field-value-8"><strong class="views-label views-label-value-8">町名: </strong><span class="field-content">{{ contractor.contractor_address_line }}</span></div>
                        <div class="views-field views-field-value-9"><strong class="views-label views-label-value-9">番地: </strong><span class="field-content">{{ contractor.contractor_address_line_2 }}</span></div>
                        <div class="views-field views-field-value-10"><strong class="views-label views-label-value-10">マンション名・部屋番号等: </strong><span class="field-content"> </span>{{ contractor.contractor_address_other }}</div>
                      </template>
                      
                      <div class="views-field views-field-value-11"><strong class="views-label views-label-value-11">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ contractor.contractor_phone }}</span></div>
                      <div class="views-field views-field-value-31"><strong class="views-label views-label-value-31">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content">{{ contractor.contractor_tel }}</span></div>
                      <div class="views-field views-field-value-12"><strong class="views-label views-label-value-12">メールアドレス: </strong><span class="field-content">{{ contractor.contractor_email }}</span></div>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (isOpen3 ? '': ' collapsed')" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="isOpen3 = !isOpen3"><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払者情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="isOpen3">
                      <div class="views-field views-field-value-14"><strong class="views-label views-label-value-14">お名前: </strong><span class="field-content">{{ payer.payer_name }}</span></div>
                      <div class="views-field views-field-value-15"><strong class="views-label views-label-value-15">フリガナ: </strong><span class="field-content">{{ payer.payer_kana }}</span></div>
                      <div class="views-field views-field-value-28"><strong class="views-label views-label-value-28">生年月日: </strong><span class="field-content">{{ payer.birthday_year + "年" + payer.birthday_month + "月" + payer.birthday_date + "日" }}</span></div>
                      <div class="views-field views-field-value-29"><strong class="views-label views-label-value-29">ご契約者様との続柄: </strong><span class="field-content">
                        <template v-if="payer.same">本人</template>
                        <template v-else-if="payer.payer_relationship == 0">本人</template>
                        <template v-else-if="payer.payer_relationship == 1">配偶者</template>
                        <template v-else-if="payer.payer_relationship == 2">その他</template>
                      </span></div>
                      <div class="views-field views-field-value-30"><strong class="views-label views-label-value-30">その他続柄: </strong><span class="field-content">{{ payer.payer_relationship_etc }}</span></div>
                      <template v-if="payer.same">
                        <div class="views-field views-field-value-31"><strong class="views-label views-label-value-16">ご住所: </strong><span class="field-content">ご契約者様と同じ</span></div>
                      </template>
                      <template v-else>
                        <div class="views-field views-field-value-16"><strong class="views-label views-label-value-16">郵便番号: </strong><span class="field-content">{{ payer.payer_zip_postal_code }}</span></div>
                        <div class="views-field views-field-value-17"><strong class="views-label views-label-value-17">都道府県: </strong><span class="field-content">{{ payer.payer_state }}</span></div>
                        <div class="views-field views-field-value-18"><strong class="views-label views-label-value-18">市区町村: </strong><span class="field-content">{{ payer.payer_city }}</span></div>
                        <div class="views-field views-field-value-19"><strong class="views-label views-label-value-19">町名: </strong><span class="field-content">{{ payer.payer_address_line }}</span></div>
                        <div class="views-field views-field-value-20"><strong class="views-label views-label-value-20">番地: </strong><span class="field-content">{{ payer.payer_address_line_2 }}</span></div>
                        <div class="views-field views-field-value-21"><strong class="views-label views-label-value-21">マンション名・部屋番号等: </strong><span class="field-content">{{ payer.payer_address_other }}</span></div>
                      </template>
                      <div class="views-field views-field-value-23"><strong class="views-label views-label-value-23">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ payer.payer_phone }}</span></div>
                      <div class="views-field views-field-value-32"><strong class="views-label views-label-value-32">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content">{{ payer.payer_tel }}</span></div>
                      <div class="views-field views-field-value-22"><strong class="views-label views-label-value-22">メールアドレス: </strong><span class="field-content">{{ payer.payer_email }}</span></div>
                    </div>
                  </fieldset>
                  <fieldset class="other views-fieldset" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend">その他</span>
                    </legend>
                    <div class="fieldset-wrapper">
                      <div class="views-field views-field-value-24"><strong class="views-label views-label-value-24">料金プラン・契約容量: </strong><span class="field-content">
                        <template v-if="entry_form.form_plan == 0">変更なし（初期契約容量のまま）</template>
                        <template v-if="entry_form.form_plan == 1">変更</template>
                      </span></div>
                      <div class="views-field views-field-value-25"><strong class="views-label views-label-value-25">希望種別: </strong><span class="field-content"> {{ entry_form.form_kind }}</span></div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="view-footer">
                <div id="top_link"><router-link to="/mansion/">TOPへ</router-link></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import { entryStore } from "../stores/store.js";
import config from "../config.js";
export default {
  components: {
    Header,
    Header_info,
    Footer,
  },
  data() {
    var store = entryStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push("/mansion/");
    } else {
      var update = store.update;
      store.$reset();
      update.dir = config.url.get.download;
      update.isOpen1 = true;
      update.isOpen2 = true;
      update.isOpen3 = true;
      return update;
    }
  },
};
</script>
<style scoped src="../assets/css/style.css" />

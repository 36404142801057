<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-change page-change-payment page-change-payment-confirm responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter"> »</span> <span class="inline odd last">確認画面</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <h2 class="element-invisible">現在地</h2>
        <section id="content">
          <h1 class="title">確認画面</h1>
          <div id="content-area">
            <form class="webform-client-form webform-client-form-82 preview" action="/mansion/change/payment/confirm?type=payment_method" method="post" id="webform-client-form-change-payment" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div class="webform-progressbar"></div>
                <div class="messages warning"><p>変更内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>
                <div class="form-item webform-component webform-component-display webform-component--field-change-transfer-paper webform-container-inline">
                  <label>ご希望のお支払方法 </label>
                  請求書（後払い.com）
                </div>
                <div class="form-item form-type-password form-item-auth-pass">
                  <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                  <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password"/>
                  <div class="description">パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link></div>
                </div>
                <div class="form-actions"><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" :disabled="send" @click="check"/></div>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { paymentStore } from "../stores/store.js";
import config from "../config.js"
import { post } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = paymentStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push({ path: "/mansion/change/payment" });
      store.$reset();
    }
    return { 
      auth_error: false,
      send : false,
      password : ""
    };
  },
  methods: {
    check: function() {
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push: async function () {
      try{
        this.$data.send = true;
        this.$data.auth_error = false;
        await post(config.url.post.payment + "?paymentMethod=0", {
              payment: "04001-0",
              password: this.$data.password,
        })
        this.$router.push({
          path: "/mansion/change/payment_complete",
        });
      }catch(ex){
        if(ex.response.data == "PasswordError"){
          this.$data.auth_error = true;
          this.$data.send = false;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
    },
  },
};
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

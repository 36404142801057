<template>
<div/>
</template>
<script>
import { mainMessage, removeData, loginStore } from '../stores/store.js';
export default {
    data() {
        var message = mainMessage();
        message.green = 3;
        removeData("token");
        removeData("x-token");
        removeData("last_login_datetime");
        loginStore().$reset();
        sessionStorage.clear();
        this.$router.push({ path : "/mansion/" });
        return {};
    }
}
</script>

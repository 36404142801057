<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in no-sidebars page-user page-user-password responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">メールアドレス登録</span>
        </div>
        <section id="content">
          <h1 class="title">メールアドレス登録</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help"><router-link to="/mansion/faq/oshietepw" target="_blank">FAQ</router-link></div>
          </div>
          <!-- /block -->
          <div id="content-area">
            <form action="#" method="post" id="user-pass" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div id="edit-step" class="form-item form-type-item">
                  <div class="step1">Step 1/2</div>
                </div>
                <p>
                  まずは、でんき案内板のログインに使用するメールアドレス（ポータルサイトメールアドレス）を登録してください。<br />登録後、ポータルサイトメールアドレス宛に、「パスワードの設定について」というメールが送信されますので、<br />
                  メールの案内に従い、お客様ご自身のパスワードを設定してください。<br />
                  ※ 入力されたメールアドレスは非公開にされ、でんき案内板からお客様へのメール送信のみに使用されます。<br />
                  ※ ドメインは「@denki-annai.com」です。メールが受信できるようにドメイン指定を設定してください。
                </p>
                <div class="form-item form-type-textfield form-item-field-user-mail">
                  <label for="email">ポータルサイトメールアドレス <span class="form-required" title="これは入力必須項目です。">*</span></label>
                  <input required type="email" id="email" name="field_user_mail" size="60" maxlength="254" class="form-text required" v-model="mail" />
                </div>
                <div id="edit-policy" class="form-wrapper">
                  <div class="privacy_policy">
                    <span class="form-required" title="このフィールドは必須です。">*</span>
                    <div class="form-item form-type-checkbox form-item-privacy">
                      <input required type="checkbox" id="edit-privacy" name="privacy" class="form-checkbox" v-model="check1"/> <label class="option" for="edit-privacy"><a href="https://www.ntt-ae.co.jp/privacy/" target="_blank">個人情報保護方針</a>に同意します。 </label>
                    </div>
                  </div>
                  <div class="user_policy">
                    <span class="form-required" title="このフィールドは必須です。">*</span>
                    <div class="form-item form-type-checkbox form-item-user">
                      <input required type="checkbox" id="edit-user" name="user" value="1" class="form-checkbox" v-model="check2"/> <label class="option" for="edit-user"><router-link to="/mansion/user_terms" target="_blank">サイトの利用規約</router-link>に同意します。 </label>
                    </div>
                  </div>
                </div>
                <div class="form-actions form-wrapper" id="edit-actions"><input type="submit" id="edit-submit" name="op" value="登録" class="form-submit" @click="check" :disabled="send"/></div>
              </div>
            </form>
          </div>
        </section>
        <div v-show="false">
          <Sidebar_first_login/>
        </div>
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Footer from "../components/Footer.vue";
import Sidebar_first_login from "@/components/Sidebar_first_login.vue";
import { loginStore, mainMessage, removeData } from "../stores/store.js";
import { put } from "../assets/js/request.js";
import config from "../config.js";
export default {
  components: {
    Header_login,
    Footer,
    Sidebar_first_login
  },
  data(){
    return {
      check1 : false,
      check2 : false,
      mail : "",
      send : false
    }
  },
  methods: {
    check: function(){
      var email1 = document.querySelector("#email");
      email1.setCustomValidity("");
      if (email1.validity.valueMissing) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }
      var splited1 = this.$data.mail.split("@");
      if (email1.validity.typeMismatch){
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if (splited1.length == 2 && (splited1[1].length == 0 || splited1[1].length == splited1[1].replace(".", "").length)) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if(splited1[0].length > 64) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }
      var privacy = document.querySelector("#edit-privacy");
      privacy.setCustomValidity("");
      if (!this.$data.check1) {
        privacy.setCustomValidity("個人情報保護方針を確認し、同意する場合はチェックをして下さい。");
      }
      var user = document.querySelector("#edit-user");
      user.setCustomValidity("");
      if (!this.$data.check2) {
        user.setCustomValidity("サイトの利用規約を確認し、同意する場合はチェックをして下さい。");
      }
    },
    push: async function () {
      this.$data.send = true;
      await put(config.url.put.mail, {
          customer : {
            portal_email : this.$data.mail
          }
      });
      removeData("x-token");
      loginStore().$reset();
      mainMessage().green = 1;
      this.$router.push({ path: "/mansion/" });
    },
  },
};
</script>
<style scoped>
.form-text {
  width: 200px;
}
</style>
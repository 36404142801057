<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
  <Header_login />
  <!-- ______________________ MAIN _______________________ -->
  <div id="main">
    <div class="container">
      <h2 class="element-invisible">現在地</h2>
      <div class="breadcrumb">
        <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
          »</span> <span class="inline even"><router-link to="/mansion/member/profile">会員情報</router-link></span> <span class="delimiter">
          »</span> <span class="inline odd last">お支払者様情報の変更</span>
      </div>
      <div class="messages error" v-if="search_error">
        <h2 class="element-invisible">エラーメッセージ</h2>
        郵便番号検索に失敗しました。
      </div>
      <section id="content">
        <!-- <div id="content-header"> -->
        <h1 class="title">お支払者様情報の変更</h1>
        <div class="block block-system" data-bid="255">
          <div class="enne_help"><router-link to="/mansion/faq/oshieteshiharaisya" target="_blank">FAQ</router-link></div>
        </div>
        <!-- /block -->
        <!-- </div> /#content-header -->
        <div id="content-area">
          <article class="node node-webform" data-nid="82">
            <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
            <div class="content">
              <form class="webform-client-form webform-client-form-82 webform-conditional-processed" action="/mansion/member/procedure?type=payer_confirm" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push">
                <div>
                  <div class="webform-progressbar"></div>
                  <div class="form-item webform-component webform-component-hidden webform-component--url" style="display: none">
                  </div>
                  <div class="form-item webform-component webform-component-hidden webform-component--req-id" style="display: none">
                  </div>
                  <div class="form-item webform-component webform-component-markup webform-component--change-contracts-info-description">
                    <p>
                      登録情報を変更したい場合は、該当欄に新しい内容をご記入ください。<br />
                      新たな記入がない項目は、現在の登録情報のまま変更されません。<br />
                      削除したい項目は、右にある削除欄にチェックを入れてください。
                    </p>
                  </div>
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed' + (now.buildingIsOpen ? '': ' collapsed') ">
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="now.buildingIsOpen = !now.buildingIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" >
                      <div class="form-item webform-component webform-component-textfield webform-component--webform-group-use-mansion-info--field-mansion-name webform-conditional-hidden" v-show="now.buildingIsOpen">
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-use-mansion-info--mansion-name">
                          <p><strong>マンション名：&nbsp;</strong>{{ now.BuildingName }}</p>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-use-mansion-info--room-number">
                          <p><strong>部屋番号：&nbsp;</strong>{{ now.RoomNo }}</p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div class="form-item webform-component webform-component-checkboxes webform-component--field-contracts-preson">
                    <label for="edit-submitted-field-contracts-preson">お支払者様 </label>
                    <div id="edit-submitted-field-contracts-preson" class="form-checkboxes">
                      <div class="form-item form-type-checkbox form-item-submitted-field-contracts-preson-1"><input type="checkbox" id="edit-submitted-field-contracts-preson-1" name="submitted[field_contracts_preson][1]" class="form-checkbox" v-model="update.contractor_same_flag"/> <label class="option" for="edit-submitted-field-contracts-preson-1">ご契約者様と同じ </label></div>
                    </div>
                  </div>
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed' + (now.payerIsOpen ? '': ' collapsed') " v-show="!update.contractor_same_flag">
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="now.payerIsOpen = !now.payerIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払者様情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper">
                      <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-name" v-show="now.payerIsOpen">
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-name-2 webform-container-inline">
                          <div class="description">現在のお支払者：{{ now.Name }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-name-2">お名前 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-name-2" name="submitted[webform_group_payer_info][field_payer_name_2]" size="40" maxlength="20" class="form-text" v-model="update.payer_name"/>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-kana-2 webform-container-inline">
                          <div class="description">現在のお支払者：{{ now.NameKana }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-kana-2">フリガナ </label>
                          <input type="text" pattern="([\u30A1-\u30FC]|\u3000)*" id="edit-submitted-webform-group-payer-info-field-payer-kana-2" name="submitted[webform_group_payer_info][field_payer_kana_2]"  size="40" maxlength="40" class="form-text" v-model="update.payer_kana"/>
                        </div>
                        <div class="form-item webform-component webform-component-date webform-component--webform-group-payer-info--field-birthday webform-container-inline">
                          <div class="description">現在のお支払者の生年月日：{{ now.Birthday }}</div>
                          <label>生年月日 </label>
                          <div class="webform-container-inline webform-datepicker">
                            <div class="form-item form-type-select form-item-submitted-webform-group-payer-info-field-birthday-year">
                              <label class="element-invisible" for="edit-submitted-webform-group-payer-info-field-birthday-year">年 </label>
                              <select class="year form-select" id="edit-submitted-webform-group-payer-info-field-birthday-year" name="submitted[webform_group_payer_info][field_birthday][year]" v-model="update.birthYear">
                                <template v-for="n in 101" :key="n">
                                    <option :value="n + now.year - 101">{{ n + now.year - 101 }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-payer-info-field-birthday-month">
                              <label class="element-invisible" for="edit-submitted-webform-group-payer-info-field-birthday-month">月 </label>
                              <select class="month form-select" id="edit-submitted-webform-group-payer-info-field-birthday-month" name="submitted[webform_group_payer_info][field_birthday][month]" v-model="update.birthMonth">
                                <template v-for="n in 12" :key="n">
                                    <option :value="n">{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <div class="form-item form-type-select form-item-submitted-webform-group-payer-info-field-birthday-day">
                              <label class="element-invisible" for="edit-submitted-webform-group-payer-info-field-birthday-day">日 </label>
                              <select class="day form-select" id="edit-submitted-webform-group-payer-info-field-birthday-day" name="submitted[webform_group_payer_info][field_birthday][day]" v-model="update.birthDay">
                                <template v-for="n in 31" :key="n">
                                    <option :value="n" >{{ n }}</option>
                                </template>
                              </select>
                            </div>
                            <input type="text" style="height: 0; width: 0; padding: 0; border: 0" :class="'webform-calendar webform-calendar-start-' + getDateString(-100, 0, 0) + ' webform-calendar-end-' + getDateString(0, 0, 0) + ' webform-calendar-day-0 hasDatepicker'" />
                            <input type="image" :src="require('@/assets/images/calendar.png')" class="webform-calendar" alt="ポップアップカレンダーを開く" title="ポップアップカレンダーを開く" />
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-markup webform-component--webform-group-payer-info--field-payer-birthdate-description">※お客様の本人確認の際に必要になります。法人のお客様は、会社の創立年月日（契約者名に代表者の名前が含まれる場合は代表者の生年月日）をご登録願います。</div>
                        <div class="form-item webform-component webform-component-radios webform-component--webform-group-payer-info--relationship-2 webform-container-inline">
                          <div class="description">現在のご契約者との続柄：
                            <template v-if="now.Relationship == 0">本人</template>
                            <template v-if="now.Relationship == 1">配偶者</template>
                            <template v-if="now.Relationship == 2">その他</template>
                          </div>
                          <label for="edit-submitted-webform-group-payer-info-relationship-2">ご契約者様との続柄 </label>
                          <div id="edit-submitted-webform-group-payer-info-relationship-2" class="form-radios">
                            <div class="form-item form-type-radio form-item-submitted-webform-group-payer-info-relationship-2"><input type="radio" id="edit-submitted-webform-group-payer-info-relationship-2-1" name="submitted[webform_group_payer_info][relationship_2]" value="0" class="form-radio" v-model="update.payer_relationship"/> <label class="option" for="edit-submitted-webform-group-payer-info-relationship-2-1">本人 </label></div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-payer-info-relationship-2"><input type="radio" id="edit-submitted-webform-group-payer-info-relationship-2-2" name="submitted[webform_group_payer_info][relationship_2]" value="1" class="form-radio" v-model="update.payer_relationship"/> <label class="option" for="edit-submitted-webform-group-payer-info-relationship-2-2">配偶者 </label></div>
                            <div class="form-item form-type-radio form-item-submitted-webform-group-payer-info-relationship-2"><input type="radio" id="edit-submitted-webform-group-payer-info-relationship-2-3" name="submitted[webform_group_payer_info][relationship_2]" value="2" class="form-radio" v-model="update.payer_relationship"/> <label class="option" for="edit-submitted-webform-group-payer-info-relationship-2-3">その他 </label></div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--relationship-other-2 webform-container-inline webform-conditional-hidden" v-show="update.payer_relationship == 2">
                          <div class="description">現在のその他続柄：{{ now.RelationshipEtc }}</div>
                          <label for="edit-submitted-webform-group-payer-info-relationship-other-2">その他続柄 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-relationship-other-2" name="submitted[webform_group_payer_info][relationship_other_2]" size="60" maxlength="16" class="form-text webform-conditional-disabled" :disabled="update.payer_relationship == 2 ? false : true" v-model="update.payer_relationship_etc" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-zipcode-2 webform-container-inline">
                          <div class="description">現在の郵便番号：{{ now.ZipPostalCode }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-zipcode-2">郵便番号 </label>
                          <input type="text" pattern="[0-9]*" style="width:auto" id="edit-submitted-webform-group-payer-info-field-payer-zipcode-2" name="submitted[webform_group_payer_info][field_payer_zipcode_2]" size="7" maxlength="7" class="form-text"  v-model="update.payer_zip_postal_code"/>
                          <span class="field-suffix"><button type="button" id="btn_payer_zipcode_2" @click="getZipcode()">郵便番号から住所を自動入力</button><br /><span class="description">お住まいの郵便番号がわからない場合は<a href="https://www.post.japanpost.jp/zipcode/" target="_blank">日本郵政の公式サイト</a>よりご確認ください。</span></span>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-pref-2 webform-container-inline">
                          <div class="description">現在の住所：{{ now.State }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-pref-2">都道府県 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-pref-2" name="submitted[webform_group_payer_info][field_payer_pref_2]" size="10" maxlength="10" class="form-text" v-model="update.payer_state" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-city-2 webform-container-inline">
                          <div class="description">現在の住所：{{ now.City }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-city-2">市区町村 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-city-2" name="submitted[webform_group_payer_info][field_payer_city_2]" size="20" maxlength="10" class="form-text" v-model="update.payer_city" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-town-2 webform-container-inline">
                          <div class="description">現在の住所：{{ now.Line }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-town-2">町名 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-town-2" name="submitted[webform_group_payer_info][field_payer_town_2]" size="20" maxlength="10" class="form-text" v-model="update.payer_address_line" />
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-house-number-2 webform-container-inline">
                          <div class="description">現在の住所：{{ now.Line2 }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-house-number-2">番地 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-house-number-2" name="submitted[webform_group_payer_info][field_payer_house_number_2]" size="20" maxlength="10" class="form-text" v-model="update.payer_address_line_2" :disabled="update.delete1"/>
                        </div>
                        <div class="form-item webform-component webform-component-checkboxes webform-component--webform-group-payer-info--delete-payer-house-number">
                          <label class="element-invisible" for="edit-submitted-webform-group-payer-info-delete-payer-house-number">番地削除キー </label>
                          <div id="edit-submitted-webform-group-payer-info-delete-payer-house-number" class="form-checkboxes">
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-payer-info-delete-payer-house-number-1"><input type="checkbox" id="edit-submitted-webform-group-payer-info-delete-payer-house-number-1" name="submitted[webform_group_payer_info][delete_payer_house_number][1]" value="1" class="form-checkbox" v-model="update.delete1" @change="deleteItem1"/> <label class="option" for="edit-submitted-webform-group-payer-info-delete-payer-house-number-1">削除 </label></div>
                          </div>
                        </div>
                        <div class="form-item webform-component webform-component-textfield webform-component--webform-group-payer-info--field-payer-address-other-2 webform-container-inline">
                          <div class="description">現在の住所：{{ now.LineOther }}</div>
                          <label for="edit-submitted-webform-group-payer-info-field-payer-address-other-2">マンション名・部屋番号等 </label>
                          <input type="text" id="edit-submitted-webform-group-payer-info-field-payer-address-other-2" name="submitted[webform_group_payer_info][field_payer_address_other_2]" size="50" maxlength="25" class="form-text" v-model="update.payer_address_other" :disabled="update.delete2"/>
                        </div>
                        <div class="form-item webform-component webform-component-checkboxes webform-component--webform-group-payer-info--delete-contractor-other-address">
                          <label class="element-invisible" for="edit-submitted-webform-group-payer-info-delete-contractor-other-address">マンション名・部屋番号等削除キー </label>
                          <div id="edit-submitted-webform-group-payer-info-delete-contractor-other-address" class="form-checkboxes">
                            <div class="form-item form-type-checkbox form-item-submitted-webform-group-payer-info-delete-contractor-other-address-1"><input type="checkbox" id="edit-submitted-webform-group-payer-info-delete-contractor-other-address-1" name="submitted[webform_group_payer_info][delete_contractor_other_address][1]" value="1" class="form-checkbox" v-model="update.delete2" @change="deleteItem2"/> <label class="option" for="edit-submitted-webform-group-payer-info-delete-contractor-other-address-1">削除 </label></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div class="form-actions"><input class="webform-next button-primary form-submit" type="submit" name="op" value="確認" @click="check"/></div>
                </div>
              </form>
            </div>
            <!-- /content -->
            <div class="links"></div>
            <!-- /links -->
          </article>
          <!-- /article #node -->
        </div>
      </section>
      <Sidebar_first_login />
      <!-- /content-inner /content -->
    </div>
  </div>
  <!-- /main -->
  <Footer />
  </div>
</template>
<script>
import Header_login from '../components/Header_login.vue'
import Sidebar_first_login from '../components/Sidebar_first_login.vue'
import Footer from '../components/Footer.vue'
import moment from "moment";
import config from "../config.js";
import $ from "jquery";
import { payerStore } from "../stores/store.js";
import { loginStore } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
import MEMS from "../assets/js/mems.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer
  },
  data() {
    var login = loginStore();
    if(login.room.room_type == 1 || login.room.room_type == 2 || login.room.room_type == 3 ){
      this.$router.push({ path :"/mansion/access_error" });
    }
    var today = new Date();
    var payer = {
      Name : "",
      NameKana : "",
      Birthday : "",
      ZipPostalCode : "",
      State : "",
      City : "",
      Line : "",
      Line2 : "",
      LineOther : "",
      Relationship : 0,
      RelationshipEtc : "",
    }
    payer["year"] = today.getFullYear();
    payer["month"] = today.getMonth() + 1;
    payer["day"] = today.getDay();
    payer["buildingIsOpen"] = true;
    payer["payerIsOpen"] = true;
    payer["BuildingName"] = login.room.building_name;
    payer["RoomNo"] = login.room.room_number;
    payer["Birthday"] = "";
    var update = {
        birthYear : today.getFullYear(),
        birthMonth : today.getMonth() + 1,
        birthDay : today.getDate(),
        contractor_same_flag : false,
        payer_name : "",
        payer_kana : "",
        payer_relationship : 0,
        payer_relationship_etc: "",
        payer_zip_postal_code : "",
        payer_state : "",
        payer_city : "",
        payer_address_line : "",
        payer_address_line_2 : "",
        payer_address_other : "",
        payer_email: "",
        payer_tel: "",
        payer_phone: "",
        delete1 : false,
        delete2 : false
      };
    return {
      now: payer,
      update: update,
      search_error: 0
    };
  },
  async created(){
    var store = payerStore();
    if(Object.keys(store.now).length != 0){
      var now = store.now;
      var update = store.update;
      store.$reset();
      if(update.birthYear == 1900
        &&  update.birthMonth == 1
        &&  update.birthDay == 1)
        {
          update["birthYear"] = new Date().getFullYear() - 100;
        }
      Object.assign(this.$data.now, now);
      Object.assign(this.$data.update, update);
    }else{
      var payer = await getData(config.url.get.payer);
      // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Relationship, RelationshipEtc }
      var contractor = await getData(config.url.get.contractor);
      // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther }
      this.$data.now.contractor = contractor;
      this.$data.update["contractor_same_flag"] = payer.ContractorSameFlag == "1";
      this.$data.update['payer_relationship'] = payer.Relationship;
      var birthday = new Date(payer.Birthday);
      this.$data.update["birthYear"] = birthday.getFullYear();
      this.$data.update["birthMonth"] = birthday.getMonth() + 1;
      this.$data.update["birthDay"] = birthday.getDate();
      var day = birthday.getFullYear()+ "年" + (birthday.getMonth() + 1) + "月" + birthday.getDate() + "日";
      payer["Birthday"] = day;
      if(day == "1900年1月1日"){
        payer["Birthday"] = "";
        this.$data.update["birthYear"] = new Date().getFullYear() - 100;
      }
      Object.assign(this.$data.now, payer);
    }
  },
  mounted: function () {
    this.$nextTick(
      function () {
          MEMS.behaviors.webform.attach(document);
          $("#edit-submitted-webform-group-payer-info-field-birthday-year").on("change", this.changeDay);
          $("#edit-submitted-webform-group-payer-info-field-birthday-month").on("change", this.changeDay);
          $("#edit-submitted-webform-group-payer-info-field-birthday-day").on("change", this.changeDay);
      }.bind(this)
    );
  },
  methods : {
    deleteItem1() {
      if(this.$data.update.delete1){
        this.$data.update.payer_address_line_2 = "";
      }
    }, 
    deleteItem2() {
      if(this.$data.update.delete2){
        this.$data.update.payer_address_other = "";
      }
    }, 
    getDateString: function (year, month, day) {
      var date = new Date();
      date.setFullYear(date.getFullYear() + year);
      date.setMonth(date.getMonth() + month);
      date.setDate(date.getDate() + day);
      return date.toLocaleDateString("sv-SE");
    },
    getZipcode: async function(){
      this.$data.search_error = 0;
      if(!this.$data.update.payer_zip_postal_code.match(/^\d{7}$/)){
        return;
      }
      try{
        var result = await getData(config.url.get.zipcode + "?postCode="  + this.$data.update.payer_zip_postal_code);
        // [{ pref, city, town }]
      }catch(ex){
        this.$data.search_error = 1;
        document.body.scrollIntoView();
        return;
      }
      if(result.length == 0){
        return;
      }
      this.$data.update.payer_state = result[0]["pref"];
      this.$data.update.payer_city = result[0]["city"];
      this.$data.update.payer_address_line = result[0]["town"];
    },
    changeDay : function(){
      this.$data.update.birthYear = document.querySelector("#edit-submitted-webform-group-payer-info-field-birthday-year").value;
      this.$data.update.birthMonth = document.querySelector("#edit-submitted-webform-group-payer-info-field-birthday-month").value;
      this.$data.update.birthDay = document.querySelector("#edit-submitted-webform-group-payer-info-field-birthday-day").value;
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      document.querySelectorAll("select").forEach(function(input) {
        input.setCustomValidity("");
      });
      if (!this.$data.update.contractor_same_flag) {
        var kana2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-kana-2");
        if (kana2.validity.patternMismatch) {
          kana2.setCustomValidity("フリガナは、カタカナと全角スペースのみで入力して下さい。");
        }
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        var birthday2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-birthday-year");
        var minday = new Date();
        minday.setFullYear(today.getFullYear() - 100);
        minday.setHours(0);
        minday.setMinutes(0);
        minday.setSeconds(0);
        minday.setMilliseconds(0);
        var day2 = new Date(this.$data.update.birthYear + "/" + String(this.$data.update.birthMonth) + "/" + String(this.$data.update.birthDay) + " 00:00:00");
        if (!moment(this.$data.update.birthYear + "/" + String(this.$data.update.birthMonth) + "/" + String(this.$data.update.birthDay), "YYYY/M/D").isValid()){
          birthday2.setCustomValidity("入力された 生年月日 は正しい日時ではありません。");
        }
        if (day2 > today){
          birthday2.setCustomValidity(today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate() + "以前の日付を指定して下さい。");
        }
        if (minday > day2){
          birthday2.setCustomValidity(minday.getFullYear() + "/" + (minday.getMonth() + 1) + "/" + minday.getDate() + "以降の日付を指定して下さい。");
        }
        var zip2 = document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-zipcode-2");
        if (this.$data.update.payer_zip_postal_code.length != 0 && this.$data.update.payer_zip_postal_code.length < 7) {
          zip2.setCustomValidity("郵便番号は、7桁でご入力ください。");
        }
        if (zip2.validity.patternMismatch) {
          zip2.setCustomValidity("郵便番号は、半角整数のみで入力して下さい。");
        }
        if (this.$data.update.payer_state.length != 0 && this.$data.update.payer_state.length > 4) {
          document.querySelector("#edit-submitted-webform-group-payer-info-field-payer-pref-2").setCustomValidity("都道府県は、4文字以下で入力して下さい。");
        }
      }
    },
    push : function(){
      this.$data.search_error = 0;
      payerStore().$reset();
      if(this.$data.update.contractor_same_flag){
        this.$data.update.payer_name = this.$data.now.contractor.Name;
        this.$data.update.payer_kana = this.$data.now.contractor.NameKana;
        this.$data.update.payer_zip_postal_code = this.$data.now.contractor.ZipPostalCode;
        this.$data.update.payer_state = this.$data.now.contractor.State;
        this.$data.update.payer_city = this.$data.now.contractor.City;
        this.$data.update.payer_address_line = this.$data.now.contractor.Line;
        this.$data.update.payer_address_line_2 = this.$data.now.contractor.Line2;
        this.$data.update.payer_address_other = this.$data.now.contractor.LineOther;
        this.$data.update.payer_relationship_etc = "";
        this.$data.update.payer_relationship = 0;
        var birthday = new Date(this.$data.now.contractor.Birthday);
        this.$data.update.birthYear = birthday.getFullYear();
        this.$data.update.birthMonth = birthday.getMonth() + 1;
        this.$data.update.birthDay = birthday.getDate();
        this.$data.update.delete1 = false;
        this.$data.update.delete2 = false;
      }
      this.$data.update["birthday"] = this.$data.update.birthYear + "年" +  this.$data.update.birthMonth + "月" + this.$data.update.birthDay + "日";
      this.$data.update["payer_birthday"] = this.$data.update.birthYear + "/" +  this.$data.update.birthMonth + "/" + this.$data.update.birthDay;
      for(var key in this.$data.update){
        if(typeof this.$data.update[key] == "string"){
          this.$data.update[key] = this.$data.update[key].trim();
        }
      }
      if(this.$data.update.payer_relationship != 2){
        this.$data.update.payer_relationship_etc = "";
      }
      if(this.$data.update.delete1){
        this.$data.update.contractor_address_line_2 = "";
      }
      if(this.$data.update.delete2){
        this.$data.update.contractor_address_other = "";
      }
      var store = payerStore();
      store.now = this.$data.now;
      store.update = this.$data.update;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type : "payer_confirm"
        }
      });
    }
  }
}
import "../assets/js/webform.js";
import "../assets/js/mems_webform_replace_half_space.js";
</script>
<style scoped>
#edit-submitted-webform-group-contractor-info-field-contractor-zipcode-2 {
  margin-right: 5px;
}
html .webform-container-inline label {
  margin-right: 10px;
  display: inline-block;
}
input[type='text'] {
  width: auto;
}
.webform-calendar:focus {
  outline: none;
}
</style>

<style scoped src="../assets/css/webform.css" />

<template>
  <aside id="sidebar-second">
    <div class="block block-views" data-bid="269">
      <h3 class="title">お知らせ</h3>
      <div class="view view-info view-id-info view-display-id-block">
        <template v-for="content in info" :key="content">
          <div class="view-content">
            <div class="views-row">
              <span class="views-field views-field-timestamp"> <span class="field-content">●</span>
              </span> <div class="views-field views-field-field-category">
                <div class="field-content"><span class="category">{{ content.Category }}</span></div>
              </div> <span class="views-field views-field-title">
                <span class="field-content"><router-link :to="'/mansion/info/' + content.Id">{{content.Title}}</router-link></span>
              </span>
            </div>
          </div>
        </template>
        <div class="more-link">
          <router-link to="/mansion/info"> もっと見る </router-link>
        </div>
      </div>
    </div>
    <!-- /block -->
    <div class="block block-views" data-bid="798">
      <h3 class="title">コラムカテゴリ</h3>
      <div class="view view-column-categories view-id-column_categories view-display-id-block_1 ennevision">
        <div class="view-content">
          <div class="item-list">
            <ul>
              <li class="views-row views-row-1 views-row-odd views-row-first">
                <div class="views-field views-field-name">
                  <span class="field-content"><router-link to="/mansion/column_list?category=1">やってみよう！</router-link></span>
                </div>
              </li>
              <li class="views-row views-row-2 views-row-even">
                <div class="views-field views-field-name">
                  <span class="field-content"><router-link to="/mansion/column_list?category=2">困ったときに</router-link></span>
                </div>
              </li>
              <li class="views-row views-row-3 views-row-odd">
                <div class="views-field views-field-name">
                  <span class="field-content"><router-link to="/mansion/column_list?category=3">カラダにいいこと</router-link></span>
                </div>
              </li>
              <li class="views-row views-row-4 views-row-even">
                <div class="views-field views-field-name">
                  <span class="field-content"><router-link to="/mansion/column_list?category=4">暮らしにプラス</router-link></span>
                </div>
              </li>
              <li class="views-row views-row-5 views-row-odd views-row-last">
                <div class="views-field views-field-name">
                  <span class="field-content"><router-link to="/mansion/column_list?category=5">便利なモノ</router-link></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- /block -->
    <div class="block block-views" data-bid="795">
      <h3 class="title">最新コラム</h3>
      <div class="view view-top-column view-id-top_column view-display-id-block ennevision">
        <div class="view-content">
          <table class="views-view-grid cols-1">
            <tbody>
              <template v-for="content in newColumn" :key="content">
              <tr class="row">
                <td class="col">
                  <div class="views-field views-field-field-image">
                    <div class="field-content">
                      <router-link :to="'/mansion/column_list/' + content.ColumnId">
                        <img :src="content.ImageUrl" alt="" width="220" height="156" />
                      </router-link>
                    </div>
                  </div>
                  <span class="views-field views-field-timestamp"> <span class="field-content">●</span>
                  </span> <span class="views-field views-field-title">
                    <span class="field-content"><router-link :to="'/mansion/column_list/' + content.ColumnId">{{ content.Title }}</router-link></span>
                  </span>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- /block -->
    <div class="block block-views" data-bid="796">
      <h3 class="title">おすすめコラム</h3>
      <div class="view view-top-column view-id-top_column view-display-id-block_1 ennevision">
        <div class="view-content">
          <table class="views-view-grid cols-1">
            <tbody>
              <template v-for="content in hotColumn" :key="content">
              <tr class="row">
                <td class="col">
                  <span class="views-field views-field-timestamp"> <span class="field-content">●</span>
                  </span> <span class="views-field views-field-title">
                    <span class="field-content"><router-link :to="'/mansion/column_list/' + content.ColumnId">{{ content.Title }}</router-link></span>
                  </span>
                </td>
              </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="view-footer">
          <router-link to="/mansion/column_list">コラム一覧</router-link>
        </div>
      </div>
    </div>
    <!-- /block -->
  </aside>
  <!-- /sidebar-second -->
</template>
<script>
import config from "../config.js";
import { getData } from "../assets/js/request.js";
export default {
  name: "Sidebar_second_login",
  data(){
    return {
      info : [],
      newColumn : [],
      hotColumn : [],
    }
  },
  async created(){
    var info = await getData(config.url.get.info_list);
    // [{ Id, Title, Category, ReleaseDate, BodySummary}]
    var new_column = await getData(config.url.get.column_list);
    // [{ ColumnId, Title, ImageUrl }]
    var hot_column = await getData(config.url.get.column_list + "?type=hot");
    // [{ ColumnId, Title, ImageUrl }]
    if(info.length > 5){
      info.splice(5, info.length - 5);
    }
    new_column.splice(1, new_column.length - 1);
    hot_column.splice(3, hot_column.length - 3);
    
    Object.assign(this.$data, {
      info: info,
      newColumn : new_column,
      hotColumn : hot_column
    });
  }
};
</script>

<style scoped src="../assets/css/ennevision_column_categories.css" />
<style scoped src="../assets/css/ennevision_columns.css" />

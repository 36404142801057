<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-member page-member-profile responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb" v-if="!Building.IsManage">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">会員情報</span>
        </div>
        <section id="content">
          <h1 class="title">会員情報</h1>
          <div id="content-area">
            <div class="view view-member-profile view-id-member_profile view-display-id-page view-dom-id-25b11a39e800e7d245771bc47eb434ef">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (IsOpen1 ? '': ' collapsed')" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"><a class="fieldset-title" href="#" @click.prevent.stop="IsOpen1 = !IsOpen1"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご利用マンション情報</a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="IsOpen1">
                      <div class="views-field views-field-field-mansion-name">
                        <strong class="views-label views-label-field-mansion-name">マンション名: </strong><span class="field-content">{{ Building.BuildingName }}</span>
                      </div>
                      <div class="views-field views-field-field-room-number">
                        <strong class="views-label views-label-field-room-number">部屋番号: </strong><span class="field-content">{{ Building.RoomNo }}</span>
                      </div>
                      <div class="views-field views-field-field-use-start-date">
                        <strong class="views-label views-label-field-use-start-date">鍵のお引渡日: </strong><span class="field-content">
                          <span class="date-display-single" property="dc:date" datatype="xsd:dateTime">{{ Building.FormMoveDate }}</span>
                          </span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (IsOpen2 ? '': ' collapsed')" data-module="views_fieldsets" id="portalsite">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="IsOpen2 = !IsOpen2"><span class="fieldset-legend-prefix element-invisible">非表示</span> ポータルサイト情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="IsOpen2">
                      <fieldset class="id・パスワード views-fieldset" data-module="views_fieldsets" id="id-pass">
                        <legend>
                          <span class="fieldset-legend">ID・パスワード</span>
                        </legend>
                        <div class="fieldset-wrapper">
                          <div class="views-field views-field-name">
                            <strong class="views-label views-label-name">ログインID: </strong><span class="field-content">{{ Customer.customer_id }}</span>
                          </div>
                          <div class="views-field views-field-nothing"><strong class="views-label views-label-nothing">パスワード: </strong><span class="field-content">********</span></div>
                          <div class="views-field views-field-php-7" v-if="!Building.IsManage">
                            <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/password/setting' })" /></span>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="メールアドレス・通知 views-fieldset" data-module="views_fieldsets" id="mail-notice">
                        <legend>
                          <span class="fieldset-legend">メールアドレス・通知</span>
                        </legend>
                        <div class="fieldset-wrapper">
                          <div class="views-field views-field-mail">
                            <strong class="views-label views-label-mail">ポータルサイトメールアドレス: </strong><span class="field-content">{{ Customer.PortalEmail }}</span>
                          </div>
                          <div class="views-field views-field-field-contracts-sub-email">
                            <strong class="views-label views-label-field-contracts-sub-email">サブメールアドレス: </strong><span class="field-content">{{ Customer.PortalSubEmail }}</span>
                          </div>
                          <div class="views-field views-field-field-contracts-notice">
                            <strong class="views-label views-label-field-contracts-notice">お知らせメール通知: </strong>
                              <span class="field-content"><div class="item-list">
                                <ul>
                                  <li v-if="Customer.mail_setting2">お知らせメール</li>
                                </ul>
                              </div></span>
                            <div class="views-field views-field-account" v-if="!Building.IsManage">
                              <strong class="views-label">ソーシャルアカウント連携: </strong>
                              <span class="field-content"><div class="item-list">
                                <ul>
                                  <template v-if="Customer.is_set_oid == 1">
                                    <li><button @click.prevent="userdelete()">連携解除</button></li>
                                  </template>
                                  <template v-if="Customer.is_set_oid == 0">
                                    <li><button @click.prevent="signup(config.signup.google_url)">Googleと連携する</button></li>
                                    <li><button @click.prevent="signup(config.signup.microsoft_url)">Microsoftと連携する</button></li>
                                    <li><button @click.prevent="signup(config.signup.amazon_url)">Amazonと連携する</button></li>
                                  </template>
                                </ul>
                              </div></span>
                            </div>
                          </div>
                          <div class="views-field views-field-php" v-if="!Building.IsManage">
                            <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/portalmail/setting' })" /></span>
                          </div>
                        </div>
                      </fieldset>
                      <div class="views-field views-field-php-12">
                        <span class="field-content" v-if="Customer.dr == 1"> <b>節電ポイントサービス</b>: 参加中 </span>
                        <span class="field-content" v-else> <b>節電ポイントサービス</b>: 参加していません </span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (IsOpen3 ? '': ' collapsed')" data-module="views_fieldsets" id="contractor">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="IsOpen3 = !IsOpen3"><span class="fieldset-legend-prefix element-invisible">非表示</span> ご契約者様情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="IsOpen3">
                      <div class="views-field views-field-field-contractor-name">
                        <strong class="views-label views-label-field-contractor-name">氏名: </strong><span class="field-content">{{ Contractor.Name }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-kana">
                        <strong class="views-label views-label-field-contractor-kana">カナ: </strong><span class="field-content">{{ Contractor.NameKana }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-birthday">
                        <strong class="views-label views-label-field-contractor-birthday">生年月日: </strong
                        ><span class="field-content"
                          ><span class="date-display-single" property="dc:date" datatype="xsd:dateTime">{{ Contractor.BirthdayString }}</span></span
                        >
                      </div>
                      <div class="views-field views-field-field-contractor-zipcode">
                        <strong class="views-label views-label-field-contractor-zipcode">郵便番号: </strong><span class="field-content">{{ Contractor.ZipPostalCode }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-pref">
                        <strong class="views-label views-label-field-contractor-pref">都道府県: </strong><span class="field-content">{{ Contractor.State }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-city">
                        <strong class="views-label views-label-field-contractor-city">市区町村: </strong><span class="field-content">{{ Contractor.City }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-town">
                        <strong class="views-label views-label-field-contractor-town">町名: </strong><span class="field-content">{{ Contractor.Line }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-house-number">
                        <strong class="views-label views-label-field-contractor-house-number">番地: </strong><span class="field-content">{{ Contractor.Line2 }}</span>
                      </div>
                      <div class="views-field views-field-field-contractor-address-other">
                        <strong class="views-label views-label-field-contractor-address-other">マンション名・部屋番号等: </strong><span class="field-content">{{ Contractor.LineOther }}</span>
                      </div>
                      <div class="views-field views-field-php-1" v-if="!Building.IsManage">
                        <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query : { type : 'contractor' }})" /> </span>
                        <span class="field-content edit" v-if="Contractor.InfoApplying">&nbsp;変更中</span>
                      </div>
                      <fieldset class="電話番号 views-fieldset" data-module="views_fieldsets">
                        <legend>
                          <span class="fieldset-legend">電話番号</span>
                        </legend>
                        <div class="fieldset-wrapper">
                          <div class="views-field views-field-field-contractor-tel">
                            <strong class="views-label views-label-field-contractor-tel">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ Contractor.Phone }}</span>
                          </div>
                          <div class="views-field views-field-field-contractor-phone">
                            <strong class="views-label views-label-field-contractor-phone">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content">{{ Contractor.TelSub }}</span>
                          </div>
                          <div class="views-field views-field-php-9" v-if="!Building.IsManage">
                            <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query : { type : 'contractor_tel' }})" /> </span>
                            <span class="field-content edit" v-if="Contractor.PhoneApplying">&nbsp;変更中</span>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (IsOpen4 ? '': ' collapsed')" data-module="views_fieldsets" id="payer">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="IsOpen4 = !IsOpen4"><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払者様情報</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="IsOpen4">
                      <div class="views-field views-field-field-payer-name">
                        <strong class="views-label views-label-field-payer-name">氏名: </strong><span class="field-content">{{ Payer.Name }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-kana">
                        <strong class="views-label views-label-field-payer-kana">カナ: </strong><span class="field-content">{{ Payer.NameKana }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-birthday">
                        <strong class="views-label views-label-field-payer-birthday">生年月日: </strong><span class="field-content"><span class="date-display-single" property="dc:date" datatype="xsd:dateTime" >{{ Payer.BirthdayString }}</span></span>
                      </div>
                      <div class="views-field views-field-php-14">
                        <strong class="views-label views-label-php-14">ご契約者様との続柄: </strong
                        ><span class="field-content">
                          <template v-if="Payer.Relationship == 0">本人</template>
                          <template v-if="Payer.Relationship == 1">配偶者</template>
                          <template v-if="Payer.Relationship == 2">その他 {{ Payer.RelationshipEtc }}</template>
                        </span>
                      </div>
                      <div class="views-field views-field-field-payer-zipcode">
                        <strong class="views-label views-label-field-payer-zipcode">郵便番号: </strong><span class="field-content">{{ Payer.ZipPostalCode }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-pref">
                        <strong class="views-label views-label-field-payer-pref">都道府県: </strong><span class="field-content">{{ Payer.State }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-city">
                        <strong class="views-label views-label-field-payer-city">市区町村: </strong><span class="field-content">{{ Payer.City }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-town">
                        <strong class="views-label views-label-field-payer-town">町名: </strong><span class="field-content">{{ Payer.Line }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-house-number">
                        <strong class="views-label views-label-field-payer-house-number">番地: </strong><span class="field-content">{{ Payer.Line2 }}</span>
                      </div>
                      <div class="views-field views-field-field-payer-address-other">
                        <strong class="views-label views-label-field-payer-address-other">マンション名・部屋番号等: </strong><span class="field-content">{{ Payer.LineOther }}</span>
                      </div>
                      <div class="views-field views-field-php-2" v-if="!Building.IsManage">
                        <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query : { type : 'payer' }})" /> </span>
                        <span class="field-content edit" v-if="Payer.InfoApplying">&nbsp;変更中</span>
                      </div>
                      <fieldset class="電話番号 views-fieldset" data-module="views_fieldsets">
                        <legend>
                          <span class="fieldset-legend">電話番号</span>
                        </legend>
                        <div class="fieldset-wrapper">
                          <div class="views-field views-field-field-payer-tel">
                            <strong class="views-label views-label-field-payer-tel">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ Payer.Phone }}</span>
                          </div>
                          <div class="views-field views-field-field-payer-phone">
                            <strong class="views-label views-label-field-payer-phone">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content">{{ Payer.TelSub }}</span>
                          </div>
                          <div class="views-field views-field-php-11" v-if="!Building.IsManage">
                            <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query : { type : 'payer_tel' }})" /> </span>
                            <span class="field-content edit" v-if="Payer.PhoneApplying">&nbsp;変更中</span>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (IsOpen5 ? '': ' collapsed')" data-module="views_fieldsets" id="payment">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="IsOpen5 = !IsOpen5"><span class="fieldset-legend-prefix element-invisible">非表示</span> お支払方法</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="IsOpen5">
                      <div class="views-field views-field-field-payment">
                        <strong class="views-label views-label-field-payment">お支払い方法: </strong
                        ><span class="field-content">
                          <template v-if="Payment.payment == '04001-0'">請求書（後払い.com）</template>
                          <template v-if="Payment.payment == '04002-0'">口座振替</template>
                          <template v-if="Payment.payment == '04003-0'">クレジットカード</template>
                          <template v-if="Payment.payment == '04003-1'">クレジットカード</template>
                        </span>
                      </div>
                      <div class="views-field views-field-php-13" v-if="!Building.IsManage">
                        <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/change/payment' })" /> </span>
                        <span class="field-content edit" v-if="Payment.applying">&nbsp;変更中</span>
                      </div>
                      <template v-if="Payment.payment == '04002-0'">
                        <fieldset class="payment-method-views-fieldset" data-module="views_fieldsets">
                          <legend><span class="fieldset-legend">口座情報</span></legend>
                          <div class="fieldset-wrapper">
                            <div class="views-field bank-name">
                              <strong class="views-label bank-name">金融機関名: </strong>
                              <span class="field-content">{{ Payment.account_transfer_bank_name }}</span>
                            </div>
                            <div class="views-field branch-name">
                              <strong class="views-label branch-name">支店名: </strong>
                              <span class="field-content">{{ Payment.account_transfer_branch_name }}</span>
                            </div>
                            <div class="views-field account-holder-kana">
                              <strong class="views-label account-holder-kana">口座名義人（フリガナ）: </strong>
                              <span class="field-content">{{ Payment.account_transfer_account_holder }}</span>
                            </div>
                            <div class="views-field account-number">
                              <strong class="views-label account-number">口座番号: </strong>
                              <template v-if="Payment.account_transfer_account_number != ''">
                                <span class="field-content">{{ Payment.account_transfer_account_number }}</span>
                              </template>
                              <template v-if="Payment.account_transfer_sign != '' && Payment.account_transfer_number != ''">
                                <span class="field-content">{{ Payment.account_transfer_sign }}</span
                                >&nbsp;<span class="field-content">{{ Payment.account_transfer_number }}</span>
                              </template>
                            </div>
                          </div>
                        </fieldset>
                      </template>
                      <template v-if="Payment.payment == '04003-0' || Payment.payment == '04003-1'">
                        <fieldset class="payment-method-views-fieldset" data-module="views_fieldsets">
                          <legend><span class="fieldset-legend">クレジットカード情報</span></legend>
                          <div class="fieldset-wrapper">
                            <div class="views-field bank-name">
                              <strong class="views-label card-no">クレジットカード番号: </strong>
                              <span class="field-content">{{ Payment.credit_card_number }}</span>
                            </div>
                            <div class="views-field branch-name">
                              <strong class="views-label card-date">クレジットカード有効期限: </strong>
                              <span class="field-content">{{ Payment.credit_card_expiration_date }}</span>
                            </div>
                          </div>
                        </fieldset>
                      </template>
                      <div class="views-field views-field-field-specification"><strong class="views-label views-label-field-specification">明細書: </strong><span class="field-content">
                        <template v-if="Contract.DetailFlag == '-'">-</template>
                        <template v-else-if="Contract.DetailFlag">紙明細（発行手数料として毎月136円（税込）が利用料金に加算されます）</template>
                        <template v-else>WEB明細</template>
                      </span></div>
                      <div class="views-field views-field-php-3" v-if="!Building.IsManage">
                        <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query : { type : 'fee_detail' }})" /> </span>
                        <span class="field-content edit" v-if="Contract.DetailFlagApplying">&nbsp;変更中</span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset :class="'views-fieldset collapsible collapse-processed' + (IsOpen6 ? '': ' collapsed')" data-module="views_fieldsets" id="rate-plan">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="IsOpen6 = !IsOpen6"><span class="fieldset-legend-prefix element-invisible">非表示</span> 料金プラン</a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="IsOpen6">
                      <div class="views-field views-field-field-rate-plan-1"><strong class="views-label views-label-field-rate-plan-1">料金プラン: </strong>
                        <span class="field-content">
                        {{ Contract.PlanName }}
                        </span>
                      </div>
                      <div class="views-field views-field-field-power-capacity"><strong class="views-label views-label-field-power-capacity">容量: </strong>
                        {{ ( Contract.CapacityNumeric == '-' || Contract.CapacityNumeric > 0) ? Contract.CapacityNumeric : "お部屋の最大容量(契約書による)" }}
                        <template v-if="Contract.CapacityUnit == 0">A</template>
                        <template v-if="Contract.CapacityUnit == 1">kVA</template>
                        <template v-if="Contract.CapacityUnit == 2">kW</template>
                      </div>
                      <div class="views-field views-field-php-6" v-if="!Building.IsManage">
                        <span class="field-content"><input type="button" value="変更" @click.prevent.stop="$router.push({ path: '/mansion/member/procedure', query : { type : 'rate_plan' }})" /> </span>
                        <span class="field-content edit" v-if="Contract.PlanApplying">&nbsp;変更中</span>
                      </div>
                      <div class="views-field views-field-php-4">
                        <span class="field-content"></span>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import { loginStore, removeData } from "../stores/store.js";
import config from "../config.js";
import { getData, post } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var building = {
      BuildingName: "",
      RoomNo: "",
      FormMoveDate: ""
    }
    var login = loginStore();
    if(this.$route.params.id){
      building.IsManage = true;
      login.customer_id = this.$route.params.id;
    }else{
      var date = new Date(login.room.form_move_date);
      var form_move_date = date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日";
      building = {
        BuildingName : login.room.building_name,
        RoomNo : login.room.room_number,
        FormMoveDate : form_move_date,
        IsManage : false
      }
    }
    return {
      Building: building,
      Customer: {
            customer_id : "",
            PortalEmail : "",
            PortalSubEmail : "",
            dr : "",
            mail_setting2 : "",
            is_set_oid : ""
      },
      Contractor: {
            Name : "",
            NameKana : "",
            Birthday : "",
            ZipPostalCode : "",
            State : "",
            City : "",
            Line : "",
            Line2 : "",
            LineOther : "",
            Phone : "",
            TelSub : "",
            BirthdayString : "",
            InfoApplying: false,
            PhoneApplying: false,
      },
      Payer: {
            Same : 0,
            Name : "",
            NameKana : "",
            Birthday : "",
            ZipPostalCode : "",
            State : "",
            City : "",
            Line : "",
            Line2 : "",
            LineOther : "",
            Relationship : 0,
            RelationshipEtc : "",
            Phone : "",
            TelSub : "",
            BirthdayString : "",
            InfoApplying: false,
            PhoneApplying: false,
      },
      Contract: {
            PlanCode : "",
            DetailFlag : 0,
            CapacityNumeric : "",
            CapacityUnit : "",
            PlanApplying: false,
            DetailFlagApplying: false,
      },
      Payment : {
            payment: "",
            account_transfer_bank_name: "",
            account_transfer_branch_name: "",
            account_transfer_account_holder: "",
            account_transfer_account_number: "",
            account_transfer_sign: "",
            credit_card_number: "",
            credit_card_expiration_date: "",
            applying: false,
      },
      IsOpen1 : true,
      IsOpen2 : true,
      IsOpen3 : true,
      IsOpen4 : true,
      IsOpen5 : true,
      IsOpen6 : true,
      config : config
    }
  },
  watch: {
    '$route': function (n) {
      if (n.hash.match(/^#/)) {
        var el = document.getElementById(n.hash.replace(/^#/, ''));
        if(el){
          el.scrollIntoView();
        }
      }
    }
  },
  mounted () {
    if (this.$route.hash.match(/^#/)) {
      var el = document.getElementById(this.$route.hash.replace(/^#/, ''));
      if(el){
        el.scrollIntoView();
      }
    }
  },
  async created(){
    var customer = await getData(config.url.get.customer);
    // { Portalmail, PortalSubEmail, dr, mail_setting2, mail_setting2, is_set_oid }
    var contractor = await getData(config.url.get.contractor);
    // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Phone, TelSub } 
    var payer = await getData(config.url.get.payer);
    // { Same, CustomerID, Name, CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Relationship, RelationshipEtc, Phone, TelSub }
    var payment = await getData(config.url.get.payment);
    //{ payment,account_transfer_bank_name,account_transfer_branch_name,account_transfer_account_holder,account_transfer_account_number,account_transfer_sign}
    var contract = await getData(config.url.get.contract);
    // { PlanCode, DetailFlag, CapacityNumeric, CapacityUnit }
    if(this.$data.Building.IsManage){
        var rooms = await getData(config.url.get.building);
        // [{customer_id, contractor_name, building_id, building_name, building_zip_postal_code, building_state, building_city, building_address_line, building_address_line_2,
        // room_id, room_number, room_type, room_note, form_move_date}]
        this.$data.Building.BuildingName = rooms[0].building_name;
        this.$data.Building.RoomNo = rooms[0].room_number;
        this.$data.Building.FormMoveDate = rooms[0].form_move_date;
        var login = loginStore();
        login.customer_id = "";
    }
    
    var birthday = new Date(contractor.Birthday);
    contractor.BirthdayString = birthday.getFullYear() + "年" + (birthday.getMonth() + 1) + "月" + birthday.getDate() + "日";
    if(contractor.BirthdayString == "1900年1月1日"){
      contractor.BirthdayString = "";
    }
    birthday = new Date(payer.Birthday);
    payer.BirthdayString = birthday.getFullYear() + "年" + (birthday.getMonth() + 1) + "月" + birthday.getDate() + "日";
    if(payer.BirthdayString == "1900年1月1日"){
      payer.BirthdayString = "";
    }
    Object.assign(this.$data.Customer, customer);
    Object.assign(this.$data.Contractor, contractor);
    Object.assign(this.$data.Payer, payer);
    Object.assign(this.$data.Payment, payment);
    Object.assign(this.$data.Contract, contract);
  },
  methods: {
    signup : function(url){
      window.open(url, '_blank');
    },
    userdelete : async function(){
      await post(config.url.post.certification, {
        state : "signup"
      });
      this.$data.Customer.is_set_oid = 0;
      removeData("token");
    }
  }
};
</script>
<style scoped>
.views-field-account button {
  width: 180px;
}
.edit {
  border: 1px solid #1090ef;
  padding: 3px;
  color: #1090ef;
}
.edit::before {
  content: "\01f5ce";
}
</style>
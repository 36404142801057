<template>
  <div class="html not-front not-logged-in no-sidebars page-confirm-mail responsive-menus-load-processed">
    <Header />
    <div class="messages status" v-show="error == -1">
      <h2 class="element-invisible">ステータスメッセージ</h2>
      電気の利用申込みに関するご案内を、入力したメールアドレスにお送りしました。
    </div>
    <div class="messages error" v-show="error == 1">
      <h2 class="element-invisible">ステータスメッセージ</h2>
      URLの有効期限が過ぎているか、入力したURLに誤りがあります。もう一度メールアドレスを入力し「送信する」をクリックしてください。
    </div>
    <div id="highlighted">
      <Header_info/>
      <div class="block block-menu highlight-menu" data-bid="273">
        <ul class="menu">
          <li class="first leaf">
            <router-link to="/mansion/faq_top">よくあるご質問</router-link>
          </li>
          <li class="leaf">
            <router-link to="/mansion/inquiry">お問い合わせ</router-link>
          </li>
          <li class="last leaf">
            <router-link to="/mansion/entry">電気の利用申込み</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">お客様メールアドレスの確認</span>
        </div>
        <section id="content">
          <h1 class="title">お客様メールアドレスの確認</h1>
          <div id="content-area">
            <form action="#" method="post" id="ennevision-entry-mail-auth-form" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div id="edit-confirm-mail" class="form-item form-type-item">
                  <div class="mail_regist">本人確認のため、お客様のメールアドレスを入力してください。<br />「送信」後、入力したメールアドレスに電気の利用申込みに関するご案内をお送りしますので、内容をご確認の上、メール本文に記載されているURLよりお申込み手続きを続けてください。<br /><br /></div>
                  <div class="form-item form-type-textfield form-item-field-user-mail">
                    <label for="email">メールアドレス <span class="form-required" title="これは入力必須項目です。">*</span> </label>
                    <input required type="email" id="email" name="field_user_mail" size="40" maxlength="254" class="form-text" v-model="customer.portalmail" />
                  </div>
                  <div class="mail_regist">※「@denki-annai.com」からのメールが受信できるようにドメイン指定を設定してください。<br /></div>
                  <input type="submit" id="edit-mail-send" name="op" value="送信する" class="form-submit" @click="check" :disabled="send"/>
                </div>
              </div>
            </form>
          </div>
        </section>
        <!-- /content-inner /content -->
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { post } from "../assets/js/request.js";
export default {
  components: {
    Header,
    Header_info,
    Footer,
  },
  data() {
    var error = 0;
    if(this.$route.query["error"]){
      error = 1;
    }
    return {
      customer: {
        portalmail: "",
      },
      error: error,
      send: false
    };
  },
  methods: {
    check: function() {
      var email1 = document.querySelector("#email");
      email1.setCustomValidity("");
      if (email1.validity.valueMissing) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }
      var splited1 = this.$data.customer.portalmail.split("@");
      if (email1.validity.typeMismatch){
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if (splited1.length == 2 && (splited1[1].length == 0 || splited1[1].length == splited1[1].replace(".", "").length)) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }else if(splited1[0].length > 64) {
        email1.setCustomValidity("メールアドレスが認識できませんでした。正しいメールアドレスを入力してください。");
      }
    },
    push: async function () {
      this.$data.send = true;
      this.$data.error = 0;
      await post(config.url.post.confirm_mail, {
        customer : {
          portal_email : this.$data.customer.portalmail
        }
      });
      this.$data.error = -1;
      this.$data.send = false;
    }
  }
};
import "../assets/js/webform.js";
import "../assets/js/mems_webform_replace_half_space.js";
</script>
<style scoped>
#content-area input[type="email"]{
  width: auto;
}
</style>
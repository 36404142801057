<template>
  <div :class="'html not-front page-member responsive-menus-load-processed ' + (isLogin ? 'logged-in one-sidebar sidebar-first' : 'not-logged-in no-sidebars')">
    <template v-if="isLogin">
      <Header_login />
    </template>
    <template v-else>
      <Header />
      <div id="highlighted">
        <Header_info/>
        <div class="block block-menu highlight-menu">
          <ul class="menu">
            <li class="first leaf">
              <router-link to="/mansion/faq_top">よくあるご質問</router-link>
            </li>
            <li class="leaf">
              <router-link to="/mansion/inquiry">お問い合わせ</router-link>
            </li>
            <li class="last leaf">
              <router-link to="/mansion/entry">電気の利用申込み</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">
            »</span> <span id="page-title" class="inline last">
              <template v-if="category == 5">ご契約者様情報の変更申請内容</template>
              <template v-if="category == 6">お支払者様情報の変更申請内容</template>
              <template v-if="category == 9">料金プラン変更申請内容</template>
              <template v-if="category == 10">料金明細確認方法の変更申請内容</template>
              <template v-if="category == 0">支払方法の変更申請内容</template>
              <template v-if="category == 16">退去手続き申請内容</template>
            </span>
        </div>
        <section id="content">
          <h1 class="title">
            <template v-if="category == 5">ご契約者様情報の変更申請内容</template>
            <template v-if="category == 6">お支払者様情報の変更申請内容</template>
            <template v-if="category == 9">料金プラン変更申請内容</template>
            <template v-if="category == 10">料金明細確認方法の変更申請内容</template>
            <template v-if="category == 0">支払方法の変更申請内容</template>
            <template v-if="category == 16">退去手続き申請内容</template>
          </h1>
          <div id="content-area">
            <div class="view view-webform-complete-info view-id-webform_complete_info view-display-id-page_8 view-dom-id-09d519f00623cb368009e7aef219ab67">
              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-use-mansion-info form-wrapper collapse-processed' + (buildingIsOpen ? '' : ' collapsed')" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend"
                        ><a class="fieldset-title" href="#" @click.prevent.stop="buildingIsOpen = !buildingIsOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span
                          ><template v-if="category == 16">お引越しの情報</template><template v-else>ご利用マンション情報</template></a><span class="summary"></span
                      ></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="buildingIsOpen">
                      <template v-if="category == 16 && webform_inquiry">
                        <div class="views-field views-field-value-26"><strong class="views-label views-label-value-26">お引越し予定日: </strong><span class="field-content">{{ webform_inquiry.move_scheduled_date_string }}</span></div>
                        <div class="views-field views-field-value-2"><strong class="views-label views-label-value-2">鍵のお引渡（ご返却）日: </strong><span class="field-content">{{ webform_inquiry.key_delivery_return_day_string }}</span></div>
                        <div class="views-field views-field-value"><strong class="views-label views-label-value">日中ご連絡の取れる電話番号: </strong><span class="field-content">{{ webform_inquiry.day_contact_tel_num }}</span></div>
                      </template>
                      <template v-else>
                        <div class="views-field views-field-value">
                          <span class="field-content"><p><strong>部屋番号：&nbsp;</strong>{{ RoomNo }}</p></span>
                        </div>
                      </template>
                    </div>
                  </fieldset>
                  <fieldset :class="'webform-component-fieldset collapsible webform-component--webform-group-payer-info form-wrapper collapse-processed' + (isOpen ? '' : ' collapsed')" data-module="views_fieldsets">
                    <legend>
                      <span class="fieldset-legend">
                        <a class="fieldset-title" href="#" @click.prevent.stop="isOpen = !isOpen"><span class="fieldset-legend-prefix element-invisible">非表示</span> 
                          <template v-if="category == 5">ご契約者様情報</template>
                          <template v-if="category == 6">お支払者様情報</template>
                          <template v-if="category == 9">料金プラン情報</template>
                          <template v-if="category == 10">料金明細書</template>
                          <template v-if="category == 0">支払方法</template>
                          <template v-if="category == 16">お引越し先住所</template>
                        </a><span class="summary"></span></span>
                    </legend>
                    <div class="fieldset-wrapper" v-show="isOpen">
                      <template v-if="category == 5">
                        <div class="views-field views-field-value-3">
                          <strong class="views-label views-label-value-3">お名前: </strong><span class="field-content">{{ contractor.Name }} </span>
                        </div>
                        <div class="views-field views-field-value-4">
                          <strong class="views-label views-label-value-4">フリガナ: </strong><span class="field-content">{{ contractor.NameKana }}</span>
                        </div>
                        <div class="views-field views-field-value-1">
                          <strong class="views-label views-label-value-1">生年月日: </strong><span class="field-content">{{ contractor.BirthdayString }}</span>
                        </div>
                        <div class="views-field views-field-value-5">
                          <strong class="views-label views-label-value-5">郵便番号: </strong><span class="field-content">{{ contractor.ZipPostalCode }}</span>
                        </div>
                        <div class="views-field views-field-value-6">
                          <strong class="views-label views-label-value-6">都道府県: </strong><span class="field-content">{{ contractor.State }}</span>
                        </div>
                        <div class="views-field views-field-value-7">
                          <strong class="views-label views-label-value-7">市区町村: </strong><span class="field-content">{{ contractor.City }}</span>
                        </div>
                        <div class="views-field views-field-value-8">
                          <strong class="views-label views-label-value-8">町名: </strong><span class="field-content">{{ contractor.Line }}</span>
                        </div>
                        <div class="views-field views-field-value-9">
                          <strong class="views-label views-label-value-9">番地: </strong><span class="field-content">{{ contractor.Line2 }}</span>
                        </div>
                        <div class="views-field views-field-value-10">
                          <strong class="views-label views-label-value-10">マンション名・部屋番号等: </strong><span class="field-content">{{ contractor.LineOther }}</span>
                        </div>
                        <div class="views-field views-field-value-23">
                          <strong class="views-label views-label-value-23">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ contractor.Phone }}</span>
                        </div>
                        <div class="views-field views-field-value-1">
                          <strong class="views-label views-label-value-1">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content"> {{ contractor.TelSub }}</span>
                        </div>
                      </template>
                      <template v-if="category == 6">
                        <div class="views-field views-field-value-14">
                          <strong class="views-label views-label-value-14">お名前: </strong><span class="field-content">{{ payer.Name }}</span>
                        </div>
                        <div class="views-field views-field-value-15">
                          <strong class="views-label views-label-value-15">フリガナ: </strong><span class="field-content">{{ payer.NameKana }}</span>
                        </div>
                        <div>
                          <strong>生年月日: </strong><span>{{ payer.BirthdayString }}</span>
                        </div>
                        <div class="views-field views-field-value-3">
                          <strong class="views-label views-label-value-3">ご契約者様との続柄: </strong><span class="field-content">
                            <template v-if="payer.Relationship == 0">本人</template>
                            <template v-if="payer.Relationship == 1">配偶者</template>
                            <template v-if="payer.Relationship == 2">その他</template>
                          </span>
                        </div>
                        <div class="views-field views-field-value-4">
                          <strong class="views-label views-label-value-4">その他続柄: </strong><span class="field-content">{{ payer.RelationshipEtc }}</span>
                        </div>
                        <div class="views-field views-field-value-16">
                          <strong class="views-label views-label-value-16">郵便番号: </strong><span class="field-content">{{ payer.ZipPostalCode }}</span>
                        </div>
                        <div class="views-field views-field-value-17">
                          <strong class="views-label views-label-value-17">都道府県: </strong><span class="field-content">{{ payer.State }}</span>
                        </div>
                        <div class="views-field views-field-value-18">
                          <strong class="views-label views-label-value-18">市区町村: </strong><span class="field-content">{{ payer.City }}</span>
                        </div>
                        <div class="views-field views-field-value-19">
                          <strong class="views-label views-label-value-19">町名: </strong><span class="field-content">{{ payer.Line }}</span>
                        </div>
                        <div class="views-field views-field-value-20">
                          <strong class="views-label views-label-value-20">番地: </strong><span class="field-content">{{ payer.Line2 }}</span>
                        </div>
                        <div class="views-field views-field-value-21">
                          <strong class="views-label views-label-value-21">マンション名・部屋番号等: </strong><span class="field-content">{{ payer.LineOther }}</span>
                        </div>
                        <div class="views-field views-field-value-23">
                          <strong class="views-label views-label-value-23">日中ご連絡のつく電話番号（携帯電話等）: </strong><span class="field-content">{{ payer.Phone }}</span>
                        </div>
                        <div class="views-field views-field-value-1">
                          <strong class="views-label views-label-value-1">上記以外の連絡先（ご自宅の電話番号等）: </strong><span class="field-content">{{ payer.TelSub }}</span>
                        </div>
                      </template>
                      <template v-if="category == 9">
                        <div class="views-field views-field-php">
                          <strong class="views-label views-label-php">料金プラン（{{ contract.PowerAreaName }}）</strong>
                          <span class="field-content">{{ contract.PlanName }}</span>
                        </div>
                        <div class="views-field views-field-value-2">
                          <span class="field-content"><strong>電力容量:</strong>{{ contract.CapacityNumeric }} </span>
                        </div>
                        <div class="views-field views-field-value">
                          <strong class="views-label views-label-value">単位: </strong
                          ><span class="field-content">
                            <template v-if="contract.CapacityUnit == 0">A</template>
                            <template v-if="contract.CapacityUnit == 1">kVA</template>
                            <template v-if="contract.CapacityUnit == 2">kW</template>
                          </span>
                        </div>
                      </template>
                      <template v-if="category == 10">
                        <div class="views-field views-field-value-26">
                          <strong class="views-label views-label-value-26">料金明細書: </strong>
                          <span class="field-content" v-if="contract.DetailFlag == 0">Web明細に変更する</span>
                          <span class="field-content" v-else>紙明細（有料）に変更する</span>
                        </div>
                      </template>
                      <template v-if="category == 0">
                        <div class="views-field views-field-value-25"><strong class="views-label views-label-value-25">ご希望のお支払方法: </strong>
                          <span v-if="payment.payment == '04001-0'" class="field-content">請求書（後払い.com）</span>
                          <span v-if="payment.payment == '04002-0'" class="field-content">口座振替</span>
                          <span v-if="payment.payment == '04003-0'" class="field-content">クレジットカード</span>
                          <span v-if="payment.payment == '04003-1'" class="field-content">クレジットカード</span>
                        </div>
                      </template>
                      <template v-if="category == 16 && webform_inquiry">
                          <div class="views-field views-field-value-5"><strong class="views-label views-label-value-5">郵便番号: </strong><span class="field-content">{{ webform_inquiry.move_address_post_code }}</span></div>
                          <div class="views-field views-field-value-6"><strong class="views-label views-label-value-6">都道府県: </strong><span class="field-content">{{ webform_inquiry.move_address_prefectures }}</span></div>
                          <div class="views-field views-field-value-7"><strong class="views-label views-label-value-7">市区町村: </strong><span class="field-content">{{ webform_inquiry.move_address_municipalities }}</span></div>
                          <div class="views-field views-field-value-8"><strong class="views-label views-label-value-8">町名: </strong><span class="field-content">{{ webform_inquiry.move_address_townname }}</span></div>
                          <div class="views-field views-field-value-9"><strong class="views-label views-label-value-9">番地: </strong><span class="field-content">{{ webform_inquiry.move_address_housenum }}</span></div>
                          <div class="views-field views-field-value-10"><strong class="views-label views-label-value-10">マンション名・部屋番号等: </strong><span class="field-content">{{ webform_inquiry.move_address_builname_etc }}</span></div>
                      </template>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- /content-inner /content -->
        <template v-if="isLogin">
          <Sidebar_first_login />
        </template>
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Header_info from "../components/Header_info.vue";
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { loginStore, isLogin, removeData } from "../stores/store.js";
import { getData } from "../assets/js/request.js";
export default {
  components: {
    Header,
    Header_info,
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var login = loginStore();
    var roomNumber = "";
    if(isLogin()){
      roomNumber = login.room.room_number;
    }
    else if(this.$route.query.category != 16){
        this.toLogin();
      }
    return {
      isLogin: isLogin(),
      RoomNo: roomNumber,
      buildingIsOpen: true,
      isOpen: true,
      category: this.$route.query.category,
      webform_inquiry: {},
      contractor: {},
      payer: {},
      contract: {},
      payment: {},
    };
  },
  async created() {
    try{
      var category = this.$route.query.category;
      var date_time = this.$route.query.date_time;
      var birthday;
      if (category == 5) {
        var contractor = await getData(config.url.get.contractor + "?date_time=" + date_time);
        // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Phone, TelSub }
        birthday = new Date(contractor.Birthday);
        contractor.BirthdayString = birthday.getFullYear() + "年" + (birthday.getMonth() + 1) + "月" + birthday.getDate() + "日";
        if(contractor.BirthdayString == "1900年1月1日"){
          contractor.BirthdayString = "";
        }
        this.$data.contractor = contractor;
      } else if (category == 6) {
        var payer = await getData(config.url.get.payer + "?date_time=" + date_time);
        // { CustomerID, Name, NameKana, Birthday, ZipPostalCode, State, City, Line, Line2, LineOther, Relationship, RelationshipEtc, Phone, TelSub }
        birthday = new Date(payer.Birthday);
        payer.BirthdayString = birthday.getFullYear() + "年" + (birthday.getMonth() + 1) + "月" + birthday.getDate() + "日";
        if(payer.BirthdayString == "1900年1月1日"){
          payer.BirthdayString = "";
        }
        this.$data.payer = payer;
      } else if (category == 9 || category == 10) {
        var contract = await getData(config.url.get.contract + "?date_time=" + date_time);
        // { PlanCode, PlanName, PowerAreaId, DetailFlag, CapacityNumeric, CapacityUnit }
        var powerAreaName = {
          8: "北海道地区",
          9: "東北地区",
          10: "東京地区",
          11: "中部地区",
          12: "北陸地区",
          13: "関西地区",
          14: "四国地区",
          15: "中国地区",
          16: "九州地区",
          17: "沖縄地区",
        };
        contract["PowerAreaName"] = powerAreaName[contract.PowerAreaId];
        this.$data.contract = contract;
      } else if (category == 0) {
        var payment = await getData(config.url.get.payment + "?date_time=" + date_time);
        this.$data.payment = payment;
      } else if (category == 16){
        var guid = this.$route.query.guid;
        var webform_inquiry = {};
        webform_inquiry = await getData(config.url.get.inquiry + "?guid=" + guid);
        var move_scheduled_date = new Date(webform_inquiry.move_scheduled_date);
        var key_delivery_return_day = new Date(webform_inquiry.key_delivery_return_day);
        webform_inquiry.move_scheduled_date_string = move_scheduled_date.getFullYear() + "年" + (move_scheduled_date.getMonth() + 1) + "月" + move_scheduled_date.getDate() + "日";
        webform_inquiry.key_delivery_return_day_string = key_delivery_return_day.getFullYear() + "年" + (key_delivery_return_day.getMonth() + 1) + "月" + key_delivery_return_day.getDate() + "日";
        this.$data.webform_inquiry = webform_inquiry;
      } else {
        this.$route.push({ path: "/mansion/error" });
      }
    }catch(ex){
      if(ex["response"]["status"] == 401 || ex["response"]["status"] == 403){
        this.toLogin();
        return;
      }else{
        this.$route.push({ path: "/mansion/error" });
      }
    }
  },
  methods: {
    toLogin: function(){
      removeData("token");
      removeData("x-token");
      removeData("last_login_datetime");
      loginStore().$reset();
      sessionStorage.clear();
      var query = this.$route.query;
      query["destination"] = encodeURIComponent("/mansion/member/submission");
      this.$router.push({path: "/mansion/", query : query});
    }
  }
};
</script>
<template>
    <aside id="sidebar-first">
      <!-- /block -->
      <div class="block block-ennevision-mypage left-change-theme" data-bid="237">
        <h3 class="title">テーマの切り替え</h3>
  
        <div class="change_theme">
          <div class="ennevision">
            <a href="#" @click.prevent.stop="change(undefined)"><span>ennevision</span></a>
          </div>
          <div class="ennevision_green">
            <a href="#" @click.prevent.stop="change('green')"><span>ennevision</span></a>
          </div>
          <div class="ennevision_red">
            <a href="#" @click.prevent.stop="change('red')"><span>ennevision</span></a>
          </div>
        </div>
      </div>
      <!-- /block -->
    </aside>
    <!-- /sidebar-first -->
  </template>
  <script>
  import $ from "jquery";
  import config from "../config.js"
  import { getData } from "../assets/js/request.js";
  export default {
    name: "Sidebar_first_login",
    data(){
      var color = localStorage.getItem("color");
      this.change(color);
      return { 
        banner : [],
        color : color,
        isMenuOpen: false
      };
    },
    async created(){
      var banner = await getData(config.url.get.banner);
      this.$data.banner = banner;
    },
    methods : {
      change: function (color) {
        this.$data.color = color;
        if(this.$data.color != 'red' && this.$data.color != 'green'){
          //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').removeAttr('disabled');
          $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
          $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
        }
        if(this.$data.color == 'red'){
          $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').removeAttr('disabled');
          //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').attr('disabled', 'disabled');
          $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
        }
        if(this.$data.color == 'green'){
          $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').removeAttr('disabled');
          //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').attr('disabled', 'disabled');
          $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
        }
        localStorage.setItem("color", color);
      }
    }
  };
  </script>
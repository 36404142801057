<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="html not-front logged-in one-sidebar sidebar-first page-node page-node- page-node-82 node-type-webform responsive-menus-load-processed">
    <Header_login />
    <!-- ______________________ MAIN _______________________ -->
    <div id="main">
      <div class="container">
        <h2 class="element-invisible">現在地</h2>
        <div class="breadcrumb">
          <span class="inline odd first"><router-link to="/mansion/">ホーム</router-link></span> <span class="delimiter">»</span> <span class="inline even last">確認画面</span>
        </div>
        <div class="messages error" v-if="auth_error">
          <h2 class="element-invisible">エラーメッセージ</h2>
          入力したパスワードに誤りがあります。正しいパスワードを入力し、送信ボタンをクリックしてください。<br>パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link>
        </div>
        <section id="content">
          <!-- <div id="content-header"> -->
          <h1 class="title">確認画面</h1>
          <div class="block block-system" data-bid="255">
            <div class="enne_help">
              <router-link to="/mansion/faq/oshietetaikyo" target="_blank">FAQ</router-link>
            </div>
          </div>
          <!-- /block -->
          <!-- </div> /#content-header -->
          <div id="content-area">
            <article class="node node-webform" data-nid="82">
              <header><span property="dc:title" content="変更手続き申請フォーム" class="rdf-meta element-hidden"></span><span property="sioc:num_replies" content="0" datatype="xsd:integer" class="rdf-meta element-hidden"></span></header>
              <div class="content">
                <form class="webform-client-form webform-client-form-82 preview" action="#" method="post" id="webform-client-form-82" accept-charset="UTF-8" @submit.prevent="push()">
                  <div>
                    <div class="webform-progressbar"></div>
                    <div class="messages warning"><p>入力内容をご確認の上、よろしければ送信ボタンをクリックして下さい。</p></div>

                    <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info form-wrapper">
                      <legend><span class="fieldset-legend">お引越しの情報</span></legend>
                      <div class="fieldset-wrapper">
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--field-moving-schedule webform-container-inline">
                          <label>お引越し予定日 </label>
                          {{ webform_inquiry.move_scheduled_date_year + "年" + webform_inquiry.move_scheduled_date_month + "月" + webform_inquiry.move_scheduled_date_date + "日" }}
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--field-next-key-delivery webform-container-inline">
                          <label>鍵のお引渡（ご返却）日 </label>
                          {{ webform_inquiry.key_delivery_return_day_year + "年" + webform_inquiry.key_delivery_return_day_month + "月" + webform_inquiry.key_delivery_return_day_date + "日" }}
                        </div>
                        <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--field-contact-information">
                          <label>日中ご連絡の取れる電話番号 </label>
                          <span class="field-prefix"></span> {{ webform_inquiry.day_contact_tel_num }} <span class="field-suffix"></span>
                        </div>
                        <fieldset class="webform-component-fieldset webform-component--webform-group-departure-info--webform-group-moving-address form-wrapper">
                          <legend><span class="fieldset-legend">お引越し先住所</span></legend>
                          <div class="fieldset-wrapper">
                            <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-zipcode">
                              <label>郵便番号 </label>
                              <span class="field-prefix"></span> {{ webform_inquiry.move_address_post_code }} <span class="field-suffix"></span>
                            </div>
                            <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-pref">
                              <label>都道府県 </label>
                              <span class="field-prefix"></span> {{ webform_inquiry.move_address_prefectures }} <span class="field-suffix"></span>
                            </div>
                            <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-city">
                              <label>市区町村 </label>
                              <span class="field-prefix"></span> {{ webform_inquiry.move_address_municipalities }} <span class="field-suffix"></span>
                            </div>
                            <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-town">
                              <label>町名 </label>
                              <span class="field-prefix"></span> {{ webform_inquiry.move_address_townname }} <span class="field-suffix"></span>
                            </div>
                            <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-house-number">
                              <label>番地 </label>
                              <span class="field-prefix"></span> {{ webform_inquiry.move_address_housenum }} <span class="field-suffix"></span>
                            </div>
                            <div class="form-item webform-component webform-component-display webform-component--webform-group-departure-info--webform-group-moving-address--field-moving-address-other">
                              <label>マンション名・部屋番号等 </label>
                              <span class="field-prefix"></span> {{ webform_inquiry.move_address_builname_etc }} <span class="field-suffix"></span>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </fieldset>
                    <div class="form-item form-type-password form-item-auth-pass">
                      <label for="pass">パスワードを入れてください。<span class="form-required" title="このフィールドは必須です。">*</span> </label>
                      <input type="password" id="pass" name="auth_pass" size="60" maxlength="128" class="form-text" v-model="password"/>
                      <div class="description">パスワードをお忘れの方は<router-link to="/mansion/user/password" target="_blank">こちら</router-link ></div>
                    </div>
                    <div class="form-actions"><input formnovalidate="formnovalidate" class="webform-previous form-submit" type="submit" name="op" value="戻る" @click.prevent.stop="back()" :disabled="send"/><input class="webform-submit button-primary form-submit" type="submit" name="op" value="送信" @click="check" :disabled="send"/></div>
                  </div>
                </form>
              </div>
              <!-- /content -->
              <div class="links"></div>
              <!-- /links -->
            </article>
            <!-- /article #node -->
          </div>
        </section>
        <!-- /content-inner /content -->
        <Sidebar_first_login />
      </div>
    </div>
    <!-- /main -->
    <Footer />
  </div>
</template>
<script>
import Header_login from "../components/Header_login.vue";
import Sidebar_first_login from "../components/Sidebar_first_login.vue";
import Footer from "../components/Footer.vue";
import config from "../config.js";
import { departureStore } from "../stores/store.js";
import { post } from "../assets/js/request.js";
export default {
  components: {
    Header_login,
    Sidebar_first_login,
    Footer,
  },
  data() {
    var store = departureStore();
    if (Object.keys(store.update).length == 0) {
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "departure",
        },
      });
    }else{
      var update = store.update;
      store.$reset();
      return {
        auth_error: false,
        send: false,
        customer : update.customer,
        webform_inquiry: update.webform_inquiry,
        password: ""
      };
    }
  },
  methods: {
    back: function () {
      var store = departureStore();
      store.update = this.$data;
      this.$router.push({
        path: "/mansion/member/procedure",
        query: {
          type: "departure",
        },
      });
    },
    check : function(){
      document.querySelectorAll("input").forEach(function(input) {
        input.setCustomValidity("");
      });
      var password = document.querySelector("#pass");
      if (this.$data.password == "") {
        password.setCustomValidity("パスワード認証 は必須です。");
      }
    },
    push: async function () {
      var store = departureStore();
      var update = this.$data.webform_inquiry;
      update.type = 3; 
      update.content = ""; 
      update.individual_corporation = "0"; 
      update.company_name = "";
      update.department_name = "";
      update.director = "";
      update.name = "";
      update.name_kana = "";
      update.building_name = "";
      update.room_number_etc = "";
      update.e_mail = "";
      update.need_proof_electric_bill_from = "";
      update.need_proof_electric_bill_to = "";
      update.issuance_fee = "";
      update.post_code = "";
      update.delivery_address = "";
      update.delivery_name = "";
      update.how_did_you_know_about_us = "";
      update.other = "";
      try{
        this.$data.send = true;
        this.$data.auth_error = false;
        await post(config.url.post.inquiry, {
            webform_inquiry : update,
            password: this.$data.password
        });
        this.$router.push({
          path: "/mansion/member/procedure",
          query: {
            type: "departure_complete",
          },
        });
        store.update = { 
          webform_inquiry: this.$data.webform_inquiry
        };
      }catch(ex){
        if(ex.response.data == "PasswordError"){
          this.$data.send = false;
          this.$data.auth_error = true;
          document.body.scrollIntoView();
        }else{
          throw ex;
        }
      }
      return false;
    },
  },
};
import "../assets/js/webform.js";
</script>

<style scoped src="../assets/css/webform.css" />

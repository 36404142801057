import { createRouter, createWebHistory } from 'vue-router';

// ログイン前
import Top_page from "@/pages/Top_page";
import Faq_top from "@/pages/Faq_top";
import Faq_category_list from "@/pages/Faq_category_list"
import Entry from "@/pages/Entry";
import Entry_kakunin from "@/pages/Entry_kakunin";
import Newentry from "@/pages/Newentry";
import Newentry_confirm from "@/pages/Newentry_confirm";
import Newentry_complete from "@/pages/Newentry_complete";
import Inquiry from "@/pages/Inquiry";
import Inquiry_document from "@/pages/Inquiry_document"
import Inquiry_confirm from "@/pages/Inquiry_confirm"
import Inquiry_complete from "@/pages/Inquiry_complete"
import User_password from "@/pages/User_password";
import Create_mailaddress from "@/pages/Create_mailaddress"
import Create_password from "@/pages/Create_password"
import Password_mail from "@/pages/Password_mail"
import Portalmail_confirm from "@/pages/Portalmail_confirm"

// ログイン前後共通
import Faq_search from "@/pages/Faq_search"
import Faq_contents from "@/pages/Faq_contents"
import Column_list from "@/pages/Column_list";
import Contents from "@/pages/Contents"
import Search_result from "@/pages/Search_result";
import Static_contents from "@/pages/Static_contents";
import Document_download from "@/pages/Document_download";
import Submission from "@/pages/Submission";

// ログイン後
import Top_page_login from "@/pages/Top_page_login";
import Member_profile from "@/pages/Member_profile";
import Condition from "@/pages/Condition";
import Info from "@/pages/Info";
import Info_contents from "@/pages/Info_contents"
import Claim from "@/pages/Claim";
import Change_payment from "@/pages/Change_payment";
import Change_payment_error from "@/pages/Change_payment_error"
import Change_payment_confirm from "@/pages/Change_payment_confirm"
import Change_payment_complete from "@/pages/Change_payment_complete"
import Password_setting from "@/pages/Password_setting";
import Portalmail_setting from "@/pages/Portalmail_setting";
import Portalmail_setting_confirm from "@/pages/Portalmail_setting_confirm";
import Member_procedure from "@/pages/Member_procedure";
import Dr_agreement from "@/pages/Dr_agreement";
import Logout from "@/pages/Logout";

// エラー
import Page500 from "@/pages/Page500";
import AccessError from "@/pages/AccessError";

const routes = [
    // エラー
    {
      path: "/:catchAll(.*)",
      name: "Static_contents",
      component: Static_contents, // 静的コンテンツ
    },
    {
      path: "/mansion/404",
      name: "404",
      component: Static_contents, // エラー画面
    },
    {
      path: "/mansion/error",
      name: "AplicationError",
      component: Page500, // エラーページへ
    },
    {
      path: "/mansion/access_error",
      name: "AccessError",
      component: AccessError, // エラーページへ
    },
    // ログイン前
    {
      path: "/mansion/",
      name: "top_page",
      component: Top_page, // トップページ(ログイン前)
    },
    {
      path: "/mansion/entry",
      name: "Entry",
      component: Entry // 電気の利用申込み（新規申込メール送信まで）
    },
    {
      path: "/mansion/entry/kakunin",
      name: "Entry_kakunin",
      component: Entry_kakunin // 電気の利用申込み（新規申込メール送信まで）
    },
    {
      path: "/mansion/newentry",
      name: "Newentry",
      component: Newentry // 電気の利用申込み（新規申込メール送信まで）
    },
    {
      path: "/mansion/newentry_confirm",
      name: "Newentry_confirm",
      component: Newentry_confirm // 電気の利用申込み確認（新規申込メール送信まで）
    },
    {
      path: "/mansion/newentry_complete",
      name: "Newentry_complete",
      component: Newentry_complete // 電気の利用申込み完了（新規申込メール送信まで）
    },
    {
      path: "/mansion/inquiry",
      name: "Inquiry",
      component: Inquiry // お問い合わせ
    },
    {
      path: "/mansion/inquiry_document",
      name: "Inquiry_document",
      component: Inquiry_document // お問い合わせ
    },
    {
      path: "/mansion/inquiry_confirm",
      name: "Inquiry_confirm",
      component: Inquiry_confirm // お問い合わせ
    },
    {
      path: "/mansion/inquiry_complete",
      name: "Inquiry_complete",
      component: Inquiry_complete // お問い合わせ
    },
    {
      path: "/mansion/user/password",
      name: "User_password",
      component: User_password // ID・パスワードをお忘れの方へ(パスワード再発行画面)
    },
    {
      path: "/mansion/password/mail",
      name: "Password_mail",
      component: Password_mail // パスワード変更（メールから遷移）
    },
    {
      path: "/mansion/confirm_mail",
      name: "Portalmail_confirm",
      component: Portalmail_confirm // お客様メールアドレスの確認
    },
    {
      path: "/mansion/create/mailaddress",
      name: "Create_mailaddress",
      component: Create_mailaddress // 初回ガイダンス（メールアドレス登録）
    },
    {
      path: "/mansion/create/password",
      name: "Create_password",
      component: Create_password // 初回ガイダンス(パスワード登録)
    },
    {
      path: "/mansion/search",
      name: "Search_result",
      component: Search_result // 検索結果画面
    },
    // ログイン前後共通
    {
      path: "/mansion/faq_top",
      name: "Faq_top",
      component: Faq_top // FAQ一覧
    },
    {
      path: "/mansion/faq_category_list/:id",
      name: "Faq_category_list",
      component: Faq_category_list // FAQ一覧
    },
    {
      path: "/mansion/faq_search",
      name: "Faq_search",
      component: Faq_search // FAQ検索結果ページ
    },
    {
      path: "/mansion/faq/:id",
      name: "Faq_contents",
      component: Faq_contents // FAQ一覧
    },
    {
      path: "/mansion/column_list",
      name: "Column_list",
      component: Column_list // コラム一覧画面
    },
    {
      path: "/mansion/column_list/:id",
      name: "Column",
      component: Contents  // コラム画面
    },
    {
      path : "/mansion/document_download",
      name : "Document_download",
      component : Document_download // パンフレット・資料ダウンロード画面
    },
    {
      path: "/mansion/member/submission",
      name: "Submission",
      component: Submission, // 
    },
    // ログイン後,
    {
      path: "/mansion/login",
      name: "Top_page_login",
      component: Top_page_login // トップページ（ログイン後）
    },
    {
      path: "/mansion/member/profile",
      name: "Member_profile",
      component: Member_profile // 会員情報画面/
    },
    {
      path: "/mansion/condition",
      name: "Condition",
      component: Condition // 電力の見える化(詳細)
    },
    {
      path: "/mansion/info",
      name: "Info",
      component: Info // お知らせ一覧
    },
    {
      path: "/mansion/info/:id",
      name: "Info_contents",
      component: Info_contents // お知らせ
    },
    {
      path: "/mansion/claim",
      name: "Claim",
      component: Claim // Web明細ダウンロード
    },
    {
      path: "/mansion/change/payment",
      name: "Change_payment",
      component: Change_payment // お支払方法の変更手続き
    },
    {
      path: "/mansion/change/payment_error",
      name: "Change_payment_error",
      component: Change_payment_error // お支払方法の変更手続きエラー
    },
    {
      path: "/mansion/change/payment_confirm",
      name: "Change_payment_confirm",
      component: Change_payment_confirm // お支払方法の変更手続き確認
    },
    {
      path: "/mansion/change/payment_complete",
      name: "Change_payment_complete",
      component: Change_payment_complete // お支払方法の変更手続き完了
    },
    {
      path: "/mansion/password/setting",
      name: "Password_setting",
      component: Password_setting // パスワードリセット
    },
    {
      path: "/mansion/portalmail/setting",
      name: "Portalmail_setting",
      component: Portalmail_setting // メールアドレス変更
    },
    {
      path: "/mansion/portalmail/setting_confirm",
      name: "Portalmail_setting_confirm",
      component: Portalmail_setting_confirm // メールアドレス変更確認
    },
    {
      path: "/mansion/member/procedure",
      name: "Member_procedure",
      component: Member_procedure, // 各種ユーザ情報変更
    },
    {
      path: "/mansion/dr/agreement",
      name: "Dr_agreement",
      component: Dr_agreement, // DR利用許諾
    },
    {
      path: "/mansion/user/logout",
      name: "Logout",
      component: Logout, // ログアウト
    }
  ];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
          selector: to.hash,
          offset: { x: 0, y: 0 }
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
});
export default router;



export default {
    auth : {
        google_url: "https://Adb2cMemsProdJp.b2clogin.com/Adb2cMemsProdJp.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_LOGIN&client_id=5241a87f-25cc-4533-8b0d-1e121804a3ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.denki-annai.com%2Fmansion%2F&scope=openid&response_type=id_token&prompt=login&domain_hint=google.com&state=login",
        microsoft_url : "https://Adb2cMemsProdJp.b2clogin.com/Adb2cMemsProdJp.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_LOGIN&client_id=5241a87f-25cc-4533-8b0d-1e121804a3ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.denki-annai.com%2Fmansion%2F&scope=openid&response_type=id_token&prompt=login&domain_hint=live.com&state=login",
        amazon_url: "https://Adb2cMemsProdJp.b2clogin.com/Adb2cMemsProdJp.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_LOGIN&client_id=5241a87f-25cc-4533-8b0d-1e121804a3ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.denki-annai.com%2Fmansion%2F&scope=openid&response_type=id_token&prompt=login&domain_hint=amazon.com&state=login"
    },
    signup : {
        google_url: "https://Adb2cMemsProdJp.b2clogin.com/Adb2cMemsProdJp.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SIGNUP&client_id=5241a87f-25cc-4533-8b0d-1e121804a3ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.denki-annai.com%2Fmansion%2F&scope=openid&response_type=id_token&prompt=login&domain_hint=google.com&state=signup",
        microsoft_url : "https://Adb2cMemsProdJp.b2clogin.com/Adb2cMemsProdJp.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SIGNUP&client_id=5241a87f-25cc-4533-8b0d-1e121804a3ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.denki-annai.com%2Fmansion%2F&scope=openid&response_type=id_token&prompt=login&domain_hint=live.com&state=signup",
        amazon_url: "https://Adb2cMemsProdJp.b2clogin.com/Adb2cMemsProdJp.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SIGNUP&client_id=5241a87f-25cc-4533-8b0d-1e121804a3ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.denki-annai.com%2Fmansion%2F&scope=openid&response_type=id_token&prompt=login&domain_hint=amazon.com&state=signup"
    },
    url :{
        post : {
            contract_all : "/entryinfo/EntryInfoNewContract",
            payment : "/externalpaymentlinkager/PaymentMethodInfoUpdate",
            password : "/updatepassword/execUpdate", 
            inquiry : "/entryinfo/EntryInfoInquiry",
            contractor : "/entryinfo/EntryInfoContractorInfo",
            contract : "/entryinfo/EntryInfoContractInfo",
            payer : "/entryinfo/EntryInfoPayerInfo",
            fee : "/entryinfo/EntryInfoContractInfo",
            confirm_mail : "/entryinfo/EntryInfoNewContract",
            password_mail : "/updatepassword/execUpdate", 
            certification : "/authuser/AuthUserID"
        },
        get : {
            building : "/entryinfo/EntryInfoBuildingInfo",
            payment : "/entryinfo/EntryInfoPaymentMethodInfo", 
            payment_info : "/getscreenconfigurationinformation/PaymentInfo",
            details_info : "/getscreenconfigurationinformation/DetailsInfo",
            claim : "/filedownloadcustomerportal/execDownload?containerName=web-statemens-file",
            column_list : "/DistElementsColumnSearch",
            column : "/DistElementsColumnSearch/",
            summary : "/getscreenconfigurationinformation/GetSummary",
            download : "/filedownloadcustomerportal/execDownload?containerName=static-contents&blobName=/content",
            faq_list : "/DistElementsQASearch",
            faq : "/DistElementsQASearch/",
            info_list : "/DistElementsContentsSearch",
            info : "/DistElementsContentsSearch/",
            zipcode : "/addresssearch/Search",
            customer : "/entryinfo/EntryInfoCustomerInfo",
            contractor : "/entryinfo/EntryInfoContractorInfo",
            payer : "/entryinfo/EntryInfoPayerInfo",
            fee : "/entryinfo/EntryInfoContractInfo",
            contract : "/entryinfo/EntryInfoContractInfo",
            plan : "/getscreenconfigurationinformation/Plan",
            search : "/DistElementsKeywordSearch",
            content : "/mansion/static-contents/content/file",
            payment_station : "/externalpaymentlinkager/PaymentMethodChangeRequest",
            dr_info : "/getscreenconfigurationinformation/DRInfo",
            day : "/getscreenconfigurationinformation/GetDetail/day",
            month : "/getscreenconfigurationinformation/GetDetail/month",
            year : "/getscreenconfigurationinformation/GetDetail/year",
            user : "/getscreenconfigurationinformation/GetDetail/user",
            week : "/getscreenconfigurationinformation/GetDetail/week",
            banner : "/DistElementsBannerSearch",
            inquiry : "/entryinfo/EntryInfoInquiry"
        },
        put : {
            dr : "/entryinfo/EntryInfoCustomerInfo",
            mail : "/entryinfo/EntryInfoCustomerInfo",
        },
    },
    access_allow_room_type : ["2", "4", "6"]
}
import { defineStore } from "pinia"
export const mainMessage = defineStore('mainMessage', {
    state: () => ({ green: 0}) // 0:表示しない
});
export const payerStore = defineStore('payerStore', {
    state: () => ({ now: {}, update: {}})
});
export const payerTelStore = defineStore('payerTelStore', {
    state: () => ({ now: {}, update: {}})
});
export const contractorStore = defineStore('contractorStore', {
    state: () => ({ now: {}, update: {}})
});
export const contractorTelStore = defineStore('contractorTelStore', {
    state: () => ({ now: {}, update: {}})
});
export const paymentStore = defineStore('paymentStore', {
    state: () => ({ now: {}, update: {}})
});
export const mailStore = defineStore('mailStore', {
    state: () => ({ now: {}, update: {}})
});
export const entryStore = defineStore('entryStore', {
    state: () => ({ now: {}, update: {}})
});
export const departureStore = defineStore('departureStore', {
    state: () => ({ now: {}, update: {}})
});
export const feedetailStore = defineStore('feedetailStore', {
    state: () => ({ now: {}, update: {}})
});
export const rateplanStore = defineStore('rateplanStore', {
    state: () => ({ now: {}, update: {}})
});
export const inquiryStore = defineStore('inquiryStore', {
    state: () => ({ now: {}, update: {}})
});
export const loginStore = defineStore('loginStore', {
    state: () => ({ rooms: [], room: {}, customer_id : "", code : -1})
});
export const infoStore = defineStore('loginStore', {
    state: () => ({ notlogin: undefined, login: undefined})
});
export const paymentTimestamp = defineStore('paymentTimestamp', {
    state: () => ({ value: []})
});
export const tokenStore = defineStore('tokenStore', {
    state: () => ({ data: {}}),
    actions: {
        isLogin() {
          return this.data["x-token"] || this.data["token"];
        },
        saveData(key, value) {
            this.data[key] = value;
        },
        loadData(key) {
            return this.data[key];
        },
        removeData(key) {
            delete this.data[key];
        }
    },
    persist: true
});
export const isLogin = function(){
    return tokenStore().isLogin();
}
export const saveData = function(key, value){
    tokenStore().saveData(key, value);
}
export const loadData = function(key){
    return tokenStore().loadData(key);
}
export const removeData  = function(key){
    tokenStore().removeData(key);
}